import React, {useState, useEffect} from "react";
import "./style.css";
import {logout} from "../../../Redux/actions/userActions";
import {useDispatch} from "react-redux";
import Modal from "../Modal-ConfirmAlert";
import {fetchOrder} from "../../../Redux/actions/orderAction";
import { v4 as uuidv4 } from 'uuid';
import API from "../../../utils/API";

import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
});

function InputModal({
    title,
    showModal,
    closeModalHandler,
    orderID,
    orderNumber,
}){
  const [/*userID*/, setUserID] = useState("");
  const [theArtNr, setTheArtNr] = useState("");
  const [theQuantity, setTheQuantity] = useState("1");
  const [theTicketNr, setTheTicketNr] = useState("");
  const [showTheModal, setShowTheModal] = useState(false);
  const [theTitle, setTheTitle] = useState("");
  const [theProductName, setTheProductName] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    const GetUserName = async () => {
      await API.getUserId()
          .then((response) => {
            if (response.status === 200) {
              setUserID(response.data);
            } else if (response.status >= 300) {
              setShowTheModal(true);
              setTheTitle(`${response.data.message} - ${response.data.details.message}`);
            } else if (response.status === 401) {
              dispatch(logout());
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void GetUserName();
  }, [localStorage.getItem("token"), dispatch]);

  const closeTheModalHandler = () => setShowTheModal(false);

  /* Remove for now until API is implemented
  const validateOrder = async (orderNumber) => {
    await API.validateOrder(orderNumber)
        .then((response) => {
          if(response.status === 200){
            setShowTheModal(true);
            setTheTitle("Validering av order okej");
          } else if (response.status >= 300) {
            setShowTheModal(true);
            setTheTitle(`${response.data.message} - ${response.data.details}`);
          } else if (response.status === 401) {
            dispatch(logout());
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
  };
   */

  const addNewOrderRow = (e) => {

    e.preventDefault();

    const getProduct = async (theArtNr) => {
      await API.getArticle(theArtNr)
          .then((response) => {
            if (response.status === 200){
              const data = [
                {
                  LineNumber: uuidv4(),
                  PartNo: response.data.ArticleNumber,
                  QtyOrdered: theQuantity,
                  DiscountPercentage: "",
                  DiscountAmount: 0,
                  LineAmount: (theQuantity * response.data.SalePrice),
                  VatRate: "",
                  errandNo: theTicketNr,
                  isCustom: true,
                }
              ];

              API.updateOrderItems(data, orderID).then((response) => {
                if (response.status === 200){
                  setTheArtNr("");
                  setTheQuantity("1");
                  dispatch(fetchOrder(encodeURI(orderNumber))).then(() => {
                      document.getElementsByClassName("orderForm__content")[0].scrollIntoView({block: "end", behavior: "instant"});
                  });
                } else if (response.status === 401) {
                  dispatch(logout());
                }
              });
            } else if (response.status >= 300) {
              setTheTitle(`${response.data.message} - ${response.data.details}`);
              setShowTheModal(true);
            } else if (response.status === 401) {
              dispatch(logout());
            }
            // validateOrder(orderNumber);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    if (theArtNr !== "")
      void getProduct(theArtNr);

  }

  const searchAndGetArticleName = (e) => {
    e.preventDefault();

    if (theArtNr !== "") {
      const getProduct = async (theArtNr) => {
        await API.getArticle(theArtNr)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.ProductName && response.data.ProductName !== "")
                  setTheProductName(response.data.ProductName);
              } else if (response.status >= 300 && response.status !== 404) {
              } else if (response.status === 401) {
                dispatch(logout());
              } else if (response.status === 404) {
                setTheProductName("Produkten hittades inte");
              }
            })
            .catch((error) => {
              console.log("Error: ", error);
            });
      };
      void getProduct(theArtNr);
    }
  }

  const saveOrderRowAndCloseModal = (e, closeModalHandler) => {

    e.preventDefault();

    if (theArtNr !== "")
      addNewOrderRow(e);

    dispatch(fetchOrder(encodeURI(orderNumber))).then(() => {
      document.getElementsByClassName("orderForm__content")[0].scrollIntoView({block: "end", behavior: "instant"});
    });
    closeModalHandler();
  }

  const reloadOrderAndCloseModal = (e, closeModalHandler) => {

    e.preventDefault();

    dispatch(fetchOrder(encodeURI(orderNumber))).then(() => {
      document.getElementsByClassName("orderForm__content")[0].scrollIntoView({block: "end", behavior: "instant"});
    });
    closeModalHandler();
  }

  return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Dialog
              className="modal"
              open={showModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={closeModalHandler}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
          >
            <DialogActions className="modal__topHeader">
              {" "}
              <span onClick={(e) => reloadOrderAndCloseModal(e, closeModalHandler)} className="modal__closeBtn">
              x
            </span>
            </DialogActions>
            <DialogTitle className="modal__header">{title}: {theArtNr !== "" ? theProductName : ""}</DialogTitle>

            <DialogContent className="modal__addNewOrderRow__body">
              <Modal
                  showModal={showTheModal}
                  closeModalHandler={closeTheModalHandler}
                  title={theTitle}
                  buttonName="Stäng"
              />
              <TextField
                  className="modal__addNewOrderRow__input"
                  label="Artikelnummer"
                  variant="outlined"
                  name="theArtNr"
                  fullWidth
                  onChange={(e) => setTheArtNr(e.target.value)}
                  value={theArtNr}
              >
              </TextField>
              <button onClick={(e) => searchAndGetArticleName(e)} className="modal__addNewOrderRow__fetchProductNamebutton">Hämta artikelnamn från artikelnummer</button>
              <TextField
                  className="modal__addNewOrderRow__input"
                  label="Antal"
                  variant="outlined"
                  name="theQuantity"
                  fullWidth
                  onChange={(e) => setTheQuantity(e.target.value)}
                  value={theQuantity}
              >
              </TextField>
              <TextField
                  className="modal__addNewOrderRow__input"
                  label="Ärendenummer i Super Office"
                  variant="outlined"
                  name="theTicketNr"
                  fullWidth
                  onChange={(e) => setTheTicketNr(e.target.value)}
                  value={theTicketNr}
              >
              </TextField>
            </DialogContent>
              <div className="modal__addNewOrderRow__buttonDiv">
                    <button onClick={(e) => addNewOrderRow(e, closeModalHandler)} className="modal__addNewOrderRow__button">
                      Spara
                    </button>
                    <button onClick={(e) => saveOrderRowAndCloseModal(e, closeModalHandler)} className="modal__addNewOrderRow__button">
                      Spara & stäng
                    </button>
              </div>
          </Dialog>
        </MuiThemeProvider>
      </div>
  );
}

export default InputModal;

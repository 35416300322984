import API from "../../utils/API";

// data from handleInput in Login.js
export const login = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "LOGIN_REQUEST",
    });
    if (!Object.keys(data).length)
      return;
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: data,
    });
    if (data.token !== undefined && data.token !== null)
      localStorage.setItem("token", JSON.stringify(data.token));
  } catch (error) {
    dispatch({
      type: "LOGIN_FAIL",
      payload: error,
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    await API.postLogout();
  } catch(e) {
    console.log("Error during logout");
    console.log(e);
  }
  localStorage.clear();
  dispatch({ type: "USER_LOGOUT" });
};

export const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
};

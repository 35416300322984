import React, {useEffect, useRef, useState} from "react";
import "./BG.css";
import SelectReact from 'react-select';
import Modal from "../../components/Modals/Modal-ConfirmAlert";
import ModalConfirmWithButtons from "../../components/Modals/Modal-ConfirmWithButtons";
import ModalBG from "../../components/Modals/Modal-BG";
import API from "../../utils/API";

function BG() {
    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState("");
    const [titleConfirmWithButtonsModal, /*setTitleConfirmWithButtonsmModal*/] = useState("");
    const [showConfirmWithButtonsModal, setShowConfirmWithButtonsModal] = useState(false);
    const [showBGModal, setShowBGModal] = useState(false);
    const [companiesOptions, setCompaniesOptions] = useState([]);
    const [/*theOrderType*/, setTheOrderType] = useState("");
    const [/*theSortType*/, setTheSortType] = useState("");
    const [/*theCompanyName*/, setTheCompanyName] = useState("");
    const [theCompanyID, setTheCompanyID] = useState("");
    const [bgID, setBgID] = useState("");
    const [bgName, setBgName] = useState("");
    const [includeApprovedBGs, setIncludeApprovedBGs] = useState(false);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [filteredOrdersPagewise, setFilteredOrdersPagewise] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [confirm, setConfirm] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const selectCompanyRef = useRef(null);

    const closeModalHandler = () => setShowModal(false);
    const closeConfirmWithButtonsModalHandler = () => setShowConfirmWithButtonsModal(false);
    const closeBGModalHandler = () => setShowBGModal(false);

    const forceClearSelect = () => {
        if (selectCompanyRef.current) {
            selectCompanyRef.current.clearValue();
            selectCompanyRef.current.inputValue = "";
        }
    };

    const customStyles = {
        control: (base) => ({
            ...base,
            height: "30px",
            width: "450px",
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
        }),
    };

    const getBGorders = async () => {
        await API.getBGApproval(theCompanyID, includeApprovedBGs, "", "")
            .then((response) => {
                if(response.status === 200) {
                    const chunkSize = 10;
                    const chunkList = [];

                    for (let i = 0; i < response.data.length; i += chunkSize) {
                        chunkList.push(response.data.slice(i, i + chunkSize));
                    }
                    setFilteredOrders(response.data);
                    setFilteredOrdersPagewise(chunkList);
                } else {
                    setFilteredOrders([]);
                    setFilteredOrdersPagewise([]);
                }
                setLoadingSpinner(false);
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    useEffect(() => {
        const getAllCompanies = async () => {
            await API.getCompany("")
                .then((response) => {
                    let companies = [];

                    companies.push({ value: "", label: "Välj företag *", isDisabled: true });
                    response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((company) => {
                        companies.push({ value: company._id, label: company.name });
                    });
                    setCompaniesOptions(companies);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllCompanies();
    }, []);

    const fetchBGorders = (e) => {
        e.preventDefault();

        setLoadingSpinner(true);
        void getBGorders();

    }

    const cleanUpFilter = (e) => {
        e.preventDefault();

        setTheOrderType("");
        setTheCompanyName("");
        setTheCompanyID("");
        setIncludeApprovedBGs(false);
        setTheSortType("");
        forceClearSelect();
        setFilteredOrders([]);
        setFilteredOrdersPagewise([]);
    }

    const handleOnChangeCompany = (selectedOption) => {

        if (selectedOption){
            setTheCompanyName(selectedOption.name);
            setTheCompanyID(selectedOption.value);
        } else {
            setTheCompanyName("");
            setTheCompanyID("");
        }
    }

    const handleBG = (theBgID, bgName) => {
        setBgID(theBgID);
        setBgName(bgName)
        setShowBGModal(true);
        setTitle("BG");
    }

    const clickedPage = (e) => {
        e.preventDefault();

        let clickedPageButton = e.target;

        setCurrentPage(clickedPageButton.value);
    }

    useEffect(() => {
        let allOrders = document.getElementsByClassName("BG__order");

        if (confirm) {
            for (let order of allOrders) {
                if (order.checked) {
                    console.log(order);
                }
            }
            setConfirm(false);
        }
    }, [confirm]);

    useEffect(() => {
        setLoadingSpinner(true);
        void getBGorders();
    },[])

    useEffect(() => {
        setLoadingSpinner(true);
        void getBGorders();
    }, [showBGModal]);

    return (
        <div className="BG">
            <form id="ordersForm">
                <hr />
                <div className="BG__filter">
                    <ModalConfirmWithButtons
                        showModal={showConfirmWithButtonsModal}
                        closeModalHandler={closeConfirmWithButtonsModalHandler}
                        setConfirmHandler={setShowConfirmWithButtonsModal}
                        title={titleConfirmWithButtonsModal}
                        buttonName="Stäng"
                    />
                    <Modal
                        showModal={showModal}
                        closeModalHandler={closeModalHandler}
                        title={title}
                        buttonName="Stäng"
                    />
                    <ModalBG
                        showBGModal={showBGModal}
                        closeBGModalHandler={closeBGModalHandler}
                        title={title}
                        buttonName="Stäng"
                        bgID={bgID}
                        bgName={bgName}
                    />
                    <div className="BG__filterOpt">
                        {/*
                        <input
                                className="BG__inputText"
                                placeholder="Sök"
                                type="text"
                                name="inputText"
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                        />*/}
                        <div className="BG__filterInputContainer">
                            <div className="BG__filterSearchDiv">
                                <h6>Företag:</h6>
                                <SelectReact
                                    className="itSupport__select BG__filterSearchSelect"
                                    name="companies"
                                    onChange={handleOnChangeCompany}
                                    options={companiesOptions}
                                    styles={customStyles}
                                    ref={selectCompanyRef}
                                    isClearable
                                    menuPortalTarget={document.body}
                                />
                                <input onClick={() => setIncludeApprovedBGs(!includeApprovedBGs)} type="checkbox" id="includeApprovedBGs" name="includeApprovedBGs" value="" checked={includeApprovedBGs}/>
                                <p className="BG__checkboxText" >Inkludera godkända BGn</p>
                                <button className="BG__searchBtn" type="submit" onClick={(e) => fetchBGorders(e)}>Sök via filter</button>
                            </div>
                        </div>
                        <button onClick={cleanUpFilter} className="BG__cleanUpFilterButton"><span>Rensa filter</span></button>
                    </div>
                </div>
            </form>
            <div className="BG__orderList">
                { loadingSpinner && <div className="BG__loadingDiv"><h1>Laddar</h1><div className="BG__loader"></div></div> }
                <p className="BG__searchResultText">Sökresultat: {filteredOrders.length > 0 ? filteredOrders.length : 0} ordrar</p>
                <div className="BG__tableContainer">
                    <table className="BG__orderListUsers">
                        <tbody>
                            <tr>
                                {/* <th><h5>Välj</h5></th> */}
                                <th><h5><b>Namn</b></h5></th>
                                <th><h5><b>Skapat</b></h5></th>
                                <th><h5><b>BG godkänt</b></h5></th>
                                <th><h5><b>KAL skickat</b></h5></th>
                            </tr>
                            {filteredOrdersPagewise.length > 0 && filteredOrdersPagewise[(currentPage-1)].map((bg, index) => (
                                <>
                                    <div className="BG__horizontalLine"></div>
                                    <tr className="BG__bgRows" key={index} onClick={() => handleBG(bg._id, bg.name)}>
                                        {/* <td><p><input className="BG__order" type="checkbox" id={bg._id} name={bg._id} value={bg._id} /></p></td> */}
                                        <td><p className="BG__bgName">{bg.name}</p></td>
                                        <td><p>{bg.datecreated.substring(0, 10)}</p></td>
                                        <td><p>{bg.approved ? "JA" : "NEJ"}</p></td>
                                        <td><p>{bg.kalsent ? "JA" : "NEJ"}</p></td>
                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </table>
                    <div className="BG__pageButtonsDiv">
                        {parseInt(currentPage ? currentPage : "") === 1 && filteredOrdersPagewise.length === 1 && (
                            <>
                                <button key={"page-1"} className="BG__pageButton BG__currentPage" value={1}>{1}</button>
                            </>
                        )}
                        {parseInt(currentPage ? currentPage : "") === 1 && filteredOrdersPagewise.length > 1 && (
                            <>
                                <button key={"page-1"} onClick={(e) => clickedPage(e)} className="BG__pageButton BG__currentPage" value={1}>{1}</button>
                                <button key={"page-next"} onClick={(e) => clickedPage(e)} className="BG__pageButton" value={(parseInt(currentPage ? currentPage : "")+1)}>{">"}</button>
                                <button key={"page-last"} onClick={(e) => clickedPage(e)} className="BG__pageButton" value={filteredOrdersPagewise.length}>{">|"}</button>
                            </>
                        )}
                        {parseInt(currentPage ? currentPage : "") > 1 && filteredOrdersPagewise.length > 1 && parseInt(currentPage ? currentPage : "") !== 1 && parseInt(currentPage ? currentPage : "") !== filteredOrdersPagewise.length && (
                            <>
                                <button key={"page-first"} onClick={(e) => clickedPage(e)} className="BG__pageButton" value={1}>{"|<"}</button>
                                <button key={"page-prev"} onClick={(e) => clickedPage(e)} className="BG__pageButton" value={(parseInt(currentPage ? currentPage : "")-1)}>{"<"}</button>
                                <button key={"page-current"} onClick={(e) => clickedPage(e)} className="BG__pageButton BG__currentPage" value={currentPage}>{currentPage}</button>
                                <button key={"page-next"} onClick={(e) => clickedPage(e)} className="BG__pageButton" value={(parseInt(currentPage ? currentPage : "")+1)}>{">"}</button>
                                <button key={"page-last"} onClick={(e) => clickedPage(e)} className="BG__pageButton" value={filteredOrdersPagewise.length}>{">|"}</button>
                            </>
                        )}
                        {parseInt(currentPage ? currentPage : "") === filteredOrdersPagewise.length && parseInt(currentPage ? currentPage : "") > 1 && filteredOrdersPagewise.length > 1 && (
                            <>
                                <button key={"page-first"} onClick={(e) => clickedPage(e)} className="BG__pageButton" value={1}>{"|<"}</button>
                                <button key={"page-prev"} onClick={(e) => clickedPage(e)} className="BG__pageButton" value={(parseInt(currentPage ? currentPage : "")-1)}>{"<"}</button>
                                <button key={"page-current"} onClick={(e) => clickedPage(e)} className="BG__pageButton BG__currentPage" value={currentPage}>{currentPage}</button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BG;

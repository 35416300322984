import React, {useEffect} from "react";
import "./DisplayOrder.css";
import OrderForm from "../../../components/OrderForm";
import Header from "../../../components/Header";
import { fetchOrder } from "../../../Redux/actions/orderAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';

function DisplayOrder() {
  const {id} = useParams();
  const dispatch = useDispatch();
  const { orderInfo } = useSelector((state) => state.order);
  const orderFormContainer = document.getElementsByClassName("displayOrder")[0];

  useEffect(() => {
      if (id !== "" || null)
          void dispatch(fetchOrder(encodeURI(id)));
      },[id, dispatch]);

  useEffect(() => {

        if(orderInfo && orderInfo[0]?.OrderHead.MarkedForDeletion)
            orderFormContainer?.classList.add("displayOrder__formContainerMarkedForDeletion");
        else {
            orderFormContainer?.classList.remove("displayOrder__formContainerMarkedForDeletion");
        }
    }, [orderInfo]);

  return (
    <div className="displayOrder">
      <Header title={`Order ${id}`} />
      <div className="displayOrder__larsson__div">
        <OrderForm orderInfo={orderInfo} />
      </div>
    </div>
  );
}

export default DisplayOrder;

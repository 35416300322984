import React, {useEffect, useRef, useState} from "react";
import "./Orderhantering.css";
//import Header from "../components/Header";
import Modal from "../../components/Modals/Modal-ConfirmAlert";
import { fetchOrder } from "../../Redux/actions/orderAction";
import { logout } from "../../Redux/actions/userActions";
import { useDispatch } from "react-redux";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DatePicker from "react-datepicker";
import {sv} from 'date-fns/esm/locale'
import moment from "moment";
import API from "../../utils/API";
import SelectReact from "react-select";

function Orderhantering() {

  const [number, setNumber] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [theShop, setTheShop] = useState("");
  const [theOrderType, setTheOrderType] = useState("");
  const [theCompany, setTheCompany] = useState("");
  const [theStartDate, setTheStartDate] = useState("");
  const [theEndDate, setTheEndDate] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filteredOrdersPagewise, setFilteredOrdersPagewise] = useState([]);
  const [expandedFilteredOrderList, setExpandedFilteredOrderList] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [shopsOptions, setShopsOptions] = useState([]);
  const [/*theCompanyName*/, setTheCompanyName] = useState("");
  const [theCompanyID, setTheCompanyID] = useState("");
  const [/*theShopName*/, setTheShopName] = useState("");
  const [theShopID, setTheShopID] = useState("");
  const [numberOfOrders, setNumberOfOrders] = useState("");
  const dispatch = useDispatch();

  const selectOrdertypeRef = useRef(null);
  const selectCompanyRef = useRef(null);
  const selectShopRef = useRef(null);
  const currentPageRef = useRef(1);

  const customStyles = {
    control: (base) => ({
      ...base,
      position: "relative",
      left: "30px",
      height: "30px",
      width: "250px",
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  const forceClearSelect = () => {
    if (selectOrdertypeRef.current) {
      selectOrdertypeRef.current.clearValue();
      selectOrdertypeRef.current.inputValue = "";
    }
    if (selectCompanyRef.current) {
      selectCompanyRef.current.clearValue();
      selectCompanyRef.current.inputValue = "";
    }
    if (selectShopRef.current) {
      selectShopRef.current.clearValue();
      selectShopRef.current.inputValue = "";
    }
  };

  const closeModalHandler = () => setShowModal(false);

  useEffect(() => {

    const getAllCompanies = async () => {
      await API.getCompany("")
          .then((response) => {
            let companies = [];

            companies.push({ value: "", label: "Välj företag", isDisabled: true });
            response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((company) => {
              companies.push({ value: company._id, label: company.name });
            });
            setCompaniesOptions(companies);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void getAllCompanies();


    const getAllShops = async () => {
      await API.getShopList("")
          .then((response) => {
            let shops = [];

            shops.push({ value: "", label: "Välj butik", isDisabled: true });
            response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((shop) => {
              shops.push({ value: shop.Code, label: shop.Name });
            });
            setShopsOptions(shops);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void getAllShops();

    document.getElementsByClassName("orderhantering__inputText")[0].focus();
  }, []);

  const reloadOrders = async () => {
    setLoadingSpinner(true);

    const data = {
      byshop: theShopID,
      bycompany: theCompanyID,
      bytype: theOrderType === "Weborder" ? "web" : "company",
      fromdate: theStartDate ? moment(theStartDate).format("yyyy-MM-DD") : "",
      todate: theEndDate ? moment(theEndDate).format("yyyy-MM-DD") : "",
      skip: (currentPageRef.current - 1) * 10,
      limit: 10,
      querytype: "filteronly",
      search: "",
    };

    await API.getCollatedOrdersByFilter(data)
        .then((response) => {
          response.data.data.sort((a, b) => b.OrderDate.localeCompare(a.OrderDate));
          const chunkSize = 10;
          const chunkList = [];
          for (let i = 0; i < response.data.data.length; i += chunkSize) {
            chunkList.push(response.data.data.slice(i, i + chunkSize));
          }
          setLoadingSpinner(false);
          setFilteredOrders(response.data.data);
          setFilteredOrdersPagewise(chunkList);
          setNumberOfOrders(response.data.count);
          setLoadingSpinner(false);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
  };

  const fetchTheOrder = (e) => {
    e.preventDefault();

    let selectInput = document.getElementsByClassName("orderhantering__select")[0];

    setNumberOfOrders("");

    if (selectInput.value === "orderno") {
      if (number === "" || null) {
        setShowModal(true);
        setTitle("Ange nummer");
      } else if (isNaN(number)) {
        setShowModal(true);
        setTitle("Ett nummer kan bara innehålla siffror");
      } else {
        setLoadingSpinner(true);

        API.getOrder(number).then((response) => {
          if (response.status === 200) {
            /* validateOrder API call test
            API.validateOrder(response.data[0]).then((response) => {
              if(response.status === 200){
                console.log(response.data);
              } else {
                console.log(response.data);
              }
            });
             */
            setLoadingSpinner(false);
            setFilteredOrders(response.data);
            setFilteredOrdersPagewise([]);
            dispatch({
              type: "GET_ORDER_SUCCESS",
              payload: response.data,
            });
            if (expandedFilteredOrderList)
              setExpandedFilteredOrderList(false);
            document.getElementsByClassName("invoiceNumbers__inputInvoiceNumber")[0].focus();
          } else if (response.status === 401) {
            void dispatch(logout());
          } else if (response.status === 404) {
            setShowModal(true);
            setTitle("Ordern finns ej");
            setLoadingSpinner(false);
          } else if (response.status === 503) {
            setShowModal(true);
            setTitle("Servern kunde ej hämta ordern");
            setLoadingSpinner(false);
          }
        });
      }
    } else {
      void dispatch(fetchOrder(""));
      setLoadingSpinner(true);
      if (!expandedFilteredOrderList)
        setExpandedFilteredOrderList(true);

      setCurrentPage(1);

      const data = {
        byshop: theShopID,
        bycompany: theCompanyID,
        bytype: "",
        fromdate: theStartDate ? moment(theStartDate).format("yyyy-MM-DD") : "",
        todate: theEndDate ? moment(theEndDate).format("yyyy-MM-DD") : "",
        skip: 0,
        limit: 10,
        querytype: selectInput.value,
        search: number,
      };

      API.getCollatedOrdersByFilter(data).then((response) => {
        if (response.status === 200) {
          if(response.data.count === 0){
            setShowModal(true);
            setTitle("Ordrar finns ej");
          }
          const chunkSize = 10;
          const chunkList = [];
          for (let i = 0; i < response.data.data.length; i += chunkSize) {
            chunkList.push(response.data.data.slice(i, i + chunkSize));
          }
          setLoadingSpinner(false);
          setFilteredOrders(response.data.data);
          setFilteredOrdersPagewise(chunkList);
          setNumberOfOrders(response.data.count);
        } else if (response.status === 401) {
          void dispatch(logout());
        } else if (response.status === 404) {
          setShowModal(true);
          setTitle("Ordrar finns ej");
          setLoadingSpinner(false);
        } else if (response.status === 503) {
          setShowModal(true);
          setTitle("Servern kunde ej hämta ordrarna");
          setLoadingSpinner(false);
        }
      });
    }
  }

  const fetchTheOrders = (e) => {
    e.preventDefault();

    setNumberOfOrders("");

    //void dispatch(fetchOrder(""));

    if (theShop !== "" || theOrderType !== "" || theCompany !== "" || theStartDate !== "" || theEndDate !== "") {
      if (theStartDate && theEndDate) {
        let compareStartDate = new Date(theStartDate).getTime();
        let compareEndDate = new Date(theEndDate).getTime();
        if (compareStartDate > compareEndDate){
          setShowModal(true);
          setTitle("Startdatumet kan inte ligga efter slutdatumet");
          return;
        }
      }
      setLoadingSpinner(true);
      if (!expandedFilteredOrderList)
        setExpandedFilteredOrderList(true);

      setCurrentPage(1);

      const data = {
        byshop: theShopID,
        bycompany: theCompanyID,
        bytype: theOrderType === "Weborder" ? "web" : "company",
        fromdate: theStartDate ? moment(theStartDate).format("yyyy-MM-DD") : "",
        todate: theEndDate ? moment(theEndDate).format("yyyy-MM-DD") : "",
        skip: 0,
        limit: 10,
        querytype: "filteronly",
        search: "",
      };

      API.getCollatedOrdersByFilter(data).then((response) => {
        if (response.status === 200) {
          if(response.data.count === 0){
            setShowModal(true);
            setTitle("Ordrar finns ej");
          }
          const chunkSize = 10;
          const chunkList = [];
          for (let i = 0; i < response.data.data.length; i += chunkSize) {
            chunkList.push(response.data.data.slice(i, i + chunkSize));
          }
          setLoadingSpinner(false);
          setFilteredOrders(response.data.data);
          setFilteredOrdersPagewise(chunkList);
          setNumberOfOrders(response.data.count);
        } else if (response.status === 401) {
          void dispatch(logout());
        } else if (response.status === 404) {
          setShowModal(true);
          setTitle("Ordrar finns ej");
          setLoadingSpinner(false);
        } else if (response.status === 503) {
          setShowModal(true);
          setTitle("Servern kunde ej hämta ordrarna");
          setLoadingSpinner(false);
        }
      });
    } else {
      setShowModal(true);
      setTitle("Du måste välja datum att filtrera på");
    }
  }

  const cleanUpFilter = (e) => {
    e.preventDefault();

    setTheShop("");
    setTheOrderType("");
    setTheCompany("");
    setTheStartDate("");
    setTheEndDate("");
    void dispatch(fetchOrder(""));
    setFilteredOrders([]);
    setFilteredOrdersPagewise([]);
    forceClearSelect();
    document.getElementById("ordersForm") && document.getElementById("ordersForm").reset();
  }

  const clickedOrder = (e) => {
    e.preventDefault();

    dispatch(fetchOrder(encodeURI(e.target.getAttribute("value")))).then(() => {
      document.getElementById("orderHead") && document.getElementById("orderHead").scrollIntoView({behavior: "instant"});
    });
  }

  const clickedOrderRow = (e) => {
    e.preventDefault();

    if (e.target.parentNode.getElementsByTagName("a")[0])
      dispatch(fetchOrder(encodeURI(e.target.parentNode.getElementsByTagName("a")[0].getAttribute("value")))).then(() => {
        document.getElementById("orderHead") && document.getElementById("orderHead").scrollIntoView({behavior: "instant"});
      });
  }

  /*
  const clickedPage = (e) => {
    e.preventDefault();

    let clickedPageButton = e.target;

    setCurrentPage(clickedPageButton.value);
  }
   */

  const firstPage = (e) => {
    e.preventDefault();

    currentPageRef.current = 1;
    void reloadOrders();
  }

  const lastPage = (e) => {
    e.preventDefault();

    currentPageRef.current = Math.ceil((numberOfOrders / 10));
    void reloadOrders();
  }

  const previousPage = (e) => {
    e.preventDefault();

    currentPageRef.current = currentPageRef.current - 1;
    void reloadOrders();
  }

  const nextPage = (e) => {
    e.preventDefault();

    currentPageRef.current = currentPageRef.current + 1;
    void reloadOrders();
  }

  const clickedSortButtonOrderNumber = (e) => {
    let sortedOrders;
    const chunkSize = 10;
    let chunkList = [];

    e.preventDefault();

    if (e.target.innerText === "Stigande") {
      e.target.innerText = "Fallande";
      sortedOrders = filteredOrders.sort((a, b) => a.OrderNo - b.OrderNo);
    }
    else {
      e.target.innerText = "Stigande";
      sortedOrders = filteredOrders.sort((a, b) => b.OrderNo - a.OrderNo);
    }
    for (let i = 0; i < sortedOrders.length; i += chunkSize) {
      chunkList.push(sortedOrders.slice(i, i + chunkSize));
    }
    setFilteredOrdersPagewise(chunkList);
  }

  const clickedSortButtonOrderStatus = (e) => {
    let sortedOrders;
    const chunkSize = 10;
    let chunkList = [];

    e.preventDefault();

    if (e.target.innerText === "Öppen") {
      e.target.innerText = "Stängd";
      sortedOrders = filteredOrders.sort((a, b) => a.OrderStatusText.StatusText.localeCompare(b.OrderStatusText.StatusText));
    }
    else {
      e.target.innerText = "Öppen";
      sortedOrders = filteredOrders.sort((a, b) => b.OrderStatusText.StatusText.localeCompare(a.OrderStatusText.StatusText));
    }
    for (let i = 0; i < sortedOrders.length; i += chunkSize) {
      chunkList.push(sortedOrders.slice(i, i + chunkSize));
    }
    setFilteredOrdersPagewise(chunkList);
  }

  const clickedSortButtonAlphanumeric = (e) => {
    let sortedOrders;
    const chunkSize = 10;
    let chunkList = [];

    e.preventDefault();

    if (e.target.innerText === "A-Ö") {
      e.target.innerText = "Ö-A";
      sortedOrders = filteredOrders.sort((a, b) => a.FirstName.localeCompare(b.FirstName));
    }
    else {
      e.target.innerText = "A-Ö";
      sortedOrders = filteredOrders.sort((a, b) => b.FirstName.localeCompare(a.FirstName));
    }
    for (let i = 0; i < sortedOrders.length; i += chunkSize) {
      chunkList.push(sortedOrders.slice(i, i + chunkSize));
    }
    setFilteredOrdersPagewise(chunkList);
  }

  const clickedSortButtonDate = (e) => {
    let sortedOrders;
    const chunkSize = 10;
    let chunkList = [];

    e.preventDefault();

    if (e.target.innerText === "Stigande") {
      e.target.innerText = "Fallande";
      sortedOrders = filteredOrders.sort((a, b) => a.OrderDate.localeCompare(b.OrderDate));
    }
    else {
      e.target.innerText = "Stigande";
      sortedOrders = filteredOrders.sort((a, b) => b.OrderDate.localeCompare(a.OrderDate));
    }
    for (let i = 0; i < sortedOrders.length; i += chunkSize) {
      chunkList.push(sortedOrders.slice(i, i + chunkSize));
    }
    setFilteredOrdersPagewise(chunkList);
  }

  const clickedSortButtonInvoiced = (e) => {
    let sortedOrders;
    const chunkSize = 10;
    let chunkList = [];

    e.preventDefault();

    if (e.target.innerText === "Ja") {
      e.target.innerText = "Nej";
      sortedOrders = filteredOrders.sort((a, b) => a.isInvoiced?.toString().localeCompare(b.isInvoiced?.toString()));
    }
    else {
      e.target.innerText = "Ja";
      sortedOrders = filteredOrders.sort((a, b) => b.isInvoiced?.toString().localeCompare(a.isInvoiced?.toString()));
    }
    for (let i = 0; i < sortedOrders.length; i += chunkSize) {
      chunkList.push(sortedOrders.slice(i, i + chunkSize));
    }
    setFilteredOrdersPagewise(chunkList);
  }

  const clickedSortButtonShop = (e) => {
    let sortedOrders;
    const chunkSize = 10;
    let chunkList = [];

    e.preventDefault();

    if (e.target.innerText === "Stigande") {
      e.target.innerText = "Fallande";
      sortedOrders = filteredOrders.sort((a, b) => a.ShopName.localeCompare(b.ShopName));
    }
    else {
      e.target.innerText = "Stigande";
      sortedOrders = filteredOrders.sort((a, b) => b.ShopName.localeCompare(a.ShopName));
    }
    for (let i = 0; i < sortedOrders.length; i += chunkSize) {
      chunkList.push(sortedOrders.slice(i, i + chunkSize));
    }
    setFilteredOrdersPagewise(chunkList);
  }

  const handleOnChangeOrderType = (selectedOption) => {

    selectedOption ? setTheOrderType(selectedOption.value) : setTheOrderType("");
  }

  const handleOnChangeCompany = (selectedOption) => {

    if (selectedOption){
      setTheCompanyName(selectedOption.name);
      setTheCompanyID(selectedOption.value);
    } else {
      setTheCompanyName("");
      setTheCompanyID("");
    }
  }

  const handleOnChangeShop = (selectedOption) => {

    if (selectedOption){
      setTheShopName(selectedOption.name);
      setTheShopID(selectedOption.value);
    } else {
      setTheShopName("");
      setTheShopID("");
    }
  }

  const onEnterHandlerOrderNumber = (e) => {

    if (e.key === "Enter"){
      e.preventDefault();
      fetchTheOrder(e);
    }
  }

  return (
    <div className="orderhantering">
      {/* <Header title="Orderhantering" /> */}
      <form id="ordersForm">
        <div className="orderhantering__filter">
          <Modal
              showModal={showModal}
              closeModalHandler={closeModalHandler}
              title={title}
              buttonName="Stäng"
          />
          <div className="orderhantering__filterOpt">
            <div className="orderhantering__filterSearchDiv">
              <label>Ordertyp:</label>
              <SelectReact
                  className="itSupport__select orderhantering__filterSearchSelect"
                  name="ordertype"
                  onChange={handleOnChangeOrderType}
                  options={[{value: "Förmånsorder", label: "Förmånsorder"}, {value: "Weborder", label: "Weborder"}]}
                  styles={customStyles}
                  ref={selectOrdertypeRef}
                  menuPortalTarget={document.body}
                  defaultValue={{value: "Förmånsorder", label: "Förmånsorder"}}
                  isClearable
              />
              <label>Företag:</label>
              <SelectReact
                  className="itSupport__select orderhantering__filterSearchSelect"
                  name="companies"
                  onChange={handleOnChangeCompany}
                  options={companiesOptions}
                  styles={customStyles}
                  ref={selectCompanyRef}
                  menuPortalTarget={document.body}
                  isClearable
              />
              <label>Butik:</label>
              <SelectReact
                  className="itSupport__select orderhantering__filterSearchSelect"
                  name="shops"
                  onChange={handleOnChangeShop}
                  options={shopsOptions}
                  styles={customStyles}
                  ref={selectShopRef}
                  menuPortalTarget={document.body}
                  isClearable
              />
              <div className="orderhantering__customDatePickerWidth">
                <label>Startdatum:</label>
                <DatePicker
                    className="orderhantering__inputTypeDate"
                    dateFormat="yyyy-MM-dd"
                    selected={theStartDate}
                    onChange={(date) => setTheStartDate(date)}
                    id="orderStartDate"
                    name="orderStartDate"
                    locale={sv}
                    required
                />
              </div>
              <div className="orderhantering__customDatePickerWidth">
                <label>Slutdatum:</label>
                <DatePicker
                    className="orderhantering__inputTypeDate"
                    dateFormat="yyyy-MM-dd"
                    selected={theEndDate}
                    onChange={(date) => setTheEndDate(date)}
                    id="orderEndDate"
                    name="orderEndDate"
                    locale={sv}
                    required
                />
              </div>
              <button
                  className="orderhantering__filterSearchBtn"
                  onClick={(e) => fetchTheOrders(e)}
              >
                Sök via filter
              </button>
              <button onClick={cleanUpFilter} className="orderhantering__cleanUpFilterButton"><span>Rensa filter</span>
              </button>
            </div>
          </div>
        </div>
        <hr/>
        <div className="orderhantering__inputContainer">
          <select className="orderhantering__select">
            <option className="orderhantering__optionText" value="orderno" selected>
              Ordernummer
            </option>
            <option className="orderhantering__optionText" value="frameno">Ramnummer</option>
            <option className="orderhantering__optionText" value="errandno">Ärendenummer</option>
            <option className="orderhantering__optionText" value="invoiceno">Fakturanummer</option>
          </select>
          <input
              className="orderhantering__inputText"
              placeholder="Sök..."
              type="text"
              name="inputText"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              onKeyDown={(e) => onEnterHandlerOrderNumber(e)}
          />
          <button
              className="orderhantering__searchBtn"
              type="submit"
              onClick={(e) => fetchTheOrder(e)}
          >
            SÖK
          </button>
        </div>
      </form>
      {loadingSpinner && <div className="orderhantering__loadingDiv"><h1>Laddar</h1>
        <div className="orderhantering__loader"></div>
      </div>}
      {filteredOrders.length > 0 && (
          <>
            <div className="orderhantering__horizontalLine"></div>
            <div className="orderhantering__orderList">
              <div className="orderhantering__orderListExpandHead">
                {numberOfOrders && <h4>Sökresultat: {numberOfOrders} ordrar</h4>}
                {!numberOfOrders && <h4>Sökresultat:</h4>}
                {expandedFilteredOrderList ? (<button className="orderhantering__expandButtons"
                                                      onClick={() => setExpandedFilteredOrderList(false)}>
                  <ExpandLessIcon className="orderhantering__expandIcons"/></button>) : (
                    <button className="orderhantering__expandButtons"
                            onClick={() => setExpandedFilteredOrderList(true)}><ExpandMoreIcon
                        className="orderhantering__expandIcons"/></button>)}
              </div>
              {expandedFilteredOrderList && (
                  <>
                    <div className="orderhantering__tableContainer">
                      <table className="orderhantering__orderListOrders">
                        <tbody>
                        <tr className="orderhantering__rowColumnsHeader">
                          <th className="orderhantering__rowColumns orderhantering__rowColumnsHeader">
                            <h5><b>Ordernummer</b> <button className="orderhantering__sortButton"
                                                    onClick={(e) => clickedSortButtonOrderNumber(e)}>Stigande</button>
                            </h5>
                          </th>
                          <th className="orderhantering__rowColumns orderhantering__rowColumnsHeader">
                            <h5><b>Orderstatus</b> <button className="orderhantering__sortButton"
                                                    onClick={(e) => clickedSortButtonOrderStatus(e)}>Öppen</button></h5>
                          </th>
                          <th className="orderhantering__rowColumns orderhantering__rowColumnsHeader"><h5><b>Företag</b></h5>
                          </th>
                          <th className="orderhantering__rowColumns orderhantering__rowColumnsHeader">
                            <h5><b>Namn</b> <button className="orderhantering__sortButton"
                                             onClick={(e) => clickedSortButtonAlphanumeric(e)}>A-Ö</button></h5>
                          </th>
                          <th className="orderhantering__rowColumns orderhantering__rowColumnsHeader">
                            <h5><b>Datum</b> <button className="orderhantering__sortButton"
                                              onClick={(e) => clickedSortButtonDate(e)}>Stigande</button></h5>
                          </th>
                          <th className="orderhantering__rowColumns orderhantering__rowColumnsHeader">
                            <h5><b>Fakturanummer</b></h5></th>
                          <th className="orderhantering__rowColumns orderhantering__rowColumnsHeader">
                            <h5><b>Fakturastatus</b> <button className="orderhantering__sortButton"
                                                      onClick={(e) => clickedSortButtonInvoiced(e)}>Ja</button></h5>
                          </th>
                          <th className="orderhantering__rowColumns orderhantering__rowColumnsHeader">
                            <h5><b>Butik</b> <button className="orderhantering__sortButton"
                                              onClick={(e) => clickedSortButtonShop(e)}>Stigande</button></h5>
                          </th>
                        </tr>
                        {filteredOrdersPagewise.length > 0 && filteredOrdersPagewise[(currentPage - 1)].map((order, index) => (
                            <tr key={index} onClick={(e) => clickedOrderRow(e)}
                                className={`orderhantering__tr ${order?.isInvoiced ? "orderhantering__invoiced" : "orderhantering__notInvoiced"}`}>
                                <td className="orderhantering__rowColumns"><h4><p><a className="orderhantering__orderLink" value={order?.OrderNo} onClick={(e) => clickedOrder(e)}>{order?.OrderNo}</a></p></h4></td>
                                <td className="orderhantering__rowColumns"><h4>{order?.OrderStatusText && order?.OrderStatusText.StatusText}</h4></td>
                                <td className="orderhantering__rowColumns"><h4>{order?.CompanyName}</h4></td>
                                <td className="orderhantering__rowColumns"><h4>{`${order?.FirstName} ${order?.LastName}`}</h4></td>
                                { order?.NewOrderDate ? <td className="orderhantering__rowColumns"><h4>{order?.NewOrderDate.slice(0,10)}</h4></td> : <td className="orderhantering__rowColumns"><h4>{order?.OrderDate?.slice(0,10)}</h4></td> }
                                <td className="orderhantering__rowColumns">
                                    {order?.InvoiceNumbers?.length === 0 ? "-" : null}
                                    {order?.InvoiceNumbers?.length > 0 && order?.InvoiceNumbers.map((invoiceNumber, index, array) => (
                                        <h4>{invoiceNumber}{index !== (array.length-1) ? ", " : null}</h4>
                                    ))}
                                </td>
                                <td className="orderhantering__rowColumns"><h6 className="orderhantering__invoice">{order?.isInvoiced ? "Ja" : "Nej"}</h6></td>
                                <td className="orderhantering__rowColumns"><h4>{order?.ShopName}</h4></td>
                              </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="orderhantering__horizontalLine"></div>
                    { numberOfOrders <= 10 && (
                        <>
                          <button key={"page-1"} className="orderhantering__pageButton orderhantering__currentPage">{1}</button>
                        </>
                    )}
                    { numberOfOrders > 10 && parseInt(currentPageRef.current ? currentPageRef.current : "") === 1 && (
                        <>
                          <button key={"page-1"} onClick={(e) => firstPage(e)} className="orderhantering__pageButton orderhantering__currentPage">{1}</button>
                          <button key={"page-next"} onClick={(e) => nextPage(e)} className="orderhantering__pageButton">{">"}</button>
                          <button key={"page-last"} onClick={(e) => lastPage(e)} className="orderhantering__pageButton">{">|"}</button>
                        </>
                    )}
                    {parseInt(currentPageRef.current ? currentPageRef.current : "") > 1 && parseInt(currentPageRef.current ? currentPageRef.current : "") !== 1 && parseInt(currentPageRef.current ? currentPageRef.current : "") !== Math.ceil((numberOfOrders / 10)) && (
                        <>
                          <button key={"page-first"} onClick={(e) => firstPage(e)} className="orderhantering__pageButton">{"|<"}</button>
                          <button key={"page-prev"} onClick={(e) => previousPage(e)} className="orderhantering__pageButton">{"<"}</button>
                          <button key={"page-current"} className="orderhantering__pageButton orderhantering__currentPage">{currentPageRef.current}</button>
                          <button key={"page-next"} onClick={(e) => nextPage(e)} className="orderhantering__pageButton">{">"}</button>
                          <button key={"page-last"} onClick={(e) => lastPage(e)} className="orderhantering__pageButton">{">|"}</button>
                        </>
                    )}
                    { numberOfOrders > 10 && parseInt(currentPageRef.current ? currentPageRef.current : "") === Math.ceil((numberOfOrders / 10)) && (
                        <>
                          <button key={"page-first"} onClick={(e) => firstPage(e)} className="orderhantering__pageButton">{"|<"}</button>
                          <button key={"page-prev"} onClick={(e) => previousPage(e)} className="orderhantering__pageButton">{"<"}</button>
                          <button key={"page-current"} onClick={(e) => lastPage(e)} className="orderhantering__pageButton orderhantering__currentPage">{currentPageRef.current}</button>
                        </>
                    )}
                  </>
              )}
            </div>
          </>)}
          <div id="orderHead"></div>
    </div>
  );
}

export default Orderhantering;

import React, {useState, useEffect, useRef} from "react";
import "./style.css";
import Modal from "../Modal-ConfirmAlert";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {sv} from 'date-fns/esm/locale'
import moment from "moment";
import API from "../../../utils/API";

import {
    Dialog,
    DialogActions,
    DialogContent,
    Slide,
    TextField,
} from "@material-ui/core";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
});

function InputModal({
    title,
    showModal,
    closeModalHandler,
    setTheDemo,
    loadTheDemo,
}) {

  const [showTheModal, setShowTheModal] = useState(false);
  const [theTitle, setTheTitle] = useState("");
  const [theAddress, setTheAddress] = useState("");
  const [aComment, setAComment] = useState("");
  const [theStartDate, setTheStartDate] = useState("");
  const [theEndDate, setTheEndDate] = useState("");
  const [theStartTime, setTheStartTime] = useState("");
  const [theEndTime, setTheEndTime] = useState("");
  const [theAffectedShop, setTheAffectedShop] = useState("");
  const [theAffectedShopLabel, setTheAffectedShopLabel] = useState("");
  const [shopsSportsonOptions, setShopsSportsonOptions] = useState([]);
  const [shopsMapList, setShopsMapList] = useState([]);
  const [loadTheSportsonShops, setLoadTheSportsonShops] = useState([]);
  const [demos, setDemos]  = useState([]);

  const selectShopsRef = useRef(null);

  const closeTheModalHandler = () => setShowTheModal(false);

  const forceClearSelect = () => {

    if (selectShopsRef.current) {
      selectShopsRef.current.clearValue();
      selectShopsRef.current.inputValue = "";
    }
  };

  const handleOnChangeAffectedStores = (selectedOption) => {

    if (selectedOption) {
      setTheAffectedShop(selectedOption.value);
      setTheAffectedShopLabel(selectedOption.label);
    }
  }

  /* Remove not in use function for now
  const handleOnChangeAffectedStoresLoad = (selectedOption) => {

    setTheAffectedShop(selectedOption.value);
  }
   */

  const handleClearDemoFields = () => {
    setTheAddress("");
    setTheAffectedShopLabel("");
    setAComment("");
    setTheStartDate("");
    setTheEndDate("");
    setTheStartTime("");
    setTheEndTime("");
    forceClearSelect();
  }
  const handleSaveDemo = (e) => {
    e.preventDefault();

    if (theStartDate !== "" && theEndDate !== "") {
      let dateFrom = new Date(theStartDate);
      let dateTo = new Date(theEndDate);

      if (theStartTime)
        dateFrom.setHours(parseInt(theStartTime.slice(0, 2)), parseInt(theStartTime.slice(3, 5)));
      if (theEndTime)
        dateTo.setHours(parseInt(theEndTime.slice(0, 2)), parseInt(theEndTime.slice(3, 5)));

      const demo = {
        address: theAddress,
        comment: aComment,
        affected_shop: theAffectedShop,
        affected_shop_label: theAffectedShopLabel,
        time_from: moment(dateFrom).add(1, "hour").toISOString(),
        time_to: moment(dateTo).add(1, "hour").toISOString(),
        startDate: moment(theStartDate).format("yyyy-MM-DD"),
        endDate: moment(theEndDate).format("yyyy-MM-DD"),
        startTime: theStartTime,
        endTime: theEndTime,
      }
      setTheDemo(demo);
    }
    closeModalHandler();
  }

  const handleAddDemo = (e) => {
    e.preventDefault();

    let theDemos = demos;

    if (theStartDate === "" /* || theEndDate === "" */ || theStartTime === "" || theEndTime === "" || theAddress === "" || theAffectedShop === "") {
      setShowTheModal(true);
      setTheTitle("Var god och fyll i obligatoriska fält markerade med *");
      return;
    }

    if (theStartDate !== "" && theEndDate !== "") {
      let dateFrom = new Date(theStartDate);
      let dateTo = new Date(theEndDate);

      if (theStartTime)
        dateFrom.setHours(parseInt(theStartTime.slice(0, 2)), parseInt(theStartTime.slice(3, 5)));
      if (theEndTime)
        dateTo.setHours(parseInt(theEndTime.slice(0, 2)), parseInt(theEndTime.slice(3, 5)));

      const demo = {
        address: theAddress,
        comment: aComment,
        affected_shop: theAffectedShop,
        affected_shop_label: theAffectedShopLabel,
        time_from: moment(dateFrom).add(1, "hour").toISOString(),
        time_to: moment(dateTo).add(1, "hour").toISOString(),
        startDate: moment(theStartDate).format("yyyy-MM-DD"),
        endDate: moment(theEndDate).format("yyyy-MM-DD"),
        startTime: theStartTime,
        endTime: theEndTime,
      }

      theDemos.push(demo);
      setTheDemo(theDemos);
      setDemos(theDemos);
      handleClearDemoFields();
      setShowTheModal(true);
      setTheTitle("Demoevent tillagt");
    }
  }

  const handleRemoveLastAddedDemo = (e) => {
    e.preventDefault();

    let theDemos = demos;

    theDemos.pop();
    setTheDemo(theDemos);
    setDemos(theDemos);
    setShowTheModal(true);
    setTheTitle("Senast tillagda demoeventet är borttaget");
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      height: "56px",
      width: "852px",
      marginLeft: "-10px",
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      textAlign: "center"
    }),
  };

  useEffect(() => {
    const getAllShopsSportson = async () => {
      await API.getShopList("", "sportson")
          .then((response) => {
            let shopsSportson = [];
            shopsSportson.push({ value: "", label: "Välj din butik/dina butiker *", isDisabled: true });
            response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((shop) => {
              shopsSportson.push({ value: shop.Code, label: shop.Name });
            });
            setShopsSportsonOptions(shopsSportson);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void getAllShopsSportson();

    const getAllShopsMapList = async () => {
      await API.getShopList("", "")
          .then((response) => {
            let shops = [];
            response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((shop) => {
              shops.push({ value: shop.Code, label: shop.Name });
            });
            setShopsMapList(shops);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void getAllShopsMapList();

  }, []);

  useEffect(() => {
    const loadSportonShops = () => {

      let tempSportsonShops = [];
      let theLabel = "";

      setLoadTheSportsonShops([]);

      shopsMapList.forEach((shop) => {
        if (loadTheDemo[0] && shop.value === loadTheDemo[0].affected_shop) {
          theLabel = shop.label;
          tempSportsonShops.push({value: loadTheDemo[0].affected_shop, label: theLabel});
        }
      });
      setLoadTheSportsonShops(tempSportsonShops);
    }
    loadSportonShops();

  }, [loadTheDemo[0]]);

  useEffect(() => {
    handleClearDemoFields();
    setDemos(loadTheDemo);
  }, [loadTheDemo]);

  useEffect(() => {
    setTheEndDate(theStartDate);
  }, [theStartDate]);

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          className="modal"
          open={showModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeModalHandler}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogActions className="modal__topHeader">
            {" "}
            <span onClick={closeModalHandler} className="modal__closeBtn">
              x
            </span>
          </DialogActions>

          <DialogContent className="modal__demo__body">
            <Modal
                showModal={showTheModal}
                closeModalHandler={closeTheModalHandler}
                title={theTitle}
                buttonName="Stäng"
            />
            <div className="modal__demo">
              <h2>{title}</h2>
              {loadTheDemo.length === 0 && (
                  <>
                    <div className="modal__demo__datesDiv">
                      <label className="modal__demo__date" htmlFor="demoStartDate">Datum *</label>
                      <DatePicker
                          className="modal__demo__date modal__demo__dateInputFields"
                          dateFormat="yyyy-MM-dd"
                          selected={theStartDate}
                          onChange={(date) => setTheStartDate(date)}
                          id="demoStartDate"
                          name="demoStartDate"
                          locale={sv}
                          required
                      />
                    </div>
                  {/*<div className="modal__demo__datesDiv">
                      <label className="modal__demo__date" htmlFor="demoStartDate">Startdatum *</label>
                      <DatePicker
                          className="modal__demo__date modal__demo__dateInputFields"
                          dateFormat="yyyy-MM-dd"
                          selected={theStartDate}
                          onChange={(date) => setTheStartDate(date)}
                          id="demoStartDate"
                          name="demoStartDate"
                          locale={sv}
                          required
                      />
                      <label className="modal__demo__date" htmlFor="demoEndDate">Slutdatum *</label>
                      <DatePicker
                          className="modal__demo__date modal__demo__dateInputFields"
                          dateFormat="yyyy-MM-dd"
                          selected={theEndDate}
                          onChange={(date) => setTheEndDate(date)}
                          id="demoEndDate"
                          name="demoEndDate"
                          locale={sv}
                          required
                      />
                    </div>*/}
                    <div className="modal__demo__timesDiv">
                      <div>
                        <label className="modal__demo__time" htmlFor="demoStartTime">Starttid *</label>
                        <input className="modal__demo__timeInputFields" value={theStartTime} onChange={(e) => setTheStartTime(e.target.value)} type="time" id="demoStartTime" name="demoStartTime" required/>
                      </div>
                      <div>
                        <label className="modal__demo__time" htmlFor="demoEndTime">Sluttid *</label>
                        <input className="modal__demo__timeInputFields" value={theEndTime} onChange={(e) => setTheEndTime(e.target.value)} type="time" id="demoEndTime" name="demoEndTime" required/>
                      </div>
                    </div>
                    <TextField
                        label="Adress"
                        variant="outlined"
                        name="Address"
                        fullWidth
                        multiline
                        minRows={4}
                        InputProps={{
                          className: "modal__demo__textFields",
                        }}
                        onChange={(e) => setTheAddress(e.target.value)}
                        value={theAddress}
                        required
                    >
                    </TextField>
                    <h6>Ansvarig butik *:</h6>
                    <Select
                        className="itSupport__select"
                        name="affectedShop"
                        onChange={handleOnChangeAffectedStores}
                        isClearable
                        options={shopsSportsonOptions}
                        styles={customStyles}
                        ref={selectShopsRef}
                        menuPortalTarget={document.body}
                        required
                    >
                    </Select>
                    <TextField
                        label="Kommentar till ansvarig butik/övrig info"
                        variant="outlined"
                        name="Comment"
                        fullWidth
                        multiline
                        minRows={4}
                        InputProps={{
                          className: "modal__demo__textFields",
                        }}
                        onChange={(e) => setAComment(e.target.value)}
                        value={aComment}
                    >
                    </TextField>
                  </>)}
              {loadTheDemo.length > 0 && (
                  <>
                    <div className="modal__demo__datesDiv">
                      <label className="modal__demo__date" htmlFor="demoStartDate">Datum *</label>
                      <DatePicker
                          className="modal__demo__date modal__demo__dateInputFields"
                          dateFormat="yyyy-MM-dd"
                          selected={theStartDate ? theStartDate : new Date(loadTheDemo[0].time_from.substring(0, 10))}
                          onChange={(date) => setTheStartDate(date)}
                          id="demoStartDate"
                          name="demoStartDate"
                          locale={sv}
                          required
                      />
                    </div>
                  {/*<div className="modal__demo__datesDiv">
                      <label className="modal__demo__date" htmlFor="demoStartDate">Startdatum *</label>
                      <DatePicker
                          className="modal__demo__date modal__demo__dateInputFields"
                          dateFormat="yyyy-MM-dd"
                          selected={theStartDate ? theStartDate : new Date(loadTheDemo[0].time_from.substring(0, 10))}
                          onChange={(date) => setTheStartDate(date)}
                          id="demoStartDate"
                          name="demoStartDate"
                          locale={sv}
                          required
                      />
                      <label className="modal__demo__date" htmlFor="demoEndDate">Slutdatum *</label>
                      <DatePicker
                          className="modal__demo__date modal__demo__dateInputFields"
                          dateFormat="yyyy-MM-dd"
                          selected={theEndDate ? theEndDate : new Date(loadTheDemo[0].time_to.substring(0, 10))}
                          onChange={(date) => setTheEndDate(date)}
                          id="demoEndDate"
                          name="demoEndDate"
                          locale={sv}
                          required
                      />
                    </div>*/}
                    <div className="modal__demo__timesDiv">
                      <div>
                        <label className="modal__demo__date" htmlFor="demoStartDate">Starttid *</label>
                        <input className="modal__demo__timeInputFields" onChange={(e) => setTheStartTime(e.target.value)} defaultValue={loadTheDemo[0].time_from.substring(11, 16)}  type="time" id="demoStartTime" name="demoStartTime" required/>
                      </div>
                      <div>
                        <label className="modal__demo__date" htmlFor="demoEndDate">Sluttid *</label>
                        <input className="modal__demo__timeInputFields" onChange={(e) => setTheEndTime(e.target.value)} defaultValue={loadTheDemo[0].time_to.substring(11, 16)} type="time" id="demoEndTime" name="demoEndTime" required/>
                      </div>
                      </div>
                    <TextField
                        label="Adress"
                        variant="outlined"
                        name="Address"
                        fullWidth
                        multiline
                        minRows={4}
                        InputProps={{
                          className: "modal__demo__textFields",
                        }}
                        onChange={(e) => setTheAddress(e.target.value)}
                        defaultValue={loadTheDemo[0].address}
                        required
                    >
                    </TextField>
                    <h6>Ansvarig butik *:</h6>
                    {loadTheSportsonShops.length === 0 && <Select
                        className="itSupport__select"
                        name="affectedShop"
                        isClearable
                        options={shopsSportsonOptions}
                        styles={customStyles}
                        onChange={handleOnChangeAffectedStores}
                        ref={selectShopsRef}
                        menuPortalTarget={document.body}
                        required
                    >
                    </Select>}
                    {loadTheSportsonShops.length > 0 && <Select
                        className="itSupport__select"
                        name="affectedShop"
                        isClearable
                        options={shopsSportsonOptions}
                        styles={customStyles}
                        onChange={handleOnChangeAffectedStores}
                        defaultValue={loadTheSportsonShops}
                        ref={selectShopsRef}
                        menuPortalTarget={document.body}
                    >
                    </Select>}
                    <TextField
                        label="Kommentar"
                        variant="outlined"
                        name="Comment"
                        fullWidth
                        multiline
                        minRows={4}
                        InputProps={{
                          className: "modal__demo__textFields",
                        }}
                        onChange={(e) => setAComment(e.target.value)}
                        defaultValue={loadTheDemo[0].comment}
                    >
                    </TextField>
                  </>)}
              {demos && demos.length > 0 && demos.map((demoEvent) => (
                  <>
                    <h5><b>Demoevent</b></h5>
                    <h6>Adress: {demoEvent.address}</h6>
                    <h6>Kommentar: {demoEvent.comment}</h6>
                    {shopsMapList && shopsMapList.map((shop) => (
                        (shop.value === demoEvent.affected_shop && (<h6>Butik: {shop.label}</h6>))
                    ))}
                    <h6>Datum: {demoEvent.time_from.substring(0, 10)}</h6>
                    <h6>Starttid: {demoEvent.time_from.substring(11, 16)}</h6>
                    <h6>Sluttid: {demoEvent.time_to.substring(11, 16)}</h6>
                  </>
              ))}
            </div>
            <div className="modal__demo__buttonDiv">
              <button onClick={(e) => handleRemoveLastAddedDemo(e)} className="modal__demo__button">
                Ta bort det senast tillagda demoeventet
              </button>
              <button onClick={(e) => handleAddDemo(e)} className="modal__demo__button">
                Spara och skapa ett till demoevent
              </button>
              <button onClick={(e) => handleSaveDemo(e)} className="modal__demo__button">
                Spara och stäng
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

export default InputModal;

import React from "react";
import "./DisplayBenefitWindow.css";
import Header from "../../../components/Header";
import { useParams } from 'react-router-dom';
import BenefitWindow from "../../../components/BenefitWindow/BenefitWindow";

function DisplayBenefitWindow() {
  const {id} = useParams();

  return (
    <div className="displayBenefitWindow">
      <Header title={`Beställningsfönster`} />
      <div className="displayBenefitWindow__larsson__div">
          <BenefitWindow id={id} />
      </div>
    </div>
  );
}

export default DisplayBenefitWindow;

import React from "react";
import "./DisplayCompany.css";
import Header from "../../../components/Header";
import { useParams } from 'react-router-dom';
import Company  from "../../../components/Company/Company";

function DisplayCompany() {
  const {id} = useParams();

  return (
    <div className="displayCompany">
      <Header title={`Bolag`} />
      <div className="displayCompany__larsson__div">
          {id && id !== "" && <Company id={id} />}
      </div>
    </div>
  );
}

export default DisplayCompany;

import React, { useState } from "react";
import "./style.css";
import FormLabel from "@material-ui/core/FormLabel";
import { Typography } from "@material-ui/core";

function RadioButtons({ checked }) {
  const [checkedValue, setCheckedValue] = useState("");

  const selectedOption = (e) => {
    setCheckedValue(e.target.value);
    checked(e);
  };

  return (
    <div>
      <FormLabel className="itSupport__label" component="legend">
        Hur allvarligt är problemet?
        <Typography>
          * 1 = Återkom vid tillfälle
        </Typography>
        <Typography>
          * 5 = Stopp i försäljningen, HÖG PRIO! Ring 010 - 303 62 50
        </Typography>
      </FormLabel>
      <div className="test">
        <label className="radioButton">
          1
          <input
            type="radio"
            value="1"
            checked={checkedValue === "1"}
            onChange={selectedOption}
          />
        </label>
        <label className="radioButton">
          2
          <input
            type="radio"
            value="2"
            checked={checkedValue === "2"}
            onChange={selectedOption}
          />
        </label>
        <label className="radioButton">
          3
          <input
            type="radio"
            value="3"
            checked={checkedValue === "3"}
            onChange={selectedOption}
          />
        </label>
        <label className="radioButton">
          4
          <input
            type="radio"
            value="4"
            checked={checkedValue === "4"}
            onChange={selectedOption}
          />
        </label>
        <label className="radioButton">
          5
          <input
            type="radio"
            value="5"
            checked={checkedValue === "5"}
            onChange={selectedOption}
          />
        </label>
      </div>
    </div>
  );
}

export default RadioButtons;

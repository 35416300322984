import React, {useState, useEffect} from "react";
import "./style.css";
import {logout} from "../../../Redux/actions/userActions";
import {useDispatch} from "react-redux";
import Modal from "../Modal-ConfirmAlert";
import API from "../../../utils/API";

import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
});

function InputModal({
    title,
    buttonName,
    showModal,
    closeModalHandler,
    windowID,
    commentID,
    commentText,
}) {
  const [/*userID*/, setUserID] = useState("");
  const [theComment, setTheComment] = useState("");
  const [showTheModal, setShowTheModal] = useState(false);
  const [theTitle, setTheTitle] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if(showModal) {
      setTheComment(commentText);
      document.getElementsByClassName("modal__editComment__input")[0]?.children[1]?.children[0]?.focus();
    }
  }, [showModal]);

  useEffect(() => {
    const GetUserName = async () => {
      await API.getUserId()
          .then((res) => {
            setUserID(res.data);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void GetUserName();
  }, [localStorage.getItem("token")]);

  const closeTheModalHandler = () => setShowTheModal(false);

  const editComment = (e) => {

    e.preventDefault();

    const data = {
      CommentId: commentID,
      CommentText: theComment,
    };

    API.updateCommentOnWindow(data, windowID).then((response) => {
      if (response.status === 200){
        setTheTitle("Kommentar sparad");
        setShowTheModal(true);
      } else if (response.status === 401) {
        void dispatch(logout());
      }
    });
  }

  const closeModal = (e, closeModalHandler) => {

    e.preventDefault();

    closeModalHandler();
  }

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          className="modal"
          open={showModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeModalHandler}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogActions className="modal__topHeader">
            {" "}
            <span onClick={closeModalHandler} className="modal__closeBtn">
              x
            </span>
          </DialogActions>
          <DialogTitle className="modal__header">{title}</DialogTitle>
          <DialogContent className="modal__editComment__body">
            <Modal
                showModal={showTheModal}
                closeModalHandler={closeTheModalHandler}
                title={theTitle}
                buttonName="Stäng"
            />
            <TextField
                className="modal__editComment__input"
                label="Kommentar"
                variant="outlined"
                name="theComment"
                fullWidth
                multiline
                minRows={8}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setTheComment(e.target.value)}
                value={theComment}
                defaultValue={commentText}
            >
            </TextField>
          </DialogContent>
          <button onClick={(e) => editComment(e, closeModalHandler)} className="modal__editComment__button">
            Spara kommentar
          </button>
          <button onClick={(e) => closeModal(e, closeModalHandler)} className="modal__editComment__button">
            {buttonName}{" "}
          </button>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

export default InputModal;

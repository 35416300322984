import React, { useState } from "react";
import "./Login.css";
import sportsonTitel from "../../img/sportsonTitel.png";
import { Button, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Modal from "../../components/Modals/Modal-ConfirmAlert";
import { login } from "../../Redux/actions/userActions";
import API from "../../utils/API";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const { control, register } = useForm();
  const dispatch = useDispatch();

  const closeModalHandler = () => setShowModal(false);

  const handleLoginInput = async () => {

      if (email === "" || password === "") {
          setShowModal(true);
          setTitle("Fälten måste vara ifyllda");
          return null;
      }

      try {
          const response = await API.postLogin({ email: email, password: password });

          if (email !== "" && password !== "" && response.status === 401) {
              setShowModal(true);
              setTitle("Användarnamn eller lösenord är felaktig");
              return null;
          } else if (response.status >= 300 && response.status !== 401) {
              setShowModal(true);
              setTitle(`${response.data.message} - ${response.data.details.message}`);
              return null;
          }
          return response.data;
      } catch (error) {
          console.error(error);
          return null;
      }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataFromHandleLoginInput = await handleLoginInput();

    if (dataFromHandleLoginInput !== null)
        await dispatch(login(dataFromHandleLoginInput));
  };

  return (
      <div className="login">
          <div className="login__imgDiv">
              <img src={sportsonTitel ? sportsonTitel : ""} alt="Login logo" className="login__img"/>
          </div>
          <div className="login__container">
              <form onSubmit={handleSubmit}>
                  <div className="login__inputContainer">
                      <TextField
                          id="filled-basic"
                          variant="filled"
                          as={TextField}
                          control={control}
                          inputRef={register({required: true})}
                          label="E-post"
                          name="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          type="email"
                          className="login__textfield"
                      ></TextField>
                      <TextField
                          id="filled-basic"
                          variant="filled"
                          as={TextField}
                          control={control}
                          inputRef={register({
                              required: true,
                          })}
                          label="Lösenord"
                          name="Password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          type="password"
                          className="login__textfield"
                      ></TextField>
                      <Button className="login__button" type="submit">
                          LOGGA IN
                      </Button>
                  </div>
              </form>
          </div>
          <Modal
              showModal={showModal}
              closeModalHandler={closeModalHandler}
              title={title}
              buttonName="Stäng"
          />
      </div>
  );
}

export default Login;

import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import "./News.css";
import API from "../../utils/API";

function News() {
    const [news, setNews] = useState("");
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    useEffect(() => {
        setLoadingSpinner(true);
        const getNewsAsHtml = async () => {
            await API.getNewsAsHtml()
                .then((res) => {
                    setLoadingSpinner(false);
                    setNews(res.data);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getNewsAsHtml();
    }, []);

    return (
        <div className="news">
            <Header title="Nyheter" />
            { loadingSpinner && <div className="news__loadingDiv"><h1>Laddar</h1><div className="news__loader"></div></div> }
            { !loadingSpinner && <div className="news__div" dangerouslySetInnerHTML={{__html: news}}></div> }
        </div>
    );
}

export default News;

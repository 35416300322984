import React, { useEffect, useState } from "react";
import "./Users.css";
import Header from "../../components/Header";
import Modal from "../../components/Modals/Modal-ConfirmWithButtons";
import ModalInfo from "../../components/Modals/Modal-ConfirmAlert";
import ModalAddNewUser from "../../components/Modals/Modal-AddNewUser";
import ModalEditUser from "../../components/Modals/Modal-EditUser";
import {logout} from "../../Redux/actions/userActions";
import {useDispatch} from "react-redux";
import API from "../../utils/API";

import {
    TextField,
    Typography,
} from "@material-ui/core";

function Users() {
    const [showModal, setShowModal] = useState(false);
    const [showModalInfo, setShowModalInfo] = useState(false);
    const [showModalAddNewUser, setShowModalAddNewUser] = useState(false);
    const [showModalEditUser, setShowModalEditUser] = useState(false);
    const [title, setTitle] = useState("");
    const [confirm, setConfirm] = useState(false);
    const [nameOrMail, setNameOrMail] = useState("");
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filteredUsersPagewise, setFilteredUsersPagewise] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [shopList, setShopList] = useState([]);
    const [userID, setUserID] = useState("");

    const dispatch = useDispatch();

    const closeModalHandler = () => setShowModal(false);
    const closeModalInfoHandler = () => setShowModalInfo(false);
    const closeModalAddNewUserHandler = () => setShowModalAddNewUser(false);
    const closeModalEditUserHandler = () => setShowModalEditUser(false);
    const setConfirmHandler = () => setConfirm(true);

    const reloadUsers = async () => {
        await API.getUsers()
            .then((response) => {
                const chunkSize = 10;
                const chunkList = [];
                for (let i = 0; i < response.data.length; i += chunkSize) {
                    chunkList.push(response.data.slice(i, i + chunkSize));
                }
                setFilteredUsers(response.data);
                setFilteredUsersPagewise(chunkList);
                setLoadingSpinner(false);
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    useEffect(() => {
        setLoadingSpinner(true);
        void reloadUsers();

        const getAllShops = async () => {
            await API.getShopList("", "sportson")
                .then((response) => {
                    let shops = [];
                    response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((shop) => {
                        shops.push({ code: shop.Code, name: shop.Name });
                    });
                    setShopList(shops);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllShops();
    }, []);

    useEffect(() => {
        let allUsers = document.getElementsByClassName("users__user");

        setLoadingSpinner(true);
        if (confirm) {
            for (let user of allUsers) {
                if (user.checked) {
                    API.deleteUserById(user.id).then((response) => {
                        if (response.status === 200){
                            void reloadUsers();
                        } else if (response.status === 401) {
                            void dispatch(logout());
                        } else if (response.status === 404) {
                            setShowModalInfo(true);
                            setTitle("Användaren finns ej");
                        }
                    });
                }
            }
            setConfirm(false);
        }
    }, [confirm, dispatch]);

    const fetchData = (e) => {
        e.preventDefault();

        setLoadingSpinner(true);

        if (nameOrMail === "" || null) {
            setShowModalInfo(true);
            setTitle("Ange namn/mail");
            void reloadUsers();
        } else {
            API.searchUser(nameOrMail).then((response) => {
                if (response.status === 200){
                    const chunkSize = 10;
                    const chunkList = [];
                    for (let i = 0; i < response.data.length; i += chunkSize) {
                        chunkList.push(response.data.slice(i, i + chunkSize));
                    }
                    setFilteredUsers(response.data);
                    setFilteredUsersPagewise(chunkList);
                    setLoadingSpinner(false);
                } else if (response.status === 401) {
                    void dispatch(logout());
                } else if (response.status === 404) {
                    setShowModalInfo(true);
                    setTitle("Användaren finns ej");
                }
            });
        }
    }

    const removeUsers = (e) => {
        e.preventDefault();

        setShowModal(true);
        setTitle("Vill du verkligen ta bort användaren/användarna?");
    }

    const addNewUser = (e) => {
        e.preventDefault();

        setShowModalAddNewUser(true);
        setTitle("Lägg till användare");
    }

    const editUser = (e) => {
        let allUsers = document.getElementsByClassName("users__user");
        let checkedUsers = 0;

        e.preventDefault();

        setUserID("");
        for (let user of allUsers) {
            if (user.checked) {
                setUserID(user.id);
                checkedUsers++;
            }
        }

        if(checkedUsers > 1){
            setUserID("");
            setShowModalInfo(true);
            setTitle("Markera endast en användare som du vill ändra");
            return;
        }

        setShowModalEditUser(true);
        setTitle("Ändra användare");
    }

    const clickedPage = (e) => {
        e.preventDefault();

        let clickedPageButton = e.target;

        setCurrentPage(clickedPageButton.value);
    }

    return (
    <div className="users">
        <Header title="Användare" />
        <form>
            <div className="users__inputContainer">
                <TextField
                    className="users__inputText"
                    label="Ange namn/mail"
                    variant="outlined"
                    type="text"
                    name="inputText"
                    value={encodeURI(nameOrMail)}
                    onChange={(e) => setNameOrMail(e.target.value)}
                />
                <button
                    className="users__searchBtn"
                    type="submit"
                    onClick={(e) => fetchData(e)}
                >
                    SÖK
                </button>
                <Modal
                    showModal={showModal}
                    closeModalHandler={closeModalHandler}
                    title={title}
                    setConfirmHandler={setConfirmHandler}
                />
                <ModalInfo
                    showModal={showModalInfo}
                    closeModalHandler={closeModalInfoHandler}
                    title={title}
                    buttonName="Stäng"
                />
                <ModalAddNewUser
                    showModal={showModalAddNewUser}
                    closeModalHandler={closeModalAddNewUserHandler}
                    reloadUsers={reloadUsers}
                    title={title}
                    buttonName="Stäng"
                />
                <ModalEditUser
                    showModal={showModalEditUser}
                    closeModalHandler={closeModalEditUserHandler}
                    reloadUsers={reloadUsers}
                    userID={userID}
                    title={title}
                    buttonName="Stäng"
                />
            </div>
        </form>
        <div className="userList">
            <p className="users__searchResultText">Sökresultat: {filteredUsers.length > 0 ? filteredUsers.length : 0} användare</p>
            <form id="ordersForm">
                <div className="orderhantering__filter">
                    <div className="orderhantering__filterOpt">
                        <Typography variant="string" className="users__userText"><button onClick={(e) => removeUsers(e)} className="users__userBtn">Ta bort användare</button></Typography>
                        <Typography variant="string" className="users__userText"><button onClick={(e) => editUser(e)} className="users__userBtn">Ändra användare</button></Typography>
                        <Typography variant="string" className="users__userText"><button onClick={(e) => addNewUser(e)} className="users__userBtn">Lägg till användare</button></Typography>
                    </div>
                </div>
            </form>
            {filteredUsers.length > 0 && (
                <>
                    { loadingSpinner && <div className="users__loadingDiv"><h1>Laddar</h1><div className="users__loader"></div></div> }
                    <div className="users__userList">
                                <div className="users__tableContainer">
                                    <table className="users__userListUsers">
                                        <tr className="users__rowColumnsHeader">
                                            <th className="users__rowColumnsHalf users__rowColumnsHeader"><h5>Välj</h5></th>
                                            <th className="users__rowColumns users__rowColumnsHeader"><h5>Namn</h5></th>
                                            <th className="users__rowColumnsDouble users__rowColumnsHeader"><h5>E-mail</h5></th>
                                            <th className="users__rowColumns users__rowColumnsHeader"><h5>Butiker</h5></th>
                                            <th className="users__rowColumns users__rowColumnsHeader"><h5>Ej aktiv</h5></th>
                                        </tr>
                                        {filteredUsersPagewise.length > 1 && filteredUsersPagewise[(parseInt(currentPage ? currentPage : "")-1)].map((user, index) => (
                                            <>
                                                <div className="users__horizontalLine"></div>
                                                <tr key={index}>
                                                    <td className="users__rowColumnsHalf"><h4><input className="users__user" type="checkbox" id={user._id} name={user._id} value={user._id}/></h4></td>
                                                    <td className="users__rowColumns"><h4>{user.name}</h4></td>
                                                    <td className="users__rowColumnsDouble"><h4>{user.email}</h4></td>
                                                    <td className="users__rowColumnsScroll">
                                                        {user.userrights && user.userrights.adminaccess && (
                                                            <h4>Alla</h4>
                                                        )}
                                                        {/*TODO !user.userrights.adminaccess istället för !user.userrights här nedanför när databasen är redo för det: */}
                                                        {user.userrights && !user.userrights.adminaccess &&user.shopaccess.length > 1 &&
                                                            <select>
                                                                {user.shopaccess.map((shop) => (
                                                                    (shopList.length > 0 && shopList.map((listedShop) => (
                                                                        (listedShop.code == shop && <option>{listedShop.name}</option>)
                                                                    )))
                                                                ))}
                                                            </select>
                                                        }
                                                        {!user.userrights && user.shopaccess.length === 1 && user.shopaccess.map((shop) => (
                                                            <>
                                                                {shopList.length > 0 && shopList.map((listedShop) => (
                                                                    (listedShop.code == shop && <h4>{listedShop.name}</h4>)
                                                                ))}
                                                            </>
                                                        ))}
                                                    </td>
                                                    <td className="users__rowColumns"><h4>{user.userinactivated ? "X" : ""}</h4></td>
                                                </tr>
                                            </>
                                        ))}
                                        {filteredUsersPagewise.length === 1 && filteredUsers.map((user, index) => (
                                            <>
                                                <div className="users__horizontalLine"></div>
                                                <tr key={index}>
                                                    <td className="users__rowColumnsHalf"><h4><input className="users__user" type="checkbox" id={user._id} name={user._id} value={user._id}/></h4></td>
                                                    <td className="users__rowColumns"><h4>{user.name}</h4></td>
                                                    <td className="users__rowColumnsDouble"><h4>{user.email}</h4></td>
                                                    <td className="users__rowColumnsScroll">
                                                        {user.userrights && user.userrights.adminaccess && (
                                                            <h4>Alla</h4>
                                                        )}
                                                        {/*TODO !user.userrights.adminaccess istället för !user.userrights här nedanför när databasen är redo för det: */}
                                                        {user.userrights && !user.userrights.adminaccess &&user.shopaccess.length > 1 &&
                                                            <select>
                                                                {user.shopaccess.map((shop) => (
                                                                    (shopList.length > 0 && shopList.map((listedShop) => (
                                                                        (listedShop.code == shop && <option>{listedShop.name}</option>)
                                                                    )))
                                                                ))}
                                                            </select>
                                                        }
                                                        {!user.userrights && user.shopaccess.length === 1 && user.shopaccess.map((shop) => (
                                                            <>
                                                                {shopList.length > 0 && shopList.map((listedShop) => (
                                                                    (listedShop.code == shop && <h4>{listedShop.name}</h4>)
                                                                ))}
                                                            </>
                                                        ))}
                                                    </td>
                                                    <td className="users__rowColumns"><h4>{user.userinactivated ? "X" : ""}</h4></td>
                                                </tr>
                                            </>
                                        ))}
                                    </table>
                                    {parseInt(currentPage ? currentPage : "") === 1 && filteredUsersPagewise.length === 1 && (
                                        <>
                                            <button key={"page-1"} className="users__pageButton users__currentPage" value={1}>{1}</button>
                                        </>
                                    )}
                                    {parseInt(currentPage ? currentPage : "") === 1 && filteredUsersPagewise.length > 1 && (
                                        <>
                                            <button key={"page-1"} onClick={(e) => clickedPage(e)} className="users__pageButton users__currentPage" value={1}>{1}</button>
                                            <button key={"page-next"} onClick={(e) => clickedPage(e)} className="users__pageButton" value={(parseInt(currentPage ? currentPage : "")+1)}>{">"}</button>
                                            <button key={"page-last"} onClick={(e) => clickedPage(e)} className="users__pageButton" value={filteredUsersPagewise.length}>{">|"}</button>
                                        </>
                                    )}
                                    {parseInt(currentPage ? currentPage : "") > 1 && filteredUsersPagewise.length > 1 && parseInt(currentPage ? currentPage : "") !== 1 && parseInt(currentPage ? currentPage : "") !== filteredUsersPagewise.length && (
                                        <>
                                            <button key={"page-first"} onClick={(e) => clickedPage(e)} className="users__pageButton" value={1}>{"|<"}</button>
                                            <button key={"page-prev"} onClick={(e) => clickedPage(e)} className="users__pageButton" value={(parseInt(currentPage ? currentPage : "")-1)}>{"<"}</button>
                                            <button key={"page-current"} onClick={(e) => clickedPage(e)} className="users__pageButton users__currentPage" value={currentPage}>{currentPage}</button>
                                            <button key={"page-next"} onClick={(e) => clickedPage(e)} className="users__pageButton" value={(parseInt(currentPage ? currentPage : "")+1)}>{">"}</button>
                                            <button key={"page-last"} onClick={(e) => clickedPage(e)} className="users__pageButton" value={filteredUsersPagewise.length}>{">|"}</button>
                                        </>
                                    )}
                                    {parseInt(currentPage ? currentPage : "") === filteredUsersPagewise.length && parseInt(currentPage ? currentPage : "") > 1 && filteredUsersPagewise.length > 1 && (
                                        <>
                                            <button key={"page-first"} onClick={(e) => clickedPage(e)} className="users__pageButton" value={1}>{"|<"}</button>
                                            <button key={"page-prev"} onClick={(e) => clickedPage(e)} className="users__pageButton" value={(parseInt(currentPage ? currentPage : "")-1)}>{"<"}</button>
                                            <button key={"page-current"} onClick={(e) => clickedPage(e)} className="users__pageButton users__currentPage" value={currentPage}>{currentPage}</button>
                                        </>
                                    )}
                                </div>
                    </div>
                </>)}
        </div>
    </div>
);
}

export default Users;
import React, {useState, useEffect} from "react";
import "./style.css";
import Select from 'react-select';
import {logout} from "../../../Redux/actions/userActions";
import {useDispatch} from "react-redux";
import Modal from "../Modal-ConfirmAlert";
import API from "../../../utils/API";

import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
});

function InputModal({
    title,
    buttonName,
    showModal,
    closeModalHandler,
    reloadUsers,
}) {
  const [theName, setTheName] = useState("");
  const [theEmail, setTheEmail] = useState("");
  const [thePassword, setThePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [thePincode, setThePincode] = useState("");
  // const [confirmPincode, setConfirmPincode] = useState("");
  const [theShops, setTheShops] = useState([]);
  const [showTheModal, setShowTheModal] = useState(false);
  const [theTitle, setTheTitle] = useState("");
  const [shopsOptions, setShopsOptions] = useState([]);
  const [theUserRights, setTheUserRights] = useState([]);
  const [theUserRight, setTheUserRight] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    const getAllShops = async () => {
      await API.getShopList("","sportson")
          .then((response) => {
            let shops = [];
            shops.push({ value: "", label: "Välj din butik/dina butiker *", isDisabled: true });
            shops.push({ value: "0", label: "Alla" });
            response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((shop) => {
              shops.push({ value: shop.Code, label: shop.Name });
            });
            setShopsOptions(shops);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void getAllShops();

    const getUserRights = async () => {
      await API.getUserRights("")
          .then((response) => {
            if (response.status === 200) {
              setTheUserRights(response.data);
            } else if (response.status >= 300) {
              setShowTheModal(true);
              setTheTitle(`${response.data.message} - ${response.data.details.message}`);
            } else if (response.status === 401) {
              dispatch(logout());
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void getUserRights();
  }, [dispatch]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: "56px",
      width: "852px",
      marginLeft: "-10px",
    }),
  };

  const closeTheModalHandler = () => setShowTheModal(false);

  const checkPassword = () => {
    return thePassword === confirmPassword;
  }

/* Remove code not in use for now
  const checkPincode = () => {
    return thePincode === confirmPincode;
  }
 */

  const addNewUser = (e) => {

    /* Rwmove code not in use for now
    if(!checkPassword() && !checkPincode()){
      setShowTheModal(true);
      setTheTitle("Lösenordet och pinkoden stämmer inte");

      return;
    }
     */
    if (!checkPassword()){
      setShowTheModal(true);
      setTheTitle("Lösenordet stämmer inte");
      return;
    }

    /* Remove code not in use for now
    if(!checkPincode()){
      setShowTheModal(true);
      setTheTitle("Pinkoden stämmer inte");
      return;
    }
     */

    e.preventDefault();

    const data = {
      name: theName,
      email: theEmail,
      password: thePassword,
      // pincode: thePincode, remove code not in use for now
      shopsaccess: theShops,
      userrights: theUserRight,
    };

    API.createUser(data).then((response) => {
      if (response.status === 201){
        reloadUsers();
      } else if (response.status === 401) {
        void dispatch(logout());
      }
    });

    closeModalHandler();
  }

  const handleOnChange = (selectedOptions) => {

    let shops = [];

    if (selectedOptions.length > 0) {
      selectedOptions.forEach((shop) => {
        shops.push(shop.value);
      });
    }
    setTheShops(shops);
  }

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          className="modal"
          open={showModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeModalHandler}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogActions className="modal__topHeader">
            {" "}
            <span onClick={closeModalHandler} className="modal__closeBtn">
              x
            </span>
          </DialogActions>
          <DialogTitle className="modal__header">{title}</DialogTitle>

          <DialogContent className="modal__addNewUser__body">
            <Modal
                showModal={showTheModal}
                closeModalHandler={closeTheModalHandler}
                title={theTitle}
                buttonName="Stäng"
            />
            <TextField
                className="modal__addNewUser__input"
                label="Namn *"
                variant="outlined"
                name="theName"
                fullWidth
                onChange={(e) => setTheName(e.target.value)}
                value={theName}
            >
            </TextField>
            <TextField
                className="modal__addNewUser__input"
                label="Email *"
                variant="outlined"
                name="theEmail"
                fullWidth
                onChange={(e) => setTheEmail(e.target.value)}
                value={theEmail}
            >
            </TextField>
            <TextField
                className="modal__addNewUser__input"
                label="Lösenord *"
                variant="outlined"
                name="thePassword"
                type="password"
                fullWidth
                onChange={(e) => setThePassword(e.target.value)}
                value={thePassword}
            >
            </TextField>
            <TextField
                className="modal__addNewUser__input"
                label="Bekräfta lösenord *"
                variant="outlined"
                name="confirmPassword"
                type="password"
                fullWidth
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
            >
            </TextField>
            {/* <TextField
                className="modal__addNewUser__input"
                label="Pinkod"
                variant="outlined"
                name="thePincode"
                type="password"
                fullWidth
                onChange={(e) => setThePincode(e.target.value)}
                value={thePincode}
            >
            </TextField>
            <TextField
                className="modal__addNewUser__input"
                label="Bekräfta pinkod"
                variant="outlined"
                name="confirmPincode"
                type="password"
                fullWidth
                onChange={(e) => setConfirmPincode(e.target.value)}
                value={confirmPincode}
            >
            </TextField> */}
            <p>Sportsonbutiker</p>
            <Select
                className="itSupport__select"
                name="theShops"
                onChange={handleOnChange}
                isMulti
                options={shopsOptions}
                styles={customStyles}
                menuPlacement="top"
            >
            </Select>
            <select
                className="itSupport__select modal__addNewUser__select"
                name="theAuthorizationlevel"
                onChange={(e) => setTheUserRight(e.target.value)}
                value={theUserRight}
            >
              <option className="itSupport__optionText" value="" disabled>
                Behörighet *
              </option>
              { theUserRights.length > 0 && theUserRights.map((userRight) => (
                  <option value={userRight._id} >{userRight.name}</option>
              ))}
            </select>
            <div className="modal__addNewUser__buttonDiv">
              <button onClick={(e) => addNewUser(e, closeModalHandler)} className="modal__addNewUser__button">
                Lägg till användare
              </button>
              <button onClick={closeModalHandler} className="modal__addNewUser__button">
                {buttonName}{" "}
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

export default InputModal;

import React, {useState, useEffect} from "react";
import "./style.css";
import {logout} from "../../../Redux/actions/userActions";
import {useDispatch} from "react-redux";
import Modal from "../Modal-ConfirmAlert";
import {fetchOrder} from "../../../Redux/actions/orderAction";
import Select from "react-select";
import API from "../../../utils/API";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
});

function InputModal({
    title,
    showModal,
    closeModalHandler,
    orderID,
    orderNumber,
    company
}) {
  const [/*userID*/, setUserID] = useState("");
  const [showTheModal, setShowTheModal] = useState(false);
  const [theTitle, setTheTitle] = useState("");
  const [benefitWindowID, setBenefitWindowID] = useState("");
  const [benefitWindowsOptions, setBenefitWindowsOptions] = useState([]);

  const dispatch = useDispatch();

  const customStyles = {
    control: (base) => ({
      ...base,
      height: "56px",
      width: "852px",
      marginLeft: "-10px",
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      textAlign: "center"
    }),
  };

  useEffect(() => {

    const GetUserName = async () => {
      await API.getUserId()
          .then((response) => {
            if (response.status === 200) {
              setUserID(response.data);
            } else if (response.status >= 300) {
              setShowTheModal(true);
              setTheTitle(`${response.data.message} - ${response.data.details.message}`);
            } else if (response.status === 401) {
              dispatch(logout());
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void GetUserName();
  }, [localStorage.getItem("token"), dispatch]);

  const closeTheModalHandler = () => setShowTheModal(false);

  /* Comment out code not in use for now
  const validateOrder = async (orderNumber) => {
    await API.validateOrder(orderNumber)
        .then((response) => {
          if(response.status === 200){
            setShowTheModal(true);
            setTheTitle("Validering av order okej");
          } else if (response.status >= 300) {
            setShowTheModal(true);
            setTheTitle(`${response.data.message} - ${response.data.details}`);
          } else if (response.status === 401) {
            dispatch(logout());
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
  };
   */

  const connectOrderToBenefitWindowHandler = (e) => {

    e.preventDefault();
    const connectOrderToBenefitWindow = async () => {

      if (benefitWindowID !== "") {
        const data =
          {
            BenefitWindow: benefitWindowID,
          };

        await API.updateOrderHead(data, orderID).then((response) => {
          if (response.status === 200) {
            dispatch(fetchOrder(encodeURI(orderNumber)));
          } else if (response.status >= 300) {
            setShowTheModal(true);
            setTheTitle(`${response.data.message} - ${response.data.details.message}`);
          } else if (response.status === 401) {
            dispatch(logout());
          }
        });
        // validateOrder(orderNumber);
      }
    }
    void connectOrderToBenefitWindow();
  }

  const saveOrderAndCloseModal = (e, closeModalHandler) => {

    e.preventDefault();

    connectOrderToBenefitWindowHandler(e);
    closeModalHandler();
  }

  const handleOnChangeBenefitWindow = (selectedOption) => {

    selectedOption ? setBenefitWindowID(selectedOption.value) : setBenefitWindowID("");
  }

  useEffect(() => {
    const getAllBenefitWindows = async () => {
      await API.getBenefitWindow("","","", false, "")
          .then((response) => {
            let benefitWindows = [];
            benefitWindows.push({ value: "", label: "Välj beställningsfönster att koppla till ordern", isDisabled: true });
            response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((benefitWindow) => {
              if (benefitWindow.name.search(company) !== -1)
                benefitWindows.push({ value: benefitWindow._id, label: benefitWindow.name });
            });
            setBenefitWindowsOptions(benefitWindows);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void getAllBenefitWindows();

  }, [company]);

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          className="modal"
          open={showModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeModalHandler}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogActions className="modal__topHeader">
            {" "}
            <span onClick={closeModalHandler} className="modal__closeBtn">
              x
            </span>
          </DialogActions>
          <DialogTitle className="modal__header">{title}</DialogTitle>
          <DialogContent className="modal__connectOrderToBenefitWindow__body">
            <Modal
                showModal={showTheModal}
                closeModalHandler={closeTheModalHandler}
                title={theTitle}
                buttonName="Stäng"
            />
            <h6>Sök beställningsfönster att koppla ordern till</h6>
            <Select
                className="itSupport__select"
                name="Search benefit window to connect to"
                options={benefitWindowsOptions}
                styles={customStyles}
                onChange={handleOnChangeBenefitWindow}
                isClearable
                menuPortalTarget={document.body}
                defaultInputValue={company}

            >
            </Select>
            <div className="modal__connectOrderToBenefitWindow__buttonDiv">
              <button onClick={(e) => connectOrderToBenefitWindowHandler(e, closeModalHandler)} className="modal__connectOrderToBenefitWindow__button">
                Koppla order till valt fönster
              </button>
              <button onClick={(e) => saveOrderAndCloseModal(e, closeModalHandler)} className="modal__connectOrderToBenefitWindow__button">
                Koppla & stäng
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

export default InputModal;

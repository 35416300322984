import React, {useState, useEffect} from "react";
import Select from 'react-select';
import "./style.css";
import {logout} from "../../../Redux/actions/userActions";
import {useDispatch} from "react-redux";
import Modal from "../Modal-ConfirmAlert";
import API from "../../../utils/API";

import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    Card,
} from "@material-ui/core";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
});

function InputModal({
    title,
    companyID,
    //companyName,
    showModal,
    closeModalHandler,
}) {
  const [theName, setTheName] = useState("");
  const [theEmail, setTheEmail] = useState("");
  const [thePhone, setThePhone] = useState("");
  const [theComment, setTheComment] = useState("");
  //const [theCompanies, setTheCompanies] = useState([]);
  const [showTheModal, setShowTheModal] = useState(false);
  const [theTitle, setTheTitle] = useState("");
  const [/*companiesOptions*/, setCompaniesOptions] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [benefitCompanyContactsOptions, setBenefitCompanyContactsOptions] = useState([]);
  const [theContact, setTheContact] = useState("");
  const [displayContact, setDisplayContact] = useState([]);

  const dispatch = useDispatch();

  const tempContacts = [];

  useEffect(() => {
      setDisplayContact([]);
  }, [showModal]);

  useEffect(() => {
    const getAllCompanies = async () => {
      await API.getCompany("")
          .then((response) => {
            let companies = [];
            companies.push({ value: "", label: "Välj bolag", isDisabled: true });
            response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((company) => {
              companies.push({ value: company._id, label: company.name });
            });
            setCompaniesOptions(companies);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void getAllCompanies();

  }, []);

  useEffect(() => {
    const getBenefitCompanyContactTagID = async () => {
      await API.getBenefitCompanyContactTagId()
          .then(() => {
            const getAllBenefitCompanyContacts = async () => {
              await API.getContact("", "", companyID)
                  .then((response) => {
                    let contacts = [];
                    contacts.push({
                      value: "",
                      label: "Välj kontaktperson",
                      isDisabled: true
                    });
                    response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((contact) => {
                      contacts.push({value: contact._id, label: contact.name});
                    });
                    setBenefitCompanyContactsOptions(contacts);
                  })
                  .catch((error) => {
                    console.log("Error: ", error);
                  });
            };
            getAllBenefitCompanyContacts();
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void getBenefitCompanyContactTagID();

  }, [companyID, contacts]);

  useEffect(() => {
    const getBenefitCompanyContactTagID = async () => {
      await API.getBenefitCompanyContactTagId()
          .then(() => {
            const getAllBenefitCompanyContacts = async () => {
              await API.getContact(theContact, "", "")
                  .then((response) => {
                      if (response.status === 200) {
                          setDisplayContact(response.data);
                      } else if (response.status >= 300) {
                          setShowTheModal(true);
                          setTheTitle(`${response.data.message} - ${response.data.details.message}`);
                      } else if (response.status === 401) {
                          dispatch(logout());
                      }
                  })
                  .catch((error) => {
                    console.log("Error: ", error);
                  });
            };
            getAllBenefitCompanyContacts();
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void getBenefitCompanyContactTagID();

  }, [theContact, dispatch]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: "56px",
      width: "852px",
      marginLeft: "-10px",
    }),
  };

  const closeTheModalHandler = () => setShowTheModal(false);

  const handleResetContactFields = () => {
      setTheName("");
      setTheEmail("");
      setThePhone("");
      setTheComment("");
  }
  const getBenefitCompanyContactTagIDAndCreateContact = async () => {
        await API.getBenefitCompanyContactTagId()
            .then((response) => {
                const data = {
                    name: theName,
                    email: theEmail,
                    phone: thePhone,
                    company: companyID,
                    tags: [response.data._id],
                    comment: theComment,
                };

                API.createContact(data).then((response) => {
                    if(response.status === 201){
                        if (contacts.length > 0)
                            contacts.map(contact => tempContacts.push(contact))
                        tempContacts.push(data);
                        setContacts(tempContacts);
                        setShowTheModal(true);
                        setTheTitle("Kontakt skapad");
                        handleResetContactFields();
                    } else if (response.status >= 300) {
                        setShowTheModal(true);
                        setTheTitle(`${response.data.message} - ${response.data.details.message}`);
                    } else if (response.status === 401) {
                        dispatch(logout());
                    }
                });
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
  };

  const addNewContactAndCloseModal = (e, closeModalHandler) => {

      e.preventDefault();

      if (theName !== "" && theEmail !== "")
        void getBenefitCompanyContactTagIDAndCreateContact();

      closeModalHandler();
  }

  const addNewContact = (e) => {

      e.preventDefault();

      void getBenefitCompanyContactTagIDAndCreateContact();

  }

  const handleOnChangeContact = (selectedOption) => {

    selectedOption ? setTheContact(selectedOption.value) : setTheContact("");
  }
/*
  const handleOnChange = (selectedOptions) => {

    let companies = [];

    if(selectedOptions.length > 0) {
      selectedOptions.map((company, index) => {
        companies.push(company.value);
      });
    };
    setTheCompanies(companies);
  }
 */

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          className="modal"
          open={showModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeModalHandler}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogActions className="modal__topHeader">
            {" "}
            <span onClick={closeModalHandler} className="modal__closeBtn">
              x
            </span>
          </DialogActions>
          <DialogTitle className="modal__header">{title}</DialogTitle>

          <DialogContent className="modal__addNewContact__body">
            <Modal
                showModal={showTheModal}
                closeModalHandler={closeTheModalHandler}
                title={theTitle}
                buttonName="Stäng"
            />
            <TextField
                className="modal__addNewContact__input"
                label="Namn *"
                variant="outlined"
                name="theName"
                fullWidth
                onChange={(e) => setTheName(e.target.value)}
                value={theName}
            >
            </TextField>
            <TextField
                className="modal__addNewContact__input"
                label="Email *"
                variant="outlined"
                name="theEmail"
                fullWidth
                onChange={(e) => setTheEmail(e.target.value)}
                value={theEmail}
            >
            </TextField>
            <TextField
                className="modal__addNewContact__input"
                label="Telefonnummer"
                variant="outlined"
                name="thePhone"
                fullWidth
                onChange={(e) => setThePhone(e.target.value)}
                value={thePhone}
            >
            </TextField>
            <TextField
                className="modal__addNewContact__input"
                label="Kommentar"
                variant="outlined"
                name="theComment"
                fullWidth
                multiline
                minRows={8}
                onChange={(e) => setTheComment(e.target.value)}
                value={theComment}
            >
            </TextField>
            {/*companyID !== "" && companyName !== "" &&
                <>
                  <h6>Company:</h6>
                  <Select
                      className="itSupport__select"
                      name="theShops"
                      onChange={handleOnChange}
                      isMulti
                      options={companiesOptions}
                      styles={customStyles}
                      defaultValue={{value: companyID, label: companyName}}
                  >
                  </Select>
                </>
            */}
            <h6>Visa kontakt:</h6>
              {showModal && <Select
                className="itSupport__select"
                name="contacts"
                isClearable
                options={benefitCompanyContactsOptions}
                styles={customStyles}
                onChange={handleOnChangeContact}
                defaultValue={[]}
                menuPlacement="top"
            >
            </Select>}
            {displayContact.length === 1 && (
                <Card className="modal__addNewContact__input">
                    <ul className="modal__addNewContact__input">
                        {displayContact[0].name && <li> <h6>{`Namn: ${displayContact[0].name}`}</h6> </li>}
                        {displayContact[0].email && <li> <p>{`Email: ${displayContact[0].email}`}</p> </li>}
                        {displayContact[0].phone && <li> <p>{`Telefon: ${displayContact[0].phone}`}</p> </li>}
                        {displayContact[0].comment && <li> <p>{`Kommentar: ${displayContact[0].comment}`}</p> </li>}
                    </ul>
                </Card>
            )}
          </DialogContent>
            <div className="modal__addNewContact__buttonDiv">
              <button onClick={(e) => addNewContact(e)} className="modal__addNewContact__button">
                Lägg till kontakt
              </button>
              <button onClick={(e) => addNewContactAndCloseModal(e, closeModalHandler)} className="modal__addNewContact__button">
                Spara och stäng
              </button>
            </div>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

export default InputModal;

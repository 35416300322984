import API from "../../utils/API";

export const postButikForm = (device, description, checked) => async (
  dispatch
) => {
  try {
    await API.postSupportTicket({
      device,
      description,
      checked,
    }).then((/*response*/) => {
      dispatch({
        type: "POST_BUTIK_FORM",
        payload: device,
        description,
        checked,
      });
    });
  } catch (error) {
    dispatch({
      type: "POST_BUTIK_FORM_FAIL",
      payload: error,
    });
  }
};

export const postHeadsForm = (headsInfo) => async (dispatch) => {
  try {
    await API.postSupportTicket({
      headsInfo,
    }).then((/*response*/) => {
      dispatch({
        type: "POST_HEADS_FORM",
        payload: headsInfo,
      });
    });
  } catch (error) {
    dispatch({
      type: "POST_HEADS_FORM_FAIL",
      payload: error,
    });
  }
};

export const postPortalForm = (portalInfo) => async (dispatch) => {
  try {
    await API.postSupportTicket({ portalInfo }).then((/*response*/) => {
      dispatch({
        type: "POST_PORTAL_FORM",
        payload: portalInfo,
      });
    });
  } catch (error) {
    dispatch({
      type: "POST_PORTAL_FORM_FAIL",
      payload: error,
    });
  }
};

export const postSupportType = (name) => async (dispatch) => {
  try {
    await API.postSupportType({ name }).then((/*response*/) => {
      dispatch({
        type: "POST_SUPPORT_TYPE",
        payload: name,
      });
    });
  } catch (error) {
    dispatch({
      type: "POST_SUPPORT_TYPE_FAIL",
      payload: error,
    });
  }
};

export const postSupportSubType = (data) => async (dispatch) => {
  try {
    await API.postSupprtSubType({ data }).then((/*response*/) => {
      dispatch({
        type: "POST_SUPPORT_SUB_TYPE",
        payload: data,
      });
    });
  } catch (error) {
    dispatch({
      type: "POST_SUPPORT_SUB_TYPE_FAIL",
      payload: error,
    });
  }
};

import React, {useState, useEffect, useRef} from "react";
import "./style.css";
import Select from 'react-select';
import {logout} from "../../../Redux/actions/userActions";
import {useDispatch} from "react-redux";
import Modal from "../Modal-ConfirmAlert";
import API from "../../../utils/API";

import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
});

function InputModal({
    title,
    buttonName,
    showModal,
    closeModalHandler,
    reloadUsers,
    userID,
}) {

  const [theName, setTheName] = useState("");
  const [theEmail, setTheEmail] = useState("");
  const [thePassword, setThePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [thePincode, setThePincode] = useState("");
  // const [confirmPincode, setConfirmPincode] = useState("");
  const [theShops, setTheShops] = useState([]);
  const [showTheModal, setShowTheModal] = useState(false);
  const [theTitle, setTheTitle] = useState("");
  const [shopsOptions, setShopsOptions] = useState([]);
  const [userRights, setUserRights] = useState([]);
  const [theUserRight, setTheUserRight] = useState("");
  const [theUserInactivated, setTheUserInactivated] = useState(false);
  const [loadTheSportsonShops, setLoadTheSportsonShops] = useState([]);

  const selectShopsRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const getAllShops = async () => {
      await API.getShopList("", "sportson")
          .then((response) => {
            let shops = [];
            shops.push({ value: "", label: "Välj din butik/dina butiker *", isDisabled: true });
            shops.push({ value: "0", label: "Alla" });
            response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((shop) => {
              shops.push({ value: shop.Code, label: shop.Name });
            });
            setShopsOptions(shops);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void getAllShops();

    const getUserRights = async () => {
      await API.getUserRights("")
          .then((response) => {
            setUserRights(response.data);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void getUserRights();
  }, []);

  useEffect(() => {
    if (selectShopsRef.current) {
      selectShopsRef.current.clearValue();
      selectShopsRef.current.inputValue = "";
    }
    const getUserByID = async () => {
            await API.getUserById(userID)
                .then((response) => {
                    setTheUserInactivated(response.data.userinactivated);
                    setTheEmail(response.data.email);
                    setTheName(response.data.name);
                    if (response.data.shopaccess.length > 0)
                      loadSportsonShops(response.data.shopaccess);
                    response.data.userrights ? setTheUserRight(response.data.userrights) : setTheUserRight("");
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
    }
    if (userID !== ""){
        void getUserByID();
    }
  }, [userID]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: "56px",
      width: "852px",
      marginLeft: "-10px",
    }),
  };

  const closeTheModalHandler = () => setShowTheModal(false);

  const checkPassword = () => {
    return thePassword === confirmPassword;
  }

  /* Remove code not in use for now
  const checkPincode = () => {
    return thePincode === confirmPincode;
  }
   */

  const editUser = (e) => {

    let allUsers = document.getElementsByClassName("users__user");

    /* Rwmove code not in use for now
    if (!checkPassword() && !checkPincode()){
      setShowTheModal(true);
      setTheTitle("Lösenordet och pinkoden stämmer inte");

      return;
    }
     */
    if (!checkPassword()){
      setShowTheModal(true);
      setTheTitle("Lösenordet stämmer inte");
      return;
    }
    /* Remove code not in use for now
    if (!checkPincode()){
      setShowTheModal(true);
      setTheTitle("Pinkoden stämmer inte");
      return;
    }
     */

    e.preventDefault();

    const data = {
      name: theName,
      email: theEmail,
      password: thePassword,
      // pincode: thePincode, remove code not in use for now
      shopsaccess: theShops,
      userrights: theUserRight,
      userinactivated: theUserInactivated,
    };

    for (let user of allUsers) {
      if (user.checked) {
        API.updateUserById(data, user.id).then((response) => {
          if (response.status === 200) {
            reloadUsers();
          } else if (response.status >= 300) {
            setShowTheModal(true);
            setTheTitle(`${response.data.message} - ${response.data.details.message}`);
          } else if (response.status === 401) {
            void dispatch(logout());
          }
        });
      }
    }
    closeModalHandler();
  }

  const loadSportsonShops = (sportsonShops) => {

    let tempSportsonShops = [];
    let theLabel = "";

    setLoadTheSportsonShops([]);

    if (sportsonShops.length > 0) {
      sportsonShops.forEach((sportsonShop) => {
        shopsOptions.forEach((shop) => {
          if (shop.value !== "" && shop.value == sportsonShop) {
            theLabel = shop.label;
            tempSportsonShops.push({value: sportsonShop, label: theLabel});
          }
        });
      });
    }
    setLoadTheSportsonShops(tempSportsonShops);
  }

  const handleOnChange = (selectedOptions) => {

    let shops = [];

    if (selectedOptions.length > 0) {
      selectedOptions.forEach((shop) => {
        shops.push(shop.value);
      });
    }
    setTheShops(shops);
  }

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          className="modal"
          open={showModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeModalHandler}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogActions className="modal__topHeader">
            {" "}
            <span onClick={closeModalHandler} className="modal__closeBtn">
              x
            </span>
          </DialogActions>
          <DialogTitle className="modal__header">{title}</DialogTitle>
          <DialogContent className="modal__editUser__body">
            <Modal
                showModal={showTheModal}
                closeModalHandler={closeTheModalHandler}
                title={theTitle}
                buttonName="Stäng"
            />
            <div className="modal__editUser__checkboxDiv">
              <label>Ej aktiv</label>
              <input onClick={() => setTheUserInactivated(!theUserInactivated)} type="checkbox" id="userInactivated" name="UserInactivated" checked={theUserInactivated}/>
            </div>
            <TextField
                className="modal__editUser__input"
                label="Namn *"
                variant="outlined"
                name="theName"
                fullWidth
                onChange={(e) => setTheName(e.target.value)}
                value={theName}
            >
            </TextField>
            <TextField
                className="modal__editUser__input"
                label="Email *"
                variant="outlined"
                name="theEmail"
                fullWidth
                onChange={(e) => setTheEmail(e.target.value)}
                value={theEmail}
            >
            </TextField>
            <TextField
                className="modal__editUser__input"
                label="Lösenord *"
                variant="outlined"
                name="thePassword"
                type="password"
                fullWidth
                onChange={(e) => setThePassword(e.target.value)}
                value={thePassword}
                placeholder={"*Ange ett nytt lösenord*"}
                InputLabelProps={{ shrink: true }}
            >
            </TextField>
            <TextField
                className="modal__editUser__input"
                label="Bekräfta lösenord *"
                variant="outlined"
                name="confirmPassword"
                type="password"
                fullWidth
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
            >
            </TextField>
            {/* <TextField
                className="modal__editUser__input"
                label="Pinkod"
                variant="outlined"
                name="thePincode"
                type="password"
                fullWidth
                onChange={(e) => setThePincode(e.target.value)}
                value={thePincode}
            >
            </TextField>
            <TextField
                className="modal__editUser__input"
                label="Bekräfta pinkod"
                variant="outlined"
                name="confirmPincode"
                type="password"
                fullWidth
                onChange={(e) => setConfirmPincode(e.target.value)}
                value={confirmPincode}
            >
            </TextField> */}
            <p>Sportsonbutiker</p>
            {loadTheSportsonShops.length > 0 && <Select
                className="itSupport__select"
                name="theShops"
                onChange={handleOnChange}
                isMulti
                options={shopsOptions}
                styles={customStyles}
                defaultValue={loadTheSportsonShops}
                ref={selectShopsRef}
                menuPlacement="top"
            >
            </Select>}
            {loadTheSportsonShops.length === 0 && <Select
                className="itSupport__select"
                name="theShops"
                onChange={handleOnChange}
                isMulti
                options={shopsOptions}
                styles={customStyles}
                menuPlacement="top"
            >
            </Select>}
            <select
                className="itSupport__select modal__editUser__select"
                name="theAuthorizationlevel"
                onChange={(e) => setTheUserRight(e.target.value)}
                value={theUserRight}
            >
              <option className="itSupport__optionText" value="" disabled>
                Behörighet *
              </option>
              { userRights.length > 0 && userRights.map((userRight) => (
                  <option value={userRight._id} >{userRight.name}</option>
              ))}
            </select>
            <div className="modal__editUser__buttonDiv">
              <button onClick={(e) => editUser(e, closeModalHandler)} className="modal__editUser__button">
                Ändra användare
              </button>
              <button onClick={closeModalHandler} className="modal__editUser__button">
                {buttonName}{" "}
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
      <Modal
          showModal={showTheModal}
          closeModalHandler={closeTheModalHandler}
          title={theTitle}
          buttonName="Stäng"
      />
    </div>
  );
}

export default InputModal;

import React, { useState, useEffect } from "react";
import "./style.css";
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import API from "../../../utils/API";
import Modal from "../../Modals/Modal-ConfirmAlert";
import RadioButtonsWithTitle from "../../RadioButtonsWithTitle";

function ArticleTextfieldsUsedBikes({
    PartNo,
    BarCode,
    ProductId,
    ProductName,
    ManufacturerId,
    SupplierId,
    MainCategoryId,
    PurchasePrice,
    SalePrice,
    Margin,
    YearModel,
    OriginalPrice,
    DrivingDistance,
    Staff,
    Comment,
    InternComment,
    commentData,
    internCommentData,
    //selectShop,
    Shop,
    shopData,
    WarehouseId,
    shopTitle,
    //manufacturerData,
    //selectManufacturer,
    manufacturerName,
    supplierName,
    //selectSupplier,
    //supplierData,
    categoryName,
    selectCategory,
    categoryData,
    //changeSearchStatus,
    handleClear,
    userNameData,
    name,
}) {
  const {
    control,
    handleSubmit,
    register,
    setValue,
    errors,
    onChange,
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      PartNo,
      BarCode,
      ProductId,
      ProductName,
      ManufacturerId,
      SupplierId,
      MainCategoryId,
      PurchasePrice,
      SalePrice,
      Margin,
      YearModel,
      OriginalPrice,
      DrivingDistance,
      Comment,
      InternComment,
      Staff,
      Shop,
      WarehouseId,
      shopTitle,
      manufacturerName,
      supplierName,
      categoryName,
      name,
    },
  });

  useEffect(() => {
    const articleTextFields = document.getElementsByClassName("articleTextfieldsUsedBikes__textfield");

    for (let articleTextField of articleTextFields) {
      // Prevent pressing enter on article search field
      articleTextField.addEventListener('keypress', function (e) {
        if (e.keyCode === 13 || e.which === 13) {
          e.preventDefault();
          return false;
        }
      });
    }

  }, []);

  const [sortType, /*setSortType*/] = useState("asc");
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [theSalePrice, setTheSalePrice] = useState("");
  const [thePurchasePrice, setThePurchasePrice] = useState("");
  const [theMargin, setTheMargin] = useState("");
  const [/*theYearModel*/, setTheYearModel] = useState("");
  const [/*theOriginalPrice*/, setTheOriginalPrice] = useState("");
  const [/*theDrivingDistance*/, setTheDrivingDistance] = useState("");
  const [/*theBatteryStatus*/, setTheBatteryStatus] = useState("");
  const [/*theCondition*/, setTheCondition] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filesChosen, setFilesChosen] = useState(false);
  const [areFilesUploaded, setAreFilesUploaded] = useState(false);
  const [theImageRef, setTheImageRef] = useState([]);

  const closeModalHandler = () => setShowModal(false);

  const handleFileChange = (event) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
    setFilesChosen(true);
    setAreFilesUploaded(false);
    event.target.value = "";
  };

  const handleUploadFiles = (e) => {
    const formData = new FormData();

    e.preventDefault();
    if (selectedFiles.length >= 2 && selectedFiles.length <= 5) {
      selectedFiles.forEach((file) => {
        formData.append('file', file);
      });

      API.uploadFiles(formData)
          .then(function (response) {
            if (response.status === 201) {
              setAreFilesUploaded(true);
              setUploadedFiles(selectedFiles);
              setTheImageRef(response.data);
              setShowModal(true);
              setTitle("Bilderna har laddats upp.");
            } else if (response.status >= 300) {
              setShowModal(true);
              setTitle(`${response.data.message} - ${response.data.details}`);
            }
          })
          .catch((/*error*/) => {
            //console.log(error);
            setShowModal(true);
            setTitle("Bilderna kunde ej laddas upp.");
          });
    } else {
      setShowModal(true);
      setTitle('Minst 2 och max 5 bilder måste bifogas. Välj bilderna med knappen till vänster och ladda därefter upp bilderna med knappen "Ladda upp bilder".');
    }
  };

  const handleRemoveFiles = () => {
    setSelectedFiles([]);
    setTheImageRef([]);

    setFilesChosen(false);
  };

  /* Remove not in use functions for now
  const sortedManufacturer = [
    ...manufacturerData.sort((a, b) => {
      const isReversed = sortType === "asc" ? 1 : -1;
      return isReversed * a.ManufacturerCode.localeCompare(b.ManufacturerCode);
    }),
  ];
   */

  /* Remove not in use functions for now
const sortedSupplier = [
  ...supplierData.sort((a, b) => {
    const isReversed = sortType === "asc" ? 1 : -1;
    return isReversed * a.Name.localeCompare(b.Name);
  }),
];
   */

const sortedCategory = [
  ...categoryData.sort((a, b) => {
    const isReversed = sortType === "asc" ? 1 : -1;
    return isReversed * a.Code.localeCompare(b.Code);
  }),
];

const handleData = (data) => {

  data.StateDescription = Comment;
  data.Comment = InternComment;
  data.Shop = Shop;
  data.ImageRef = theImageRef;
  delete data.shopTitle;
  delete data.Staff;

  return data;
};

const onSubmit = (data, e) => {

  if (selectedFiles.length >= 2 && selectedFiles.length <= 5) {
    handleData(data);

    API.registerUsedBike(data)
        .then(function (response) {
          if (response.status === 201){
            setShowModal(true);
            setTitle(`Begagnatartikel med artikelnummer ${response.data.UsedBikePartNo} har skapats, invänta bekräftelse från HK innan du kan lägga in den i kassan.`);
            handleRemoveFiles();
            handleClear();
            e.target.reset();
          } else if (response.status >= 300) {
            setShowModal(true);
            setTitle(`${response.data.message} - ${response.data.details}`);
          } else if (response.status === 400){
            setShowModal(true);
            setTitle(response.data.message);
          }
        })
        .catch((/*error*/) => {
          //console.log(error);
          setShowModal(true);
          setTitle(`Fel: artikeln med artikelnummer ${data.PartNo} kunde ej hanteras.`);
        });
  }
  else {
    setShowModal(true);
    setTitle('Minst 2 och max 5 bilder måste bifogas. Välj bilderna med knappen till vänster och ladda därefter upp bilderna med knappen "Ladda upp bilder".');
  }
};

const handleCountMargin = () => {

  let tempMargin = "";

  if (theSalePrice != 0 && thePurchasePrice != 0 && !isNaN(theSalePrice) && !isNaN(thePurchasePrice)) {
    tempMargin = ((theSalePrice - (thePurchasePrice * 1.25)) / theSalePrice);
    tempMargin = tempMargin * 100;
    if (tempMargin !== "")
      tempMargin = new Number(tempMargin).toFixed(2);
  }

  if (!isNaN(tempMargin))
    if (tempMargin !== "")
      setTheMargin(tempMargin);
  if (theSalePrice === "" && thePurchasePrice === "")
    setTheMargin("");
};

useEffect(() => {
  handleCountMargin();
}, [theSalePrice, thePurchasePrice, PartNo]);

useEffect(() => {
  setTheMargin(Margin);
}, [Margin]);

return (
  <div className="articleTextfields">
    <div className="articleTextfieldsUsedBikes__container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="articleTextfieldsUsedBikes__textFieldContainer">
          {/* Remove for now
          <TextField
            as={TextField}
            control={control}
            inputRef={register({ required: true })}
            error={errors.ProductName}
            label="Produktnamn"
            variant="outlined"
            className="articleTextfieldsUsedBikes__textfield"
          ></TextField>
          */}
            <TextField
              as={TextField}
              control={control}
              inputRef={register({ required: true })}
              error={errors.PartNo}
              label="Artikelnummer"
              variant="outlined"
              name="PartNo"
              className="articleTextfieldsUsedBikes__textfield"
            ></TextField>
            {/* Remove for now
            <TextField
              as={TextField}
              control={control}
              inputRef={register({
                required: true,
              })}
              error={errors.BarCode}
              label="Streckkod"
              variant="outlined"
              name="BarCode"
              className="articleTextfieldsUsedBikes__textfield"
            ></TextField>
            */}
            {/*
            <select
              defaultValue={ManufacturerId?.id}
              className="articleTextfieldsUsedBikes__shopSelectorFull"
              onChange={selectManufacturer}
              ref={register({ required: true })}
              error={errors.ManufacturerId}
            >
              <option className="articleTextfieldsUsedBikes__optionText" value="">
                Välj varumärke
              </option>
              {sortedManufacturer
                ? manufacturerData.map((m) => (
                    <option
                      className="articleTextfieldsUsedBikes__optionText"
                      key={m.ManufacturerId}
                      value={m.ManufacturerId}
                      selected={m.ManufacturerId === ManufacturerId}
                    >
                      {m.ManufacturerCode}
                    </option>
                  ))
                : null}
            </select>
            <label className="articleTextfieldsUsedBikes__selectLabels">Varumärke</label>
            */}
            {/*
            <select
              defaultValue={SupplierId?.id}
              className="articleTextfieldsUsedBikes__shopSelectorFull"
              onChange={selectSupplier}
              ref={register({ required: true })}
              error={errors.SupplierId}
            >
              <option className="articleTextfieldsUsedBikes__optionText" value="">
                Välj leverantör
              </option>
              {sortedSupplier
                ? supplierData.map((supplier) => (
                    <option
                      className="articleTextfieldsUsedBikes__optionText"
                      key={supplier.SupplierId}
                      value={supplier.SupplierId}
                      selected={supplier.SupplierId === SupplierId}
                    >
                      {supplier.Name}
                    </option>
                  ))
                : null}
            </select>
            <label className="articleTextfieldsUsedBikes__selectLabels">Leverantör</label>
            */}
            <select
              defaultValue={MainCategoryId}
              className="articleTextfieldsUsedBikes__shopSelectorFull"
              onChange={selectCategory}
              ref={register({ required: true })}
              name="MainCategoryId"
            >
              {sortedCategory
                ? categoryData.map((c) => (
                    <option
                      className="articleTextfieldsUsedBikes__optionText"
                      key={c.Id}
                      value={c.Id}
                      selected={c.Id === MainCategoryId}
                    >
                      {c.Code}
                    </option>
                  ))
                : null}
              <option className="articleTextfieldsUsedBikes__optionText" value="">
                Välj artikelgrupp
              </option>
            </select>
            <label className="articleTextfieldsUsedBikes__selectLabels">Artikelgrupp</label>
          </div>
          <div className="articleTextfieldsUsedBikes__priceContainer">
            <TextField
              as={TextField}
              control={control}
              inputRef={register({ required: true })}
              error={errors.PurchasePrice}
              label="Inpris"
              variant="outlined"
              name="PurchasePrice"
              className="articleTextfieldsUsedBikes__textfield"
              onChange={(e) => setThePurchasePrice(e.target.value)}
              onFocus={(e) => setThePurchasePrice(e.target.value)}
              required
            ></TextField>
            <TextField
              as={TextField}
              control={control}
              inputRef={register({ required: true })}
              error={errors.SalePrice}
              label="Utpris"
              variant="outlined"
              name="SalePrice"
              className="articleTextfieldsUsedBikes__textfield"
              onChange={(e) => setTheSalePrice(e.target.value)}
              onFocus={(e) => setTheSalePrice(e.target.value)}
              required
            ></TextField>
            <TextField
                label="Marginal"
                variant="outlined"
                name="Margin"
                className="articleTextfieldsUsedBikes__textfield"
                value={theMargin}
            ></TextField>
          </div>
          {/* TODO: Remove for now
          <div className="articleTextfieldsUsedBikes__priceContainer">
          <TextField
              as={TextField}
              control={control}
              inputRef={register({ required: true })}
              error={errors.YearModel}
              label="Årsmodell"
              variant="outlined"
              name="YearModel"
              className="articleTextfieldsUsedBikes__textfield"
              onChange={(e) => setTheYearModel(e.target.value)}
              onFocus={(e) => setTheYearModel(e.target.value)}
          ></TextField>
          <TextField
              as={TextField}
              control={control}
              inputRef={register({ required: true })}
              error={errors.OriginalPrice}
              label="Ursprungspris"
              variant="outlined"
              name="OriginalPrice"
              className="articleTextfieldsUsedBikes__textfield"
              onChange={(e) => setTheOriginalPrice(e.target.value)}
              onFocus={(e) => setTheOriginalPrice(e.target.value)}
              required
          ></TextField>
          <TextField
              as={TextField}
              control={control}
              inputRef={register({ required: true })}
              error={errors.DrivingDistance}
              label="Körsträcka"
              variant="outlined"
              name="DrivingDistance"
              className="articleTextfieldsUsedBikes__textfield"
              onChange={(e) => setTheDrivingDistance(e.target.value)}
              onFocus={(e) => setTheDrivingDistance(e.target.value)}
          ></TextField>
        </div>
        <div className="articleTextfieldsUsedBikes__textFieldContainer">
          <RadioButtonsWithTitle
              title="Batteristatus"
              checked={(e) => setTheBatteryStatus(e.target.value)}
          />
        </div>
        <div className="articleTextfieldsUsedBikes__textFieldContainer">
          <RadioButtonsWithTitle
              title="Allmänt skick"
              checked={(e) => setTheCondition(e.target.value)}
          />
        </div>
        */}
        <div>
          <TextField
            className="articleTextfieldsUsedBikes__commentBox"
            label="Beskriv produktens skick"
            variant="outlined"
            name="Comment"
            multiline
            minRows={4}
            onChange={commentData}
            required
          ></TextField>
        </div>
          <div>
            <TextField
                className="articleTextfieldsUsedBikes__commentBox"
                label="Lägg till intern kommentar"
                variant="outlined"
                name="internComment"
                multiline
                minRows={4}
                onChange={internCommentData}
            ></TextField>
          </div>
          <div className="articleTextfieldsUsedBikes__fileUploadDiv">
            <legend className="articleTextfieldsUsedBikes__fileUploadDivLegend">Bilder *</legend>
            <div className="articleTextfieldsUsedBikes__fileUploadButtonsDiv">
              <input className="articleTextfieldsUsedBikes__fileInputButton" type="file" multiple onChange={handleFileChange} />
              <button className="articleTextfieldsUsedBikes__imageUploadButtons" onClick={(e) => handleUploadFiles(e)} >Ladda upp bilder</button>
              {filesChosen && <button className="articleTextfieldsUsedBikes__imageUploadButtons" onClick={handleRemoveFiles}>Ta bort bilder</button>}
            </div>
            {filesChosen && !areFilesUploaded && (
                <div className="articleTextfieldsUsedBikes__centerText">
                  <ul>
                    <h5>Följande bilder är valda:</h5>
                    {selectedFiles.map((file, index) => (
                        <>
                          <li key={index}>{file.name}</li>
                          <img src={URL.createObjectURL(file)} alt={file.name} key={index} className="articleTextfieldsUsedBikes__previewImage" />
                        </>
                    ))}
                    <h5>Ladda upp bilderna med </h5>
                    <h5>knappen "Ladda upp bilder".</h5>
                    <h5>Alternativt ta bort bilderna med </h5>
                    <h5>knappen "Ta bort bilder".</h5>
                  </ul>
                </div>
            )}
            {areFilesUploaded && (
                <div className="articleTextfieldsUsedBikes__centerText">
                  <ul>
                    <p>Följande bilder har laddats upp:</p>
                    {uploadedFiles.map((file, index) => (
                        <>
                          <li key={index}>{file.name}</li>
                          <img src={URL.createObjectURL(file)} alt={file.name} key={index} className="articleTextfieldsUsedBikes__previewImage" />
                        </>
                    ))}
                  </ul>
                </div>
            )}
            {!filesChosen && !areFilesUploaded && (
                <>
                  <p className="articleTextfieldsUsedBikes__noFilesText">Inga bilder har valts.</p>
                  <p className="articleTextfieldsUsedBikes__noFilesText">Välj bilder med knappen till vänster och</p>
                  <p className="articleTextfieldsUsedBikes__noFilesText">ladda upp bilderna med knappen "Ladda upp bilder".</p>
                  <p className="articleTextfieldsUsedBikes__noFilesText">(minst 2 och max 5 bilder)</p>
                </>
            )}
          </div>
          <div className="articleTextfieldsUsedBikes__priceContainer">
            <select
              value={WarehouseId.id}
              className="articleTextfieldsUsedBikes__shopSelector articleTextfieldsUsedBikes__butikSelect"
              onChange={shopTitle}
              ref={register({ required: true })}
              name="shopTitle"
            >
              {shopData.length > 1 ? (<option className="articleTextfieldsUsedBikes__optionText" value="">
                Välj butik
              </option>) : null }
              {shopData
                ? shopData.map((s) => (
                    <option
                      className="articleTextfieldsUsedBikes__textfield"
                      key={s.WarehouseId}
                      value={s.WarehouseId}
                    >
                      {s.Name}
                    </option>
                  ))
                : null}
            </select>
            <label className="articleTextfieldsUsedBikes__butikLabel">Butik</label>
            <TextField
                label="Personalens namn"
              as={TextField}
              value={userNameData.name}
              control={control}
              inputRef={register({ required: true })}
              error={errors.name}
              // label="Namn"
              variant="outlined"
              className="articleTextfieldsUsedBikes__textfield"
              onChange={userNameData.name}
                name="name"
            >
              {userNameData.name}
            </TextField>
          </div>
          <button
            className="articleTextfieldsUsedBikes__sendBtn"
            type="submit"
            onClick={(e) => {
              setValue(e.target.value);
              handleClear();
            }}
          >
            SKICKA
          </button>
        </form>
      </div>
      <Modal
          showModal={showModal}
          closeModalHandler={closeModalHandler}
          title={title}
          buttonName="Stäng"
      />
    </div>
  );
}

export default ArticleTextfieldsUsedBikes;

import React from "react";
import "./style.css";

function Comment({ comment }) {
    return (
        <>
            <h6 className="commentCompanyOrWindow__date">{comment.DateCreated.slice(0,10)} {comment.LastEditBy ? ` ( Ändrad ${comment.DateLastEdited.slice(0,10)} )` : null}</h6>
            <h6 className="commentCompanyOrWindow__comment_text" dangerouslySetInnerHTML={{__html: comment.CommentText}}></h6>
        </>
    );
}

export default Comment;
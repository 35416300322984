import React, {useState} from "react";
import "./style.css";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import API from "../../utils/API";
import {fetchOrder} from "../../Redux/actions/orderAction";
import {logout} from "../../Redux/actions/userActions";
import {useDispatch} from "react-redux";
import Modal from "../Modals/Modal-ConfirmAlert";

function InvoiceNumbers({ orderInfo, order }) {
    const [title, setTitle] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [invoiceNumber, setInvoiceNumber] = useState("");
    //const [invoiceDate, setInvoiceDate] = useState(undefined);
    const dispatch = useDispatch();

    const closeModalHandler = () => setShowModal(false);

    const onEnterHandlerAddInvoiceNumber = (e, orderID, orderNumber, previousOrderIncomingInvoices) => {

        if (e.key === "Enter"){
            saveOrderInvoiceNumberHandler(e, orderID, orderNumber, previousOrderIncomingInvoices);
        }
    }

    const showSaveButtonHandler = (e) => {
        if(e.target.nextElementSibling.classList.contains("orderForm__hidden"))
            e.target.nextElementSibling.classList.remove("orderForm__hidden");
    }

    const saveOrderInvoiceNumberHandler = (e,orderID, orderNumber, previousOrderIncomingInvoices = []) => {
        e.preventDefault();

        if (invoiceNumber !== "") {
            const data = {
                OrderIncomingInvoiced: true,
                OrderIncomingInvoices: [...previousOrderIncomingInvoices, {
                    InvoiceNumber: invoiceNumber,
                    //InvoiceDate: moment(invoiceDate).format("yyyy-MM-DD"),
                }],
            };

            API.updateOrderHead(data, orderID).then((response) => {
                if (response.status === 200) {
                    // TODO separate duplicate code below to a function:
                    dispatch(fetchOrder(encodeURI(orderNumber))).then(() => {
                        document.getElementsByClassName("invoiceNumbers__invoiceContainer")[0].scrollIntoView({block: "end", behavior: "instant"});
                    });
                } else if (response.status >= 300) {
                    setShowModal(true);
                    setTitle(`${response.data.message} - ${response.data.details.message}`);
                } else if (response.status === 401) {
                    void dispatch(logout());
                }
                setInvoiceNumber("");
                //setInvoiceDate(undefined);
            });
            //validateOrder(orderNumber);
        } else {
            setShowModal(true);
            setTitle("Fältet för fakturanummer kan inte vara tomt");
        }
    }

    const editOrderInvoiceNumberHandler = (e,orderID, orderNumber, previousOrderIncomingInvoices = [], index, newInvoiceNumber) => {
        e.preventDefault();

        e.target.classList.add("orderForm__hidden");

        if (newInvoiceNumber !== "") {
            [...previousOrderIncomingInvoices][index].InvoiceNumber = newInvoiceNumber;
            const data = {
                OrderIncomingInvoiced: true,
                OrderIncomingInvoices: [...previousOrderIncomingInvoices],
            };

            API.updateOrderHead(data, orderID).then((response) => {
                if (response.status === 200) {
                    // TODO separate duplicate code below to a function:
                    dispatch(fetchOrder(encodeURI(orderNumber))).then(() => {
                        document.getElementsByClassName("invoiceNumbers__invoiceContainer")[0].scrollIntoView({block: "end", behavior: "instant"});
                    });
                } else if (response.status >= 300) {
                    setShowModal(true);
                    setTitle(`${response.data.message} - ${response.data.details.message}`);
                } else if (response.status === 401) {
                    void dispatch(logout());
                }
                setInvoiceNumber("");
                //setInvoiceDate(undefined);
            });
            //validateOrder(orderNumber);
        } else {
            setShowModal(true);
            setTitle("Fältet för fakturanummer kan inte vara tomt");
        }
    }

    const deleteOrderInvoiceNumberHandler = (e,orderID, orderNumber, previousOrderIncomingInvoices = [], index) => {
        e.preventDefault();

        const removeInvoiceInArray = (index) => {
            const invoices = [...previousOrderIncomingInvoices];

            invoices.splice(index, 1);
            return invoices;
        }

        const data = {
            OrderIncomingInvoiced: [...previousOrderIncomingInvoices].length > 1,
            OrderIncomingInvoices: [...previousOrderIncomingInvoices].length === 1 ? [] : removeInvoiceInArray(index),
        };

        API.updateOrderHead(data, orderID).then((response) => {
            if (response.status === 200) {
                // TODO separate duplicate code below to a function:
                dispatch(fetchOrder(encodeURI(orderNumber))).then(() => {
                    document.getElementsByClassName("invoiceNumbers__invoiceContainer")[0].scrollIntoView({block: "end", behavior: "instant"});
                });
            } else if (response.status >= 300) {
                setShowModal(true);
                setTitle(`${response.data.message} - ${response.data.details.message}`);
            } else if (response.status === 401) {
                void dispatch(logout());
            }
        });
        //validateOrder(orderNumber);
    }

    return (
        <>
            <Modal
                showModal={showModal}
                closeModalHandler={closeModalHandler}
                title={title}
                buttonName="Stäng"
            />
            <div className="invoiceNumbers__invoiceContainer">
                <h4>Fakturanummer från butik</h4>
                <div className="invoiceNumbers__addInvoiceNumberDiv"><input type="text"
                                                                                                      className="orderForm__input invoiceNumbers__inputInvoiceNumber"
                                                                                                      onKeyDown={(e) => onEnterHandlerAddInvoiceNumber(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo, orderInfo[order].OrderHead.OrderIncomingInvoices)}
                                                                                                      onChange={(e) => setInvoiceNumber(e.target.value)}/>
                    <button
                        onClick={(e) => saveOrderInvoiceNumberHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo, orderInfo[order].OrderHead.OrderIncomingInvoices)}
                        className="invoiceNumbers__addInvoiceNumberButton"><span className="orderForm__iconAndText"><AddIcon
                        className="orderForm__icons"/></span></button>
                </div>
                {/* editInvoiceNumber && <><p>Fakturadatum:</p><DatePicker className="orderForm__inputTypeDate" dateFormat="yyyy-MM-dd" selected={invoiceDate} onChange={(date) => setInvoiceDate(date)} value={invoiceDate}  id="invoiceDate" name="invoiceDate" locale={sv} required/></> */}
                {orderInfo[order].OrderHead?.OrderIncomingInvoices && orderInfo[order].OrderHead.OrderIncomingInvoices.map((invoice, index) => (
                    <>
                        <h5><span className="invoiceNumbers__invoiceNumber">Faktnr {index + 1}</span><input type="text"
                                                                                                       className="invoiceNumbers__editInvoiceNumber"
                                                                                                       defaultValue={invoice.InvoiceNumber}
                                                                                                       onChange={(e) => showSaveButtonHandler(e)}/>
                            <button className="invoiceNumbers__editInvoiceNumberButton orderForm__hidden"
                                    onClick={(e) => editOrderInvoiceNumberHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo, orderInfo[order].OrderHead.OrderIncomingInvoices, index, document.getElementsByClassName("invoiceNumbers__editInvoiceNumber")[index].value)}><span
                                className="orderForm__iconAndText"><CheckCircleOutlineIcon
                                className="orderForm__icons"/></span>
                            </button>
                            <button className="invoiceNumbers__deleteInvoiceNumberButton"
                                    onClick={(e) => deleteOrderInvoiceNumberHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo, orderInfo[order].OrderHead.OrderIncomingInvoices, index)}><span
                                className="orderForm__iconAndText"><DeleteIcon className="orderForm__icons"/></span>
                            </button>
                        </h5>
                        {/* <h5><span className="orderForm__invoiceNumber">Faktnr {index+1}</span>{`${invoice.InvoiceNumber} (${invoice.InvoiceDate.slice(0,10)})`}</h5> */}
                    </>
                ))}
            </div>
        </>
    );
}

export default InvoiceNumbers;
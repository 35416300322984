import React, {useEffect, useState} from "react";
import "./style.css";
//import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
//import { Checkbox } from "@material-ui/core";
//import InputModal from "../InputModal";
import Modal from "../../Modals/Modal-ConfirmAlert";

function EditProductRow({ orderRows, index, itemSalesPrices, setEditedItem, editedItem }) {
  //const [toggle, setToggle] = useState(false);
  //const [showModal, setShowModal] = useState(false);
  //const [isCheck, setIsCheck] = useState("");
  //const [open, setOpen] = React.useState(false);
  //const [grossAmount, setGrossAmount] = useState(orderRows[0].Items[index].LineAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")); Before SP-472
    const [grossAmount, setGrossAmount] = useState(orderRows[index].GrossAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
  const [quantity, setQuantity] = useState(orderRows[index].QtyOrdered);
  //const [vatRate, setVatRate] = useState(orderRows[index].VatRate);
  const [errandNumber, setErrandNumber] = useState(orderRows[index].ErrandNo);
  const [frameNumber, setFrameNumber] = useState(orderRows[index].FrameNo);
  const [discountPercentage, /*setDiscountPercentage*/] = useState(orderRows[index].DiscountPercentage);
  const [discountAmount, setDiscountAmount] = useState(orderRows[index].DiscountAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
  //const [discountAmountTotal, setDiscountAmountTotal] = useState((orderRows[0].Items[index].LineAmount - orderRows[0].Items[index].DiscountAmount)); Before SP-472
    const [discountAmountTotal, setDiscountAmountTotal] = useState((orderRows[index].GrossAmount - orderRows[index].DiscountAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");

  /* Remove unused code for now
  const handleClose = () => {
    setOpen(false);
    // setShowModal(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    // setShowModal(true);
  };

  const handleChecked = (event) => {
    setIsCheck(event.target.value);
  };

  const handleToggle = () => {
    setToggle((toggle) => !toggle);
  };
   */

  const closeModalHandler = () => setShowModal(false);

    const showSaveButtonHandler = (e) => {
        if(e.target.parentElement.parentElement.parentElement.parentElement.parentElement.children[2].classList.contains("orderForm__hidden"))
            e.target.parentElement.parentElement.parentElement.parentElement.parentElement.children[2].classList.remove("orderForm__hidden");
    }

  useEffect(() => {
     const data =
         {
             LineNumber: orderRows[index].LineNumber,
             PartNo: orderRows[index].PartNo,
             QtyOrdered: quantity && quantity !== "" ? parseInt(quantity) : parseInt(quantity.replaceAll(/\s/g,"")),
             DiscountPercentage: discountPercentage,
             DiscountAmount: discountAmount && discountAmount !== "" ? parseInt(discountAmount) : 0,
             //LineAmount: quantity > 1 ? response.data.SalePrice * quantity : parseInt(grossAmount.replaceAll(/\s/g,"")), Before SP-472
             LineAmount: quantity >= 1 ? itemSalesPrices.get(orderRows[index].PartNo) * quantity : "",
             VatRate: "",
             FrameNo: frameNumber,
             errandNo: errandNumber,
             isEdited: true,
         };

     setEditedItem(data);
  }, [quantity]);

    useEffect(() => {
        const data =
            {
                LineNumber: orderRows[index].LineNumber,
                PartNo: orderRows[index].PartNo,
                QtyOrdered: quantity,
                DiscountPercentage: discountPercentage,
                DiscountAmount: discountAmount && discountAmount !== "" ? parseInt(discountAmount) : 0,
                LineAmount: parseInt(grossAmount.replaceAll(/\s/g,"")),
                VatRate: "",
                FrameNo: frameNumber,
                errandNo: errandNumber,
                isEdited: true,
            };
        setEditedItem(data);

    }, [grossAmount]);

    useEffect(() => {
        const data =
            {
                LineNumber: orderRows[index].LineNumber,
                PartNo: orderRows[index].PartNo,
                QtyOrdered: quantity,
                DiscountPercentage: discountPercentage,
                DiscountAmount: discountAmount && discountAmount !== "" && discountAmount > 0 ? parseInt(discountAmount) : 0,
                //LineAmount: orderRows[index].LineAmount, Before SP-472
                LineAmount: quantity >= 1 ? itemSalesPrices.get(orderRows[index].PartNo) * quantity : "",
                VatRate: "",
                FrameNo: frameNumber,
                errandNo: errandNumber,
                isEdited: true,
            };
        setEditedItem(data);

    }, [discountPercentage, discountAmount]);

    useEffect(() => {
        const data =
            {
                LineNumber: orderRows[index].LineNumber,
                PartNo: orderRows[index].PartNo,
                QtyOrdered: quantity,
                DiscountPercentage: discountPercentage,
                DiscountAmount: discountAmount && discountAmount !== "" ? parseInt(discountAmount) : 0,
                LineAmount: quantity >= 1 ? itemSalesPrices.get(orderRows[index].PartNo) * quantity : "",
                VatRate: "",
                FrameNo: frameNumber,
                errandNo: errandNumber,
                isEdited: orderRows[index].isEdited,
            };
        setEditedItem(data);

    }, [errandNumber, frameNumber]);

  return (
    <div className="editProductRow">
      <Modal
          showModal={showModal}
          closeModalHandler={closeModalHandler}
          title={title}
          buttonName="Stäng"
      />
      <div className="editProductRow__Container">
        <div className="editProductRow__container">
          <div className="editProductRow__infoContainer">
            <img
              className="editProductRow__img"
              src={orderRows[index]?.ImageLink}
              alt="Product"
              loading="lazy"
            />
            <div className="editProductRow__bikeInfo">
              <div className="editProductRow__supplierTag">
                <h3>
                  {/* {orderRows[0].Items[index].Manufacturer.ManufacturerCode} */}
                  {orderRows[index]?.Supplier && orderRows[index]?.Supplier[0]?.Name ? orderRows[index]?.Supplier[0]?.Name : ""}
                </h3>
              </div>
              <h1>{orderRows[index]?.ProductName}</h1>
              <div className="editProductRow__isDiv">
                {orderRows[index]?.isDeleted ? <h6 className="editProductRow__itemIsDeleted">RADERAD</h6> : null}
                {orderRows[index]?.isCustom ? <h6 className="editProductRow__itemIsCustom">TILLAGD</h6> : null}
                {orderRows[index]?.isEdited ? <h6 className="editProductRow__itemIsedited">ÄNDRAD</h6> : null}
              </div>
              {/*<span>{orderRows[0].Items[index].FrameNo}</span>*/}
            </div>
          </div>
          <div className="editProductRow__secondContainer">
            <h3 className="editProductRow__artNr">
              {orderRows[index]?.PartNo}
            </h3>
            <h3 className="editProductRow__barcode">
              {orderRows[index]?.EanCode}
            </h3>
          </div>
          {/* </div> */}
          {/* ))} */}
          {/* </div>
            ))} */}
          {/*
          <div className="editProductRow__comment">
            <button
              className="editProductRow__commentBtn"
              onClick={() => {
                handleClickOpen();
                setTitle("Lägg till kommentar");
              }}
            >
              <ChatBubbleOutlineIcon className="editProductRow__commentIcon" />{" "}
              Kommentera skick
            </button>
            <InputModal
              open={open}
              closeModalHandler={handleClose}
              title={title}
              bodyContent=""
              buttonName="Spara"
            />
          </div>
          */}
          <div className="editProductRow__thirdContainer">
            <input type="text" className="editProductRow__grossPrice" onChange={(e) => setGrossAmount(e.target.value)} onKeyDown={(e) => showSaveButtonHandler(e)} defaultValue={grossAmount} />
          </div>
            {/*
            {toggle ? (
              <div className="editProductRow__checkbox">
                Sitter på cykeln
                <Checkbox
                  color="default"
                  value="onBike"
                  checked={isCheck === "onBike"}
                  onChange={handleChecked}
                />
                Kassera{" "}
                <Checkbox
                  color="default"
                  value="kassera"
                  checked={isCheck === "kassera"}
                  onChange={handleChecked}
                />
              </div>
            ) : null}
            */}
            {/*
            <div className="editProductRow__optionBtnContainer">
              <button
                // value={}
                onClick={handleToggle}
                className="editProductRow__receiveBtn"
              >
                Mottagen
              </button>
              <button
                className="editProductRow__missingBtn"
                onClick={() => {
                  handleClickOpen();
                  setTitle("Varför saknas artikeln?");
                }}
              >
                Saknas
              </button>
              <InputModal
                open={open}
                handleClose={handleClose}
                title={title}
                // bodyContent=""
                buttonName="Spara"
              />
            </div>
            */}
          <div className="editProductRow__fourthContainer">
            <input type="text" className="editProductRow__quantity" onChange={(e) => setQuantity(e.target.value)} onKeyDown={(e) => showSaveButtonHandler(e)} defaultValue={quantity} />
          </div>
            <div className="editProductRow__fifthContainer">
                <input className="editProductRow__discountInputs" type="text"
                       onChange={(e) => setDiscountAmount(e.target.value)} onKeyDown={(e) => showSaveButtonHandler(e)} defaultValue={discountAmount}/>
            </div>
            {/*<div className="editProductRow__sixthContainer">
            <input className="editProductRow__discountInputs" type="text" onChange={(e) => setDiscountPercentage(e.target.value)} defaultValue={discountPercentage} />
          </div>*/}
            <div className="editProductRow__sixthContainer">
                <input className="editProductRow__discountInputs" type="text"
                       onChange={(e) => setDiscountAmountTotal(e.target.value)} defaultValue={discountAmountTotal}
                       disabled/>
            </div>
            {orderRows[index]?.isBike ? <div className="editProductRow__seventhContainer">
                <h6>Ärendenummer:</h6>
                <textarea className="editProductRow__errandAndFrameNumberInputs" rows="2" onChange={(e) => setErrandNumber(e.target.value)} onKeyDown={(e) => showSaveButtonHandler(e)} defaultValue={errandNumber} />
              {/* !orderRows[0].Items[index].isDeleted && */} <>
              <h6>Ramnummer:</h6>
              <textarea className="editProductRow__errandAndFrameNumberInputs" rows="2" onChange={(e) => setFrameNumber(e.target.value)} onKeyDown={(e) => showSaveButtonHandler(e)} defaultValue={frameNumber} />
              </> {/* } */}
            </div> : <div className="editProductRow__seventhContainer"><div className="editProductRow__fillColumn"></div></div>}
        </div>
      </div>
    </div>
  );
}

export default EditProductRow;

import React, {useState, useEffect} from "react";
import "./style.css";
import Modal from "../Modal-ConfirmAlert";
import {logout} from "../../../Redux/actions/userActions";
import {useDispatch} from "react-redux";
import API from "../../../utils/API";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@material-ui/core";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
});

function InputModal({
    //title,
    showModal,
    closeModalHandler,
    benefitWindowID,
}) {
  const [showTheModal, setShowTheModal] = useState(false);
  const [theTitle, setTheTitle] = useState("");
  const [okOrders, setOkOrders] = useState([]);
  const [missingFrameNoOrders, setMissingFrameNoOrders] = useState([]);

  const dispatch = useDispatch();

  const closeTheModalHandler = () => setShowTheModal(false);

  useEffect(() => {
    if (benefitWindowID && benefitWindowID !== "") {
      setOkOrders([]);
      setMissingFrameNoOrders([]);
      API.validateOU(benefitWindowID).then((response) => {
        if (response.status === 200) {
          let theOkOrders = response.data.OkOrders || [];
          let theMissingFrameNoOrders = response.data.MissingFrameNo || [];

          setOkOrders(theOkOrders);
          setMissingFrameNoOrders(theMissingFrameNoOrders);
        } else if (response.status >= 300) {
          //setShowTheModal(true);
          //setTheTitle(`${response.data.message} - ${response.data.details.message}`);
        } else if (response.status === 401) {
          void dispatch(logout());
        }
      });
    }
  }, [benefitWindowID, dispatch]);

  const generateOU = async (e, type) => {
    e.preventDefault();

    if (benefitWindowID && benefitWindowID !== "") {
      API.generateOU(benefitWindowID).then((response) => {
        if (response.status === 200) {
          setShowTheModal(true);
          setTheTitle(`${type} genererat och skickat till DropBox`);
        } else if (response.status >= 300) {
          setShowTheModal(true);
          setTheTitle("OU kunde ej genereras");
        } else if (response.status === 401) {
          dispatch(logout());
        }
      });
    }
  };

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          className="modal"
          open={showModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeModalHandler}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="modal__topHeaderValidateOU">
            <DialogActions className="modal__topHeaderValidateOU">
              {" "}
              <span onClick={closeModalHandler} className="modal__closeBtn">
                x
              </span>
            </DialogActions>
            <h2 className="modal__header">Validering av OU</h2>
          </div>
          <DialogContent className="modal__validateOU__body">
            <Modal
                showModal={showTheModal}
                closeModalHandler={closeTheModalHandler}
                title={theTitle}
                buttonName="Stäng"
            />
            {okOrders.length > 0 && <h5>OK ordrar:</h5>}
            {okOrders.length > 0 && okOrders.map((order) =>(
                <>
                  <a href={`/order/${order.OrderNo}`} rel="noopener noreferrer" target="_blank">{order.OrderNo},</a>
                </>
            ))}
            {missingFrameNoOrders.length > 0 && <h5>Ordrar som saknar ramnummer:</h5>}
            {missingFrameNoOrders.length > 0 && missingFrameNoOrders.map((order) =>(
                <>
                  <a href={`/order/${order.OrderNo}`} rel="noopener noreferrer" target="_blank">{order.OrderNo},</a>
                </>
            ))}
            {<br />}
        {missingFrameNoOrders.length > 0 ? <b>DET FINNS {missingFrameNoOrders.length} ORDRAR SOM SAKNAR RAMNUMMER. VID GENERERING AV OU KOMMER DÄRFÖR ETT OU-STOPP GENERERAS.</b> : okOrders.length > 0 && <b>DET FINNS INGA ORDRAR SOM SAKNAR RAMNUMMER. VID GENERERING AV OU KOMMER DÄRFÖR ETT OU-KOMPLETT GENERERAS.</b>}
          {okOrders.length === 0 && missingFrameNoOrders.length === 0 && <b>DET FINNS INGA ORDRAR ATT GENERERA OU FÖR.</b>}
          </DialogContent>
          {okOrders.length > 0 || missingFrameNoOrders.length > 0 ? <b>Vill du göra detta?</b> : null}
          {missingFrameNoOrders.length > 0 ? <button onClick={(e) => generateOU(e, "OU-STOPP")} className="modal__OU__button">Generera OU-STOPP</button> : null }
          {okOrders.length > 0 && missingFrameNoOrders.length === 0 ? <button onClick={(e) => generateOU(e, "OU-KOMPLETT")} className="modal__OU__button">Generera OU-KOMPLETT</button> : null }
          <button onClick={closeModalHandler} className="modal__OU__button">Avbryt</button>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

export default InputModal;

import React, {useEffect, useState, useCallback} from "react";
import "./PlanBenefitWindowsPC.css";
import Header from "../../components/Header";
import 'devextreme/dist/css/dx.light.css';
import ClearIcon from '@material-ui/icons/Clear';
import {useDispatch} from "react-redux";
import {logout} from "../../Redux/actions/userActions";
import API from "../../utils/API";

import {
    DataGrid,
    Column,
    Grouping,
    FilterRow,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
    Scrolling,
} from 'devextreme-react/data-grid';

import SelectBox from 'devextreme-react/select-box';
import CheckBox from 'devextreme-react/check-box';

const displayModes = [
    { text: "Display Mode 'full'", value: 'full' },
    { text: "Display Mode 'compact'", value: 'compact' },
];
const allowedPageSizes = [5, 10, 'all'];

const rulesRender = (data) => {
    let rules = "";

    data.value.val.forEach((rule, index) => {
        if (index === 0)
            rules += `${rule.rule.name}\r\n - ${rule.rule.description}\r\n`
        if (index > 0)
            rules += `_______________________________________________________\r\n${rule.rule.name}\r\n - ${rule.rule.description}\r\n`
        if (rule.parameters) {
            if (rule.parameters.x)
                rules += `    X: ${rule.parameters.x}    `
            if (rule.parameters.y)
                rules += `    Y: ${rule.parameters.y}\r\n`
        }
    });

    return (rules !== "" ? <textarea rows={4} cols={40} disabled>{rules}</textarea> : "");
}

const checkRender = (data) => {
    return (data.value && data.value.val ? <ClearIcon /> : "");
}
/* Remove for now until needed
const agreementRender = (data) => {
    API.getAgreement(data.value).then((response) => {
        // TODO render the agreement
        return response.data;
    });
}
 */
/*
const demoEventsRender = (data) => {
    let demoEvents = "";

    if (data.value && data.value.length > 0) {
        data.value.forEach((demoEvent) => {
            if (demoEvent.time_from.substring(0, 10) !== demoEvent.time_to.substring(0, 10))
                demoEvents += `Demoevent\n Adress: ${demoEvent.address}\n Tid: ${demoEvent.time_from.substring(14, 19)} - ${demoEvent.time_to.substring(11, 16)}\n Startdatum: ${demoEvent.time_from.substring(0, 10)}\n Slutdatum: ${demoEvent.time_to.substring(0, 10)}\n\n`;
            else if (demoEvent.time_from.substring(0, 10) !== "" && demoEvent.time_to.substring(0, 10) !== "" && demoEvent.time_from.substring(0, 10) === demoEvent.time_to.substring(0, 10))
                demoEvents += `Demoevent\n Adress: ${demoEvent.address}\n Tid: ${demoEvent.time_from.substring(14, 19)} - ${demoEvent.time_to.substring(11, 16)}\n Datum: ${demoEvent.time_from.substring(0, 10)}\n\n`;
        });
    }
    return demoEvents;
}
*/

const PlanBenefitWindowsPC = () => {
    const [windowList, setWindowList] = useState([]);
    const [displayMode, setDisplayMode] = useState('full');
    const [showPageSizeSelector, setShowPageSizeSelector] = useState(true);
    const [showInfo, setShowInfo] = useState(true);
    const [showNavButtons, setShowNavButtons] = useState(true);
    const dispatch = useDispatch();

    const displayModeChange = useCallback((value) => {
        setDisplayMode(value);
    }, []);
    const showPageSizeSelectorChange = useCallback((value) => {
        setShowPageSizeSelector(value);
    }, []);
    const showInfoChange = useCallback((value) => {
        setShowInfo(value);
    }, []);
    const showNavButtonsChange = useCallback((value) => {
        setShowNavButtons(value);
    }, []);
    const isCompactMode = useCallback(() => displayMode === 'compact', [displayMode]);

    window.generateWindowList = function() {
        let benefitWindows = [];
        let aBenefitWindow = {};

        API.getWindowList(0, 0).then((response) => {
            if (response.status === 200){
                response.data.forEach((benefitWindow,index) => {
                    aBenefitWindow = {
                        ID: (index+1),
                        window_setup_complete: benefitWindow.window_setup_complete.val, // Uppsatt i portalen
                        org_nr: benefitWindow.company ? benefitWindow.company.org_nr : "", // Orgnr
                        name: benefitWindow.company ? benefitWindow.company.name : "", // Bolagsnamn
                        // alternate_name: benefitWindow.company.alternate_name, // Alternativt bolagsnamn
                        benefit_window_id: benefitWindow._id.val, // Beställningsfönster ID
                        benefit_window_name: benefitWindow.name.val, // Beställningsfönster
                        startdate: benefitWindow.startdate.val.substring(0,10), // Datum från
                        enddate: benefitWindow.enddate.val.substring(0,10), // Datum till
                        // portal_username: benefitWindow.company.portal_username, // Portalanvändarnamn
                        // portal_password: benefitWindow.company.portal_password, // Portallösenord
                        window_rules: benefitWindow.window_rules, // Regler
                        conditions: benefitWindow.company ? benefitWindow.company.conditions : "", // Subvention
                        // standard_agreement: benefitWindow.standard_agreement, // Standard UH
                        security_agreement: benefitWindow.security_agreement, // Trygghetspaket
                        agreement: benefitWindow.agreement ? benefitWindow.agreement.val : "", // Avtal
                        // customer_specific_agreement: benefitWindow.customer_specific_agreement, // Kundspecifikt UH
                        // agreement_uploaded: benefitWindow.company.agreement_uploaded, // Avtal uppladdat?
                        company_id: benefitWindow.company ? benefitWindow.company._id : "", // Company ID
                        // benefit_company_id: benefitWindow.company.benefit_company_id, // B2E Site
                        // benefit_costplace_id: benefitWindow.company.benefit_costplace_id, // Enhet kostnadsställe
                        calculation_factor: benefitWindow.calculation_factor.val, // Hyresfaktor
                        // bank_id_required: benefitWindow.company.bank_id_required, // Förmånstagaren signerar med BankID
                        // delivery_options : benefitWindow.company.delivery_options, // Leveransalternativ
                        // affected_stores: benefitWindow.affected_stores.val[0] ? benefitWindow.affected_stores.val[0].Name : "", // Berörda butiker
                        // external_stores: benefitWindow.external_stores.val[0] ? benefitWindow.external_stores.val[0].Name : "", // Externa partners
                        // demo: benefitWindow.demo.val, //Demo
                        // leasing_months: `${benefitWindow.leasing_months.val} månader`, // Avtalslängd
                        // ongoing_reconciliation: benefitWindow.company.ongoing_reconciliation, // Avvikande avstämning
                        // weekly_reconciliation: benefitWindow.company.weekly_reconciliation, // Veckovis avstämmning
                        sales_person: benefitWindow.sales_person.val.name, // Säljare Personalcyklar
                        outgoing_invoicenr: benefitWindow.outgoing_invoicenr.val ? benefitWindow.outgoing_invoicenr.val : "", // Fakturanummer
                        invoice_date: benefitWindow.invoice_date.val ? benefitWindow.invoice_date.val.substring(0,10) : "", // Fakturadatum
                        window_status: benefitWindow.windowstatustext, // Fönsterstatus
                        ou_generated: benefitWindow.ou_generated.val, // OU genererat
                    }
                    benefitWindows.push(aBenefitWindow);
                });
                setWindowList(benefitWindows);
            } else if (response.status === 401) {
                void dispatch(logout());
            }
        });
    }
    /*
    const updateCompanyData = (data, companyID) => {
        API.updateCompany(data,companyID).then((response) => {
            if(response.status === 200){
                window.generateWindowList();
            } else if (response.status === 401) {
            }
        });
    }
    */

    useEffect(() => {
        /*
        const sort = {
            key: "startdate",
            order: -1,
        }
         */
        window.generateWindowList();
        document.getElementById("gridContainer").scrollIntoView({behavior: 'smooth'});
    }, []);

    return (
        <div className="planBenefitWindowsPC">
            <Header title="HD-listan" />
            <DataGrid
                id="gridContainer"
                dataSource={windowList}
                keyExpr="ID"
                showBorders={true}
                allowColumnResizing={true}
                className="planBenefitWindowsPC__centerText"
                wordWrapEnabled={true}
            >
                <Scrolling
                    columnRenderingMode="none"
                    useNative={false}
                    scrollByContent={true}
                    scrollByThumb={true}
                    showScrollbar="always"
                />
                <FilterRow visible={true} />
                <GroupPanel visible={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                {/* <Selection mode="multiple" /> */}
                <Grouping autoExpandAll={false} />
                <Paging defaultPageSize={10} />
                <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes ? allowedPageSizes : [5, 10, 'all']}
                    displayMode={displayMode ? displayMode : "adaptive"}
                    showPageSizeSelector={showPageSizeSelector}
                    showInfo={showInfo}
                    showNavigationButtons={showNavButtons}
                />
                {/*
                <Column dataField="window_setup_complete"
                        cellRender={windowSetupCompleteCheckBoxRender}
                        caption="Uppsatt i portalen"
                        width={75}
                        allowEditing={false}
                />
                */}
                {/*
                <Column dataField="org_nr"
                        caption="Orgnr"
                        groupIndex={0}
                />
                */}
                <Column dataField="name"
                        width={225}
                        caption="Bolagsnamn"
                        //groupIndex={0}
                />
                {/*
                <Column dataField="alternate_name"
                        caption="Publikt namn"
                        width={150}
                />
                */}
                <Column dataField="benefit_window_name"
                        width={250}
                        caption="Beställningsfönster"
                />
                <Column dataField="startdate"
                        dataType="date"
                        caption="Datum från"
                        width={150}
                        format="yyyy-MM-dd"
                        selectedFilterOperation=">="
                />
                <Column dataField="enddate"
                        dataType="date"
                        caption="Datum till"
                        width={150}
                        format="yyyy-MM-dd"
                        selectedFilterOperation="<="
                />
                {/*
                <Column dataField="delivery_options"
                        caption="Leveransalternativ"
                        width={150}
                />
                */}
                {/*
                <Column dataField="benefit_costplace_id"
                        caption="Enheter/kostnadsställen"
                        width={150}
                />
                */}
                {/*
                <Column dataField="external_stores"
                        caption="Externa partners"
                        width={150}
                />
                */}
                <Column dataField="calculation_factor"
                        caption="Hyresfaktor"
                        width={150}
                />
                {/*
                <Column dataField="benefit_company_id"
                        caption="B2E Site"
                        width={150}
                />
                */}
                {/*
                <Column dataField="portal_username"
                        caption="Portalanvändarnamn"
                        width={150}
                />
                */}
                {/*
                <Column dataField="portal_password"
                        caption="Portallösenord"
                        width={150}
                />
                */}
                <Column dataField="window_rules"
                        width={350}
                        caption="Regler"
                        cellRender={rulesRender}
                />
                <Column dataField="conditions"
                        caption="Subvention"
                        width={150}
                />
                {/*
                <Column dataField="standard_agreement"
                        cellRender={checkRender}
                        caption="Standard UH"
                        width={100}
                />
                */}
                <Column dataField="security_agreement"
                        cellRender={checkRender}
                        caption="Trygghetspaket"
                        width={125}
                />
                {/*<Column dataField="agreement"
                        caption="Avtal"
                        width={150}
                        cellRender={agreementRender}
                />*/}
                {/*
                <Column dataField="customer_specific_agreement"
                        cellRender={checkRender}
                        caption="Kundspecifikt UH"
                        width={125}
                />
                */}
                {/*
                <Column dataField="agreement_uploaded"
                        cellRender={checkRender}
                        caption="Avtal uppladdat?"
                        width={125}
                />
                */}
                {/*
                <Column dataField="leasing_months"
                        caption="Avtalslängd"
                        width={150}
                />
                */}
                {/*
                <Column dataField="demo"
                        caption="Demo"
                        width={150}
                        cellRender={demoEventsRender}
                />
                */}
                <Column dataField="sales_person"
                        caption="Säljare Personalcyklar"
                        width={125}
                        //groupIndex={1}
                />
                {/*
                <Column dataField="bank_id_required"
                        cellRender={checkRender}
                        caption="Förmånstagaren signerar med BankID"
                        width={100}
                />
                */}
                {/*
                <Column dataField="affected_stores"
                        caption="Berörda butiker"
                        width={150}
                />
                */}
                {/*
                <Column dataField="ongoing_reconciliation"
                        allowSorting={true}
                        cellRender={checkRender}
                        caption="Avvikande avstämning"
                        width={150}
                />
                <Column dataField="weekly_reconciliation"
                        cellRender={checkRender}
                        caption="Veckovis avstämmning"
                        width={125}
                />
                */}
                <Column dataField="window_status"
                        caption="Status"
                        width={150}
                />
                <Column dataField="ou_generated"
                        cellRender={checkRender}
                        caption="OU genererat"
                        width={125}
                />
                <Column dataField="outgoing_invoicenr"
                        caption="Fakturanummer"
                        width={150}
                />
                <Column dataField="invoice_date"
                        caption="Fakturadatum"
                        width={150}
                />
            </DataGrid>
            <div className="options">
                <div className="caption">Options</div>
                <div className="option-container">
                    <div className="option">
                        <SelectBox
                            id="displayModes"
                            items={displayModes}
                            displayExpr="text"
                            inputAttr={{ 'aria-label': 'Refresh Mode' }}
                            valueExpr="value"
                            value={displayMode}
                            onValueChange={displayModeChange}
                        />
                    </div>
                    <div className="option">
                        <CheckBox
                            id="showPageSizes"
                            text="Show Page Size Selector"
                            value={showPageSizeSelector}
                            onValueChange={showPageSizeSelectorChange}
                        />
                    </div>
                    <div className="option">
                        <CheckBox
                            id="showInfo"
                            text="Show Info Text"
                            value={showInfo}
                            onValueChange={showInfoChange}
                        />
                    </div>
                    <div className="option">
                        <CheckBox
                            id="showNavButtons"
                            text="Show Navigation Buttons"
                            value={showNavButtons}
                            onValueChange={showNavButtonsChange}
                            disabled={isCompactMode()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlanBenefitWindowsPC;
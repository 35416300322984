import axios from "axios";
import authHeader from "../services/auth-header";
axios.defaults.validateStatus = () => true;

const { /*REACT_APP_DEV_API, REACT_APP_MAIN_API, REACT_APP_LOCAL,*/ REACT_APP_BASE_URL_API } = process.env;
// const baseURL = REACT_APP_LOCAL;
// const baseURL = "http://dev-api.sportson.se";
// const baseURL = "http://localhost:3001";
const baseURL = REACT_APP_BASE_URL_API;

// /Orders:

const API = {
  getOrder: async function (order) {
    return axios.get(baseURL + `/Orders/GetCollatedOrder/${order}`, {
      headers: authHeader(),
    });
  },

    /* Deprecated
  getLastOrderChange: async function () {
    return axios.get(baseURL + `/Orders/GetLastOrderChange`, {
      headers: authHeader(),
    });
  },
     */

    /* Comment out code not in use for now
  getCompanyList: async function () {
    return axios.get(baseURL + `/Orders/GetCompanyList`, {
      headers: authHeader(),
    });
  },
     */

    getCollatedOrdersByFilter: async function (data) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                "Content-Type": "application/json",
                Authorization: Authorization,
            };
            axios
                .get(
                    baseURL + `/Orders/GetCollatedOrdersByFilter?byshop=${data.byshop}&bycompany=${data.bycompany}&bytype=${data.bytype}&fromdate=${data.fromdate}&todate=${data.todate}&skip=${data.skip}&limit=${data.limit}&querytype=${data.querytype}&search=${data.search}`,
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    /* Comment out code until endpoint is fully implemented
    validateOrder: async function (orderNumber) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                "Content-Type": "application/json",
                Authorization: Authorization,
            };
            axios
                .post(
                    baseURL + `/Orders/ValidateOrder/`,
                    {
                        OrderHead: data.OrderHead,
                        Items: data.Items,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },
    */

    updateCollatedOrder: async function (data, orderID) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                "Content-Type": "application/json",
                Authorization: Authorization,
            };
            axios
                .put(
                    baseURL + `/Orders/UpdateCollatedOrder/${orderID}`,
                    {
                        NewShop: data.NewShop,
                        NewOrderDate: data.NewOrderDate,
                        MarkedForDeletetion: data.MarkedForDeletetion,
                        OrderOutgoingInvoiced: data.OrderOutgoingInvoiced,
                        OrderOutgoingInvoiceNumber: data.OrderOutgoingInvoiceNumber,
                        OrderIncomingInvoiced: data.OrderIncomingInvoiced,
                        OrderIncomingInvoiceNumbers: data.OrderIncomingInvoiceNumbers,
                        BenefitWindow: data.BenefitWindow,
                        Items: data.Items,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    updateOrderHead: async function (data, orderID) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                Authorization: Authorization,
            };
            axios
                .put(
                    baseURL + `/Orders/UpdateOrderHead/${orderID}`,
                    {
                        NewShop: data.NewShop,
                        NewOrderDate: data.NewOrderDate,
                        MarkedForDeletetion: data.MarkedForDeletetion,
                        OrderOutgoingInvoiced: data.OrderOutgoingInvoiced,
                        OrderOutgoingInvoices: data.OrderOutgoingInvoices,
                        OrderIncomingInvoiced: data.OrderIncomingInvoiced,
                        OrderIncomingInvoices: data.OrderIncomingInvoices,
                        BenefitWindow: data.BenefitWindow,
                        IgnoreRules: data.IgnoreRules,
                        MarkedForDeletion: data.MarkedForDeletion,
                        ApprovedForReturn: data.ApprovedForReturn,
                        Returned: data.Returned,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    updateOrderItems: async function (data, orderID) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                Authorization: Authorization,
            };
            axios
                .put(
                    baseURL + `/Orders/UpdateOrderItems/${orderID}`,
                    {
                        Items: data,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    deleteOrderItems: async function (data, orderID) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                Authorization: Authorization,
            };
            axios
                .put(
                    baseURL + `/Orders/DeleteOrderItems/${orderID}`,
                    {
                        Items: data,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    addCommentToOrder: async function (data, orderID) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                Authorization: Authorization,
            };
            axios
                .post(
                    baseURL + `/Orders/AddCommentToOrder/${orderID}`,
                    {
                        CommentText: data.CommentText,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    updateCommentOnOrder: async function (data, orderID) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                "Content-Type": "application/json",
                Authorization: Authorization,
            };
            axios
                .put(
                    baseURL + `/Orders/UpdateCommentOnOrder/${orderID}`,
                    {
                        CommentId: data.CommentId,
                        CommentText: data.CommentText,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    deleteCommentOnOrder: async function (data, orderID) {
        return axios.delete(baseURL + `/Orders/DeleteCommentOnOrder/${orderID}?CommentId=${data.CommentId}`, {
            headers: authHeader(),
        });
    },

    restoreOrder: async function (orderID) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                Authorization: Authorization,
            };
            axios
                .post(
                    baseURL + `/Orders/RestoreOrder/${orderID}`,
                    {},
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    validateOrderRestore: async function (orderID) {
        return axios.get(baseURL + `/Orders/ValidateOrderRestore/${orderID}`, {
            headers: authHeader(),
        });
    },

    // /giftcard:

  getGiftCard: async function (card) {
        /*
     const getparams = JSON.stringify({
       search: card,
     });
         */

    return axios.get(baseURL + `/giftcard/giftcard/?search=${card}`, {
      headers: authHeader(),
    });
  },

    // /Application:

    getUserRights: async function () {
        return axios.get(baseURL + `/Application/UserRights`, {
            headers: authHeader(),
        });
    },

    getDeliveryOptions: async function () {
        return axios.get(baseURL + `/Application/DeliveryOptions`, {
            headers: authHeader(),
        });
    },

    getApplications: async function () {
        return axios.get(baseURL + `/Application/Applications`, {
            headers: authHeader(),
        });
    },

    // /users:

    getUserShop: async function () {
        return axios.get(baseURL + `/users/usershop`, {
            headers: authHeader(),
        });
    },

    getShop: async function () {
        return axios.get(baseURL + `/users/usershop/`, {
            headers: authHeader(),
        });
    },

  getUserName: async function () {
    return axios.get(baseURL + `/users/username`, {
      headers: authHeader(),
    });
  },

  getUserId: async function () {
    return axios.get(baseURL + `/users/userid`, {
      headers: authHeader(),
    });
  },

  getUserById: async function (userID) {
    return axios.get(baseURL + `/users/user/${userID}`, {
      headers: authHeader(),
    });
  },

    getLoggedInUserRights: async function (rights = "") {
        return axios.get(baseURL + `/users/userrights/${rights}?`, {
            headers: authHeader(),
        });
    },

    deleteUserById: async function (userID) {
    return axios.delete(baseURL + `/users/user/${userID}`, {
      headers: authHeader(),
    });
  },

  getUsers: async function () {
    return axios.get(baseURL + `/users/users`, {
      headers: authHeader(),
    });
  },

  searchUser: async function (nameOrMail) {
    return axios.get(baseURL + `/users/searchuser?search=${nameOrMail}`, {
      headers: authHeader(),
    });
  },

  createUser: async function (data) {
    return new Promise((resolve, reject) => {
      const { Authorization } = authHeader();
      const headers = {
        "Content-Type": "application/json",
        Authorization: Authorization,
      };
      axios
          .post(
              baseURL + `/users/user`,
              {
                name: data.name,
                email: data.email,
                password: data.password,
                shopaccess: data.shopsaccess,
                userrights: data.userrights,
              },
              {
                headers,
              }
          )
          .then((response) => {
            resolve(response)
          }).catch((e) => {
        reject(e)
      });
    });
  },

  updateUserById: async function (data, userID) {
    return new Promise((resolve, reject) => {
      const { Authorization } = authHeader();
      const headers = {
        "Content-Type": "application/json",
        Authorization: Authorization,
      };
      axios
          .put(
              baseURL + `/users/user/${userID}`,
              {
                name: data.name,
                email: data.email,
                password: data.password,
                shopaccess: data.shopsaccess,
                userrights: data.userrights,
                  userinactivated: data.userinactivated,
              },
              {
                headers,
              }
          )
          .then((response) => {
            resolve(response)
          }).catch((e) => {
        reject(e)
      });
    });
  },

    // /contacts:

    createContact: async function (data) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                "Content-Type": "application/json",
                Authorization: Authorization,
            };
            axios
                .post(
                    baseURL + `/contacts/contact`,
                    {
                        name: data.name,
                        email: data.email,
                        cellphone: data.cellphone,
                        phone: data.phone,
                        role: data.role,
                        connected_user: data.connected_user,
                        company: data.company,
                        salesperson: data.salesperson,
                        tags: data.tags,
                        comment: data.comment,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    getContact: async function (contactID, tagID, companyID) {
        return axios.get(baseURL + `/contacts/contact/${contactID}?&tag=${tagID}&company=${companyID}`, {
            headers: authHeader(),
        });
    },

    getBenefitCompanyContactTagId: async function () {
        return axios.get(baseURL + `/contacts/benefitcompanycontacttagid`, {
            headers: authHeader(),
        });
    },

    getGetPersonalcyklarContactTagId: async function () {
        return axios.get(baseURL + `/contacts/personalcyklarcontacttagid`, {
            headers: authHeader(),
        });
    },

    createCompany: async function (data) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                "Content-Type": "application/json",
                Authorization: Authorization,
            };
            axios
                .post(
                    baseURL + `/contacts/company`,
                    {
                        tags: data.tags,
                        websites: data.websites,
                        name: data.name,
                        alternate_name: data.alternate_name,
                        address: data.address,
                        general_portal: data.general_portal,
                        personalcyklar_salesperson: data.personalcyklar_salesperson,
                        benefit_subcompany_id: data.benefit_subcompany_id,
                        benefit_company_id: data.benefit_company_id,
                        benefit_costplace_id: data.benefit_costplace_id,
                        optional_spellings: data.optional_spellings,
                        org_nr: data.org_nr,
                        window_rules: data.window_rules,
                        window_rules_rule: data.window_rules_rule,
                        window_rules_parameters: data.window_rules_parameters,
                        external_partners: data.external_partners,
                        sportson_shops: data.sportson_shops,
                        shown_in_contacts: data.shown_in_contacts,
                        bank_id_required: data.bank_id_required,
                        calculation_factor: data.calculation_factor,
                        contact: data.contact,
                        //standard_agreement: data.standard_agreement,
                        //security_agreement: data.security_agreement,
                        security_agreement_active_from: data.security_agreement_active_from,
                        security_agreement_active_to: data.security_agreement_active_to,
                        customer_specific_agreement: data.customer_specific_agreement,
                        agreement: data.agreement,
                        weekly_reconciliation: data.weekly_reconciliation,
                        ongoing_reconciliation: data.ongoing_reconciliation,
                        conditions: data.conditions,
                        portal_username: data.portal_username,
                        portal_password: data.portal_password,
                        delivery_options: data.delivery_options,
                        company_representative: data.company_representative,
                        ApplicationIds: data.ApplicationIds,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    updateCompany: async function (data, companyID) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                "Content-Type": "application/json",
                Authorization: Authorization,
            };
            axios
                .put(
                    baseURL + `/contacts/company/${companyID}`,
                    {
                        tags: data.tags,
                        websites: data.websites,
                        name: data.name,
                        alternate_name: data.alternate_name,
                        address: data.address,
                        general_portal: data.general_portal,
                        personalcyklar_salesperson: data.personalcyklar_salesperson,
                        benefit_subcompany_id: data.benefit_subcompany_id,
                        benefit_company_id: data.benefit_company_id,
                        benefit_costplace_id: data.benefit_costplace_id,
                        optional_spellings: data.optional_spellings,
                        org_nr: data.org_nr,
                        window_rules: data.window_rules,
                        window_rules_rule: data.window_rules_rule,
                        window_rules_parameters: data.window_rules_parameters,
                        external_partners: data.external_partners,
                        sportson_shops: data.sportson_shops,
                        shown_in_contacts: data.shown_in_contacts,
                        bank_id_required: data.bank_id_required,
                        calculation_factor: data.calculation_factor,
                        contact: data.contact,
                        //standard_agreement: data.standard_agreement,
                        //security_agreement: data.security_agreement,
                        security_agreement_active_from: data.security_agreement_active_from,
                        security_agreement_active_to: data.security_agreement_active_to,
                        customer_specific_agreement: data.customer_specific_agreement,
                        agreement: data.agreement,
                        weekly_reconciliation: data.weekly_reconciliation,
                        ongoing_reconciliation: data.ongoing_reconciliation,
                        conditions: data.conditions,
                        portal_username: data.portal_username,
                        portal_password: data.portal_password,
                        delivery_options: data.delivery_options,
                        company_representative: data.company_representative,
                        ApplicationIds: data.ApplicationIds,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    getCompany: async function (companyID) {
        return axios.get(baseURL + `/contacts/company/${companyID}`, {
            headers: authHeader(),
        });
    },

    getCompanySearch: async function (companyID,search,skip,limit) {
        return axios.get(baseURL + `/contacts/company/${companyID}?search=${search}&skip=${skip}&limit=${limit}`, {
            headers: authHeader(),
        });
    },

    addCommentToCompany: async function (data, companyID) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                Authorization: Authorization,
            };
            axios
                .post(
                    baseURL + `/contacts/AddCommentToCompany/${companyID}`,
                    {
                        CommentText: data.CommentText,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    updateCommentOnCompany: async function (data, companyID) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                "Content-Type": "application/json",
                Authorization: Authorization,
            };
            axios
                .put(
                    baseURL + `/contacts/UpdateCommentOnCompany/${companyID}`,
                    {
                        CommentId: data.CommentId,
                        CommentText: data.CommentText,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    deleteCommentOnCompany: async function (data, companyID) {
        return axios.delete(baseURL + `/contacts/DeleteCommentOnCompany/${companyID}?CommentId=${data.CommentId}`, {
            headers: authHeader(),
        });
    },

    // /products:

    getArticle: async function (product) {
        return axios.get(
            baseURL + `/products/GetProductByPartNoOrBarcode/?code=${product}`,
            {
                headers: authHeader(),
                // Ignore the catch in axios, Continue to check the status
                validateStatus: (status) => {
                    if (status < 400 || status === 404) {
                        return status;
                    }
                },
            }
        );
    },

  getSupplier: async function () {
    return axios.get(baseURL + `/products/GetSupplierList/`, {
      headers: authHeader(),
    });
  },

  getManufacturer: async function () {
    return axios.get(baseURL + `/products/GetManufacturerList/`, {
      headers: authHeader(),
    });
  },

    getCategory: async function () {
        return axios.get(baseURL + `/products/GetCategoryList/`, {
            headers: authHeader(),
        });
    },

  getShopList: async function (shop, shopType = "") {
    return axios.get(
      baseURL + `/products/GetShopList/?ignoreFilter=false&gln=${shop}&shop_type=${shopType}`,
      {
        headers: authHeader(),
      }
    );
  },

  postArticle: async function (data) {
    return axios.post(baseURL + `/products/SubmitProductRequest`, data, {
      headers: authHeader(),
    });
  },

   // /rss:

    getNewsAsHtml: async function () {
        return axios.get(baseURL + `/rss/GetLatestAsHtml`, {
            headers: authHeader(),
        });
    },

    registerUsedBike: async function (data) {
        return axios.post(baseURL + `/products/RegisterUsedBike`, data, {
            headers: authHeader(),
        });
    },

   // /Authorization:

  postLogin: async function (info) {
    return axios.post(baseURL + `/Authorization/login`, info);
  },

  postLogout: async function () {
    return axios.post(baseURL + `/Authorization/logout`);
  },

  validateToken: async function () {
    return axios.get(baseURL + `/Authorization/validate`, {
      headers: authHeader(),
    });
  },

  /*
  postSupportType: async function (name, ticket) {
    const { Authorization } = await authHeader();

    const header = {
      "Content-Type": "application/json",
      Authorization: Authorization,
    };
    return axios
      .post(
        baseURL + `/Maintenance/SupportType`,
        { name: name },
        {
          headers: header,
        }
      )
      .then((res) => {
        const { name, _id } = res.data;
        this.postSupportSubType(name, _id, ticket);
      });
  },

  postSupportSubType: async function (name, parenttype, ticket) {
    const { Authorization } = await authHeader();
    const headers = {
      "Content-Type": "application/json",
      Authorization: Authorization,
    };
    return axios
      .post(
        baseURL + `/Maintenance/SupportSubType`,
        { name, parenttype },
        {
          headers,
        }
      )
      .then((res) => {
        const { _id } = res.data;
        this.postSupportTicket(ticket, _id);
      });
  },
   */

  // /Maintenance:

  getSupportType: async function (_id) {
    return axios.get(
        baseURL + `/Maintenance/SupportType/${_id}`,
        {
          headers: authHeader(),
        }
    );
  },

  getSupportSubType: async function (_id) {
    return new Promise((resolve, reject) => {
      const { Authorization } = authHeader();
      const headers = {
        Authorization: Authorization,
      };
      axios
          .post(
              baseURL + `/Maintenance/supportsubtype/`,
              {
                parenttype: _id
              },
              {
                headers,
              }
          )
          .then((response) => {
            resolve(response)
          }).catch((e) => {
        reject(e)
      });
    });
  },

    // /benefit:

    getBenefitWindow: async function (_id = "", fromdate = "", todate = "", orderlist, company_id = "", open_only = false, only_affected_stores = false, skip = "", limit = "", not_ou_windows = false, shops = "", abor_invoiced = false, salesperson_id = "") {
        return axios.get(
            baseURL + `/benefit/window/${_id}?fromdate=${fromdate}&todate=${todate}&orderlist=${orderlist}&company_id=${company_id}&open_only=${open_only}&only_affected_stores=${only_affected_stores}&not_ou_windows=${not_ou_windows}&shops=${shops}&skip=${skip}&limit=${limit}&abor_invoiced=${abor_invoiced}&salesperson_id=${salesperson_id}`,
            {
                headers: authHeader(),
            }
        );
    },

    getBenefitWindowIgnoreCancelled: async function (_id = "", fromdate = "", todate = "", orderlist, company_id = "", open_only = false, only_affected_stores = false, skip = "", limit = "", not_ou_windows = false, shops = "", abor_invoiced = false, salesperson_id = "", ignore_cancelled  = true) {
        return axios.get(
            baseURL + `/benefit/window/${_id}?fromdate=${fromdate}&todate=${todate}&orderlist=${orderlist}&company_id=${company_id}&open_only=${open_only}&only_affected_stores=${only_affected_stores}&not_ou_windows=${not_ou_windows}&shops=${shops}&skip=${skip}&limit=${limit}&abor_invoiced=${abor_invoiced}&salesperson_id=${salesperson_id}&ignore_cancelled=${ignore_cancelled}`,
            {
                headers: authHeader(),
            }
        );
    },

    getBenefitWindowSortByName: async function (_id = "", fromdate = "", todate = "", orderlist, company_id = "", open_only = false, only_affected_stores = false, skip = "", limit = "", not_ou_windows = false, shops = "", abor_invoiced = false, salesperson_id = "", sort_name = "") {
        return axios.get(
            baseURL + `/benefit/window/${_id}?fromdate=${fromdate}&todate=${todate}&orderlist=${orderlist}&company_id=${company_id}&open_only=${open_only}&only_affected_stores=${only_affected_stores}&not_ou_windows=${not_ou_windows}&shops=${shops}&skip=${skip}&limit=${limit}&abor_invoiced=${abor_invoiced}&salesperson_id=${salesperson_id}&sort_name=${sort_name}`,
            {
                headers: authHeader(),
            }
        );
    },

    getBenefitWindowSortByInvoiceDate: async function (_id = "", fromdate = "", todate = "", orderlist, company_id = "", open_only = false, only_affected_stores = false, skip = "", limit = "", not_ou_windows = false, shops = "", abor_invoiced = false, salesperson_id = "", sort_invoicedate = "") {
        return axios.get(
            baseURL + `/benefit/window/${_id}?fromdate=${fromdate}&todate=${todate}&orderlist=${orderlist}&company_id=${company_id}&open_only=${open_only}&only_affected_stores=${only_affected_stores}&not_ou_windows=${not_ou_windows}&shops=${shops}&skip=${skip}&limit=${limit}&abor_invoiced=${abor_invoiced}&salesperson_id=${salesperson_id}&sort_invoicedate=${sort_invoicedate}`,
            {
                headers: authHeader(),
            }
        );
    },

    getBenefitWindowIgnoreCancelledForCompany: async function (_id = "", fromdate = "", todate = "", orderlist, company_id = "", ignore_cancelled = true) {
        return axios.get(
            baseURL + `/benefit/window/${_id}?fromdate=${fromdate}&todate=${todate}&orderlist=${orderlist}&company_id=${company_id}&ignore_cancelled=${ignore_cancelled}`,
            {
                headers: authHeader(),
            }
        )
    },

    /* Commented out code not in use for now
    getBenefitWindowStatus: async function (_id) {
        return axios.get(
            baseURL + `/Benefit/windowstatus/${_id}`,
            {
                headers: authHeader(),
            }
        );
    },
     */

    getAgreement: async function (_id) {
        return axios.get(
            baseURL + `/benefit/Agreement/${_id}`,
            {
                headers: authHeader(),
            }
        );
    },

    getWindowRule: async function (_id) {
        return axios.get(
            baseURL + `/benefit/windowrule/${_id}`,
            {
                headers: authHeader(),
            }
        );
    },

    generateBG: async function (_id) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                Authorization: Authorization,
            };
            axios
                .post(
                    baseURL + `/benefit/generatebg/`,
                    {
                        _id
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    validateBG: async function () {
        return axios.get(
            baseURL + `/benefit/ValidateBg`,
            {
                headers: authHeader(),
            }
        );
    },

    validateOU: async function (benefitWindowID) {
        return axios.get(baseURL + `/benefit/validateou/${benefitWindowID}`, {
            headers: authHeader(),
        });
    },

    getBGApproval: async function (company, approved, skip = "", limit = "") {
        return axios.get(
            baseURL + `/benefit/bgapproval?company=${company}&approved=${approved}&skip=${skip}&limit=${limit}`,
            {
                headers: authHeader(),
            }
        );
    },

    getBGOrders: async function (_id = "") {
        return axios.get(
            baseURL + `/benefit/bgapproval/${_id}`,
            {
                headers: authHeader(),
            }
        );
    },

    approveBG: async function (orders, BG_ID) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                Authorization: Authorization,
            };
            axios
                .post(
                    baseURL + `/benefit/approvebg/${BG_ID}`,
                    {
                        orders
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    generateKAL: async function () {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                Authorization: Authorization,
            };
            axios
                .post(
                    baseURL + `/benefit/generatekal`,
                    { },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    generateOU: async function (_id = "") {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();

            const headers = {
                "Content-Type": "application/json",
                Authorization: Authorization,
            };
            axios
                .post(
                    baseURL + `/benefit/generateou/${_id}`,
                    { },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    createNewBenefitWindow: async function (data) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                "Content-Type": "application/json",
                Authorization: Authorization,
            };
            axios
                .post(
                    baseURL + `/benefit/window`,
                    {
                        name: data.name,
                        company: data.company,
                        connected_subcompany: data.connected_subcompany,
                        startdate: data.startdate,
                        enddate: data.enddate,
                        window_rules: data.window_rules,
                        outgoing_invoicenr: data.outgoing_invoicenr,
                        invoice_date: data.invoice_date,
                        calculation_factor: data.calculation_factor,
                        sales_person: data.sales_person,
                        affected_stores: data.affected_stores,
                        external_stores: data.external_stores,
                        window_setup_complete: data.window_setup_complete,
                        contract_start: data.contract_start,
                        bank_id_required: data.bank_id_required,
                        general_portal: data.general_portal,
                        demo: data.demo,
                        standard_agreement: data.standard_agreement,
                        security_agreement: data.security_agreement,
                        customer_specific_agreement: data.customer_specific_agreement,
                        agreement: data.agreement,
                        conditions: data.conditions,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    updateBenefitWindow: async function (data, benefitWindowID) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                "Content-Type": "application/json",
                Authorization: Authorization,
            };
            axios
                .put(
                    baseURL + `/benefit/window/${benefitWindowID}`,
                    {
                        name: data.name,
                        company: data.company,
                        connected_subcompany: data.connected_subcompany,
                        startdate: data.startdate,
                        enddate: data.enddate,
                        window_rules: data.window_rules,
                        outgoing_invoicenr: data.outgoing_invoicenr,
                        invoice_date: data.invoice_date,
                        calculation_factor: data.calculation_factor,
                        sales_person: data.sales_person,
                        affected_stores: data.affected_stores,
                        external_stores: data.external_stores,
                        window_setup_complete: data.window_setup_complete,
                        contract_start: data.contract_start,
                        bank_id_required: data.bank_id_required,
                        general_portal: data.general_portal,
                        demo: data.demo,
                        standard_agreement: data.standard_agreement,
                        security_agreement: data.security_agreement,
                        customer_specific_agreement: data.customer_specific_agreement,
                        agreement: data.agreement,
                        cancelled: data.cancelled,
                        conditions: data.conditions,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    updateBenefitWindowInvoice: async function (data, benefitWindowID) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                "Content-Type": "application/json",
                Authorization: Authorization,
            };
            axios
                .put(
                    baseURL + `/benefit/window/${benefitWindowID}`,
                    {
                        outgoing_invoicenr: data.outgoing_invoicenr,
                        invoice_date: data.invoice_date,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    cancelBenefitWindow: async function (benefitWindowID) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                "Content-Type": "application/json",
                Authorization: Authorization,
            };
            axios
                .put(
                    baseURL + `/benefit/window/${benefitWindowID}`,
                    {
                        cancelled: true,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    getGlobalBenefitFactor: async function () {
        return axios.get(baseURL + `/benefit/GlobalBenefitFactor`, {
            headers: authHeader(),
        });
    },

    addCommentToWindow: async function (data, windowID) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                Authorization: Authorization,
            };
            axios
                .post(
                    baseURL + `/benefit/AddCommentToWindow/${windowID}`,
                    {
                        CommentText: data.CommentText,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    updateCommentOnWindow: async function (data, windowID) {
        return new Promise((resolve, reject) => {
            const { Authorization } = authHeader();
            const headers = {
                "Content-Type": "application/json",
                Authorization: Authorization,
            };
            axios
                .put(
                    baseURL + `/benefit/UpdateCommentOnWindow/${windowID}`,
                    {
                        CommentId: data.CommentId,
                        CommentText: data.CommentText,
                    },
                    {
                        headers,
                    }
                )
                .then((response) => {
                    resolve(response)
                }).catch((e) => {
                reject(e)
            });
        });
    },

    deleteCommentOnWindow: async function (data, windowID) {
        return axios.delete(baseURL + `/benefit/DeleteCommentOnWindow/${windowID}?CommentId=${data.CommentId}`, {
            headers: authHeader(),
        });
    },

    // /Files:

  uploadFiles: async function (formdata) {
    return axios.post(baseURL + `/Files/upload`, formdata, {
      headers: authHeader(),
    });
  },

    uploadAgreement: async function (companyID, file) {
        return axios.post(baseURL + `/files/uploadagreement/${companyID}`, file, {
            headers: authHeader(),
        });
    },

    //  /Support:

  postSupportTicket: async function (ticketbody, supportsubtype) {
    return new Promise((resolve, reject) => {
      const { Authorization } = authHeader();

      const headers = {
        "Content-Type": "application/json",
        Authorization: Authorization,
      };
      axios
        .post(
          baseURL + `/Support/supportticket`,
          { ticketbody, supportsubtype },
          {
            headers,
          }
        )
        .then((response) => {
          resolve(response.data.ticketid)
          // this.postSupportTicket;
        }).catch((e) => {
          reject(e)
      });
    });
  },

    // /Giftcard:

  updateGiftCard: async function (data) {
    return axios.put(baseURL + `/Giftcard/giftcard `, data, {
      headers: authHeader(),
    });
  },

    // /DataExtract:

    getWindowList: async function (skip, limit, changeonly = false) {
        return axios.get(
            baseURL + `/DataExtract/WindowList?skip=${skip}&limit=${limit}&changeonly=${changeonly}`,
            {
                headers: authHeader(),
            }
        );
    },
};

export default API;
import React, { useState } from "react";
import "./style.css";
import { TextField } from "@material-ui/core";
import RadioButtons from "../../RadioButtons";
import { useForm } from "react-hook-form";
import Modal from "../../Modals/Modal-ConfirmAlert";
import API from "../../../utils/API";

function Servicesystem({ selectedValue, option, supportSubTypeID }) {
  const [problemType, setProblemType] = useState("");
  const [comment, setComment] = useState("");
  const [checked, isChecked] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [files, setFiles] = useState([]);

  const closeModalHandler = () => setShowModal(false);

  /*
  const handleOnChange = (e) => {
    let tempFiles = [];
    let index = 0; // Setting index to array index so it is easy to remove an element in the handleRemoveFile function

    for (let file of fileList) {
      tempFiles.push({ index: index, file: file, name: file.name});
      index++;
    }
    for (let file of files) {
      tempFiles.push({ index: index, file: file.file, name: file.name});
      index++;
    }
    setFiles(tempFiles);
  }
   */

  const handleRemoveFile = (e) => {
    let tempList = files;
    if (tempList.length === 1){
      setFiles([]);
    } else {
      tempList.splice(e.target.id, 1);
      for (let i = parseInt(e.target.id); i < tempList.length; i++) {
        tempList[i].index = i;
      }
      setFiles([...tempList]);
    }
  }

  const { /* control, */ handleSubmit, errors, register } = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      problemType,
      comment,
      files,
      checked,
    },
  });

  const onSubmit = () => {
    const ticketbody = {
      problemType,
      comment,
      files,
      checked,
    };
    /*
    API.getSupportSubType()
        .then((supportSubTypeID) => {
          console.log("SubtypeID: " + supportSubTypeID);
        })
        .catch((error) => {
          console.log("Supportsubtypen gick inte att hitta");
        });
    console.log("SupporttypID: " + supportTypeID);
     */

    API.postSupportTicket(ticketbody, supportSubTypeID)
      .then((ticketid) => {
        setShowModal(true);
        setTitle(`Ditt supportärende med nummer ${ticketid} har skapats!`);
        handleClear();
      })
      .catch((/*error*/) => {
        setShowModal(true);
        setTitle("Något gick fel, din förfrågan har inte skickats");
      });
    /*
    API.postFiles(files)
        .then((res) => {
        })
        .catch((error) => {
          console.log(error);
        });
     */
  };

  const handleClear = () => {
    setProblemType("");
    setComment("");
    setFiles([]);
    isChecked("");
  };

  const handleRadioBtn = (e) => {
    isChecked(e.target.value);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <select
          className="itSupport__selectHKSupport"
          name="problemType"
          onChange={(e) => setProblemType(e.target.value)}
          value={problemType}
          ref={register({ required: true })}
        >
          <option className="itSupport__optionText" value="">
            Är det fel, behövs en förändring eller en instruktionsmanual? *
          </option>
          <option>Har hittat en bugg/fel i systemet</option>
          <option>Skulle vilja ändra/lägga till detta</option>
          <option>Hur ska jag göra detta? Lär mig</option>
        </select>
        <TextField
          className="headsKassa__input"
          label="Beskriv ditt problem så utförligt du kan *"
          variant="outlined"
          name="comments"
          multiline
          minRows={4}
          fullWidth
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          inputRef={register({ required: true })}
          error={errors.comments}
        ></TextField>
        <div className="fileUpload">
          <TextField
              name="fileField"
              label="Ladda upp filer"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
          >
          </TextField>
          <input value="" title="" className="fileInput" type="file" placeholder="" name="file" /* onChange={handleOnChange} */ multiple />
          {files.length > 0 ? <ul className="filesUl">
            {files.map((file) => (
                <li>
                  {file.name} <button className="removeFileButton" type="button" onClick={handleRemoveFile} id={file.index}>X</button>
                </li>
            ))}
          </ul>: null}
        </div>
        <RadioButtons checked={handleRadioBtn} />
        <button className="itSupport__button" type="submit">
          Skicka
        </button>
        <Modal
          showModal={showModal}
          closeModalHandler={closeModalHandler}
          title={title}
          buttonName="Stäng"
        />
      </form>
    </div>
  );
}

export default Servicesystem;

import React, { useState, useEffect } from "react";
import "./style.css";
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import API from "../../../utils/API";
import Modal from "../../Modals/Modal-ConfirmAlert";

function ArticleTextfields({
  ArticleNumber,
  BarCode,
  ProductId,
  ProductName,
  ManufacturerId,
  SupplierId,
  MainCategoryId,
  PurchasePrice,
  SalePrice,
  Staff,
  Comment,
  commentData,
  //selectShop,
  shopData,
  WarehouseId,
  shopTitle,
  manufacturerData,
  selectManufacturer,
  manufacturerName,
  supplierName,
  selectSupplier,
  supplierData,
  categoryName,
  selectCategory,
  categoryData,
  changeSearchStatus,
  handleClear,
  userNameData,
  name,
}) {
  const {
    control,
    handleSubmit,
    register,
    setValue,
    errors,
    //onChange,
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      ArticleNumber,
      BarCode,
      ProductId,
      ProductName,
      ManufacturerId,
      SupplierId,
      MainCategoryId,
      PurchasePrice,
      SalePrice,
      Comment,
      Staff,
      WarehouseId,
      shopTitle,
      manufacturerName,
      supplierName,
      categoryName,
      name,
    },
  });

  useEffect(() => {
    const articleTextFields = document.getElementsByClassName("articleTextfields__textfield");

    for (let articleTextField of articleTextFields) {
      // Prevent pressing enter on article search field
      articleTextField.addEventListener('keypress', function (e) {
        if (e.keyCode === 13 || e.which === 13) {
          e.preventDefault();
          return false;
        }
      });
    }

  }, []);

  const [sortType, /*setSortType*/] = useState("asc");
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");

  const closeModalHandler = () => setShowModal(false);

  const sortedManufacturer = [
    ...manufacturerData.sort((a, b) => {
      const isReversed = sortType === "asc" ? 1 : -1;
      return isReversed * a.ManufacturerCode.localeCompare(b.ManufacturerCode);
    }),
  ];

  const sortedSupplier = [
    ...supplierData.sort((a, b) => {
      const isReversed = sortType === "asc" ? 1 : -1;
      return isReversed * a.Name.localeCompare(b.Name);
    }),
  ];

  const sortedCategory = [
    ...categoryData.sort((a, b) => {
      const isReversed = sortType === "asc" ? 1 : -1;
      return isReversed * a.Code.localeCompare(b.Code);
    }),
  ];

  const handleFormData = (data) => {
    data.ProductId = ProductId;
    data.Comment = Comment;
    data.Shop = { WarehouseId: data.shopTitle, Staff: data.name };
    delete data.shopTitle;
    delete data.Staff;

    return data;
  };

  const onSubmit = (data, e) => {
    handleFormData(data);

    API.postArticle(data)
      .then(function (response) {
        if (response.status >= 300) {
          setShowModal(true);
          setTitle(`${response.data.message} - ${response.data.details.message}`);
        }
        changeSearchStatus();
        handleClear();
      })
      .catch((/*error*/) => {
        //console.log(error);
        setShowModal(true);
        setTitle(`Fel: artikeln med artikelnummer ${data.ArticleNumber} kunde ej hanteras.`);
      });

    e.target.reset();
  };

  return (
    <div className="articleTextfields">
      <div className="articleTextfields__container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="articleTextfields__textFieldContainer">
            <TextField
              as={TextField}
              control={control}
              inputRef={register({ required: true })}
              error={errors.ProductName}
              label="Produktnamn"
              variant="outlined"
              name="ProductName"
              className="articleTextfields__textfield"
            ></TextField>
            <TextField
              as={TextField}
              control={control}
              inputRef={register({ required: true })}
              error={errors.ArticleNumber}
              label="Artikelnummer"
              variant="outlined"
              name="ArticleNumber"
              className="articleTextfields__textfield"
            ></TextField>
            <TextField
              as={TextField}
              control={control}
              inputRef={register({
                required: true,
              })}
              error={errors.BarCode}
              label="Streckkod"
              variant="outlined"
              name="BarCode"
              className="articleTextfields__textfield"
            ></TextField>
            <select
              defaultValue={ManufacturerId?.id}
              className="articleTextfields__shopSelectorFull"
              onChange={selectManufacturer}
              ref={register({ required: true })}
              //error={errors.ManufacturerId}
              name="ManufacturerId"
            >
              <option className="articleTextfields__optionText" value="">
                Välj varumärke
              </option>
              {sortedManufacturer
                ? manufacturerData.map((m) => (
                    <option
                      className="articleTextfields__optionText"
                      key={m.ManufacturerId}
                      value={m.ManufacturerId}
                      selected={m.ManufacturerId === ManufacturerId}
                    >
                      {m.ManufacturerCode}
                    </option>
                  ))
                : null}
            </select>
            <label className="articleTextfields__selectLabels">Varumärke</label>
            <select
              defaultValue={SupplierId?.id}
              className="articleTextfields__shopSelectorFull"
              onChange={selectSupplier}
              ref={register({ required: true })}
              //error={errors.SupplierId}
              name="SupplierId"
            >
              <option className="articleTextfields__optionText" value="">
                Välj leverantör
              </option>
              {sortedSupplier
                ? supplierData.map((supplier) => (
                    <option
                      className="articleTextfields__optionText"
                      key={supplier.SupplierId}
                      value={supplier.SupplierId}
                      selected={supplier.SupplierId === SupplierId}
                    >
                      {supplier.Name}
                    </option>
                  ))
                : null}
            </select>
            <label className="articleTextfields__selectLabels">Leverantör</label>
            <select
              defaultValue={MainCategoryId}
              className="articleTextfields__shopSelectorFull"
              onChange={selectCategory}
              ref={register({ required: true })}
              //error={errors.MainCategoryId}
              name="MainCategoryId"
            >
              {sortedCategory
                ? categoryData.map((c) => (
                    <option
                      className="articleTextfields__optionText"
                      key={c.Id}
                      value={c.Id}
                      selected={c.Id === MainCategoryId}
                    >
                      {c.Code}
                    </option>
                  ))
                : null}
              <option className="articleTextfields__optionText" value="">
                Välj artikelgrupp
              </option>
            </select>
            <label className="articleTextfields__selectLabels">Artikelgrupp</label>
          </div>
          <div className="articleTextfields__priceContainer">
            <TextField
              as={TextField}
              control={control}
              inputRef={register({ required: true })}
              error={errors.PurchasePrice}
              label="Inpris"
              variant="outlined"
              name="PurchasePrice"
              className="articleTextfields__textfield"
            ></TextField>
            <TextField
              as={TextField}
              control={control}
              inputRef={register({ required: true })}
              error={errors.SalePrice}
              label="Utpris"
              variant="outlined"
              name="SalePrice"
              className="articleTextfields__textfield"
            ></TextField>
          </div>
          <TextField
            className="articleTextfields__commentBox"
            label="Lägg till kommentar"
            variant="outlined"
            name="Comment"
            multiline
            minRows={4}
            onChange={commentData}
          ></TextField>
          <div className="articleTextfields__priceContainer">
            <select
              value={WarehouseId.id}
              className="articleTextfields__shopSelector articleTextfields__butikSelect"
              onChange={shopTitle}
              ref={register({ required: true })}
              //error={errors.shopTitle}
              name="shopTitle"
            >
              {shopData.length > 1 ? (<option className="articleTextfields__optionText" value="">
                Välj butik
              </option>) : null }
              {shopData
                ? shopData.map((s) => (
                    <option
                      className="articleTextfields__textfield"
                      key={s.WarehouseId}
                      value={s.WarehouseId}
                    >
                      {s.Name}
                    </option>
                  ))
                : null}
            </select>
            <label className="articleTextfields__butikLabel">Butik</label>
            <TextField
                label="Personalens namn"
              as={TextField}
              value={userNameData.name}
              control={control}
              inputRef={register({ required: true })}
              error={errors.name}
              // label="Namn"
              variant="outlined"
              name="name"
              className="articleTextfields__textfield"
              onChange={userNameData.name}
            >
              {userNameData.name}
            </TextField>
          </div>

          <button
            className="articleTextfields__sendBtn"
            type="submit"
            onClick={(e) => {
              setValue(e.target.value);
              handleClear();
            }}
          >
            SKICKA
          </button>
        </form>
      </div>
      <Modal
          showModal={showModal}
          closeModalHandler={closeModalHandler}
          title={title}
          buttonName="Stäng"
      />
    </div>
  );
}

export default ArticleTextfields;

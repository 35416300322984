import React, { useState } from "react";
import "./style.css";
// import { useFormik } from "formik";
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Modal from "../../Modals/Modal-ConfirmAlert";
import API from "../../../utils/API";

function Portal({ selectedValue, option, supportSubTypeID }) {
  const [subjectType, setSubjectType] = useState("");
  const [describe, setDescribe] = useState("");
  const [service, setService] = useState("");
  const [system, setSystem] = useState("");
  const [version, setVersion] = useState("");
  const [browser, setBrowser] = useState();
  const [nameType, setNameType] = useState("");
  const [emailType, setEmailType] = useState("");
  const [shopsType, setShopsType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [files, setFiles] = useState([]);

  const closeModalHandler = () => setShowModal(false);

  const handleOnChange = (e) => {
    let tempFiles = [];
    let index = 0; // Setting index to array index so it is easy to remove an element in the handleRemoveFile function

    for (let file of e.target.files) {
      tempFiles.push({ index: index, file: file, name: file.name});
      index++;
    }
    for (let file of files) {
      tempFiles.push({ index: index, file: file.file, name: file.name});
      index++;
    }
    setFiles(tempFiles);
  }

  const handleRemoveFile = (e) => {
    let tempList = files;
    if (tempList.length === 1){
      setFiles([]);
    } else {
      tempList.splice(e.target.id, 1);
      for (let i = parseInt(e.target.id); i < tempList.length; i++) {
        tempList[i].index = i;
      }
      setFiles([...tempList]);
    }
  }

  const { /* control, */ handleSubmit, errors, register } = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      subjectType,
      describe,
      service,
      system,
      version,
      browser,
      nameType,
      emailType,
      shopsType,
      files,
    },
  });

  const onSubmit = () => {
    const ticketbody = {
      subjectType,
      describe,
      service,
      system,
      version,
      browser,
      nameType,
      emailType,
      shopsType,
      files,
    };

    API.postSupportTicket(ticketbody, supportSubTypeID)
        .then((ticketid) => {
          setShowModal(true);
          setTitle(`Ditt supportärende med nummer ${ticketid} har skapats!`);
          handleClear();
        })
        .catch((/*error*/) => {
          setShowModal(true);
          setTitle("Något gick fel, din förfrågan har inte skickats");
        });
    /*
    API.postFiles(files)
        .then((res) => {
        })
        .catch((error) => {
          console.log(error);
        });
     */
  };

  const handleClear = () => {
    setDescribe("");
    setService("");
    setSystem("");
    setVersion("");
    setBrowser("");
    setNameType("");
    setEmailType("");
    setShopsType("");
    setFiles([]);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <select
            className="itSupport__selectHKSupport"
            name="subjectType"
            onChange={(e) => setSubjectType(e.target.value)}
            value={subjectType}
            ref={register({ required: true })}
        >
          <option className="itSupport__optionText" value="">
            Välj aktuellt ärende *
          </option>
          <option>Portalen - Support/felanmälan</option>
          <option>Portalen(nya) - Lägg till användare</option>
        </select>
        {subjectType === "Portalen - Support/felanmälan" && (
            <>
              <TextField
              className="portal__input"
              label="Beskriv felet så utförligt som möjligt *"
              variant="outlined"
              name="describe"
              multiline
              minRows={4}
              fullWidth
              onChange={(e) => setDescribe(e.target.value)}
              value={describe}
              inputRef={register({ required: true })}
              error={errors.describe}
            ></TextField>
            <TextField
                className="portal__input"
                label="Vilken sida/tjänst gäller det? *"
                variant="outlined"
                name="service"
                multiline
                minRows={4}
                fullWidth
                onChange={(e) => setService(e.target.value)}
                value={service}
                inputRef={register({ required: true })}
                error={errors.service}
            ></TextField>
            {errors.system && (
              <p
                className="errorMessage"
                style={{ color: "red", marginTop: "12px" }}
              >
                {" "}
                Ange typ av fel *
              </p>
            )}
            <select
              className="itSupport__selectHKSupport"
              name="system"
              onChange={(e) => setSystem(e.target.value)}
              value={system}
              ref={register({ required: true })}
            >
              <option className="itSupport__optionText" value="">
                Vilket operativsystem använder ni? *
              </option>
              <option>Windows</option>
              <option>macOS</option>
              <option>Linux</option>
              <option>Vet ej</option>
            </select>
            <TextField
              className="portal__input"
              label="Version av operativ system? (t.ex. Windows 7, macOS Big Sur) Valfritt"
              variant="outlined"
              name="version"
              fullWidth
              onChange={(e) => setVersion(e.target.value)}
              value={version}
            >
              {/* {bodyContent}{" "} */}
            </TextField>
            {errors.browser && (
              <p
                className="errorMessage"
                style={{ color: "red", marginTop: "12px" }}
              >
                {" "}
                Ange webbläsare *
              </p>
            )}
            <select
              className="itSupport__selectHKSupport"
              name="browser"
              onChange={(e) => setBrowser(e.target.value)}
              value={browser}
              ref={register({ required: true })}
            >
              <option className="itSupport__optionText" value="">
                Vilken webbläsare använder ni? *
              </option>
              <option>Chrome</option>
              <option>Internet Explorer</option>
              <option>Firefox</option>
              <option>Safari</option>
              <option>Edge</option>
              <option>Vet ej</option>
            </select>
            </>
        )}
        {subjectType === "Portalen(nya) - Lägg till användare" && (
            <>
              <TextField
                  className="portal__input"
                  label="För- och efternamn"
                  variant="outlined"
                  name="nameType"
                  fullWidth
                  onChange={(e) => setNameType(e.target.value)}
                  value={nameType}
                  inputRef={register({ required: true })}
                  error={errors.nameType}
              >
              </TextField>
              {errors.nameType && (
                  <p
                      className="errorMessage"
                  >
                    {" "}
                    Ange namn *
                  </p>
              )}
              <TextField
                  className="portal__input"
                  label="Mejladress"
                  variant="outlined"
                  name="emailType"
                  fullWidth
                  onChange={(e) => setEmailType(e.target.value)}
                  value={emailType}
                  inputRef={register({ required: true })}
                  error={errors.emailType}
              >
              </TextField>
              {errors.emailType && (
                  <p
                      className="errorMessage"
                  >
                    {" "}
                    Ange mejladress *
                  </p>
              )}
              <TextField
                  className="portal__input"
                  label="Butik/butiker/övrig info"
                  variant="outlined"
                  name="shopsType"
                  fullWidth
                  onChange={(e) => setShopsType(e.target.value)}
                  value={shopsType}
                  inputRef={register({ required: true })}
                  error={errors.emailType}
              >
              </TextField>
              {errors.shopsType && (
                  <p
                      className="errorMessage"
                  >
                    {" "}
                    Ange butik/butiker/övrig info *
                  </p>
              )}
            </>
        )}
        <div className="fileUpload">
          <TextField
              name="fileField"
              label="Ladda upp filer"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
          >
          </TextField>
          <input value="" title="" className="fileInput" type="file" placeholder="" name="file" onChange={handleOnChange} multiple />
          {files.length > 0 ? <ul className="filesUl">
            {files.map((file) => (
                <li>
                  {file.name} <button className="removeFileButton" type="button" onClick={handleRemoveFile} id={file.index}>X</button>
                </li>
            ))}
          </ul>: null}
        </div>
        <button className="itSupport__button" type="submit">
          Skicka
        </button>
        <Modal
          showModal={showModal}
          closeModalHandler={closeModalHandler}
          title={title}
          buttonName="Stäng"
        />
      </form>
    </div>
  );
}

export default Portal;

import React, {useState, useEffect} from "react";
import "./style.css";
import {useDispatch} from "react-redux";
import Modal from "../Modal-ConfirmAlert";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MessageIcon from '@material-ui/icons/Message';
import {logout} from "../../../Redux/actions/userActions";
import API from "../../../utils/API";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  TextField,
} from "@material-ui/core";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
});

function InputModal({
    //title,
    showBGModal,
    closeBGModalHandler,
    bgID,
    bgName,
}) {
  const [showTheModal, setShowTheModal] = useState(false);
  const [theTitle, setTheTitle] = useState("");
  const [bg, setBG] = useState([]);
  const [expandOrderList, setExpandOrderList] = useState(true);
  //const [expandComment, setExpandComment] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [searchString, setSearchString] = useState("");

  const dispatch = useDispatch();

  const closeTheModalHandler = () => setShowTheModal(false);

  useEffect(() => {
    const openBG = async (bgID) => {
      await API.getBGOrders(bgID)
          .then((response) => {
            if (response.status === 200) {
              setBG(response.data);
            } else if (response.status >= 300 && response.status !== 404) {
              setShowTheModal(true);
              setTheTitle(`${response.data.message} - ${response.data.details.message}`);
            } else if (response.status === 401) {
              dispatch(logout());
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };

    setBG([]);
    void openBG(bgID);
  }, [bgID]);

  useEffect(() => {
   setSearchString("");
  }, [closeBGModalHandler]);

  useEffect(() => {
    let allOrders = document.getElementsByClassName("modal__BG__order");
    const orders = [];

    if (confirm) {
      for (let order of allOrders) {
          const data = {
            orderno: order.value,
            approved: order.checked,
            comment: order.nextSibling.nextSibling.nextSibling.nextSibling.nextSibling ? order.nextSibling.nextSibling.nextSibling.nextSibling.nextSibling.children[1].firstChild.value : "",
          }
          orders.push(data);
      }
      setConfirm(false);

      const approveBG = async (orders, bgID) => {
        await API.approveBG(orders,bgID)
            .then((response) => {
              if (response.status === 200) {
                setShowTheModal(true);
                setTheTitle("BG-ordrar godkända");
              } else if (response.status >= 300) {
                setShowTheModal(true);
                setTheTitle(`${response.data.message} - ${response.data.details.message}`);
              } else if (response.status === 401) {
                dispatch(logout());
              }
            })
            .catch((error) => {
              console.log("Error: ", error);
            });
      };
      void approveBG(orders, bgID);
    }
  }, [confirm]);

  const toggleShowComment = (e, index) => {
    let comment = document.getElementsByClassName("modal__BG__comment")[index];

    e.preventDefault();

    for (let icon of comment.previousSibling.previousSibling.children) {
      icon.classList.contains("modal__BG__hide") ? icon.classList.remove("modal__BG__hide") : icon.classList.add("modal__BG__hide");
    }

    comment.classList.contains("modal__BG__hide") ? comment.classList.remove("modal__BG__hide") : comment.classList.add("modal__BG__hide");
  }

  const saveAndExit = (e) => {
    let comments = document.getElementsByClassName("modal__BG__comment");
    let theComment = "";
    let orderNumber = "";
    let orderID = "";

    e.preventDefault();

    setConfirm(true); // Tillagd från knappen "Godkänn BG för valda ordrar"

    for (let comment of comments) {
      theComment = comment.children[1].children[0].value;
      orderNumber = comment.previousSibling.previousSibling.previousSibling.previousSibling.previousElementSibling.value;
      orderID = comment.children[1].children[0].id;

      if (theComment !== "") {
        const data = {
          CommentText: theComment,
        };

        API.addCommentToOrder(data, orderID, orderNumber).then((response) => {
          if (response.status === 200) {
          } else if (response.status === 401) {
            void dispatch(logout());
          }
        });
      }
    }
    closeBGModalHandler();
  }

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          className="modal"
          open={showBGModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeBGModalHandler}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="modal__topHeaderBenefitWindow">
            <DialogActions className="modal__topHeaderBenefitWindow">
              {" "}
              <span onClick={closeBGModalHandler} className="modal__closeBtn">
                x
              </span>
            </DialogActions>
            <h1 className="modal__header">BG</h1>
            <h2 className="modal__dateHeader">{bgName}</h2>
            {/* <span className="modal__dateRange"><h2>2023-12-01 - 2023-12-31</h2></span> */}
            <TextField
                label="Sök på ordernr/namn"
                variant="outlined"
                name="Search"
                className="modal__searchField"
                onChange={(e) => setSearchString(e.target.value)}
                value={searchString}
            >
            </TextField>
          </div>
          <DialogContent className="modal__BG__body">
            <Modal
                showModal={showTheModal}
                closeModalHandler={closeTheModalHandler}
                title={theTitle}
                buttonName="Stäng"
            />
            <p>BG-ordrar</p>
            <div className="modal__BG">
              <button onClick={() => setExpandOrderList(!expandOrderList)} className="modal__expandButton">{ expandOrderList ? <ExpandLessIcon /> : <ExpandMoreIcon />}</button><p>Ordrar - Avmarkera ej godkända ordrar</p>
              { expandOrderList && bg[0] && bg.map((order, index) =>
                (<>
                  {searchString === "" && <>
                  <div className="modal__hr"></div>
                  <input className="modal__BG__order" type="checkbox" id={order.OrderNo} name={order.OrderNo} value={order.OrderNo} defaultChecked/>
                  <a href={`/order/${order.OrderNo}`} rel="noopener noreferrer" target="_blank" className="modal__BG__orderLink">{order.OrderNo}</a>
                  <button onClick={(e) => toggleShowComment(e, index)} className="modal__expandCommentButton"><ExpandMoreIcon className="modal__BG__hide" /><ExpandLessIcon/></button>
                  <label>Kommentar {order.OrderComments && order.OrderComments.length > 0 ? <MessageIcon className="modal__icons" /> : null}</label>
                    <label className="modal__BG__name">{order.FirstName} {order.LastName}</label>
                  <TextField
                        label="Kommentar"
                        variant="outlined"
                        name="Comment"
                        fullWidth
                        className="modal__BG__comment modal__BG__hide"
                        id={order._id}
                  >
                  </TextField>
                  </>}
                  {searchString !== "" && order.OrderNo.toString().includes(searchString) && <>
                    <div className="modal__hr"></div>
                    <input className="modal__BG__order" type="checkbox" id={order.OrderNo} name={order.OrderNo} value={order.OrderNo} defaultChecked/>
                    <a href={`/order/${order.OrderNo}`} rel="noopener noreferrer" target="_blank" className="modal__BG__orderLink">{order.OrderNo}</a>
                    <button onClick={(e) => toggleShowComment(e, index)} className="modal__expandCommentButton"><ExpandMoreIcon className="modal__BG__hide" /><ExpandLessIcon/></button>
                    <label>Kommentar {order.OrderComments && order.OrderComments.length > 0 ? <MessageIcon className="modal__icons" /> : null}</label>
                    <label className="modal__BG__name">{order.FirstName} {order.LastName}</label>
                    <TextField
                        label="Kommentar"
                        variant="outlined"
                        name="Comment"
                        fullWidth
                        className="modal__BG__comment modal__BG__hide"
                        id={order._id}
                    >
                    </TextField>
                  </>}
                  {searchString !== "" && `${order.FirstName} ${order.LastName}`.toUpperCase().includes(searchString.toUpperCase()) && <>
                    <div className="modal__hr"></div>
                    <input className="modal__BG__order" type="checkbox" id={order.OrderNo} name={order.OrderNo} value={order.OrderNo} defaultChecked/>
                    <a href={`/order/${order.OrderNo}`} rel="noopener noreferrer" target="_blank" className="modal__BG__orderLink">{order.OrderNo}</a>
                    <button onClick={(e) => toggleShowComment(e, index)} className="modal__expandCommentButton"><ExpandMoreIcon className="modal__BG__hide" /><ExpandLessIcon/></button>
                    <label>Kommentar {order.OrderComments && order.OrderComments.length > 0 ? <MessageIcon className="modal__icons" /> : null}</label>
                    <label className="modal__BG__name">{order.FirstName} {order.LastName}</label>
                    <TextField
                        label="Kommentar"
                        variant="outlined"
                        name="Comment"
                        fullWidth
                        className="modal__BG__comment modal__BG__hide"
                        id={order._id}
                    >
                    </TextField>
                  </>}
                </>))
              }
            </div>
          </DialogContent>
          <div className="modal__BG__buttonDiv">
            {/* <button className="modal__BG__button" onClick={() => setConfirm(true)}>Godkänn BG för valda ordrar</button> */}
            {/*
            <button className="modal__BG__button">Generera KAL</button>
            <button className="modal__BG__button">Generera OU-STOPP</button>
            <button className="modal__BG__button">Generera OU-KOMPLETT</button>
            */}
            {/* <button onClick={(e) => saveAndExit(e)} className="modal__BG__button modal__BG__closeButton">
              Spara & stäng
            </button> */}
            <button onClick={(e) => saveAndExit(e)} className="modal__BG__button modal__BG__closeButton">
              Godkänn & stäng
            </button>
          </div>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

export default InputModal;

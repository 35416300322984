import React, {useState, useEffect} from "react";
import "./style.css";
import {logout} from "../../../Redux/actions/userActions";
import {useDispatch} from "react-redux";
import Modal from "../Modal-ConfirmAlert";
import API from "../../../utils/API";

import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
});

function InputModal({
    title,
    showModal,
    closeModalHandler,
    windowID,
}) {
  const [/*userID*/, setUserID] = useState("");
  const [theComment, setTheComment] = useState("");
  const [showTheModal, setShowTheModal] = useState(false);
  const [theTitle, /*setTheTitle*/] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if(showModal) {
      setTheComment("");
      document.getElementsByClassName("modal__addNewComment__input")[0]?.children[1]?.children[0]?.focus();
    }
  }, [showModal]);

  useEffect(() => {
    const GetUserName = async () => {
      await API.getUserId()
          .then((res) => {
            setUserID(res.data);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void GetUserName();
  }, [localStorage.getItem("token")]);

  const closeTheModalHandler = () => setShowTheModal(false);

  const saveCommentAndCloseModal = (e) => {

    e.preventDefault();

    if(theComment !== "") {
      const data = {
        CommentText: theComment,
      };

      API.addCommentToWindow(data, windowID).then((response) => {
        if (response.status === 200) {
        } else if (response.status === 401) {
          void dispatch(logout());
        }
      }).then(() => {
        closeModalHandler();
      });
    } else {
      closeModalHandler();
    }
  }

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          className="modal"
          open={showModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeModalHandler}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogActions className="modal__topHeader">
            {" "}
            <span onClick={closeModalHandler} className="modal__closeBtn">
              x
            </span>
          </DialogActions>
          <DialogTitle className="modal__header">{title}</DialogTitle>

          <DialogContent className="modal__addNewComment__body">
            <Modal
                showModal={showTheModal}
                closeModalHandler={closeTheModalHandler}
                title={theTitle}
                buttonName="Stäng"
            />
            <TextField
                className="modal__addNewComment__input"
                label="Kommentar"
                variant="outlined"
                name="theComment"
                fullWidth
                multiline
                minRows={8}
                onChange={(e) => setTheComment(e.target.value)}
                value={theComment}
            >
            </TextField>
          </DialogContent>
          <button onClick={(e) => saveCommentAndCloseModal(e, closeModalHandler)} className="modal__addNewComment__button">
            Spara & stäng
          </button>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

export default InputModal;

import React, { useState, useEffect } from "react";
import "./style.css";
import { postSupportType } from "../../Redux/actions/itSupportAction";
import { useDispatch } from "react-redux";
import ITSupport from "../Support/ITSupport";
//import ButikSupport from "../ButikSupport";

import {
  Dialog,
  //DialogContent,
  DialogActions,
  //DialogTitle,
  //TextField,
  Slide,
} from "@material-ui/core";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
});

function HkModal({ /* title, bodyContent, */ open, handleClose }) {
  const [toggleState, setToggleState] = useState(1);
  const [option, setOption] = useState("");

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const storeOption = () => {
      if (toggleState === 1) {
        setOption("IT-support");
      }
      if (toggleState === 2) {
        setOption("Butik-support");
      }
      if (toggleState === 3) {
        setOption("Skyltbegäran");
      }
      if (toggleState === 4) {
        setOption("Öppettider");
      }
    };
    storeOption();
  });

  const handleClick = (index) => {
    toggleTab(index);

    void dispatch(postSupportType(option));
  };

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          TransitionComponent={Transition}
        >
          <div className="hkSupport__toggleBtn">
            <div
              style={{
                borderBottomLeftRadius: "5px",
                borderTopLeftRadius: "5px",
              }}
              className={
                toggleState === 1
                  ? "hkSupport__tabs active-tabs"
                  : "hkSupport__tabs"
              }
              onClick={() => handleClick(1)}
            >
            </div>
            {/* <div
              style={{
                borderBottomRightRadius: "5px",
                borderTopRightRadius: "5px",
              }}
              className={
                toggleState === 2
                  ? "hkSupport__tabs active-tabs"
                  : "hkSupport__tabs"
              }
              onClick={() => handleClick(2)}
            >
              Butik-support
            </div> */}
            {/* <div
              style={{
                borderBottomRightRadius: "5px",
                borderTopRightRadius: "5px",
              }}
              className={
                toggleState === 3
                  ? "hkSupport__tabs active-tabs"
                  : "hkSupport__tabs"
              }
              onClick={() => handleClick(3)}
            >
              Skyltbegäran
            </div> */}
            {/* <div
              style={{
                borderBottomRightRadius: "5px",
                borderTopRightRadius: "5px",
              }}
              className={
                toggleState === 4
                  ? "hkSupport__tabs active-tabs"
                  : "hkSupport__tabs"
              }
              onClick={() => handleClick(4)}
            >
              Öppettider
            </div> */}
            <DialogActions className="modal__topHeader">
              {" "}
              <span onClick={handleClose} className="modal__closeBtn">
                x
              </span>
            </DialogActions>
          </div>

          <div
            className={
              toggleState === 1
                ? "hkSupport__content active-content"
                : "hkSupport__content"
            }
          >
            <ITSupport option={option} />
          </div>
          {/*  <div
            className={
              toggleState === 2
                ? "hkSupport__content active-content"
                : "hkSupport__content"
            }
          >
            <ButikSupport option={option} />
          </div>
          <div
            className={
              toggleState === 3
                ? "hkSupport__content active-content"
                : "hkSupport__content"
            }
          >
            <DialogTitle className="modal__header">{title}</DialogTitle>

            <DialogContent className="modal__body ">
              <TextField
                label="Skyltbegäran"
                variant="outlined"
                name="Comment"
                multiline
                rows={4}
                fullWidth
              >
                {bodyContent}
              </TextField>
            </DialogContent>
          </div>
          <div
            className={
              toggleState === 4
                ? "hkSupport__content active-content"
                : "hkSupport__content"
            }
          >
            <DialogTitle className="modal__header">{title}</DialogTitle>

            <DialogContent className="modal__body ">
              <select className="hkSupport__select">
                <option className="hkSupport__optionText" value="">
                  Portalen
                </option>
                <option>Kassa / Servicesystem</option>
              </select>
              <TextField
                label="öppettider"
                variant="outlined"
                name="Comment"
                multiline
                rows={4}
                fullWidth
              >
                {bodyContent}
              </TextField>
            </DialogContent>
          </div> */}
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

export default HkModal;

import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import Login from "./pages/Login/Login";
import ArticleForm from "./pages/ArticleForm/ArticleForm";
import UsedBikesForm from "./pages/UsedBikesForm/UsedBikesForm";
import Giftcard from "./pages/Giftcard/Giftcard";
import Order from "./pages/SearchOrder/SearchOrder";
import DisplayOrder from "./pages/Display/DisplayOrder/DisplayOrder";
import DisplayCompany from "./pages/Display/DisplayCompany/DisplayCompany";
import DisplayBenefitWindow from "./pages/Display/DisplayBenefitWindow/DisplayBenefitWindow";
import Ordercentral from "./pages/Ordercentral/Ordercentral";
import Orderhantering from "./pages/Orderhantering/Orderhantering";
import Formanscykel from "./components/FormanscykelFonster";
import Users from "./pages/Users/Users";
import News from "./pages/News/News";
import ButiksService from "./pages/ButiksService/ButiksService"; // Some CSS here that is important for the rest of the App
import { useSelector, useDispatch } from "react-redux";
import { logout } from "./Redux/actions/userActions";
import SupportTickets from "./pages/SupportTickets/SupportTickets";
import Company from "./pages/Bolag/Bolag";
import BenefitWindow from "./pages/Formansfonster/Formansfonster";
import PlanBenefitWindowsAdmin from "./pages/PlanBenefitWindowsAdmin/PlanBenefitWindowsAdmin";
import PlanBenefitWindowsPC from "./pages/PlanBenefitWindowsPC/PlanBenefitWindowsPC";
import API from "./utils/API";

function App() {
  const { userInfo } = useSelector((state) => state.user);
  const [adminAccess, setAdminAccess] = useState(false);
  const [shopStaff, setShopStaff] = useState(false);
  const [inactive, setInactive] = useState(false);
  const dispatch = useDispatch();

    const validateToken = async () => {
        return await API.validateToken().then((response) => {
            return response.status === 200;
        });
    }

    const getLoggedInUserRights = async () => {
        await API.getLoggedInUserRights()
            .then((response) => {
                    if (response.data.name === "Butikspersonal")
                        setShopStaff(true);
                    setAdminAccess(response.data.adminaccess);
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    useEffect(() => {
        setInterval(() => {
            if (localStorage.getItem("token")) {
                validateToken().then((response) => {
                    if (!response)
                        void dispatch(logout());
                });
            } else {
                void dispatch(logout());
            }
        }, 600000);
    }, []);

  return (
    <div>
      <Router>
        <div className="app">
          { !userInfo && !localStorage.getItem("token") ? (
              <>
                  <Redirect to="/" />
                  <Login />
              </>
          ) : validateToken() && (
            <div>
              <div className="app__nav">
                  { getLoggedInUserRights() && <Sidebar
                  onCollapse={(inactive) => {
                    setInactive(inactive);
                  }}
                  adminaccess={adminAccess}
                  shopstaff={shopStaff}
                /> }
              </div>
              <div className={`app__container ${inactive ? "inactive" : ""}`}>
                <Switch>
                    {adminAccess && <Route path="/admin/users" exact component={Users}></Route>}
                    {adminAccess && <Route path="/admin/company" exact component={Company}></Route>}
                    {adminAccess && <Route path="/admin/company/:id" exact component={DisplayCompany}></Route>}
                    {adminAccess && <Route path="/admin/benefitwindow" exact component={BenefitWindow}></Route>}
                    {adminAccess && <Route path="/admin/benefitwindow/:id" exact component={DisplayBenefitWindow}></Route>}
                    {adminAccess && <Route path="/admin/planbenefitwindowsadmin" exact component={PlanBenefitWindowsAdmin}></Route>}
                    {adminAccess && <Route path="/admin/supporttickets" exact component={SupportTickets}></Route>}

                    <Route path="/admin/planbenefitwindowspc" exact component={PlanBenefitWindowsPC}></Route>

                    <Route path="/" exact component={News}></Route>
                    <Route path="/giftcard" exact component={Giftcard}></Route>
                    <Route path="/order" exact component={Order}></Route>
                    <Route path="/order/:id" exact component={DisplayOrder}></Route>
                    <Route path="/ordercentral" exact component={Ordercentral}></Route>
                    <Route path="/articleform" exact component={ArticleForm}></Route>
                    <Route path="/orderhantering" exact component={Orderhantering}></Route>
                    <Route path="/formanscykel" exact component={Formanscykel}></Route>
                    <Route path="/usedbikesform" exact component={UsedBikesForm}></Route>
                </Switch>
              </div>
            </div>
          )}
        </div>
      </Router>
    </div>
  );
}

export default App;

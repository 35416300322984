import React, {useEffect, useState, useRef} from "react";
import "./BenefitWindow.css";
import Header from "../../components/Header";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import {logout} from "../../Redux/actions/userActions";
import {useDispatch} from "react-redux";
import ModalConfirmAlert from "../../components/Modals/Modal-ConfirmAlert";
import ModalBenefitWindowConditions from "../../components/Modals/Modal-BenefitWindowConditions";
import ModalDemo from "../../components/Modals/Modal-Demo";
import ModalConfirmWithButtons from "../../components/Modals/Modal-ConfirmWithButtons";
import {sv} from 'date-fns/esm/locale'
import moment from "moment";
import API from "../../utils/API";
import {TextField} from "@material-ui/core";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ModalAddNewCommentWindow from "../../components/Modals/Modal-AddNewCommentWindow";
import ModalEditCommentWindow from "../../components/Modals/Modal-EditCommentWindow";
import CommentCompanyOrWindow from "../../components/CommentCompanyOrWindow";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCommentIcon from "@material-ui/icons/AddComment";
import WarningIcon from "@material-ui/icons/Warning";
import DoneIcon from '@material-ui/icons/Done';
import LockIcon from '@material-ui/icons/Lock';
import Tooltip from "@material-ui/core/Tooltip";

function BenefitWindow({ id = "" }) {
    const [createBenefitWindow, setCreateBenefitWindow] = useState(false);
    const [editBenefitWindow, setEditBenefitWindow] = useState(false);
    const [benefitWindowOpen, setBenefitWindowOpen] = useState(false);
    const [benefitWindowClosed, setBenefitWindowClosed] = useState(false);
    const [showModalBenefitWindowConditions, setShowModalBenefitWindowConditions] = useState(false);
    const [showModalDemo, setShowModalDemo] = useState(false);
    const [bankIdRequired, setBankIdRequired] = useState(false);
    const [shopsSportsonOptions, setShopsSportsonOptions] = useState([]);
    const [shopsExternalPartnersOptions, setShopsExternalPartnersOptions] = useState([]);
    const [shopsMapList, setShopsMapList] = useState([]);
    const [externalPartnersMapList, setExternalPartnersMapList] = useState([]);
    const [/*theName*/, setTheName] = useState("");
    const [theCompany, setTheCompany] = useState("");
    const [theCompanyID, setTheCompanyID] = useState("");
    const [theConnectedSubcompany, /*setTheConnectedSubcompany*/] = useState("");
    const [theStartDate, setTheStartDate] = useState("");
    const [theEndDate, setTheEndDate] = useState("");
    const [theRulesAndConditions, setTheRulesAndConditions] = useState([]);
    const [theDemo, setTheDemo] = useState([]);
    const [loadBenefitWindowRules, setLoadBenefitWindowRules] = useState([]);
    const [theOutgoingInvoicenr, setTheOutgoingInvoicenr] = useState("");
    const [theInvoiceDate, setTheInvoiceDate] = useState("");
    const [theCalculationFactor, setTheCalculationFactor] = useState("");
    const [theSalesperson, setTheSalesperson] = useState("");
    const [loadTheSalesperson, setLoadTheSalesperson] = useState([]);
    const [/*theSportsonShops*/, setTheSportsonShops] = useState([]);
    const [loadTheSportsonShops, setLoadTheSportsonShops] = useState([]);
    const [loadTheExternalPartners, setLoadTheExternalPartners] = useState([]);
    const [theAffectedStores, setTheAffectedStores] = useState([]);
    const [theExternalStores, setTheExternalStores] = useState([]);
    const [theWindowSetupComplete, setTheWindowSetupComplete] = useState(false);
    //const [theContractStart, setTheContractStart] = useState("");
    const [theLeasingAgreementFile, /*setTheLeasingAgreementFile*/] = useState(false);
    const leasingFactor = useRef("");
    const [benefitWindowID, setBenefitWindowID] = useState([]);
    const [title, setTitle] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [companiesOptions, setCompaniesOptions] = useState([]);
    const [benefitWindowsOptions, setBenefitWindowsOptions] = useState([]);
    const [personalcyklarcContactsOptions, setPersonalcyklarContactsOptions] = useState([]);
    const [agreementOptions, setAgreementOptions] = useState([]);
    const [publicPortal, setPublicPortal] = useState(false);
    const [publicPortalDisabled, setPublicPortalDisabled] = useState(true);
    //const [standardAgreement, setStandardAgreement] = useState(true);
    //const [securityAgreement, setSecurityAgreement] = useState(false);
    const [customerSpecificAgreement, setCustomerSpecificAgreement] = useState(false);
    const [isDisabledBenefitWindowConditions, setIsDisabledBenefitWindowConditions] = useState(true);
    const [cancelled, setCancelled] = useState(false);
    const [benefitWindowCancelled, setBenefitWindowCancelled] = useState(false);
    const [loadTheDemo, setLoadTheDemo] = useState([]);
    const [titleConfirmWithButtonsModal, setTitleConfirmWithButtonsmModal] = useState("");
    const [showConfirmWithButtonsModal, setShowConfirmWithButtonsModal] = useState(false);
    const [cancelBenefitWindow, setCancelBenefitWindow] = useState(false);
    const [theAlternateName, setTheAlternateName] = useState("");
    //const [dataPreviousBenefitWindow, setDataPreviousBenefitWindow] = useState({});
    const [theConditions, setTheConditions] = useState("");
    //const [selectedFiles, setSelectedFiles] = useState([]); Remove for SP-665
    //const [uploadedFiles, setUploadedFiles] = useState([]); Remove for SP-665
    //const [filesChosen, setFilesChosen] = useState(false); Remove for SP-665
    //const [areFilesUploaded, setAreFilesUploaded] = useState(false); Remove for SP-665
    //const [/*theAgreementRef*/, setTheAgreementRef] = useState([]); Remove for SP-665
    const [expandedComments, setExpandedComments] = useState(true);
    const [comments, setComments] = useState([]);
    const [showModalAddNewComment, setShowModalAddNewComment] = useState(false);
    const [showModalEditComment, setShowModalEditComment] = useState(false);
    const [theCommentID, setTheCommentID] = useState(undefined);
    const [theCommentText, setTheCommentText] = useState(undefined);
    const [theAgreement, setTheAgreement] = useState("");
    const [loadTheAgreement, setLoadTheAgreement] = useState([]);

    const closeModalHandler = () => setShowModal(false);
    const closeModalBenefitWindowConditionsHandler = () => setShowModalBenefitWindowConditions(false);
    const closeConfirmWithButtonsModalHandler = () => setShowConfirmWithButtonsModal(false);
    const setCancelBenefitWindowHandler = () => setCancelBenefitWindow(true);
    const closeModalDemoHandler = () => setShowModalDemo(false);
    const closeModalAddNewCommentHandler = () => setShowModalAddNewComment(false);
    const closeModalEditCommentHandler = () => setShowModalEditComment(false);

    const selectCompanyRef = useRef(null);
    const selectShopsRef = useRef(null);
    const selectExternalPartnersRef = useRef(null);
    const selectBenefitWindowRef = useRef(null);
    const selectSalespersonRef = useRef(null);
    const selectAgreementRef = useRef(null);
    const dispatch = useDispatch();

    const addNewCommentHandler = (e, windowID) => {
        e.preventDefault();

        if (windowID && windowID !== "") {
            setShowModalAddNewComment(true);
            setTitle("Lägg till kommentar");
        }
    }

    const editCommentHandler = (e, windowID, commentID, commentText) => {
        e.preventDefault();

        setTheCommentID(commentID);
        setTheCommentText(commentText);
        setShowModalEditComment(true);
        setTitle("Ändra kommentar");
    }

    const deleteCommentHandler = (e, windowID, commentID) => {
        e.preventDefault();

        const data = {
            CommentId: commentID,
        };

        API.deleteCommentOnWindow(data, windowID).then((response) => {
            if (response.status === 200){
                void handleGetCommentsBenefitWindow();
            } else if (response.status === 401) {
                void dispatch(logout());
            }
        });
    }

    /* Remove for SP-665
    const handleFileChange = (event) => {
        setSelectedFiles([...selectedFiles, ...event.target.files]);
        setFilesChosen(true);
        setAreFilesUploaded(false);
        event.target.value = "";
    };

    const handleUploadFiles = (e) => {
        const formData = new FormData();

        e.preventDefault();
        if (selectedFiles.length === 1) {
            formData.append('file', selectedFiles[0]);
            if (benefitWindowID && benefitWindowID[0] !== "") {
                API.uploadAgreement(benefitWindowID, formData)
                    .then(function (response) {
                        if (response.status === 201) {
                            setAreFilesUploaded(true);
                            setUploadedFiles(selectedFiles);
                            setTheAgreementRef(response.data);
                            setShowModal(true);
                            setTitle("Avtalet har laddats upp.");
                        } else if (response.status >= 300) {
                            setShowModal(true);
                            setTitle(`${response.data.message} - ${response.data.details}`);
                        }
                    })
                    .catch(error) => {
                        //console.log(error);
                        setShowModal(true);
                        setTitle("Avtalet kunde ej laddas upp.");
                    });
            } else {
                setShowModal(true);
                setTitle("Du måste välja ett beställningsfönster att koppla avtalet till");
            }
            //setTheFormdata(formData);
        } else {
            setShowModal(true);
            setTitle('1 avtal måste bifogas. Lägg till dessa under knappen "Bläddra" och ladda upp med knappen "Ladda upp filer".');
        }
    };

    const handleRemoveFiles = () => {
        setSelectedFiles([]);
        setTheAgreementRef([]);
        setFilesChosen(false);
    };
     */

    const forceClearSelect = () => {

        if (selectShopsRef.current) {
            selectShopsRef.current.clearValue();
            selectShopsRef.current.inputValue = "";
        }
        if (selectExternalPartnersRef.current) {
            selectExternalPartnersRef.current.clearValue();
            selectExternalPartnersRef.current.inputValue = "";
        }
        if (selectSalespersonRef.current) {
            selectSalespersonRef.current.clearValue();
            selectSalespersonRef.current.inputValue = [];
        }
        if (selectAgreementRef.current) {
            selectAgreementRef.current.clearValue();
            selectAgreementRef.current.inputValue = "";
        }
    };

    const forceClearSelectBenefitWindow = () => {
        if (selectBenefitWindowRef.current) {
            selectBenefitWindowRef.current.clearValue();
            selectBenefitWindowRef.current.inputValue = "";
        }
    };

    /* Remove not needed code for now
    const handleCopyPreviousBenefitWindow = () => {

        const data = {
            //name: theName,
            company: theCompany,
            connected_subcompany: theConnectedSubcompany,
            //startdate: theStartDate, not needed to be passed from previous benefit window
            //enddate: theEndDate, not needed to be passed from previous benefit window
            window_rules: theRulesAndConditions,
            outgoing_invoicenr: theOutgoingInvoicenr,
            invoice_date: theInvoiceDate,
            calculation_factor: theCalculationFactor,
            sales_person: theSalesperson,
            load_sales_person: loadTheSalesperson,
            load_affected_stores: loadTheSportsonShops,
            affected_stores: theAffectedStores,
            external_stores: theExternalStores,
            window_setup_complete: theWindowSetupComplete,
            contract_start: theContractStart,
            leasing_agreement_file: theLeasingAgreementFile,
            leasing_factor: leasingFactor.current,
            bank_id_required: bankIdRequired,
            general_portal: publicPortal,
            demo: theDemo,
            standard_agreement: standardAgreement,
            security_agreement: securityAgreement,
        };
        setDataPreviousBenefitWindow(data);
    }

    const handleInsertPreviousCopiedBenefitWindow = (e) => {
        e.preventDefault();

        if(dataPreviousBenefitWindow.company) {
            setTheCompany(dataPreviousBenefitWindow.company);
            setTheCalculationFactor(dataPreviousBenefitWindow.calculation_factor);
            leasingFactor.current = dataPreviousBenefitWindow.leasing_factor;
            if (dataPreviousBenefitWindow.load_sales_person) {
                setTheSalesperson(dataPreviousBenefitWindow.sales_person);
                setLoadTheSalesperson(dataPreviousBenefitWindow.load_sales_person);
            }
            if (dataPreviousBenefitWindow.affected_stores.length > 0) {
                loadSportsonShops(dataPreviousBenefitWindow.affected_stores);
                selectShopsRef.current.inputValue = loadTheSportsonShops;
            }
            if (dataPreviousBenefitWindow.external_stores.length > 0) {
                selectExternalPartnersRef.current = dataPreviousBenefitWindow.external_stores;
                setTheExternalStores(dataPreviousBenefitWindow.external_stores);
                loadExternalPartners(dataPreviousBenefitWindow.external_stores);
            }
            setTheWindowSetupComplete(dataPreviousBenefitWindow.window_setup_complete);
            setPublicPortal(dataPreviousBenefitWindow.general_portal);
            setBankIdRequired(dataPreviousBenefitWindow.bank_id_required);
            setStandardAgreement(dataPreviousBenefitWindow.standard_agreement);
            setSecurityAgreement(dataPreviousBenefitWindow.security_agreement);
            setLoadBenefitWindowRules(dataPreviousBenefitWindow.window_rules);
        }
    }
     */

    const handleResetBenefitWindowFields = () => {

        if (editBenefitWindow) {
            setBenefitWindowID([""]);
            setTheCompanyID("");
            setTheCompany("");
            setTheName("");
            setTheAlternateName("");
            setTheSalesperson("");
            setLoadTheSalesperson([]);
            setTheInvoiceDate("");
            setTheAffectedStores([]);
            setBankIdRequired(false);
            setTheWindowSetupComplete(false);
            //setStandardAgreement(true);
            //setSecurityAgreement(false);
            setCustomerSpecificAgreement(false);
            setCancelled(false);
            setComments([]);
            forceClearSelect();
        }
        forceClearSelect();
        setLoadTheAgreement([]);
        setTheStartDate("");
        setTheEndDate("");
        setTheCalculationFactor("");
        setTheRulesAndConditions([]);
        setLoadBenefitWindowRules([]);
        setTheDemo([]);
        setLoadTheDemo([]);
        setTheConditions("");
        setTheOutgoingInvoicenr("");
    }

    const handleCreateBenefitWindowSwitch = (e) => {
        e.preventDefault();

        setCreateBenefitWindow(true);
        setEditBenefitWindow(false);
        setBenefitWindowID([""]);
        setTheCompanyID("");
        setTheAlternateName("");
        setCustomerSpecificAgreement(false);
        //setFilesChosen(false); Remove for SP-665
        // setAreFilesUploaded(false); Remove for SP-665

        handleResetBenefitWindowFields();
    }

    const handleChangeBenefitWindowSwitch = (e) => {
        e.preventDefault();

        setEditBenefitWindow(true);
        setCreateBenefitWindow(false);
        setBenefitWindowID([""]);
        setTheCompanyID("");
        setTheCompany("");
        setTheAlternateName("");
        setCustomerSpecificAgreement(false);
        //setFilesChosen(false); Remove for SP-665
        //setAreFilesUploaded(false); Remove for SP-665

        handleResetBenefitWindowFields();
    }

    const handleUpdateCompany = () => {
        const data = {
            alternate_name: theAlternateName,
        }

        API.updateCompany(data, theCompanyID).then((response) => {
            if (response.status === 200){
            } else if (response.status >= 300) {
                setShowModal(true);
                setTitle(`${response.data.message} - ${response.data.details}`);
            } else if (response.status === 401) {
                void dispatch(logout());
            }
        });
    }

    const handleCreateBenefitWindow = (e) => {
        //const formData = new FormData(); Remove for SP-665

        e.preventDefault();

        if(theStartDate === null || theStartDate === "" || theEndDate === null || theEndDate === "") {
            setShowModal(true);
            setTitle("Beställningsfönstret måste ha ett start- och slutdatum");
            return;
        }

        if (theAgreement === ""){
            setShowModal(true);
            setTitle("Ett UH-avtal måste väljas vid skapa beställningsfönster");
            return;
        }
        /* Remove for SP-665
                if (customerSpecificAgreement && theAgreement !== "")
                    if(!filesChosen) {
                        setShowModal(true);
                        setTitle("Du måste välja ett kundspecifikt UH-avtal att ladda upp");
                        return;
                    }

         */

        handleUpdateCompany();

        let data = {};

        if (!publicPortal) {
            data = {
                //name: theName,
                company: theCompanyID,
                connected_subcompany: theConnectedSubcompany,
                startdate: moment(theStartDate).format("yyyy-MM-DD"),
                enddate: moment(theEndDate).format("yyyy-MM-DD"),
                window_rules: theRulesAndConditions,
                outgoing_invoicenr: theOutgoingInvoicenr,
                invoice_date: theInvoiceDate,
                calculation_factor: theCalculationFactor,
                sales_person: theSalesperson,
                affected_stores: theAffectedStores,
                external_stores: theExternalStores,
                window_setup_complete: theWindowSetupComplete,
                //contract_start: theContractStart,
                leasing_agreement_file: theLeasingAgreementFile,
                leasing_factor: leasingFactor.current,
                bank_id_required: bankIdRequired,
                general_portal: publicPortal,
                demo: theDemo,
                //standard_agreement: customerSpecificAgreement ? false : standardAgreement,
                //security_agreement: securityAgreement,
                customer_specific_agreement: customerSpecificAgreement,
                agreement: theAgreement,
                conditions: theConditions,
            };
        } else {
            data = {
                //name: theName,
                company: theCompanyID,
                connected_subcompany: theConnectedSubcompany,
                startdate: moment(theStartDate).format("yyyy-MM-DD"),
                enddate: moment(theEndDate).format("yyyy-MM-DD"),
                outgoing_invoicenr: theOutgoingInvoicenr,
                invoice_date: theInvoiceDate,
                sales_person: theSalesperson,
                affected_stores: theAffectedStores,
                external_stores: theExternalStores,
                window_setup_complete: theWindowSetupComplete,
                //contract_start: theContractStart,
                leasing_agreement_file: theLeasingAgreementFile,
                leasing_factor: leasingFactor.current,
                bank_id_required: bankIdRequired,
                general_portal: publicPortal,
                demo: theDemo,
                //standard_agreement: customerSpecificAgreement ? false : standardAgreement,
                //security_agreement: securityAgreement,
                customer_specific_agreement: customerSpecificAgreement,
                agreement: theAgreement,
                conditions: theConditions,
            };
        }

        API.createNewBenefitWindow(data).then((response) => {
            if (response.status === 201){
                setShowModal(true);
                setTitle("Beställningsfönster skapat");
                //handleCopyPreviousBenefitWindow();
                setTheStartDate("");
                setTheEndDate("");

                //handleResetBenefitWindowFields();
                /* Remove for SP-665
                if (selectedFiles[0]) {
                    formData.append('file', selectedFiles[0]);
                    API.uploadAgreement(response.data._id, formData)
                        .then(function (response) {
                            if (response.status === 201) {
                                setSelectedFiles([]);
                                setTheAgreementRef([]);
                                setFilesChosen(false);
                                setShowModal(true);
                                //handleResetBenefitWindowFields();
                            } else if (response.status >= 300) {
                                setShowModal(true);
                                setTitle(`${response.data.message} - ${response.data.details}`);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            setShowModal(true);
                            setTitle("Avtalet kunde ej laddas upp.");
                        });
                } */
            } else if (response.status >= 300) {
                setShowModal(true);
                setTitle(`${response.data.message} - ${response.data.details}`);
            } else if (response.status === 401) {
                void dispatch(logout());
            } else if (response.status === 503) {
                setShowModal(true);
                setTitle("Problem med servern. Försök igen senare.");
            }
        });
    }

    const handleUpdateBenefitWindow = (e) => {
        e.preventDefault();

        if(theStartDate === null || theStartDate === "" || theEndDate === null || theEndDate === "") {
            setShowModal(true);
            setTitle("Beställningsfönstret måste ha ett start- och slutdatum");
            return;
        }

        // TODO take in consideration if customerSpecificAgreement is already uploaded beforehand for the chosen benefit window (when flag for this is added to the backend)
        // Remove for SP-665:
        // if(customerSpecificAgreement && theAgreement !== "")
        // if(!areFilesUploaded /* && agreement is not already uploaded */) {
        // setShowModal(true);
        //setTitle("Du måste ladda upp ett kundspecifikt UH-avtal");
        //return;
        // }

        handleUpdateCompany();

        let data = {};

        if(!benefitWindowClosed) {
            if (!publicPortal) {
                data = {
                    //name: theName,
                    company: theCompanyID,
                    connected_subcompany: theConnectedSubcompany,
                    startdate: moment(theStartDate).format("yyyy-MM-DD"),
                    enddate: moment(theEndDate).format("yyyy-MM-DD"),
                    window_rules: theRulesAndConditions,
                    outgoing_invoicenr: theOutgoingInvoicenr,
                    invoice_date: theInvoiceDate,
                    calculation_factor: theCalculationFactor,
                    sales_person: theSalesperson,
                    affected_stores: theAffectedStores,
                    external_stores: theExternalStores,
                    window_setup_complete: theWindowSetupComplete,
                    //contract_start: theContractStart,
                    leasing_agreement_file: theLeasingAgreementFile,
                    leasing_factor: leasingFactor.current,
                    bank_id_required: bankIdRequired,
                    general_portal: publicPortal,
                    demo: theDemo,
                    //standard_agreement: customerSpecificAgreement ? false : standardAgreement,
                    //security_agreement: securityAgreement,
                    customer_specific_agreement: customerSpecificAgreement,
                    agreement: theAgreement,
                    cancelled: cancelled,
                    conditions: theConditions,
                };
            } else {
                data = {
                    //name: theName,
                    company: theCompanyID,
                    connected_subcompany: theConnectedSubcompany,
                    startdate: moment(theStartDate).format("yyyy-MM-DD"),
                    enddate: moment(theEndDate).format("yyyy-MM-DD"),
                    outgoing_invoicenr: theOutgoingInvoicenr,
                    invoice_date: theInvoiceDate,
                    sales_person: theSalesperson,
                    affected_stores: theAffectedStores,
                    external_stores: theExternalStores,
                    window_setup_complete: theWindowSetupComplete,
                    //contract_start: theContractStart,
                    leasing_agreement_file: theLeasingAgreementFile,
                    leasing_factor: leasingFactor.current,
                    bank_id_required: bankIdRequired,
                    general_portal: publicPortal,
                    demo: theDemo,
                    //standard_agreement: customerSpecificAgreement ? false : standardAgreement,
                    //security_agreement: securityAgreement,
                    customer_specific_agreement: customerSpecificAgreement,
                    agreement: theAgreement,
                    cancelled: cancelled,
                    conditions: theConditions,
                };
            }
        } else if (benefitWindowClosed) {
            data = {
                outgoing_invoicenr: theOutgoingInvoicenr,
                invoice_date: theInvoiceDate,
            };
        }

        if(benefitWindowID !== "") {
            if(!benefitWindowClosed) {
                API.updateBenefitWindow(data, benefitWindowID).then((response) => {
                    if (response.status === 200) {
                        setShowModal(true);
                        setTitle("Beställningsfönster ändrat");
                    } else if (response.status === 201) {

                    } else if (response.status >= 300) {
                        setShowModal(true);
                        setTitle(`${response.data.message} - ${response.data.details}`);
                    } else if (response.status === 401) {
                        void dispatch(logout());
                    }
                }).then(() => {
                    setEditBenefitWindow(false);
                    setEditBenefitWindow(true);
                    handleResetBenefitWindowFields();
                    if (selectCompanyRef.current) {
                        selectCompanyRef.current.clearValue();
                        selectCompanyRef.current.inputValue = "";
                    }
                });
            } else if (benefitWindowClosed) {
                API.updateBenefitWindowInvoice(data, benefitWindowID).then((response) => {
                    if (response.status === 200) {
                        setShowModal(true);
                        setTitle("Beställningsfönster ändrat");
                    } else if (response.status === 201) {

                    } else if (response.status >= 300) {
                        setShowModal(true);
                        setTitle(`${response.data.message} - ${response.data.details}`);
                    } else if (response.status === 401) {
                        void dispatch(logout());
                    } else if (response.status === 503) {
                        setShowModal(true);
                        setTitle("Problem med servern. Försök igen senare.");
                    }
                }).then(() => {
                    setEditBenefitWindow(false);
                    setEditBenefitWindow(true);
                    handleResetBenefitWindowFields();
                    if (selectCompanyRef.current) {
                        selectCompanyRef.current.clearValue();
                        selectCompanyRef.current.inputValue = "";
                    }
                });
            }
        }
    }

    const handleOnChangeCompany = (selectedOption) => {
        handleResetBenefitWindowFields();
        const indexOfFirst = selectedOption?.label.indexOf("(");

        if (selectedOption) {
            setTheCompanyID(selectedOption.value);
            setTheCompany(selectedOption.label.substring(0, indexOfFirst - 1));
        } else {
            setTheCompanyID("");
            setTheCompany("");
        }
    }

    const handleOnSearch = (e) => {
        const getCompanyOnSearch = async () => {
            if (e.target.value !== "")
                await API.getCompanySearch("", e.target.value, "",1)
                    .then((response) => {
                        if (response.data[0]){
                            if(e.target.value === response.data[0].name){
                                setTheCompanyID(response.data[0]._id);
                                setTheCompany(response.data[0].name);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log("Error: ", error);
                    });
        };
        void getCompanyOnSearch();
    }

    const handleOnChangeBenefitWindow = (selectedOption) => {
        //setFilesChosen(false); Remove for SP-665
        //setAreFilesUploaded(false); Remove for SP-665

        selectedOption ? setBenefitWindowID(selectedOption.value) : setBenefitWindowID([""]);
    }

    const handleBenefitWindowConditions = (e, isDisabled) => {
        e.preventDefault();

        if (!publicPortal)
            setIsDisabledBenefitWindowConditions(isDisabled);
        else
            setIsDisabledBenefitWindowConditions(true);
        setShowModalBenefitWindowConditions(true);
        setTitle("Begränsningar");
    }

    const handleDemo = (e) => {
        e.preventDefault();

        setShowModalDemo(true);
        setTitle("Demoevent");
    }

    const handleSetOptions = (selectedOptions) => {

        let options = [];

        if (selectedOptions.length > 0) {
            selectedOptions.forEach((option) => {
                options.push(option.value);
            });
        }
        return options;
    }

    const handleOnChangeAffectedStores = (selectedOptions) => {
        setTheAffectedStores(handleSetOptions(selectedOptions));
    }

    const handleOnChangeSalesPerson = (selectedOption) => {
        selectedOption ? setTheSalesperson(selectedOption.value) : setTheSalesperson("");
    }

    // TODO if upload_file_required is true add check that an agreement is uploaded
    const handleOnChangeAgreement = (selectedOption) => {
        selectedOption ? setTheAgreement(selectedOption.value) : setTheAgreement("");
        selectedOption ? setCustomerSpecificAgreement(selectedOption.upload_file_required) : setCustomerSpecificAgreement(false);
    }

    const handleOnChangeExternalStores = (selectedOptions) => {
        setTheExternalStores(handleSetOptions(selectedOptions));
    }

    const customStyles = {
        control: (base) => ({
            ...base,
            height: "56px",
            width: "852px",
            marginLeft: "-10px",
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            textAlign: "center"
        }),
        menuList: styles => {
            return {
                ...styles,
                maxHeight: 136
            };
        },
        valueContainer: (provided) => ({
            ...provided,
            whiteSpace: "nowrap",
            overflow: "hidden",
            flexWrap: 'nowrap',
        }),
    };

    const customControlStyles = {
        control: (base) => ({
            ...base,
            height: "160px !important",
            width: "852px",
            marginLeft: "-10px",
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            textAlign: "center"
        }),
        menuList: styles => {
            return {
                ...styles,
                maxHeight: 136
            };
        },
    };

    const loadSalesPerson = (salesPerson) => {

        let tempSalesPersons = [];

        setLoadTheSalesperson([]);
        tempSalesPersons.push({value: salesPerson._id, label: salesPerson.name});
        setTheSalesperson(salesPerson._id);
        setLoadTheSalesperson(tempSalesPersons);
    }

    const loadAgreement = (agreement) => {

        let tempAgreement = [];

        setLoadTheAgreement([]);

        agreementOptions.forEach((aAgreement) => {
            if (aAgreement.value === agreement) {
                tempAgreement.push({value: agreement, label: aAgreement.label});
            }
        });
        setLoadTheAgreement(tempAgreement);
    }

    const loadSportsonShops = (sportsonShops) => {

        let tempSportsonShops = [];
        let theLabel = "";

        setLoadTheSportsonShops([]);

        if (sportsonShops.length > 0) {
            sportsonShops.forEach((sportsonShop) => {
                shopsMapList.forEach((shop) => {
                    if (shop.value === sportsonShop) {
                        theLabel = shop.label;
                        tempSportsonShops.push({value: sportsonShop, label: theLabel});
                    }
                });
            });
        }
        setLoadTheSportsonShops(tempSportsonShops);
    }

    const loadExternalPartners = (partnersExternal) => {

        let tempExternalPartners = [];
        let theLabel = "";

        setLoadTheExternalPartners([]);

        if (partnersExternal.length > 0) {
            partnersExternal.forEach((externalPartner) => {
                externalPartnersMapList.forEach((partner) => {
                    if (partner.value === externalPartner) {
                        theLabel = partner.label;
                        tempExternalPartners.push({value: externalPartner, label: theLabel});
                    }
                });
            });
        }
        setLoadTheExternalPartners(tempExternalPartners);
    }

    useEffect(() => {
        const getGlobalBenefitFactor = async () => {
            await API.getGlobalBenefitFactor()
                .then((response) => {
                    leasingFactor.current = response.data.value;
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getGlobalBenefitFactor();

        const getGetPersonalcyklarContactTagID = async () => {
            await API.getGetPersonalcyklarContactTagId()
                .then((response) => {
                    const getAllPersonalCyklarContacts = async () => {
                        await API.getContact("",response.data._id, "")
                            .then((response) => {
                                let salespersons = [];
                                salespersons.push({ value: "", label: "Välj säljare på Personalcyklar", isDisabled: true });
                                response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((salesperson) => {
                                    salespersons.push({ value: salesperson._id, label: salesperson.name });
                                });
                                setPersonalcyklarContactsOptions(salespersons);
                            })
                            .catch((error) => {
                                console.log("Error: ", error);
                            });
                    };
                    getAllPersonalCyklarContacts();
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getGetPersonalcyklarContactTagID();

        const getAllShopsSportson = async () => {
            await API.getShopList("", "sportson")
                .then((response) => {
                    let shopsSportson = [];
                    shopsSportson.push({ value: "", label: "Välj din butik/dina butiker *", isDisabled: true });
                    response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((shop) => {
                        shopsSportson.push({ value: shop.Code, label: shop.Name });
                    });
                    setShopsSportsonOptions(shopsSportson);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllShopsSportson();

        const getAllShopsExternalPartners = async () => {
            await API.getShopList("", "external")
                .then((response) => {
                    let shopsExternalPartners = [];
                    shopsExternalPartners.push({ value: "", label: "Välj externa partners *", isDisabled: true });
                    response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((shop) => {
                        shopsExternalPartners.push({ value: shop.Code, label: shop.Name });
                    });
                    setShopsExternalPartnersOptions(shopsExternalPartners);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllShopsExternalPartners();

        const getAllCompanies = async () => {
            await API.getCompany("")
                .then((response) => {
                    let companies = [];
                    companies.push({ value: "", label: "Välj bolag *", isDisabled: true });
                    response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((company) => {
                        company.alternate_name ? companies.push({ value: company._id, label: `${company.name} (${company.org_nr}) (${company.alternate_name})` }) : companies.push({ value: company._id, label: `${company.name} (${company.org_nr})` });
                    });
                    setCompaniesOptions(companies);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllCompanies();

        const getAllBenefitWindows = async () => {
            await API.getBenefitWindow("","","", true, "")
                .then((response) => {
                    let benefitWindows = [];
                    benefitWindows.push({ value: "", label: "Välj beställningsfönster *", isDisabled: true });
                    response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((benefitWindow) => {
                        benefitWindows.push({ value: benefitWindow._id, label: benefitWindow.name });
                    });
                    setBenefitWindowsOptions(benefitWindows);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllBenefitWindows();

        const getAllShopsMapList = async () => {
            await API.getShopList("", "")
                .then((response) => {
                    let shops = [];
                    response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((shop) => {
                        shops.push({ value: shop.Code, label: shop.Name });
                    });
                    setShopsMapList(shops);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllShopsMapList();

        const getAllExternalPartnersMapList = async () => {
            await API.getShopList("", "external")
                .then((response) => {
                    let externalPartners = [];
                    response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((externalPartner) => {
                        externalPartners.push({ value: externalPartner.Code, label: externalPartner.Name });
                    });
                    setExternalPartnersMapList(externalPartners);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllExternalPartnersMapList();

        const getAgreements = async () => {
            await API.getAgreement("")
                .then((response) => {
                    let agreements = [];
                    agreements.push({ value: "", label: "Välj avtal", isDisabled: true });
                    response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((agreement) => {
                        agreements.push({ value: agreement._id, label: agreement.name, upload_file_required: agreement.upload_file_required });
                    });
                    setAgreementOptions(agreements);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAgreements();
    }, []);

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];

        forceClearSelect();
        setComments([]);

        if(benefitWindowID[0] !== "") {
            const getBenefitWindow = async () => {
                await API.getBenefitWindow(benefitWindowID, "", "", true, "")
                    .then((response) => {
                        if (response.data.length === 1) {
                            setTheName(response.data[0].name);
                            setTheCompanyID(response.data[0].company._id);
                            if (editBenefitWindow) {
                                setTheStartDate(new Date(response.data[0].startdate.substring(0, 10)) ? new Date(response.data[0].startdate.substring(0, 10)) : "");
                                setTheEndDate(new Date(response.data[0].enddate.substring(0, 10)) ? new Date(response.data[0].enddate.substring(0, 10)) : "");
                                setTheOutgoingInvoicenr(response.data[0].outgoing_invoicenr);
                            } else if (createBenefitWindow) {
                                setTheStartDate(today);
                                setTheEndDate(today);
                            }
                            setBenefitWindowOpen(response.data[0].windowopen);
                            setBenefitWindowClosed(response.data[0].windowclosed);
                            setPublicPortal(false);
                            if (response.data[0].general_portal && editBenefitWindow)
                                setPublicPortal(response.data[0].general_portal);
                            setTheWindowSetupComplete(response.data[0].window_setup_complete);
                            if (response.data[0].invoice_date)
                                setTheInvoiceDate(new Date(response.data[0].invoice_date.substring(0, 10)) ? new Date(response.data[0].invoice_date.substring(0, 10)) : "");
                            if (response.data[0].sales_person) {
                                setTheSalesperson(response.data[0].sales_person._id);
                                loadSalesPerson(response.data[0].sales_person);
                            }
                            if (response.data[0].affected_stores.length > 0) {
                                setTheSportsonShops(response.data[0].affected_stores);
                                setTheAffectedStores(response.data[0].affected_stores);
                                loadSportsonShops(response.data[0].affected_stores);
                            }
                            if (response.data[0].agreement) {
                                setTheAgreement(response.data[0].agreement);
                                loadAgreement(response.data[0].agreement);
                            }
                            if(!response.data[0].general_portal) {
                                if (response.data[0].bank_id_required)
                                    setBankIdRequired(response.data[0].bank_id_required);
                                // if (response.data[0].standard_agreement)
                                // setStandardAgreement(response.data[0].standard_agreement);
                                if (response.data[0].external_stores.length > 0) {
                                    setTheExternalStores(response.data[0].external_stores);
                                    loadExternalPartners(response.data[0].external_stores);
                                }
                                //if (response.data[0].customer_specific_agreement)
                                setCustomerSpecificAgreement(response.data[0].customer_specific_agreement);
                                setTheCalculationFactor(response.data[0].calculation_factor);
                                setTheRulesAndConditions([]);
                                setLoadBenefitWindowRules([]);
                                setTheRulesAndConditions(response.data[0].window_rules);
                                setLoadBenefitWindowRules(response.data[0].window_rules);
                                setTheDemo(response.data[0].demo);
                                setLoadTheDemo(response.data[0].demo);
                                setLoadBenefitWindowRules(response.data[0].window_rules);
                                //if (editBenefitWindow)
                                //setSecurityAgreement(response.data[0].security_agreement);
                            }
                            if (editBenefitWindow) {
                                setBenefitWindowCancelled(response.data[0].cancelled);
                                if(response.data[0].conditions && !response.data[0].general_portal)
                                    setTheConditions(response.data[0].conditions);
                            }
                            setComments(response.data[0].comments);
                            // TODO get if agreement is uploaded flag once that flag is available
                        }
                    })
                    .catch((error) => {
                        console.log("Error: ", error);
                    });
            };
            void getBenefitWindow();
        }
    },[benefitWindowID, showModalAddNewComment, showModalEditComment]);

    useEffect(() => {

        if (editBenefitWindow) {
            setBenefitWindowsOptions([]);
            const getBenefitWindowsForACompany = async () => {
                setBenefitWindowsOptions([]);
                await API.getBenefitWindowIgnoreCancelledForCompany("", "", "", true, theCompanyID, true)
                    .then((response) => {
                        let benefitWindows = [];
                        benefitWindows.push({value: "", label: "Välj beställningsfönster *", isDisabled: true});
                        response.data.sort((a, b) => b.startdate.localeCompare(a.startdate)).forEach((benefitWindow) => {
                            benefitWindows.push({value: benefitWindow._id, label: benefitWindow.name});
                        });
                        setBenefitWindowsOptions(benefitWindows);
                    })
                    .catch((error) => {
                        console.log("Error: ", error);
                    });
            };
            theCompanyID !== "" ? getBenefitWindowsForACompany() : forceClearSelect();
        }

        const getCompany = async () => {
            if(theCompanyID === undefined || theCompanyID === "")
                return;
            await API.getCompany(theCompanyID)
                .then((response) => {
                    if (response.data.length === 1) {
                        setTheCompany(response.data[0].name);
                        setTheSalesperson(response.data[0].personalcyklar_salesperson._id);
                        loadSalesPerson(response.data[0].personalcyklar_salesperson[0]);
                        setTheAlternateName("");
                        setTheAlternateName(response.data[0].alternate_name);
                        //loadAlternateName(theCompanyID, response.data[0].alternate_name);
                        setTheSportsonShops(response.data[0].sportson_shops);
                        setTheAffectedStores(response.data[0].sportson_shops);
                        loadSportsonShops(response.data[0].sportson_shops);
                        //setStandardAgreement(response.data[0].standard_agreement);
                        setPublicPortal(response.data[0].general_portal);
                        if (response.data[0].general_portal && createBenefitWindow) {
                            setPublicPortal(response.data[0].general_portal);
                        }
                        if(!response.data[0].general_portal) {
                            setBankIdRequired(response.data[0].bank_id_required);
                            setTheRulesAndConditions([]);
                            setLoadBenefitWindowRules([]);
                            setTheRulesAndConditions(response.data[0].window_rules);
                            setLoadBenefitWindowRules(response.data[0].window_rules);
                            setTheExternalStores(response.data[0].external_partners);
                            loadExternalPartners(response.data[0].external_partners);
                            //if (createBenefitWindow)
                            //setSecurityAgreement(response.data[0].security_agreement);
                            setTheConditions(response.data[0].conditions);
                        }
                        if (response.data[0].agreement) {
                            setTheAgreement(response.data[0].agreement);
                            loadAgreement(response.data[0].agreement);
                            setCustomerSpecificAgreement(response.data[0].customer_specific_agreement);
                        }
                    }
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getCompany();
        /*
    if (theCompanyID === "") {
        setLoadTheAlternateName([]);
    }
         */

    },[theCompanyID]);

    const handleGetCommentsBenefitWindow = async () => {
        await API.getBenefitWindow(benefitWindowID,"","", true, "")
            .then((response) => {
                if(response.data[0])
                    setComments(response.data[0].comments);
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    const handleCancelBenefitWindow = () => {
        if (benefitWindowID !== "") {
            setCancelled(true);
            setTitleConfirmWithButtonsmModal("Vill du verkligen cancellera beställningsfönstret?");
            setShowConfirmWithButtonsModal(true);
        }
    };

    useEffect(() => {

        if (cancelBenefitWindow){
            if (cancelled) {
                if (benefitWindowID !== "") {
                    API.cancelBenefitWindow(benefitWindowID).then((response) => {
                        if (response.status === 200) {
                            setShowModal(true);
                            setTitle("Beställningsfönstret cancellerat");
                            setBenefitWindowCancelled(true);
                        } else if (response.status === 201) {

                        } else if (response.status >= 300) {
                            setShowModal(true);
                            setTitle(`${response.data.message} - ${response.data.details}`);
                        } else if (response.status === 401) {
                            void dispatch(logout());
                        }
                    });
                }
            }
        }
        setCancelled(false);

    },[cancelBenefitWindow]);

    useEffect(() => {
        setIsDisabledBenefitWindowConditions(publicPortal);
    }, [publicPortal]);

    /*
    useEffect(() => {
        if (theAgreement !== "")
            setCustomerSpecificAgreement(false);
    }, [theAgreement]);
     */

    useEffect(() => {
        if (theCompanyID !== "")
            forceClearSelectBenefitWindow();
    }, [theCompanyID]);

    useEffect(() => {
        if (benefitWindowID && benefitWindowID !== "")
            void handleGetCommentsBenefitWindow();
    }, [showModalAddNewComment, showModalEditComment]);

    useEffect(() => {
        setEditBenefitWindow(true);
        setBenefitWindowID(id ? id : [""]);
    }, [id]);

    return (
        <div className="benefitWindow">
            <Header title="Beställningsfönster" />
            <ModalConfirmAlert
                showModal={showModal}
                closeModalHandler={closeModalHandler}
                title={title}
                buttonName="Stäng"
            />
            <ModalBenefitWindowConditions
                showModal={showModalBenefitWindowConditions}
                closeModalHandler={closeModalBenefitWindowConditionsHandler}
                companyID={theCompanyID}
                companyWindowRules={loadBenefitWindowRules}
                setCompanyWindowRules={setLoadBenefitWindowRules}
                setTheRulesAndConditions={setTheRulesAndConditions}
                title={title}
                buttonName="Stäng"
                isDisabledBenefitWindowConditions={isDisabledBenefitWindowConditions}
            />
            <ModalConfirmWithButtons
                showModal={showConfirmWithButtonsModal}
                closeModalHandler={closeConfirmWithButtonsModalHandler}
                setConfirmHandler={setCancelBenefitWindowHandler}
                title={titleConfirmWithButtonsModal}
                buttonName="Stäng"
            />
            <ModalDemo
                key={theCompanyID}
                showModal={showModalDemo}
                closeModalHandler={closeModalDemoHandler}
                title={title}
                setTheDemo={setTheDemo}
                loadTheDemo={loadTheDemo}
            />
            <ModalAddNewCommentWindow
                showModal={showModalAddNewComment}
                closeModalHandler={closeModalAddNewCommentHandler}
                title={title}
                buttonName="Stäng"
                windowID={benefitWindowID}
            />
            <ModalEditCommentWindow
                showModal={showModalEditComment}
                closeModalHandler={closeModalEditCommentHandler}
                title={title}
                buttonName="Stäng"
                windowID={benefitWindowID}
                commentID={theCommentID}
                commentText={theCommentText}
            />
            <div className="benefitWindow__inputContainer">
                { !createBenefitWindow && !editBenefitWindow && <h1 className="benefitWindow__header"><button className="benefitWindow__switchButton">Ändra Beställningsfönster</button></h1> }
                { editBenefitWindow && <h1 className="benefitWindow__header"><button className="benefitWindow__switchButton">Ändra Beställningsfönster</button></h1> }
                { createBenefitWindow && (
                    <>
                        {/*
                        <h6>Sök publikt namn</h6>
                        {loadTheAlternateName.length === 0 && <Select
                            className="itSupport__select"
                            label="Sök publikt namn"
                            variant="outlined"
                            name="Search alternate name"
                            styles={customStyles}
                            onChange={handleOnChangeAlternateName}
                            options={alternateNamesOptions}
                            isClearable
                            menuPortalTarget={document.body}
                        >
                        </Select>}
                        {loadTheAlternateName.length > 0 && <Select
                            className="itSupport__select"
                            label="Sök publikt namn"
                            variant="outlined"
                            name="Search alternate name"
                            styles={customStyles}
                            onChange={handleOnChangeAlternateName}
                            options={alternateNamesOptions}
                            defaultValue={loadTheAlternateName}
                            isClearable
                            menuPortalTarget={document.body}
                        >
                        </Select>}
                        */}
                        {/*
                        <TextField
                            label="Fönsternamn"
                            variant="outlined"
                            name="name"
                            fullWidth
                            InputProps={{
                                className: "benefitWindow__textFields",
                            }}
                            onChange={(e) => setTheName(e.target.value)}
                            value={theName}
                            required
                        >
                        </TextField>
                        */}
                        <TextField
                            label="Bolagsnamn"
                            variant="outlined"
                            name="Name"
                            fullWidth
                            InputProps={{
                                className: "benefitWindow__textFields",
                            }}
                            //onChange={(e) => setTheCompany(e.target.value)}
                            value={theCompany}
                            required
                            disabled
                        >
                        </TextField>
                        <div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div>
                        <TextField
                            label="Publikt namn (måste vara unikt)"
                            variant="outlined"
                            name="AlternativeName"
                            fullWidth
                            InputProps={{
                                className: "benefitWindow__textFields",
                            }}
                            //onChange={(e) => setTheAlternateName(e.target.value)}
                            value={theAlternateName}
                            disabled
                        >
                        </TextField>
                        <div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div>
                        <TextField
                            label="Subvention"
                            variant="outlined"
                            name="Conditions"
                            fullWidth
                            multiline
                            minRows={2}
                            InputProps={{
                                className: "bolag__textFields",
                            }}
                            value={theConditions}
                            disabled
                        >
                        </TextField>
                        <div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div>
                        {!publicPortal && <TextField
                            label="Hyresfaktor"
                            variant="outlined"
                            name="rentFactor"
                            fullWidth
                            InputProps={{
                                className: "benefitWindow__textFields",
                            }}
                            onChange={(e) => setTheCalculationFactor(e.target.value)}
                            value={theCalculationFactor}
                        >
                        </TextField>}
                        {publicPortal && <> <TextField
                            label="Hyresfaktor"
                            variant="outlined"
                            name="rentFactor"
                            fullWidth
                            InputProps={{
                                className: "benefitWindow__textFields",
                            }}
                            onChange={(e) => setTheCalculationFactor(e.target.value)}
                            value={theCalculationFactor}
                            disabled
                        >
                        </TextField><div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div> </>}
                        <TextField
                            label="Förmånsfaktor"
                            variant="outlined"
                            name="benefitFactor"
                            fullWidth
                            InputProps={{
                                className: "benefitWindow__textFields",
                            }}
                            value={leasingFactor.current}
                            disabled
                        >
                        </TextField>
                        <div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div>
                        <div className="benefitWindow__flexRow">
                            <div>
                                <label className="benefitWindow__date" htmlFor="benefitWindowStartDate">Startdatum *</label>
                                <DatePicker
                                    className="benefitWindow__date benefitWindow__dateInputFields"
                                    dateFormat="yyyy-MM-dd"
                                    selected={theStartDate}
                                    onChange={(date) => setTheStartDate(date)}
                                    id="benefitWindowStartDate"
                                    name="benefitWindowStartDate"
                                    locale={sv}
                                    required
                                />
                            </div>
                            <div>
                                <label className="benefitWindow__date" htmlFor="benefitWindowEndDate">Slutdatum *</label>
                                <DatePicker
                                    className="benefitWindow__date benefitWindow__dateInputFields"
                                    dateFormat="yyyy-MM-dd"
                                    selected={theEndDate}
                                    onChange={(date) => setTheEndDate(date)}
                                    id="benefitWindowEndDate"
                                    name="benefitWindowEndDate"
                                    locale={sv}
                                    required
                                />
                            </div>
                        </div>
                        <h6>Säljare Personalcyklar *</h6>
                        {loadTheSalesperson.length > 0 && <Select
                            className="itSupport__select"
                            name="salesperson"
                            options={personalcyklarcContactsOptions}
                            styles={customStyles}
                            onChange={handleOnChangeSalesPerson}
                            required
                            defaultValue={loadTheSalesperson}
                            menuPortalTarget={document.body}
                            ref={selectSalespersonRef}
                        >
                        </Select>}
                        {loadTheSalesperson.length === 0 && <Select
                            className="itSupport__select"
                            name="salesperson"
                            options={personalcyklarcContactsOptions}
                            styles={customStyles}
                            onChange={handleOnChangeSalesPerson}
                            required
                            menuPortalTarget={document.body}
                            ref={selectSalespersonRef}
                        >
                        </Select>}
                        <h6>Berörda Sportsonbutiker (företaget finns på följande orter)</h6>
                        {loadTheSportsonShops.length > 0 && <Select
                            className="itSupport__select benefitWindow__theShops"
                            name="theAffectedStores"
                            onChange={handleOnChangeAffectedStores}
                            isMulti
                            isClearable
                            options={shopsSportsonOptions}
                            styles={customControlStyles}
                            defaultValue={loadTheSportsonShops}
                            ref={selectShopsRef}
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                        >
                        </Select>}
                        {loadTheSportsonShops.length === 0 && <Select
                            className="itSupport__select benefitWindow__theShops"
                            name="theAffectedStores"
                            onChange={handleOnChangeAffectedStores}
                            isMulti
                            isClearable
                            options={shopsSportsonOptions}
                            styles={customControlStyles}
                            ref={selectShopsRef}
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                        >
                        </Select>}
                        <h6>Externa partners</h6>
                        { loadTheExternalPartners.length > 0 && <Select
                            className="itSupport__select benefitWindow__theExternalPartners"
                            name="theExternalPartners"
                            onChange={handleOnChangeExternalStores}
                            isMulti
                            isClearable
                            options={shopsExternalPartnersOptions}
                            styles={customControlStyles}
                            defaultValue={loadTheExternalPartners}
                            ref={selectExternalPartnersRef}
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isDisabled={publicPortal}
                        >
                        </Select> }
                        { loadTheExternalPartners.length === 0 && <Select
                            className="itSupport__select benefitWindow__theExternalPartners"
                            name="theExternalPartners"
                            onChange={handleOnChangeExternalStores}
                            isMulti
                            isClearable
                            options={shopsExternalPartnersOptions}
                            styles={customControlStyles}
                            ref={selectExternalPartnersRef}
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isDisabled={publicPortal}
                        >
                        </Select> }
                        {publicPortal && <LockIcon className="benefitWindow__disabledField"/>}
                        <div className="benefitWindow__flexCenter">
                            <div>
                                <input onClick={() => setPublicPortal(!publicPortal)} type="checkbox" id="thePublicPortal" name="thePublicPortal" checked={publicPortal} disabled/>
                                <p className="benefitWindow__checkboxText benefitWindow__warningText" >Gemensamma portalen<Tooltip title={"Gemensamma portalen får endast användas för bolag med max 1-2 anställda"} arrow><WarningIcon className="benefitWindow__commentIcon"/></Tooltip></p>
                                <LockIcon className="benefitWindow__disabledFieldTooltip"/>
                            </div>
                            <div>
                                <input onClick={() => setBankIdRequired(!bankIdRequired)} type="checkbox" id="bankID" name="bankID" checked={bankIdRequired} disabled={publicPortal}/>
                                <p className="benefitWindow__checkboxText" >Förmånstagaren signerar med BankID</p>
                                {publicPortal && <LockIcon className="benefitWindow__disabledFieldCheckboxWithoutTooltip" />}
                            </div>
                            {/*<div>
                                <input onClick={() => setStandardAgreement(!standardAgreement)} type="checkbox" id="standardAgreement" name="standardAgreement" checked={standardAgreement} />
                                <p className="benefitWindow__checkboxText" >Standard UH</p>
                            </div>*/}
                            {/*<div>
                                <input onClick={() => setSecurityAgreement(!securityAgreement)} type="checkbox" id="standardAgreement" name="standardAgreement" checked={securityAgreement} />
                                <p className="benefitWindow__checkboxText" >Trygghetspaket</p>
                            </div>*/}
                        </div>
                        <h6>UH-avtal *</h6>
                        {loadTheAgreement.length > 0 && <> <Select
                            className="itSupport__select"
                            name="agreement"
                            options={agreementOptions}
                            styles={customStyles}
                            onChange={handleOnChangeAgreement}
                            required
                            menuPortalTarget={document.body}
                            ref={selectAgreementRef}
                            isClearable
                            defaultValue={publicPortal ? [(agreementOptions.filter(agreement => agreement.label === "AvtalTrygg - Standardavtalet med Trygghetspaketet"))[0]] : loadTheAgreement}
                            //isDisabled={publicPortal} removed for SP-665
                            isDisabled // added for SP-665

                        >
                        </Select> {publicPortal && <LockIcon className="benefitWindow__disabledField"/>} </>}
                        {loadTheAgreement.length === 0 && <> <Select
                            className="itSupport__select"
                            name="agreement"
                            options={agreementOptions}
                            styles={customStyles}
                            onChange={handleOnChangeAgreement}
                            required
                            menuPortalTarget={document.body}
                            ref={selectAgreementRef}
                            isClearable
                            defaultValue={publicPortal ? [(agreementOptions.filter(agreement => agreement.label === "AvtalTrygg - Standardavtalet med Trygghetspaketet"))[0]] : []}
                            //isDisabled={publicPortal} removed for SP-665
                            isDisabled // added for SP-665
                        >
                        </Select> {publicPortal && <LockIcon className="benefitWindow__disabledField"/>} </>}
                        {/*<div>
                            <input onClick={() => setCustomerSpecificAgreement(!customerSpecificAgreement)} type="checkbox" id="customerSpecificAgreement" name="customerSpecificAgreement" checked={customerSpecificAgreement} />
                            <p className="benefitWindow__checkboxText" >Kundspecifikt UH</p>
                        </div>*/}
                        <div className="benefitWindow__flexCenter">
                            {/* Removed for SP-665 customerSpecificAgreement && <div className="benefitWindow__fileUploadDiv">
                            <legend className="benefitWindow__fileUploadDivLegend">Ladda upp kundspecifikt UH-avtal</legend>
                            <div className="benefitWindow__fileUploadButtonsDiv">
                                <input className="benefitWindow__fileInputButton" type="file" multiple onChange={handleFileChange} />
                                {filesChosen && <button className="benefitWindow__fileUploadButtons" onClick={handleRemoveFiles}>Ta bort avtal</button>}
                            </div>
                            {filesChosen && !areFilesUploaded && (
                                <div className="benefitWindow__centerText">
                                    <>
                                        <h6>Följande avtal är valda för uppladdning:</h6>
                                        {selectedFiles.map((file, index) => (
                                            <p key={index}>{file.name}</p>
                                        ))}
                                        <h6>Ta bort valda avtal för uppladdning med </h6>
                                        <h6>knappen "Ta bort avtal".</h6>
                                    </>
                                </div>
                            )}
                            {!filesChosen && !areFilesUploaded && (
                                <>
                                    <p>Välj avtal på knappen till vänster.</p>
                                </>
                            )}
                        </div> */}
                        </div>
                        <div className="benefitWindow__flexCenter">
                            <button className="bolag__conditionsButton" onClick={(e) => handleBenefitWindowConditions(e, false)} disabled={publicPortal}>Begränsningar {theRulesAndConditions.length > 0 || loadBenefitWindowRules.length > 0 ? <DoneIcon className="benefitWindow__iconInButton" /> : ""}</button>
                            {publicPortal && <div className="benefitWindow__disabledButton"><LockIcon className="benefitWindow__lockIcon"/></div>}
                            <button className="benefitWindow__demoButton" onClick={(e) => handleDemo(e)} disabled={publicPortal}>Demoevent</button>
                            {publicPortal && <div className="benefitWindow__disabledButton"><LockIcon className="benefitWindow__lockIcon"/></div>}
                        </div>
                        <div>
                            <button className="benefitWindow__createAndCopyButtons" onClick={(e) => handleCreateBenefitWindow(e)}>Skapa fönstret</button>
                            {/* <button className="benefitWindow__createAndCopyButtons" onClick={(e) => handleInsertPreviousCopiedBenefitWindow(e)}>Kopiera in senaste fönstret</button> */}
                        </div>
                    </>
                )}
                {editBenefitWindow && <>
                    {benefitWindowID[0] && benefitWindowCancelled && <div className="benefitWindow__cancelledBenefitWindowBanner"><h3>Fönstret är cancellerat</h3></div>}
                    {/*
                    <h6>Sök publikt namn</h6>
                    {loadTheAlternateName.length === 0 && <Select
                        className="itSupport__select"
                        label="Sök publikt namn"
                        variant="outlined"
                        name="Search alternate name"
                        styles={customStyles}
                        onChange={handleOnChangeAlternateName}
                        options={alternateNamesOptions}
                        isClearable
                        menuPortalTarget={document.body}
                    >
                    </Select>}
                    {loadTheAlternateName.length > 0 && <Select
                        className="itSupport__select"
                        label="Sök publikt namn"
                        variant="outlined"
                        name="Search alternate name"
                        styles={customStyles}
                        onChange={handleOnChangeAlternateName}
                        options={alternateNamesOptions}
                        defaultValue={loadTheAlternateName}
                        isClearable
                        menuPortalTarget={document.body}
                    >
                    </Select>}
                    */}
                    {/*
                        <TextField
                            label="Fönsternamn"
                            variant="outlined"
                            name="name"
                            fullWidth
                            InputProps={{
                                className: "benefitWindow__textFields",
                            }}
                            onChange={(e) => setTheName(e.target.value)}
                            value={theName}
                            required
                        >
                        </TextField>
                        */}
                </> }
                { editBenefitWindow && benefitWindowClosed && benefitWindowID.length > 0 && (
                    <>
                        <TextField
                            label="Bolagsnamn"
                            variant="outlined"
                            name="Name"
                            fullWidth
                            InputProps={{
                                className: "benefitWindow__textFields",
                            }}
                            //onChange={(e) => setTheCompany(e.target.value)}
                            value={theCompany}
                            required
                            disabled
                        >
                        </TextField>
                        <div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div>
                        <TextField
                            label="Publikt namn (måste vara unikt)"
                            variant="outlined"
                            name="AlternativeName"
                            fullWidth
                            InputProps={{
                                className: "bolag__textFields",
                            }}
                            //onChange={(e) => setTheAlternateName(e.target.value)}
                            value={theAlternateName}
                            disabled
                        >
                        </TextField>
                        <div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div>
                        <TextField
                            label="Subvention"
                            variant="outlined"
                            name="Conditions"
                            fullWidth
                            multiline
                            minRows={2}
                            InputProps={{
                                className: "bolag__textFields",
                            }}
                            value={theConditions}
                            disabled
                        >
                        </TextField>
                        <div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div>
                        <TextField
                            label="Hyresfaktor"
                            variant="outlined"
                            name="rentFactor"
                            fullWidth
                            InputProps={{
                                className: "benefitWindow__textFields",
                            }}
                            onChange={(e) => setTheCalculationFactor(e.target.value)}
                            value={theCalculationFactor}
                            disabled
                        >
                        </TextField>
                        <div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div>
                        <TextField
                            label="Förmånsfaktor"
                            variant="outlined"
                            name="benefitFactor"
                            fullWidth
                            InputProps={{
                                className: "benefitWindow__textFields",
                            }}
                            value={leasingFactor.current}
                            disabled
                        >
                        </TextField>
                        <div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div>
                        <div className="benefitWindow__flexRow">
                            <div>
                                <label className="benefitWindow__date" htmlFor="benefitWindowStartDate">Startdatum
                                    *</label>
                                <DatePicker
                                    className="benefitWindow__date benefitWindow__dateInputFields"
                                    dateFormat="yyyy-MM-dd"
                                    selected={theStartDate}
                                    onChange={(date) => setTheStartDate(date)}
                                    id="benefitWindowStartDate"
                                    name="benefitWindowStartDate"
                                    locale={sv}
                                    required
                                />
                            </div>
                            <div>
                                <label className="benefitWindow__date" htmlFor="benefitWindowEndDate">Slutdatum
                                    *</label>
                                <DatePicker
                                    className="benefitWindow__date benefitWindow__dateInputFields"
                                    dateFormat="yyyy-MM-dd"
                                    selected={theEndDate}
                                    onChange={(date) => setTheEndDate(date)}
                                    id="benefitWindowStartDate"
                                    name="benefitWindowStartDate"
                                    locale={sv}
                                    required
                                />
                            </div>
                        </div>
                        <h6>Säljare Personalcyklar *</h6>
                        {loadTheSalesperson.length > 0 && <> <Select
                            className="itSupport__select"
                            name="salesperson"
                            options={personalcyklarcContactsOptions}
                            styles={customStyles}
                            onChange={handleOnChangeSalesPerson}
                            required
                            defaultValue={loadTheSalesperson}
                            isDisabled
                            menuPortalTarget={document.body}
                            ref={selectSalespersonRef}
                        >
                        </Select> <div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div> </>}
                        {loadTheSalesperson.length === 0 && <> <Select
                            className="itSupport__select"
                            name="salesperson"
                            options={personalcyklarcContactsOptions}
                            styles={customStyles}
                            onChange={handleOnChangeSalesPerson}
                            required
                            isDisabled
                            menuPortalTarget={document.body}
                            ref={selectSalespersonRef}
                        >
                        </Select> <div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div> </>}
                        <h6>Berörda Sportsonbutiker (företaget finns på följande orter)</h6>
                        {loadTheSportsonShops.length > 0 && <> <Select
                            className="itSupport__select benefitWindow__theShops"
                            name="theAffectedStores"
                            onChange={handleOnChangeAffectedStores}
                            isMulti
                            isClearable
                            options={shopsSportsonOptions}
                            styles={customControlStyles}
                            defaultValue={loadTheSportsonShops}
                            isDisabled
                            ref={selectShopsRef}
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                        >
                        </Select> <div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div></>}
                        {loadTheSportsonShops.length === 0 && <> <Select
                            className="itSupport__select benefitWindow__theShops"
                            name="theAffectedStores"
                            onChange={handleOnChangeAffectedStores}
                            isMulti
                            isClearable
                            options={shopsSportsonOptions}
                            styles={customControlStyles}
                            isDisabled
                            ref={selectShopsRef}
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                        >
                        </Select> <div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div></>}
                        <h6>Externa partners</h6>
                        {loadTheExternalPartners.length > 0 && <> <Select
                            className="itSupport__select benefitWindow__theExternalPartners"
                            name="theExternalPartners"
                            onChange={handleOnChangeExternalStores}
                            isMulti
                            isClearable
                            options={shopsExternalPartnersOptions}
                            styles={customControlStyles}
                            defaultValue={loadTheExternalPartners}
                            ref={selectExternalPartnersRef}
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isDisabled
                        >
                        </Select> <LockIcon className="benefitWindow__disabledField"/> </>}
                        {loadTheExternalPartners.length === 0 && <> <Select
                            className="itSupport__select benefitWindow__theExternalPartners"
                            name="theExternalPartners"
                            onChange={handleOnChangeExternalStores}
                            isMulti
                            isClearable
                            options={shopsExternalPartnersOptions}
                            styles={customControlStyles}
                            ref={selectExternalPartnersRef}
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isDisabled
                        >
                        </Select> <LockIcon className="benefitWindow__disabledField"/> </>}
                        <div className="benefitWindow__flexCenter">
                            <div>
                                <input onClick={() => setTheWindowSetupComplete(!theWindowSetupComplete)} type="checkbox" id="theWindowSetupComplete" name="theWindowSetupComplete" checked={theWindowSetupComplete} disabled/>
                                <p className="benefitWindow__checkboxText">Portal uppdaterad</p>
                                <LockIcon className="benefitWindow__disabledFieldCheckboxWithoutTooltip" />
                            </div>
                            <div>
                                <input onClick={() => setPublicPortal(!publicPortal)} type="checkbox" id="thePublicPortal" name="thePublicPortal" checked={publicPortal} disabled/>
                                <p className="benefitWindow__checkboxText benefitWindow__warningText">Gemensamma portalen<Tooltip title={"Gemensamma portalen får endast användas för bolag med max 1-2 anställda"} arrow><WarningIcon className="bolag__commentIcon"/></Tooltip></p>
                                <LockIcon className="benefitWindow__disabledFieldTooltip" />
                            </div>
                            <div>
                                <input onClick={() => setBankIdRequired(!bankIdRequired)} type="checkbox" id="bankID" name="bankID" checked={bankIdRequired} disabled/>
                                <p className="benefitWindow__checkboxText">Förmånstagaren signerar med BankID</p>
                                <LockIcon className="benefitWindow__disabledFieldTooltip" />
                            </div>
                            {/*<div>
                                <input onClick={() => setStandardAgreement(!standardAgreement)} type="checkbox" id="standardAgreement" name="standardAgreement" checked={standardAgreement} disabled/>
                                <p className="benefitWindow__checkboxText" >Standard UH</p>
                            </div>*/}
                            {/*<div>
                                <input onClick={() => setSecurityAgreement(!securityAgreement)} type="checkbox" id="standardAgreement" name="standardAgreement" checked={securityAgreement} disabled/>
                                <p className="benefitWindow__checkboxText" >Trygghetspaket</p>
                            </div>*/}
                        </div>
                        <h6>UH-avtal *</h6>
                        {loadTheAgreement.length > 0 && <> <Select
                            className="itSupport__select"
                            name="agreement"
                            options={agreementOptions}
                            styles={customStyles}
                            onChange={handleOnChangeAgreement}
                            required
                            menuPortalTarget={document.body}
                            ref={selectAgreementRef}
                            isClearable
                            defaultValue={publicPortal ? [(agreementOptions.filter(agreement => agreement.label === "AvtalTrygg - Standardavtalet med Trygghetspaketet"))[0]] : loadTheAgreement}
                            isDisabled
                        >
                        </Select> <LockIcon className="benefitWindow__disabledField"/> </>}
                        {loadTheAgreement.length === 0 && <> <Select
                            className="itSupport__select"
                            name="agreement"
                            options={agreementOptions}
                            styles={customStyles}
                            onChange={handleOnChangeAgreement}
                            required
                            menuPortalTarget={document.body}
                            ref={selectAgreementRef}
                            isClearable
                            defaultValue={publicPortal ? [(agreementOptions.filter(agreement => agreement.label === "AvtalTrygg - Standardavtalet med Trygghetspaketet"))[0]] : []}
                            isDisabled
                        >
                        </Select> <LockIcon className="benefitWindow__disabledField"/> </>}
                        {/*<div className="benefitWindow__securityAgreementDiv">
                            <div>
                                <input onClick={() => setCustomerSpecificAgreement(!customerSpecificAgreement)} type="checkbox" id="customerSpecificAgreement" name="customerSpecificAgreement" checked={customerSpecificAgreement} />
                                <p className="benefitWindow__checkboxText" >Kundspecifikt UH-avtal</p>
                            </div>*/}
                        {/* Remove for SP-665  customerSpecificAgreement && <div className="benefitWindow__fileUploadDiv">
                                <legend className="benefitWindow__fileUploadDivLegend">Ladda upp kundspecifikt
                                    UH-avtal
                                </legend>
                                <div className="benefitWindow__fileUploadButtonsDiv">
                                    <input className="benefitWindow__fileInputButton" type="file" multiple
                                           onChange={handleFileChange}/>
                                    <button className="benefitWindow__fileUploadButtons"
                                            onClick={(e) => handleUploadFiles(e)}>Ladda upp avtal
                                    </button>
                                    {filesChosen && <button className="benefitWindow__fileUploadButtons"
                                                            onClick={handleRemoveFiles}>Ta bort avtal</button>}
                                </div>
                                {filesChosen && !areFilesUploaded && (
                                    <div className="benefitWindow__centerText">
                                        <>
                                            <h6>Följande avtal är valda:</h6>
                                            {selectedFiles.map((file, index) => (
                                                <p key={index}>{file.name}</p>
                                            ))}
                                            <h6>Ladda upp avtal med </h6>
                                            <h6>knappen "Ladda upp avtal".</h6>
                                            <h6>Alternativt ta bort avtal med </h6>
                                            <h6>knappen "Ta bort avtal".</h6>
                                        </>
                                    </div>
                                )}
                                {areFilesUploaded && (
                                    <div className="benefitWindow__centerText">
                                        <>
                                            <p>Följande avtal har laddats upp:</p>
                                            {uploadedFiles.map((file, index) => (
                                                <p key={index}>{file.name}</p>
                                            ))}
                                        </>
                                    </div>
                                )}
                                {!filesChosen && !areFilesUploaded && (
                                    <>
                                        <p>Välj avtal på knappen till vänster</p>
                                        <p>och ladda upp avtal med knappen "Ladda upp avtal".</p>
                                    </>
                                )}
                            </div>
                        </div>*/}
                        <div className="benefitWindow__flexCenter">
                            <button className="bolag__conditionsButton" onClick={(e) => handleBenefitWindowConditions(e, true)} disabled={publicPortal}>Begränsningar {theRulesAndConditions.length > 0 || loadBenefitWindowRules.length > 0 ? <DoneIcon className="benefitWindow__iconInButton" /> : ""}</button>
                            {publicPortal && <div className="benefitWindow__disabledButton"><LockIcon className="benefitWindow__lockIcon"/></div>}
                            <button className="benefitWindow__demoButton" onClick={(e) => handleDemo(e, true)} disabled={publicPortal}>Demoevent</button>
                            {publicPortal && <div className="benefitWindow__disabledButton"><LockIcon className="benefitWindow__lockIcon"/></div>}
                        </div>
                        <div className="benefitWindow__invoiceDiv">
                            <label>Fylls i av Bike Action</label>
                            <div className="benefitWindow__flexRow">
                                <div className="benefitWindow__invoiceDateDiv">
                                    <label htmlFor="agreementStartDate">Fakturadatum</label>
                                    <DatePicker
                                        className="benefitWindow__date benefitWindow__dateInputFields"
                                        dateFormat="yyyy-MM-dd"
                                        selected={theInvoiceDate}
                                        onChange={(date) => setTheInvoiceDate(date)}
                                        id="agreementStartDate"
                                        name="agreementStartDate"
                                        locale={sv}
                                    />
                                </div>
                                <div className="benefitWindow__invoiceNumberDiv">
                                    <label>Fakturanummer</label>
                                    <input type="text" onChange={(e) => setTheOutgoingInvoicenr(e.target.value)}
                                           value={theOutgoingInvoicenr}/>
                                </div>
                            </div>
                        </div>
                        <div className="benefitWindow__sportsonComments">
                            <h5>Kommentarer <ChatBubbleIcon className="benefitWindow__commentIcon"/>
                            </h5>{expandedComments ? (
                            <button className="benefitWindow__expandButton" onClick={() => setExpandedComments(false)}>
                                <ExpandLessIcon/></button>) : (
                            <button className="benefitWindow__expandButton" onClick={() => setExpandedComments(true)}>
                                <ExpandMoreIcon/></button>)}
                        </div>
                        {expandedComments && <div className="benefitWindow__comments">
                            {comments && comments.sort((a, b) => {
                                return new Date(a.DateCreated) - new Date(b.DateCreated)
                            }).map((comment, index) => (
                                (!comment.Deleted && <div key={index} className="benefitWindow__sportsonComment">
                                    <CommentCompanyOrWindow comment={comment}/>
                                    <button className="benefitWindow__commentButton"
                                            onClick={(e) => editCommentHandler(e, benefitWindowID, comment._id, comment.CommentText)}>
                                        <EditIcon/></button>
                                    <button className="benefitWindow__commentButton"
                                            onClick={(e) => deleteCommentHandler(e, benefitWindowID, comment._id)}>
                                        <DeleteIcon/></button>
                                </div>)
                            ))}
                            <button onClick={(e) => addNewCommentHandler(e, benefitWindowID[0])}
                                    className="benefitWindow__editButton"><span
                                className="benefitWindow__iconAndText"><AddCommentIcon
                                className="benefitWindow__icons"/> Lägg till kommentar</span></button>
                        </div>}
                        { benefitWindowID[0] && !benefitWindowCancelled && <button className="benefitWindow__cancelButton" onClick={() => handleCancelBenefitWindow()} type="checkbox" id="cancelled" name="cancelled">Cancellera fönster</button> }
                        <button className="benefitWindow__confirmButton" onClick={(e) => handleUpdateBenefitWindow(e)}>Spara</button>
                    </>
                )}
                {editBenefitWindow && !benefitWindowClosed && (
                    <>
                        <TextField
                            label="Bolagsnamn"
                            variant="outlined"
                            name="Name"
                            fullWidth
                            InputProps={{
                                className: "benefitWindow__textFields",
                            }}
                            //onChange={(e) => setTheCompany(e.target.value)}
                            value={theCompany}
                            required
                            disabled
                        >
                        </TextField>
                        <Tooltip title={"Detta värde kan inte ändras på grund av att fönstret har öppnat"} arrow><div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div></Tooltip>
                        <TextField
                            label="Publikt namn (måste vara unikt)"
                            variant="outlined"
                            name="AlternativeName"
                            fullWidth
                            InputProps={{
                                className: "bolag__textFields",
                            }}
                            //onChange={(e) => setTheAlternateName(e.target.value)}
                            value={theAlternateName}
                            disabled
                        >
                        </TextField>
                        <Tooltip title={"Detta värde kan inte ändras på grund av att fönstret har öppnat"} arrow><div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div></Tooltip>
                        <TextField
                            label="Subvention"
                            variant="outlined"
                            name="Conditions"
                            fullWidth
                            multiline
                            minRows={2}
                            InputProps={{
                                className: "bolag__textFields",
                            }}
                            value={theConditions}
                            disabled
                        >
                        </TextField>
                        <Tooltip title={"Detta värde kan inte ändras på grund av att fönstret har öppnat"} arrow><div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div></Tooltip>
                        {(benefitWindowOpen || publicPortal) && <> <TextField
                            label="Hyresfaktor"
                            variant="outlined"
                            name="rentFactor"
                            fullWidth
                            InputProps={{
                                className: "benefitWindow__textFields",
                            }}
                            onChange={(e) => setTheCalculationFactor(e.target.value)}
                            value={theCalculationFactor}
                            disabled
                        >
                        </TextField> <Tooltip title={"Detta värde kan inte ändras på grund av att fönstret har öppnat"} arrow><div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div></Tooltip></>}
                        {(!benefitWindowOpen && !publicPortal) && <TextField
                            label="Hyresfaktor"
                            variant="outlined"
                            name="rentFactor"
                            fullWidth
                            InputProps={{
                                className: "benefitWindow__textFields",
                            }}
                            onChange={(e) => setTheCalculationFactor(e.target.value)}
                            value={theCalculationFactor}
                        >
                        </TextField>}
                        <TextField
                            label="Förmånsfaktor"
                            variant="outlined"
                            name="benefitFactor"
                            fullWidth
                            InputProps={{
                                className: "benefitWindow__textFields",
                            }}
                            value={leasingFactor.current}
                            disabled
                        >
                        </TextField>
                        <Tooltip title={"Detta värde kan inte ändras på grund av att fönstret har öppnat"} arrow><div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div></Tooltip>
                        <div className="benefitWindow__flexRow">
                            <div>
                                <label className="benefitWindow__date" htmlFor="benefitWindowStartDate">Startdatum
                                    * {benefitWindowOpen && <Tooltip title={"Detta värde kan inte ändras på grund av att fönstret har öppnat"} arrow><LockIcon className="benefitWindow__disabledText" /></Tooltip>}</label>
                                <DatePicker
                                    className="benefitWindow__date benefitWindow__dateInputFields"
                                    dateFormat="yyyy-MM-dd"
                                    selected={theStartDate}
                                    onChange={(date) => setTheStartDate(date)}
                                    id="benefitWindowStartDate"
                                    name="benefitWindowStartDate"
                                    locale={sv}
                                    required
                                    readOnly={benefitWindowOpen}
                                />
                            </div>
                            <div>
                                <label className="benefitWindow__date" htmlFor="benefitWindowEndDate">Slutdatum
                                    *</label>
                                <DatePicker
                                    className="benefitWindow__date benefitWindow__dateInputFields"
                                    dateFormat="yyyy-MM-dd"
                                    selected={theEndDate}
                                    onChange={(date) => setTheEndDate(date)}
                                    id="benefitWindowEndDate"
                                    name="benefitWindowEndDate"
                                    locale={sv}
                                    required
                                />
                            </div>
                        </div>
                        <h6>Säljare Personalcyklar *</h6>
                        {loadTheSalesperson.length > 0 && <Select
                            className="itSupport__select"
                            name="salesperson"
                            options={personalcyklarcContactsOptions}
                            styles={customStyles}
                            onChange={handleOnChangeSalesPerson}
                            required
                            defaultValue={loadTheSalesperson}
                            menuPortalTarget={document.body}
                            ref={selectSalespersonRef}
                            isDisabled={benefitWindowOpen}
                        >
                        </Select>}
                        {loadTheSalesperson.length === 0 && <Select
                            className="itSupport__select"
                            name="salesperson"
                            options={personalcyklarcContactsOptions}
                            styles={customStyles}
                            onChange={handleOnChangeSalesPerson}
                            required
                            menuPortalTarget={document.body}
                            ref={selectSalespersonRef}
                            isDisabled={benefitWindowOpen}
                        >
                        </Select>}
                        {benefitWindowOpen && <Tooltip title={"Detta värde kan inte ändras på grund av att fönstret har öppnat"} arrow><div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div></Tooltip>}
                        <h6>Berörda Sportsonbutiker (företaget finns på följande orter)</h6>
                        {loadTheSportsonShops.length > 0 && <Select
                            className="itSupport__select benefitWindow__theShops"
                            name="theAffectedStores"
                            onChange={handleOnChangeAffectedStores}
                            isMulti
                            isClearable
                            options={shopsSportsonOptions}
                            styles={customControlStyles}
                            defaultValue={loadTheSportsonShops}
                            ref={selectShopsRef}
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isDisabled={benefitWindowOpen}
                        >
                        </Select>}
                        {loadTheSportsonShops.length === 0 && <Select
                            className="itSupport__select benefitWindow__theShops"
                            name="theAffectedStores"
                            onChange={handleOnChangeAffectedStores}
                            isMulti
                            isClearable
                            options={shopsSportsonOptions}
                            styles={customControlStyles}
                            ref={selectShopsRef}
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isDisabled={benefitWindowOpen}
                        >
                        </Select>}
                        {benefitWindowOpen && <Tooltip title={"Detta värde kan inte ändras på grund av att fönstret har öppnat"} arrow><div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div></Tooltip>}
                        <h6>Externa partners</h6>
                        {loadTheExternalPartners.length > 0 && <Select
                            className="itSupport__select benefitWindow__theExternalPartners"
                            name="theExternalPartners"
                            onChange={handleOnChangeExternalStores}
                            isMulti
                            isClearable
                            options={shopsExternalPartnersOptions}
                            styles={customControlStyles}
                            defaultValue={loadTheExternalPartners}
                            ref={selectExternalPartnersRef}
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isDisabled={publicPortal || benefitWindowOpen}
                        >
                        </Select>}
                        {loadTheExternalPartners.length === 0 && <Select
                            className="itSupport__select benefitWindow__theExternalPartners"
                            name="theExternalPartners"
                            onChange={handleOnChangeExternalStores}
                            isMulti
                            isClearable
                            options={shopsExternalPartnersOptions}
                            styles={customControlStyles}
                            ref={selectExternalPartnersRef}
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isDisabled={publicPortal || benefitWindowOpen}
                        >
                        </Select>}
                        {benefitWindowOpen && !publicPortal && <Tooltip title={"Detta värde kan inte ändras på grund av att fönstret har öppnat"} arrow><div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div></Tooltip>}
                        {publicPortal && <div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div>}
                        <div className="benefitWindow__flexCenter">
                            <div>
                                <input onClick={() => setTheWindowSetupComplete(!theWindowSetupComplete)}
                                       type="checkbox" id="theWindowSetupComplete" name="theWindowSetupComplete"
                                       checked={theWindowSetupComplete} disabled/>
                                <p className="benefitWindow__checkboxText">Portal uppdaterad</p>
                                <LockIcon className="benefitWindow__disabledFieldCheckboxWithoutTooltip" />
                            </div>
                            <div>
                                <input onClick={() => setPublicPortal(!publicPortal)} type="checkbox"
                                       id="thePublicPortal" name="thePublicPortal" checked={publicPortal}
                                       disabled/>
                                <p className="benefitWindow__checkboxText benefitWindow__warningText">Gemensamma
                                    portalen<Tooltip
                                        title={"Gemensamma portalen får endast användas för bolag med max 1-2 anställda"}
                                        arrow><WarningIcon className="bolag__commentIcon"/></Tooltip></p>
                                {benefitWindowOpen && !publicPortal && <Tooltip title={"Detta värde kan inte ändras på grund av att fönstret har öppnat"} arrow><LockIcon className="benefitWindow__disabledFieldTooltip" /></Tooltip>}
                                {!benefitWindowOpen && <LockIcon className="benefitWindow__disabledFieldTooltip" />}
                            </div>
                            <div>
                                <input disabled={benefitWindowOpen} onClick={() => setBankIdRequired(!bankIdRequired)} type="checkbox" id="bankID"
                                       name="bankID" checked={bankIdRequired} disabled={benefitWindowOpen || publicPortal}/>
                                <p className="benefitWindow__checkboxText">Förmånstagaren signerar med BankID</p>
                                {benefitWindowOpen && !publicPortal && <Tooltip title={"Detta värde kan inte ändras på grund av att fönstret har öppnat"} arrow><LockIcon className="benefitWindow__disabledFieldCheckboxWithoutTooltip" /></Tooltip>}
                                {publicPortal && <LockIcon className="benefitWindow__disabledFieldCheckboxWithoutTooltip" />}
                            </div>
                            {/*<div>
                                <input onClick={() => setStandardAgreement(!standardAgreement)} type="checkbox" id="standardAgreement" name="standardAgreement" checked={standardAgreement} />
                                <p className="benefitWindow__checkboxText" >Standard UH</p>
                            </div>*/}
                            {/*<div>
                                <input onClick={() => setSecurityAgreement(!securityAgreement)} type="checkbox" id="standardAgreement" name="standardAgreement" checked={securityAgreement} />
                                <p className="benefitWindow__checkboxText" >Trygghetspaket</p>
                            </div>*/}
                        </div>
                        <h6>UH-avtal *</h6>
                        {loadTheAgreement.length > 0 && <> <Select
                            className="itSupport__select"
                            name="agreement"
                            options={agreementOptions}
                            styles={customStyles}
                            onChange={handleOnChangeAgreement}
                            required
                            menuPortalTarget={document.body}
                            ref={selectAgreementRef}
                            isClearable
                            //isDisabled={benefitWindowOpen || publicPortal} Remove for SP-665
                            defaultValue={publicPortal ? [(agreementOptions.filter(agreement => agreement.label === "AvtalTrygg - Standardavtalet med Trygghetspaketet"))[0]] : loadTheAgreement}
                            isDisabled
                        >
                        </Select> {benefitWindowOpen || publicPortal && <LockIcon className="benefitWindow__disabledField"/>} </>}
                        {loadTheAgreement.length === 0 && <> <Select
                            className="itSupport__select"
                            name="agreement"
                            options={agreementOptions}
                            styles={customStyles}
                            onChange={handleOnChangeAgreement}
                            required
                            menuPortalTarget={document.body}
                            ref={selectAgreementRef}
                            isClearable
                            // isDisabled={benefitWindowOpen || publicPortal} Remove for SP-665
                            defaultValue={publicPortal ? [(agreementOptions.filter(agreement => agreement.label === "AvtalTrygg - Standardavtalet med Trygghetspaketet"))[0]] : []}
                            isDisabled
                        >
                        </Select> {benefitWindowOpen ||publicPortal && <LockIcon className="benefitWindow__disabledField"/>} </>}
                        {benefitWindowOpen && <Tooltip title={"Detta värde kan inte ändras på grund av att fönstret har öppnat"} arrow><div className="benefitWindow__disabledField"><LockIcon className="benefitWindow__lockIcon" /></div></Tooltip>}
                        <div className="benefitWindow__securityAgreementDiv">
                            {/*<div>
                                <input onClick={() => setCustomerSpecificAgreement(!customerSpecificAgreement)} type="checkbox" id="customerSpecificAgreement" name="customerSpecificAgreement" checked={customerSpecificAgreement} />
                                <p className="benefitWindow__checkboxText" >Kundspecifikt UH</p>
                            </div>*/}
                            {/* customerSpecificAgreement && <div className="benefitWindow__fileUploadDiv">
                                {benefitWindowOpen && <Tooltip title={"Detta värde kan inte ändras på grund av att fönstret har öppnat"} arrow><LockIcon /></Tooltip>}
                                <legend className="benefitWindow__fileUploadDivLegend">Ladda upp kundspecifikt
                                    UH-avtal
                                </legend>
                                <div className="benefitWindow__fileUploadButtonsDiv">
                                    <input disabled={benefitWindowOpen} className="benefitWindow__fileInputButton" type="file" multiple
                                           onChange={handleFileChange}/>
                                    <button disabled={benefitWindowOpen} className="benefitWindow__fileUploadButtons"
                                            onClick={(e) => handleUploadFiles(e)}>Ladda upp avtal
                                    </button>
                                    {filesChosen && <button className="benefitWindow__fileUploadButtons"
                                                            onClick={handleRemoveFiles}>Ta bort avtal</button>}
                                </div>
                                {filesChosen && !areFilesUploaded && (
                                    <div className="benefitWindow__centerText">
                                        <>
                                            <h6>Följande avtal är valda:</h6>
                                            {selectedFiles.map((file, index) => (
                                                <p key={index}>{file.name}</p>
                                            ))}
                                            <h6>Ladda upp avtal med </h6>
                                            <h6>knappen "Ladda upp avtal".</h6>
                                            <h6>Alternativt ta bort avtal med </h6>
                                            <h6>knappen "Ta bort avtal".</h6>
                                        </>
                                    </div>
                                )}
                                {areFilesUploaded && (
                                    <div className="benefitWindow__centerText">
                                        <>
                                            <p>Följande avtal har laddats upp:</p>
                                            {uploadedFiles.map((file, index) => (
                                                <p key={index}>{file.name}</p>
                                            ))}
                                        </>
                                    </div>
                                )}
                                {!filesChosen && !areFilesUploaded && (
                                    <>
                                        <p>Välj avtal på knappen till vänster</p>
                                        <p>och ladda upp avtal med knappen "Ladda upp avtal".</p>
                                    </>
                                )}
                            </div> */}
                        </div>
                        <div className="benefitWindow__flexCenter">
                            <button disabled={benefitWindowOpen} className="bolag__conditionsButton" onClick={(e) => handleBenefitWindowConditions(e, false)} disabled={publicPortal}>Begränsningar {theRulesAndConditions.length > 0 || loadBenefitWindowRules.length > 0 ? <DoneIcon className="benefitWindow__iconInButton" /> : ""}</button>
                            {benefitWindowOpen && !publicPortal && <Tooltip title={"Detta värde kan inte ändras på grund av att fönstret har öppnat"} arrow><div className="benefitWindow__disabledButton"><LockIcon className="benefitWindow__lockIcon" /></div></Tooltip>}
                            {publicPortal && <div className="benefitWindow__disabledButton"><LockIcon className="benefitWindow__lockIcon" /></div>}
                            <button disabled={benefitWindowOpen} className="benefitWindow__demoButton" onClick={(e) => handleDemo(e)} disabled={publicPortal}>Demoevent</button>
                            {benefitWindowOpen && !publicPortal && <Tooltip title={"Detta värde kan inte ändras på grund av att fönstret har öppnat"} arrow><div className="benefitWindow__disabledButton"><LockIcon className="benefitWindow__lockIcon"/></div></Tooltip>}
                            {publicPortal && <div className="benefitWindow__disabledButton"><LockIcon className="benefitWindow__lockIcon"/></div>}
                        </div>
                        <div className="benefitWindow__invoiceDiv">
                            <label>Fylls i av Bike Action {benefitWindowOpen && <Tooltip title={"Detta värde kan inte ändras på grund av att fönstret har öppnat"} arrow><LockIcon className="benefitWindow__disabledText" /></Tooltip>}</label>
                            <div className="benefitWindow__flexRow">
                                <div className="benefitWindow__invoiceDateDiv">
                                    <label htmlFor="agreementStartDate">Fakturadatum</label>
                                    <DatePicker
                                        className="benefitWindow__date benefitWindow__dateInputFields"
                                        dateFormat="yyyy-MM-dd"
                                        selected={theInvoiceDate}
                                        onChange={(date) => setTheInvoiceDate(date)}
                                        id="agreementStartDate"
                                        name="agreementStartDate"
                                        locale={sv}
                                        readOnly={benefitWindowOpen}
                                    />
                                </div>
                                <div className="benefitWindow__invoiceNumberDiv">
                                    <label>Fakturanummer</label>
                                    <input disabled={benefitWindowOpen} type="text" onChange={(e) => setTheOutgoingInvoicenr(e.target.value)}
                                           value={theOutgoingInvoicenr}/>
                                </div>
                            </div>
                        </div>
                        <div className="benefitWindow__sportsonComments">
                            <h5>Kommentarer <ChatBubbleIcon className="benefitWindow__commentIcon"/>
                            </h5>{expandedComments ? (
                            <button className="benefitWindow__expandButton" onClick={() => setExpandedComments(false)}>
                                <ExpandLessIcon/></button>) : (
                            <button className="benefitWindow__expandButton" onClick={() => setExpandedComments(true)}>
                                <ExpandMoreIcon/></button>)}
                        </div>
                        {expandedComments && <div className="benefitWindow__comments">
                            {comments && comments.sort((a, b) => {
                                return new Date(a.DateCreated) - new Date(b.DateCreated)
                            }).map((comment, index) => (
                                (!comment.Deleted && <div key={index} className="benefitWindow__sportsonComment">
                                    <CommentCompanyOrWindow comment={comment}/>
                                    <button className="benefitWindow__commentButton"
                                            onClick={(e) => editCommentHandler(e, benefitWindowID, comment._id, comment.CommentText)}>
                                        <EditIcon/></button>
                                    <button className="benefitWindow__commentButton"
                                            onClick={(e) => deleteCommentHandler(e, benefitWindowID, comment._id)}>
                                        <DeleteIcon/></button>
                                </div>)
                            ))}
                            <button onClick={(e) => addNewCommentHandler(e, benefitWindowID[0])}
                                    className="benefitWindow__editButton"><span
                                className="benefitWindow__iconAndText"><AddCommentIcon
                                className="benefitWindow__icons"/> Lägg till kommentar</span></button>
                        </div>}
                        { benefitWindowID[0] && !benefitWindowCancelled && <button className="benefitWindow__cancelButton" onClick={() => handleCancelBenefitWindow()} type="checkbox" id="cancelled" name="cancelled">Cancellera fönster</button> }
                        <button className="benefitWindow__confirmButton" onClick={(e) => handleUpdateBenefitWindow(e)}>Spara</button>
                    </>
                )}
            </div>
        </div>
    );
}

export default BenefitWindow;
import React from "react";
import "./style.css";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
});

const confirmed = (e, setConfirmHandler, closeModalHandler) => {
  e.preventDefault();
  setConfirmHandler();
  closeModalHandler();
}

const cancelled = (e, closeModalHandler) => {
  e.preventDefault();
  closeModalHandler();
}

function InputModal({
    title,
    setConfirmHandler,
    showModal,
    closeModalHandler,
}) {
  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          className="modalConfirmWithButtons"
          open={showModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeModalHandler}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogActions className="modalConfirmWithButtons__topHeader">
            {" "}
            <span onClick={closeModalHandler} className="modalConfirmWithButtons__closeBtn">
              x
            </span>
          </DialogActions>
          <DialogTitle className="modalConfirmWithButtons__header">{title}</DialogTitle>
          <DialogContent className="modalConfirmWithButtons__body ">
            <button onClick={(e) => confirmed(e, setConfirmHandler, closeModalHandler)} className="modalConfirmWithButtons__button">Ja</button>
            <button onClick={(e) => cancelled(e, closeModalHandler)} className="modalConfirmWithButtons__button">Nej</button>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

export default InputModal;

import React, {useEffect, useRef, useState} from "react";
import "./FormanscykelFonsterHanteringPC.css";
import SelectReact from 'react-select';
import Modal from "../../components/Modals/Modal-ConfirmAlert";
import { logout } from "../../Redux/actions/userActions";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import {sv} from 'date-fns/esm/locale'
import moment from "moment";
import API from "../../utils/API";
import FormanscykelFonsterPC from "../../components/FormanscykelFonsterPC";
//import Select from "../../components/Select";

function FormanscykelFonsterHanteringPC({shopstaff}) {
    //const [number, setNumber] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState("");
    const [theSalesperson, setTheSalesperson] = useState("");
    const [personalcyklarContactsOptions, setPersonalcyklarContactsOptions] = useState([]);
    const [companiesOptions, setCompaniesOptions] = useState([]);
    const [shopsOptions, setShopsOptions] = useState([]);
    const [/*theOrderType*/, setTheOrderType] = useState("");
    const [/*theSortType*/, setTheSortType] = useState("");
    const [/*theCompanyName*/, setTheCompanyName] = useState("");
    const [theCompanyID, setTheCompanyID] = useState("");
    const [/*theShopName*/, setTheShopName] = useState("");
    const [theShopID, setTheShopID] = useState("");
    const [theStartDate, setTheStartDate] = useState("");
    const [theEndDate, setTheEndDate] = useState("");
    const [invoiced, setInvoiced] = useState(false);
    const [aborInvoiced, setAborInvoiced] = useState(false);
    const [searchForBenefitWindows, setSearchForBenefitWindows] = useState(false);
    const [onlyMyAffectedStores, setOnlyMyAffectedStores] = useState(false);
    const [onlyOpenBenefitWindows, setOnlyOpenBenefitWindows] = useState(false);
    const [notOUWindows, setNotOUWindows] = useState(true);
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const selectSalesPersonRef = useRef(null);
    const selectCompanyRef = useRef(null);
    const selectShopRef = useRef(null);

    const dispatch = useDispatch();

    const closeModalHandler = () => setShowModal(false);


    const forceClearSelect = () => {
        if (selectSalesPersonRef.current) {
            selectSalesPersonRef.current.clearValue();
            selectSalesPersonRef.current.inputValue = "";
        }
        if (selectCompanyRef.current) {
            selectCompanyRef.current.clearValue();
            selectCompanyRef.current.inputValue = "";
        }
        if (selectShopRef.current) {
            selectShopRef.current.clearValue();
            selectShopRef.current.inputValue = "";
        }
    };

    const customStyles = {
        control: (base) => ({
            ...base,
            position: "relative",
            left: "30px",
            height: "30px",
            width: "250px",
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
        }),
    };

    const fetchBenefitWindows = (e) => {
        e.preventDefault();

        if (theStartDate && theEndDate) {
            let compareStartDate = new Date(theStartDate).getTime();
            let compareEndDate = new Date(theEndDate).getTime();
            if (compareStartDate > compareEndDate){
                setShowModal(true);
                setTitle("Startdatumet kan inte ligga efter slutdatumet");
                return;
            }
        }

        setSearchForBenefitWindows(!searchForBenefitWindows);
    }

    const cleanUpFilter = (e) => {
        e.preventDefault();

        setTheOrderType("");
        setTheCompanyName("");
        setTheCompanyID("");
        setTheStartDate("");
        setTheEndDate("");
        setOnlyMyAffectedStores(false);
        setOnlyOpenBenefitWindows(false);
        setNotOUWindows(true);
        setTheSortType("");
        forceClearSelect();
        document.getElementById("ordersForm").reset();
        setSearchForBenefitWindows(!searchForBenefitWindows);
    }

    const handleOnChangeSalesPerson = (selectedOption) => {
        selectedOption ? setTheSalesperson(selectedOption.value) : setTheSalesperson("");
    }

    const handleOnChangeCompany = (selectedOption) => {

        if (selectedOption){
            setTheCompanyName(selectedOption.name);
            setTheCompanyID(selectedOption.value);
        } else {
            setTheCompanyName("");
            setTheCompanyID("");
        }
    }

    const handleOnChangeShop = (selectedOption) => {

        if (selectedOption){
            setTheShopName(selectedOption.name);
            setTheShopID(selectedOption.value);
        } else {
            setTheShopName("");
            setTheShopID("");
        }
    }

    const getGetPersonalcyklarContactTagID = async () => {
        await API.getGetPersonalcyklarContactTagId()
            .then((response) => {
                const getAllPersonalCyklarContacts = async () => {
                    await API.getContact("",response.data._id,"")
                        .then((response) => {
                            let salespersons = [];
                            salespersons.push({ value: "", label: "Välj säljare på Personalcyklar", isDisabled: true });
                            response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((salesperson) => {
                                salespersons.push({ value: salesperson._id, label: salesperson.name });
                            });
                            setPersonalcyklarContactsOptions(salespersons);
                        })
                        .catch((error) => {
                            console.log("Error: ", error);
                        });
                };
                getAllPersonalCyklarContacts();
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    useEffect(() => {
        void getGetPersonalcyklarContactTagID();
        const getAllCompanies = async () => {
            await API.getCompany("")
                .then((response) => {
                    let companies = [];

                    companies.push({ value: "", label: "Välj företag", isDisabled: true });
                    response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((company) => {
                        companies.push({ value: company._id, label: company.name });
                    });
                    setCompaniesOptions(companies);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllCompanies();


        const getAllShops = async () => {
            await API.getShopList("")
                .then((response) => {
                    let shops = [];

                    shops.push({ value: "", label: "Välj butik", isDisabled: true });
                    response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((shop) => {
                        shops.push({ value: shop.Code, label: shop.Name });
                    });
                    setShopsOptions(shops);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllShops();

        /*
        const getAllBenefitWindowsStatuses = async () => {
            await API.getBenefitWindowStatus("")
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        }
        getAllBenefitWindowsStatuses();
         */

    }, []);


    useEffect(() => {
        if (notOUWindows)
            setOnlyOpenBenefitWindows(false)
    }, [notOUWindows])

    useEffect(() => {
        if (onlyOpenBenefitWindows)
            setNotOUWindows(false)
    }, [onlyOpenBenefitWindows])

    useEffect( () => {
        invoiced ? setAborInvoiced(true) : setAborInvoiced(false);
    }, [invoiced])

    return (
        <div className="formanscykelFonsterHanteringPC">
            <form id="ordersForm">
                <hr />
                <div className="formanscykelFonsterHanteringPC__filter">
                    <Modal
                        showModal={showModal}
                        closeModalHandler={closeModalHandler}
                        title={title}
                        buttonName="Stäng"
                    />
                    <div className="formanscykelFonsterHanteringPC__filterOpt">
                        {/*
                        <input
                                className="formanscykelFonsterHanteringPC__inputText"
                                placeholder="Sök"
                                type="text"
                                name="inputText"
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                        />*/}
                        <div className="formanscykelFonsterHanteringPC__filterInputContainer">
                            <div className="formanscykelFonsterHanteringPC__filterSearchDiv">
                                <div className="formanscykelFonsterHanteringPC__searchSelectsDiv">
                                    <div>
                                        <h6>Säljare:</h6>
                                        <SelectReact
                                            className="itSupport__select formanscykelFonsterHanteringPC__filterSearchSelect"
                                            name="salesperson"
                                            onChange={handleOnChangeSalesPerson}
                                            options={personalcyklarContactsOptions}
                                            styles={customStyles}
                                            ref={selectSalesPersonRef}
                                            menuPortalTarget={document.body}
                                            isClearable
                                        />
                                    </div>
                                    <div>
                                        <h6>Företag:</h6>
                                        <SelectReact
                                            className="itSupport__select formanscykelFonsterHanteringPC__filterSearchSelect"
                                            name="companies"
                                            onChange={handleOnChangeCompany}
                                            options={companiesOptions}
                                            styles={customStyles}
                                            ref={selectCompanyRef}
                                            menuPortalTarget={document.body}
                                            isClearable
                                        />
                                    </div>
                                    <div>
                                        <h6>Butik:</h6>
                                        <SelectReact
                                            className="itSupport__select formanscykelFonsterHanteringPC__filterSearchSelect"
                                            name="shops"
                                            onChange={handleOnChangeShop}
                                            options={shopsOptions}
                                            styles={customStyles}
                                            ref={selectShopRef}
                                            menuPortalTarget={document.body}
                                            isClearable
                                        />
                                    </div>
                                </div>
                                <label>Startdatum:</label>
                                <div className="formanscykelFonsterHanteringPC__customDatePickerWidth">
                                    <DatePicker
                                        className="formanscykelFonsterHanteringPC__inputTypeDate"
                                        dateFormat="yyyy-MM-dd"
                                        selected={theStartDate}
                                        onChange={(date) => setTheStartDate(date)}
                                        id="orderStartDate"
                                        name="orderStartDate"
                                        locale={sv}
                                        required
                                    />
                                </div>
                                <label>Slutdatum:</label>
                                <div className="formanscykelFonsterHanteringPC__customDatePickerWidth">
                                    <DatePicker
                                        className="formanscykelFonsterHanteringPC__inputTypeDate"
                                        dateFormat="yyyy-MM-dd"
                                        selected={theEndDate}
                                        onChange={(date) => setTheEndDate(date)}
                                        id="orderEndDate"
                                        name="orderEndDate"
                                        locale={sv}
                                        required
                                    />
                                </div>
                                <div className="formanscykelFonsterHanteringPC__checkboxDiv">
                                    <div>
                                        <input onClick={() => setOnlyMyAffectedStores(!onlyMyAffectedStores)} type="checkbox" id="onlyMyAffectedStores" name="onlyMyAffectedStores" value="" checked={onlyMyAffectedStores}/>
                                        <p className="formanscykelFonsterHanteringPC__checkboxText">Endast fönster för mina butiker</p>
                                    </div>
                                    <div>
                                        <input onClick={() => setOnlyOpenBenefitWindows(!onlyOpenBenefitWindows)} type="checkbox" id="onlyOpenBenefitWindows" name="onlyOpenBenefitWindows" value="" checked={onlyOpenBenefitWindows}/>
                                        <p className="formanscykelFonsterHanteringPC__checkboxText">Endast öppna fönster</p>
                                    </div>
                                    <div>
                                        <input onClick={() => setNotOUWindows(!notOUWindows)} type="checkbox" id="notOUWindows" name="notOUWindows" value="" checked={notOUWindows}/>
                                        <p className="formanscykelFonsterHanteringPC__checkboxText">Ej klara OU</p>
                                    </div>
                                    <div>
                                        <input onClick={(e) => setInvoiced(!invoiced)} type="checkbox" id="invoiced" name="invoiced" value={invoiced}/>
                                        <p className="formanscykelFonsterHanteringPC__checkboxText">Fakturerat Abor</p>
                                    </div>
                                </div>
                                    <button className="formanscykelFonsterHanteringPC__searchBtn" type="submit"
                                            onClick={(e) => fetchBenefitWindows(e)}>Sök via filter
                                    </button>
                                </div>
                                {/*
                            <Select value={theOrderType} onChange={(e) => setTheOrderType(e.target.value)} title="Status" options={["Pågående","Klart att leverera", "Stopp för förändring", "Fönstret stängt"]} />
                            <label className="formanscykelFonsterHanteringPC__sortLabel"><b>Sortera</b></label>
                            <Select value={theSortType} onChange={(e) => setTheSortType(e.target.value)} title="Sortering" options={["Pågående","Klart att leverera", "Stopp för förändring", "Fönstret stängt"]} />
                            */}
                            </div>
                            {/*
            <Select title="Datumintervall" options={["Ramnummer","Fakturanummer"]} />
            */}
                        {/*
            <Select title="Beställningsfönster" options={["Ramnummer","Fakturanummer"]} />
            */}
                        <button onClick={cleanUpFilter} className="formanscykelFonsterHanteringPC__cleanUpFilterButton"><span>Rensa filter</span></button>
                    </div>
                </div>
            </form>
            { loadingSpinner && <div className="formanscykelFonsterHanteringPC__loadingDiv"><h1>Laddar</h1><div className="formanscykelFonsterHanteringPC__loader"></div></div> }
            {!searchForBenefitWindows && <FormanscykelFonsterPC fromdate={theStartDate ? moment(theStartDate).format("yyyy-MM-DD") : ""} todate={theEndDate ? moment(theEndDate).format("yyyy-MM-DD") : ""} companyID={theCompanyID} onlyOpenBenefitWindows={onlyOpenBenefitWindows} onlyAffectedStores={onlyMyAffectedStores} notOUWindows={notOUWindows} shops={theShopID} aborInvoiced={aborInvoiced} salesperson={theSalesperson}/> }
            {searchForBenefitWindows && <FormanscykelFonsterPC fromdate={theStartDate ? moment(theStartDate).format("yyyy-MM-DD") : ""} todate={theEndDate ? moment(theEndDate).format("yyyy-MM-DD") : ""} companyID={theCompanyID} onlyOpenBenefitWindows={onlyOpenBenefitWindows} onlyAffectedStores={onlyMyAffectedStores} notOUWindows={notOUWindows} shops={theShopID} aborInvoiced={aborInvoiced} salesperson={theSalesperson}/> }
        </div>
    );
}

export default FormanscykelFonsterHanteringPC;

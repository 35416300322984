import React, { useEffect, useState } from "react";
import ArticleTextfields from "../../components/ArticleTextFields/ArticleTextFields";
import Header from "../../components/Header";
import "./ArticleForm.css";
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { logout } from "../../Redux/actions/userActions";
import { useDispatch } from "react-redux";
import Modal from "../../components/Modals/Modal-ConfirmAlert";
import API from "../../utils/API";

function ArticleForm() {
  const [article, setArticle] = useState("");
  const [articleData, setArticleData] = useState([]);
  const [name, /*setName*/] = useState("");
  const [comment, setComment] = useState("");
  const [manufacturerId, setManufacturerId] = useState("");
  const [/*manufacturer*/, setManufacturer] = useState("");
  const [manufacturerData, setManufacturerData] = useState([]);
  const [supplierId, setSupplierId] = useState("");
  const [/*supplier*/, setSupplier] = useState("");
  const [supplierData, setSupplierData] = useState([]);
  const [mainCategoryId, setMainCategoryId] = useState("");
  const [/*category*/, setCategory] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [shop, setShop] = useState("");
  const [shopData, setShopData] = useState([]);
  const { handleSubmit } = useForm();
  const [search, setSearch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [userNameData, setUserNameData] = useState("");
  const dispatch = useDispatch();

  const closeModalHandler = () => setShowModal(false);

  async function fetchData(e) {
    e.preventDefault();
    setArticleData(article[0]);
    setSupplierId("");
    setManufacturerId("");
    setMainCategoryId("");

    if (article === "" || null) {
      setShowModal(true);
      setTitle("Sökfältet är tomt!");
    } else {
      await API.getArticle(article)
        .then((response) => {
          if (response.status === 500 || response.status === 400) {
            // throw new Error("status: ", response.status);
            alert("Meddelandet har inte skickats iväg");
          }
          if (response.status === 200) {
            const data = response.data;
            setArticleData([data]);
            setSupplierId(response.data.SupplierId);
            setManufacturerId(response.data.ManufacturerId);
            setMainCategoryId(response.data.MainCategoryId);
          } else if (response.status === 401) {
            dispatch(logout());
          } else if (response.status === 404) {
            setArticleData(article[0]);
          }
        })
        .catch((/*error*/) => {
          setShowModal(true);
          setTitle("något gick fel, försök igen");
        });
    }
  }

  useEffect(() => {

    const getSupplier = async () => {
      await API.getSupplier()
        .then((response) => {
          setSupplierData(response.data);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    };
    void getSupplier();

    const getManufacturer = async () => {
      await API.getManufacturer()
        .then((response) => {
          setManufacturerData(response.data);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    };
    void getManufacturer();

    const getCategory = async () => {
      await API.getCategory()
        .then((response) => {
          setCategoryData(response.data);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    };
    void getCategory();

    const getShop = async () => {
      await API.getUserShop()
        .then((response) => {
          response.data.sort((a, b) => a.Name.localeCompare(b.Name));
          setShopData(response.data);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    };
    void getShop();

    const getUserName = async () => {
      await API.getUserName()
        .then((response) => {
          setUserNameData(response.data);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    };
    void getUserName();

  }, []);

  const handleSelectedShop = (e) => {
    setShop(e.target.value);
  };

  const handleSelectedManufacturer = (e) => {
    setManufacturer(e.target.value);
  };

  const handleSelectedSupplier = (e) => {
    setSupplier(e.target.value);
  };

  const handleSelectedCategory = (e) => {
    setCategory(e.target.value);
  };

  // hides the form and shows the success message
  const changeSearchStatus = () => {
    setSearch(false);

    setShowModal(true);
    setTitle("Artikelbegäran har skickats!");
  };

  const handleClear = () => {
    setArticle("");
  };

  return (
    <div className="articleform">
      <form onSubmit={handleSubmit}>
        <div className="articleForm__inputContainer">
          <Header title="Artikelbegäran" />
          <TextField
            className="articleForm__inputText"
            label="Ange artikelnr / streckkod"
            variant="outlined"
            type="text"
            name="comment"
            value={article}
            onChange={(e) => setArticle(e.target.value)}
          />
          <button
            className="articleForm__searchBtn"
            type="submit"
            onClick={(e) => {
              setArticleData(fetchData(e));
              setSearch(true);
            }}
          >
            SÖK
          </button>
          <Modal
            showModal={showModal}
            closeModalHandler={closeModalHandler}
            title={title}
            buttonName="Stäng"
          />
        </div>
      </form>
      {search && (
        <div className="articleForm__formContainer">
          {Object.keys(articleData).map((p) => (
            <div key={articleData[0].ProductId}>
              <div className="horizintalLine">
                <hr />
              </div>
              <ArticleTextfields
                ProductId={articleData[p].ProductId}
                ArticleNumber={article}
                BarCode={articleData[p].BarCode}
                ProductName={articleData[p].ProductName}
                Comment={comment}
                commentData={(e) => setComment(e.target.value)}
                selectManufacturer={handleSelectedManufacturer}
                ManufacturerId={manufacturerId}
                manufacturerData={manufacturerData}
                selectSupplier={handleSelectedSupplier}
                SupplierId={supplierId}
                supplierData={supplierData}
                selectCategory={handleSelectedCategory}
                MainCategoryId={mainCategoryId}
                categoryData={categoryData}
                PurchasePrice={articleData[p].PurchasePrice}
                SalePrice={articleData[p].SalePrice}
                selectShop={handleSelectedShop}
                WarehouseId={shop}
                shopData={shopData}
                userNameData={userNameData}
                name={name}
                // nameInput={(e) => setName(e.target.value)}
                changeSearchStatus={changeSearchStatus}
                handleClear={handleClear}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ArticleForm;

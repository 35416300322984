import React, {useState, useEffect} from "react";
import "./style.css";
import Modal from "../Modal-ConfirmAlert";
import {logout} from "../../../Redux/actions/userActions";
import {useDispatch} from "react-redux";
import API from "../../../utils/API";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@material-ui/core";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
});

function InputModal({
    title,
    showModal,
    closeModalHandler,
    companyID,
    companyWindowRules,
    setCompanyWindowRules,
    setTheRulesAndConditions,
    isDisabledBenefitWindowConditions,
}) {
  const [showTheModal, setShowTheModal] = useState(false);
  const [theTitle, setTheTitle] = useState("");
  const [rulesAndConditions, setRulesAndConditions] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);
  const [areNoLimits, setAreNoLimits] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    let allConditions = document.getElementsByClassName("modal__benefitWindowConditions_checkbox");

    if (showModal)
      setAreNoLimits(true);

    for (let condition of allConditions) {
      if (condition.checked) {
        setAreNoLimits(false);
      }
    }

  }, [showModal])

  useEffect(() => {
    let allConditions = document.getElementsByClassName("modal__benefitWindowConditions_checkbox");

    if (areNoLimits) {
      setCompanyWindowRules([]);
      for (let condition of allConditions) {
        if (condition.checked) {
          condition.checked = false;
        }
      }
    }

  }, [areNoLimits])

  useEffect(() => {
    const getWindowRules = async (companyID) => {
      await API.getWindowRule(companyID)
          .then((response) => {
            if (response.status === 200) {
              setRulesAndConditions(response.data);
            } else if (response.status >= 300) {
              setShowTheModal(true);
              setTheTitle(`${response.data.message} - ${response.data.details.message}`);
            } else if (response.status === 401) {
              dispatch(logout());
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void getWindowRules();

  }, [companyID]);

  useEffect(() => {
    let tempFilteredArray = [];

    if (rulesAndConditions.length > 0 && companyWindowRules && companyWindowRules[0] && companyWindowRules[0].rule && companyWindowRules.length > 0)
      tempFilteredArray = rulesAndConditions.filter(obj => !companyWindowRules.some(item => item.rule._id === obj._id));

    setFilteredArray(tempFilteredArray);

  }, [companyWindowRules]);

  const closeTheModalHandler = () => setShowTheModal(false);

  const handleSaveConditions = (e) => {
    e.preventDefault();

    let allConditions = document.getElementsByClassName("modal__benefitWindowConditions_checkbox");
    let conditionsToSave = [];
    let inputs = [];

    for (let condition of allConditions) {
      if (condition.checked) {
        let conditionToSave = {};
        let rule = "";
        let theParameters = {};
        inputs = condition.parentNode.parentNode.getElementsByTagName("input");
        for (let i = 0; i < inputs.length; i++) {
          if (i === 0){
            if (inputs[0])
              if (inputs[0].value === ""){
                setTheTitle("Valda vilkor måste ha värden.");
                setShowTheModal(true);
                return;
              }
              rule = inputs[0].value;
          }
          else if (i > 0){
            if (inputs[i].value === ""){
              setTheTitle("Valda vilkor måste ha värden.");
              setShowTheModal(true);
              return;
            }
            theParameters[inputs[i].name] = inputs[i].value;
          }
        }

        conditionToSave = {
          rule: rule,
          parameters: theParameters
        }
        conditionsToSave.push(conditionToSave);
      }
    }
    setTheRulesAndConditions(conditionsToSave);
    closeModalHandler();
  }

  const checkboxClickedHandler = () => {
    setAreNoLimits(false);
    setRulesAndConditions([...rulesAndConditions]);
  }

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          className="modal"
          open={showModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeModalHandler}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogActions className="modal__topHeader">
            {" "}
            <span onClick={closeModalHandler} className="modal__closeBtn">
              x
            </span>
          </DialogActions>
          <DialogContent className="modal__benefitWindowConditions__body">
            <Modal
                showModal={showTheModal}
                closeModalHandler={closeTheModalHandler}
                title={theTitle}
                buttonName="Stäng"
            />
            <div className="modal__benefitWindowConditions">
              <h2>{title}</h2>
              {/*console.log(companyID)}
              {console.log(companyWindowRules)}
              {console.log(rulesAndConditions)}
              {console.log(filteredArray)*/}
              <h5 className="modal__benefitWindowConditionsRuleTitle">Inga begränsningar <input
                  className="modal__benefitWindowConditions_checkboxNoLimits" type="checkbox" checked={areNoLimits}
                  onClick={() => setAreNoLimits(!areNoLimits)} disabled={isDisabledBenefitWindowConditions}/></h5>
              {companyID !== "" && companyWindowRules.length > 0 && companyWindowRules.map((ruleCondition, index) =>
                  (<>
                    {ruleCondition.rule && <div className="modal__benefitWindowConditions__condition">
                      <h5>{ruleCondition.rule.name} <input className="modal__benefitWindowConditions_checkbox"
                               type="checkbox" id={ruleCondition.rule.id} name="id"
                               value={ruleCondition.rule._id}
                               onClick={() => checkboxClickedHandler()}
                               defaultChecked={true}
                               disabled={isDisabledBenefitWindowConditions}/>
                      </h5>
                      {!areNoLimits && document.getElementsByClassName("modal__benefitWindowConditions_checkbox")[index]?.checked && <>
                        <h6>({ruleCondition.rule.description})</h6>
                        <div className="modal__benefitWindowConditions__parameters">
                          {ruleCondition.rule.extraValues && ruleCondition.rule.extraValues.map((value) => (
                              <>
                                <h6>{value.toUpperCase()}:<input className="modal__benefitWindowConditions__input"
                                                                 type="input" id={ruleCondition.rule.id} name={value}
                                                                 defaultValue={ruleCondition.parameters[value]}
                                                                 disabled={isDisabledBenefitWindowConditions}/></h6>
                              </>
                          ))}
                        </div>
                      </>}
                    </div>}
                  </>))
              }
              {companyID !== "" && filteredArray.length > 0 && filteredArray.map((ruleCondition, index) =>
                  (<>
                    <div className="modal__benefitWindowConditions__condition">
                      <h5>{ruleCondition.name} <input className="modal__benefitWindowConditions_checkbox modal__benefitWindowConditions_checkboxFilteredArray"
                               type="checkbox" id={ruleCondition.id} name="id"
                               value={ruleCondition._id} onClick={() => checkboxClickedHandler()}
                               disabled={isDisabledBenefitWindowConditions}
                        />
                      </h5>
                      {!areNoLimits && document.getElementsByClassName("modal__benefitWindowConditions_checkboxFilteredArray")[index]?.checked && <>
                        <h6>({ruleCondition.description})</h6>
                        <div className="modal__benefitWindowConditions__parameters">
                          {ruleCondition.extraValues && ruleCondition.extraValues.map((value) => (
                              <>
                                <h6>{value.toUpperCase()}:<input className="modal__benefitWindowConditions__input"
                                                                 type="input" id={ruleCondition.id} name={value}
                                                                 disabled={isDisabledBenefitWindowConditions}/></h6>
                              </>
                          ))}
                        </div>
                      </>}
                    </div>
                  </>))
              }
              {companyID === "" && rulesAndConditions && rulesAndConditions.map((ruleCondition, index) =>
                  (<>
                    <div className="modal__benefitWindowConditions__condition">
                      <h5>{ruleCondition.name} <input className="modal__benefitWindowConditions_checkbox"
                               type="checkbox" id={ruleCondition.id} name="id"
                               value={ruleCondition._id} onClick={() => checkboxClickedHandler()}
                               disabled={isDisabledBenefitWindowConditions}
                        />
                      </h5>
                      {!areNoLimits && document.getElementsByClassName("modal__benefitWindowConditions_checkbox")[index]?.checked && <>
                        <h6>({ruleCondition.description})</h6>
                        <div className="modal__benefitWindowConditions__parameters">
                          {ruleCondition.extraValues && ruleCondition.extraValues.map((value) => (
                              <>
                                <h6>{value.toUpperCase()}:<input className="modal__benefitWindowConditions__input"
                                                                 type="input" id={ruleCondition.id} name={value}
                                                                 disabled={isDisabledBenefitWindowConditions}/></h6>
                              </>
                          ))}
                        </div>
                      </>}
                    </div>
                  </>))
              }
              {companyID !== "" && filteredArray.length === 0 && companyWindowRules.length === 0 && rulesAndConditions && rulesAndConditions.map((ruleCondition, index) =>
                  (<>
                    <div className="modal__benefitWindowConditions__condition">
                      <h5>{ruleCondition.name} <input className="modal__benefitWindowConditions_checkbox"
                               type="checkbox" id={ruleCondition.id} name="id"
                               value={ruleCondition._id} onClick={() => checkboxClickedHandler()}
                               disabled={isDisabledBenefitWindowConditions}
                        />
                      </h5>
                      {!areNoLimits && document.getElementsByClassName("modal__benefitWindowConditions_checkbox")[index]?.checked && <>
                        <h6>({ruleCondition.description})</h6>
                        <div className="modal__benefitWindowConditions__parameters">
                          {ruleCondition.extraValues && ruleCondition.extraValues.map((value) => (
                              <>
                                <h6>{value.toUpperCase()}:<input className="modal__benefitWindowConditions__input"
                                                                 type="input" id={ruleCondition.id} name={value}
                                                                 disabled={isDisabledBenefitWindowConditions}/></h6>
                              </>
                          ))}
                        </div>
                      </>}
                    </div>
                  </>))
              }
              {companyWindowRules && companyWindowRules[0] && !companyWindowRules[0].rule && rulesAndConditions && rulesAndConditions.map((ruleCondition, index) =>
                  (<>
                    <div className="modal__benefitWindowConditions__condition">
                      <h5>{ruleCondition.name} <input className="modal__benefitWindowConditions_checkbox"
                                                      type="checkbox" id={ruleCondition.id} name="id"
                                                      value={ruleCondition._id}
                                                      disabled={isDisabledBenefitWindowConditions}
                        />
                      </h5>
                      {!areNoLimits && document.getElementsByClassName("modal__benefitWindowConditions_checkbox")[index]?.checked && <>
                        <h6>({ruleCondition.description})</h6>
                        <div className="modal__benefitWindowConditions__parameters">
                          {ruleCondition.extraValues && ruleCondition.extraValues.map((value) => (
                              <>
                                <h6>{value.toUpperCase()}:<input className="modal__benefitWindowConditions__input"
                                                                 type="input" id={ruleCondition.id} name={value}
                                                                 disabled={isDisabledBenefitWindowConditions}/></h6>
                              </>
                          ))}
                        </div>
                      </>}
                    </div>
                  </>))
              }
            </div>
          </DialogContent>
          <button onClick={(e) => handleSaveConditions(e)} className="modal__benefitWindowConditions__button">
            Spara och stäng
          </button>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

export default InputModal;

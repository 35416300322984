import React, {useEffect, useState, useRef} from "react";
import "./style.css";
import API from "../../utils/API";
//import {logout} from "../../Redux/actions/userActions";
import ModalBenefitWindow from "../Modals/Modal-BenefitWindow";
import OrderForm from "../OrderForm";

function FormanscykelFonster({fromdate, todate, companyID, onlyOpenBenefitWindows, onlyAffectedStores, skip, limit, notOUWindows, shops, aborInvoiced}) {
    const [benefitWindows, setBenefitWindows] = useState([])
    const [numberOfBenefitWindows, setNumberOfBenefitWindows] = useState("");
    const [/*userShopData*/, setUserShopData] = useState([]);
    //const [showOnlyMyShops, setShowOnlyMyShops] = useState(false);
    const [showModalBenefitWindow, setShowModalBenefitWindow] = useState(false);
    const [title, setTitle] = useState("");
    const [benefitWindow, setBenefitWindow] = useState("");
    const currentPageRef = useRef(1);
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [benefitWindowsLimitPagewise, setBenefitWindowsLimitPagewise] = useState(10);
    //let checkboxShowOnlyMyShops = document.getElementById("windowsMyShops");

    const closeModalBenefitWindowHandler = () => setShowModalBenefitWindow(false);

    const firstPage = (e) => {
        e.preventDefault();

        currentPageRef.current = 1;
        void reloadBenefitWindows();
    }

    const lastPage = (e) => {
        e.preventDefault();

        currentPageRef.current = Math.ceil((numberOfBenefitWindows / benefitWindowsLimitPagewise));
        void reloadBenefitWindows();
    }

    const previousPage = (e) => {
        e.preventDefault();

        currentPageRef.current = currentPageRef.current - 1;
        void reloadBenefitWindows();
    }

    const nextPage = (e) => {
        e.preventDefault();

        currentPageRef.current = currentPageRef.current + 1;
        void reloadBenefitWindows();
    }

    const handleBenefitWindow = (e,windowID) => {
        e.preventDefault();

        if (e.target.tagName !== "SELECT") {
            setBenefitWindow(windowID);
            setShowModalBenefitWindow(true);
            setTitle("Beställningsfönsternamn");
        }
    }

    const reloadBenefitWindows = async () => {
        setLoadingSpinner(true);

        await API.getBenefitWindowIgnoreCancelled("",fromdate, todate, true, companyID, onlyOpenBenefitWindows, onlyAffectedStores, (currentPageRef.current - 1) * benefitWindowsLimitPagewise, benefitWindowsLimitPagewise, notOUWindows, shops, aborInvoiced)
            .then((response) => {
                response.data.data.sort((a, b) => b.startdate.localeCompare(a.startdate));
                setBenefitWindows(response.data.data);
                setNumberOfBenefitWindows(response.data.count);
                setLoadingSpinner(false);
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    useEffect(() => {
        const getUserShop = async () => {
            await API.getUserShop()
                .then((res) => {
                    if (res.status === 200) {
                        res.data.sort((a, b) => a.Name.localeCompare(b.Name));
                        setUserShopData(res.data[0].Code);
                    }
                    if (res.status === 401) {
                    }
                })
                .catch((error) => {

                    console.log("Error: ", error);
                });
        };
        void getUserShop();
        void reloadBenefitWindows();
    }, []);

    useEffect(() => {
        reloadBenefitWindows();
    }, [benefitWindowsLimitPagewise])

    return (
          <>
              <ModalBenefitWindow
                  showModal={showModalBenefitWindow}
                  closeModalHandler={closeModalBenefitWindowHandler}
                  title={title}
                  buttonName="Stäng"
                  benefitWindow={benefitWindow}
              />
              <div className="formanscykelFonster">
                  <h4 className="formanscykelFonster__searchResultsHeader">Sökresultat: {numberOfBenefitWindows} fönster</h4>
                  { loadingSpinner && <div className="formanscykelFonster__loadingDiv"><h1>Laddar</h1><div className="formanscykelFonster__loader"></div></div> }
                  { benefitWindows.length > 0 && benefitWindows.map((window,index) => {
                  return  (
                      <table className="formanscykelFonster__table">
                          <tbody>
                              { index === 0 ? <tr className="formanscykelFonster__rowHeader">
                                      {/* <th className="formanscykelFonster__rowColumns"><h5>Datum</h5></th> */}
                                      {/* <th className="formanscykelFonster__rowColumns"><h5 className="formanscykelFonster__company">Företagsnamn</h5></th> */}
                                      <th className="formanscykelFonster__rowColumns"><h5><b>Fönster</b></h5></th>
                                      <th className="formanscykelFonster__rowColumns"><h5><b>Status</b></h5></th>
                                      <th className="formanscykelFonster__rowColumns"><h5><b>Berörda butiker</b></h5></th>
                                      <th className="formanscykelFonster__rowColumns"><h5><b>OU genererat</b></h5></th>
                                      <th className="formanscykelFonster__rowColumns"><h5><b>Fakturerad Abor</b></h5></th>
                                      <th className="formanscykelFonster__rowColumns"><h5><b>Fakturanummer</b></h5></th>
                                      <th className="formanscykelFonster__rowColumns"><h5><b>Faktureringsdatum</b></h5></th>
                                  </tr> : <tr>
                                  <th className="formanscykelFonster__rowColumns"></th>
                                  <th className="formanscykelFonster__rowColumns"></th>
                                  <th className="formanscykelFonster__rowColumns"></th>
                                  <th className="formanscykelFonster__rowColumns"></th>
                                  <th className="formanscykelFonster__rowColumns"></th>
                                  <th className="formanscykelFonster__rowColumns"></th>
                                  <th className="formanscykelFonster__rowColumns"></th>
                              </tr>  }
                              { window.length > 0 && index % 2 === 0 ?
                                  <tr className="formanscykelFonster__rowGray formanscykelFonster__row"
                                      onClick={(e) => handleBenefitWindow(e, window._id)}>
                                      {/* <td className="formanscykelFonster__rowColumnsDate formanscykelFonster__rowColumns"><h4><span className="formanscykelFonster__dateRange">{ window && window.startdate ? window.startdate.slice(0,10) : null} - { window && window.enddate ? window.enddate.slice(0,10) : null}</span></h4></td> */}
                                      {/* <td className="formanscykelFonster__rowColumns"><h4 className="formanscykelFonster__company">{ window && window.company ? window.company.name : null}</h4></td> */}
                                      <td className="formanscykelFonster__rowColumns"><h5 className="formanscykelFonster__month">{window ? window.name : null}</h5></td>
                                      <td className="formanscykelFonster__rowColumns"><h4>{window && window.windowstatustext ? window.windowstatustext : "-"}</h4></td>
                                      <td className="formanscykelFonster__rowColumns">{window && window.affectedStoresObjects && window.affectedStoresObjects.length === 1 ? <h4>{window.affectedStoresObjects[0].Name}</h4> : null}{window && window.affectedStoresObjects && window.affectedStoresObjects.length > 1 && <select>{window && window.affectedStoresObjects && window.affectedStoresObjects.length > 1 ? window.affectedStoresObjects.map((store) => <option>{store ? store.Name : null}</option>) : null}</select>}</td>
                                      <td className="formanscykelFonster__rowColumns"><h4>{window && window.ou_generated ? "Ja" : "Nej"}</h4></td>
                                      <td className="formanscykelFonster__rowColumns"><h4>{window && window.outgoing_invoicenr ? "Ja" : "Nej"}</h4></td>
                                      <td className="formanscykelFonster__rowColumns"><h4>{window && window.outgoing_invoicenr ? window.outgoing_invoicenr : "-"}</h4></td>
                                      <td className="formanscykelFonster__rowColumns"><h4>{window && window.invoice_date ? window.invoice_date.substring(0, 10) : "-"}</h4></td>
                                  </tr> : <tr className="formanscykelFonster__row" onClick={(e) => handleBenefitWindow(e, window._id)}>
                                      {/* <td className="formanscykelFonster__rowColumnsDate formanscykelFonster__rowColumns"><h4><span className="formanscykelFonster__dateRange">{window && window.startdate ? window.startdate.slice(0,10) : null} - {window && window.enddate ? window.enddate.slice(0,10) : null}</span></h4></td> */}
                                      {/* <td className="formanscykelFonster__rowColumns"><h4 className="formanscykelFonster__company">{window && window.company ? window.company.name : null}</h4></td> */}
                                      <td className="formanscykelFonster__rowColumns"><h5 className="formanscykelFonster__month">{window ? window.name : null}</h5></td>
                                      <td className="formanscykelFonster__rowColumns"><h4>{window && window.windowstatustext ? window.windowstatustext : "-"}</h4></td>
                                      <td className="formanscykelFonster__rowColumns">{window && window.affectedStoresObjects && window.affectedStoresObjects.length === 1 ? <h4>{window.affectedStoresObjects[0].Name}</h4> : null}{window && window.affectedStoresObjects && window.affectedStoresObjects.length > 1 && <select>{window && window.affectedStoresObjects && window.affectedStoresObjects.length > 1 ? window.affectedStoresObjects.map((store) => <option>{store ? store.Name : null}</option>) : null}</select>}</td>
                                      <td className="formanscykelFonster__rowColumns"><h4>{window && window.ou_generated ? "Ja" : "Nej"}</h4></td>
                                      <td className="formanscykelFonster__rowColumns"><h4>{window && window.outgoing_invoicenr ? "Ja" : "Nej"}</h4></td>
                                      <td className="formanscykelFonster__rowColumns"><h4>{window && window.outgoing_invoicenr ? window.outgoing_invoicenr : "-"}</h4></td>
                                      <td className="formanscykelFonster__rowColumns"><h4>{window && window.invoice_date ? window.invoice_date.substring(0, 10) : "-"}</h4></td>
                                  </tr>}
                          </tbody>
                      </table>
                  )
                  })}
                  <div className="formanscykelFonster__pageButtonsDiv">
                      {numberOfBenefitWindows <= 10 && (
                          <>
                              <button key={"page-1"}
                                      className="formanscykelFonster__pageButton formanscykelFonster__currentPage">{1}</button>
                          </>
                      )}
                      {numberOfBenefitWindows > benefitWindowsLimitPagewise && parseInt(currentPageRef.current ? currentPageRef.current : "") === 1 && (
                          <>
                              <button key={"page-1"} onClick={(e) => firstPage(e)}
                                      className="formanscykelFonster__pageButton formanscykelFonster__currentPage">{1}</button>
                              <button key={"page-next"} onClick={(e) => nextPage(e)}
                                      className="formanscykelFonster__pageButton">{">"}</button>
                              <button key={"page-last"} onClick={(e) => lastPage(e)}
                                      className="formanscykelFonster__pageButton">{">|"}</button>
                          </>
                      )}
                      {parseInt(currentPageRef.current ? currentPageRef.current : "") > 1 && parseInt(currentPageRef.current ? currentPageRef.current : "") !== 1 && parseInt(currentPageRef.current ? currentPageRef.current : "") !== Math.ceil((numberOfBenefitWindows / benefitWindowsLimitPagewise)) && (
                          <>
                              <button key={"page-first"} onClick={(e) => firstPage(e)}
                                      className="formanscykelFonster__pageButton">{"|<"}</button>
                              <button key={"page-prev"} onClick={(e) => previousPage(e)}
                                      className="formanscykelFonster__pageButton">{"<"}</button>
                              <button key={"page-current"}
                                      className="formanscykelFonster__pageButton formanscykelFonster__currentPage">{currentPageRef.current}</button>
                              <button key={"page-next"} onClick={(e) => nextPage(e)}
                                      className="formanscykelFonster__pageButton">{">"}</button>
                              <button key={"page-last"} onClick={(e) => lastPage(e)}
                                      className="formanscykelFonster__pageButton">{">|"}</button>
                          </>
                      )}
                      {numberOfBenefitWindows > benefitWindowsLimitPagewise && parseInt(currentPageRef.current ? currentPageRef.current : "") === Math.ceil((numberOfBenefitWindows / benefitWindowsLimitPagewise)) && (
                          <>
                              <button key={"page-first"} onClick={(e) => firstPage(e)}
                                      className="formanscykelFonster__pageButton">{"|<"}</button>
                              <button key={"page-prev"} onClick={(e) => previousPage(e)}
                                      className="formanscykelFonster__pageButton">{"<"}</button>
                              <button key={"page-current"} onClick={(e) => lastPage(e)}
                                      className="formanscykelFonster__pageButton formanscykelFonster__currentPage">{currentPageRef.current}</button>
                          </>
                      )}
                      <div className="formanscykelFonster__pageLimitButtonsDiv">
                          <p>Visa antal per sida:</p>
                          <button onClick={() => setBenefitWindowsLimitPagewise(5)}>5</button>
                          <button onClick={() => setBenefitWindowsLimitPagewise(10)}>10</button>
                          <button onClick={() => setBenefitWindowsLimitPagewise(25)}>25</button>
                          <button onClick={() => setBenefitWindowsLimitPagewise(50)}>50</button>
                          <button onClick={() => setBenefitWindowsLimitPagewise(100)}>100</button>
                      </div>
                  </div>
                  <div className="formanscykelFonster__orderDiv">
                    <OrderForm />
                  </div>
              </div>
          </>
      );
}

export default FormanscykelFonster;

import React from "react";
import "./style.css";
import sportsonImg from "../../img/sportsonSvartLogo.jpg";
import { useSelector } from "react-redux";

function Kvittens() {
  const { orderInfo } = useSelector((state) => state.order);
  return (
    <div className="kvittens">
      <div className="kvittens__header">
        <img className="kvittens__logo" src={sportsonImg ? sportsonImg : ""} alt="" loading="lazy" />
        {Object.keys(orderInfo).map((order) => (
          <div key={order.Id}>
            <h3 className="kvittens__butik">
              {orderInfo[order].OrderHead.Shop.Name}
            </h3>
          </div>
        ))}
        <div className="kvittens__title">
          <h1>KVITTENS</h1>
          <span>
            på återlämnad <br /> förmånscykel
          </span>
        </div>
      </div>
      <div className="kvittens__subHeader">
        {Object.keys(orderInfo).map((order) => (
          <div key={order.Id}>
            <h3>{orderInfo[order].OrderHead.Customer.FirstName}</h3>
            <div className="kvittens__orderContainer">
              <div className="kvittens__datum">
                <span>Orderdatum</span>
                <h4>{orderInfo[order].OrderHead.OrderDate.split("", 10)}</h4>
              </div>
              <div className="kvittens__orderNr">
                <span>OrderNummer</span>
                <h4>{orderInfo[order].OrderHead.OrderNo}</h4>
              </div>
            </div>
            <hr />
            <div className="kvittens__productDetails">
                <table className="kvittens__table">
                    <tbody>
                        <tr>
                            <td className="kvittens__tableHeader">Produkt</td>
                        </tr>
                        {Object.keys(orderInfo).map((index) => (
                            <div className="test" key={index.Id}>
                                {orderInfo[0].Items.map((item, i) => (
                                    <tr key={i.Id}>
                                        <td className="kvittens__product">
                                            {item.ProductName}
                                        </td>
                                    </tr>
                                ))}
                            </div>
                        ))}
                    </tbody>
                </table>
                <table className="kvittens__table2">
                    <tbody>
                        <tr>
                            <td className="kvittens__tableHeader2">Artikelnummer</td>
                        </tr>
                        {Object.keys(orderInfo).map((index) => (
                            <div className="test" key={index.Id}>
                                {orderInfo[0].Items.map((item, i) => (
                                    <tr key={i.Id}>
                                        <td className="kvittens_partNo">{item.PartNo}</td>
                                    </tr>
                                ))}
                            </div>
                        ))}
                    </tbody>
                </table>
            </div>
          </div>
        ))}
      </div>
      <div className="kvittens__signatureContainer">
        <div className="kvittens__wrap">
          <span className="kvittens__employee">Signatur (personal)</span>
          <span className="kvittens__customer">Signatur (kund)</span>
          <span className="kvittens__customer">Datum</span>
        </div>
      </div>
    </div>
  );
}

export default Kvittens;

import React, {useEffect, useState} from "react";
import "./style.css";
import html2pdf from "html2pdf.js";
//import ProductRow from "../ProductRow/ProductRow-Customer";
import EditProductRow from "../ProductRow/EditProductRow-Customer";
import ProductRowShopView from "../ProductRow/ProductRow-Shop";
import Comment from "../Comment";
import InvoiceNumbers from "../InvoiceNumbers";
import {useDispatch, useSelector} from "react-redux";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import AddCommentIcon from '@material-ui/icons/AddComment';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Modal from "../Modals/Modal-ConfirmAlert";
import ModalAddNewComment from "../Modals/Modal-AddNewCommentOrder";
import ModalEditComment from "../Modals/Modal-EditCommentOrder";
import ModalDeleteOrderRowConfirmWithButtons from "../Modals/Modal-ConfirmWithButtons";
import ModalConnectOrderToBenefitWindow from "../Modals/Modal-ConnectOrderToBenefitWindow";
import {logout} from "../../Redux/actions/userActions";
import {fetchOrder} from "../../Redux/actions/orderAction";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import ModalAddNewOrderRow from "../Modals/Modal-AddNewOrderRow";
import DatePicker from "react-datepicker";
import {sv} from 'date-fns/esm/locale'
import moment from "moment";
import ModalConfirmWithButtons from "../Modals/Modal-ConfirmWithButtons";
import API from "../../utils/API";
import SelectReact from "react-select";

function OrderForm() {
  const [toggleState, /*setToggleState*/] = useState(1);
  const [expandedCustomerComments, setExpandedCustomerComments] = useState(true);
  const [expandedInternalComments, setExpandedInternalComments] = useState(true);
  const [editOrderDate, setEditOrderDate] = useState(false);
  const [editCustomerInfo, setEditCustomerInfo] = useState(false);
  const [editOrderRow, setEditOrderRow] = useState(false);
  const [title, setTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalAddNewComment, setShowModalAddNewComment] = useState(false);
  const [showModalEditComment, setShowModalEditComment] = useState(false);
  const [showModalAddNewOrderRow, setShowModalAddNewOrderRow] = useState(false);
  const [showModalDeleteOrderRowConfirmWithButtons, setShowModalDeleteOrderRowConfirmWithButtons] = useState(false);
  const [showModalConnectOrderToBenefitWindow, setShowModalConnectOrderToBenefitWindow] = useState(false);
  const [showModalDeleteOrderConfirmWithButtons, setShowModalDeleteOrderConfirmWithButtons] = useState(false);
  const [theOrderNumber, setTheOrderNumber] = useState("");
  const [theOrderID, setTheOrderID] = useState(undefined);
  const [theLineNumber, setTheLineNumber] = useState("");
  const [theCommentID, setTheCommentID] = useState(undefined);
  const [theCommentText, setTheCommentText] = useState(undefined);
  const [orderDate, setOrderDate] = useState(undefined);
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerZipCode, setCustomerZipCode] = useState("");
  const [customerCompany, setCustomerCompany] = useState("");
  const [deleteOrderRowConfirm, setDeleteOrderRowConfirm] = useState(false);
  const [deleteOrderConfirm, setDeleteOrderConfirm] = useState(false);
  const [theEditedItem, setTheEditedItem] = useState({});
  const [ignoreRules, setIgnoreRules] = useState(false);
  const [adminAccess, setAdminAccess] = useState(false);
  const [shopsOptions, setShopsOptions] = useState([]);
  const [/*theShopName*/, setTheShopName] = useState("");
  const [theShopID, setTheShopID] = useState("");
  const [approvedForReturn, setApprovedForReturn] = useState(false);
  const [returned, setReturned] = useState(false);
  const { orderInfo } = useSelector((state) => state.order);
  const [items, setItems] = useState([]);
  const [itemsSalePrices, setItemsSalePrices] = useState(new Map());
  const [saveOrderRowChanges, setSaveOrderRowChanges] = useState(false);
  const [ableToRestoreOrder, setAbleToRestoreOrder] = useState(false);
  //const [totalGrossAmount, setTotalGrossAmount] = useState(orderInfo[0].OrderHead.TotalGrossAmount.toLocaleString());
  //const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(orderInfo[0].OrderHead.TotalInvoiceAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
  const [totalGrossAmount, setTotalGrossAmount] = useState("");
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState("");

  const orderFormContainer = document.getElementsByClassName("ordercentral__larsson__div")[0];

  const dispatch = useDispatch();

  const customStyles = {
    control: (base) => ({
      ...base,
      position: "relative",
      left: "30px",
      height: "30px",
      width: "250px",
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  const muiTheme = createTheme({
    overrides: {
      MuiStepper: {
        root: {
          padding: "10px 24px",
        }
      },
      MuiStepIcon: {
        root: {
          color: "var(--sportson-grey-color)",
          '&$active': {
            color: "var(--sportson-grey-color)",
          },
          '&$completed': {
            color: "var(--sportson-green-color) !important",
          },
        },
      },
      MuiStepConnector: {
        active: {
          border: "1px solid var(--sportson-grey-color)",
        },
        completed: {
          border: "1px solid var(--sportson-green-color) !important",
        },
      },
    }
  });

  const closeModalHandler = () => setShowModal(false);
  const closeModalAddNewCommentHandler = () => setShowModalAddNewComment(false);
  const closeModalEditCommentHandler = () => setShowModalEditComment(false);
  const closeModalAddNewOrderRowHandler = () => setShowModalAddNewOrderRow(false);
  const closeModalDeleteOrderRowConfirmWithButtonsHandler = () => setShowModalDeleteOrderRowConfirmWithButtons(false);
  const closeModalConnectOrderToBenefitWindowHandler = () => setShowModalConnectOrderToBenefitWindow(false);
  const setDeleteOrderRowConfirmHandler = () => setDeleteOrderRowConfirm(true);
  const setDeleteOrderConfirmHandler = () => setDeleteOrderConfirm(true);
  const closeModalDeleteOrderConfirmWithButtonsHandler = () => setShowModalDeleteOrderConfirmWithButtons(false);

  useEffect(() => {
    const getLoggedInUserRights = async () => {
      await API.getLoggedInUserRights()
          .then((response) => {
            setAdminAccess(response.data.adminaccess);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void getLoggedInUserRights();
    const getAllShops = async () => {
      await API.getShopList("")
          .then((response) => {
            let shops = [];

            shops.push({ value: "", label: "Välj butik", isDisabled: true });
            response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((shop) => {
              shops.push({ value: shop.Code, label: shop.Name });
            });
            setShopsOptions(shops);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void getAllShops();

  }, []);

  useEffect(() => {
    let salePrices = new Map();

    const getSalePrice = async (theArtNr) => {
      await API.getArticle(theArtNr)
          .then((response) => {
            if (response.status === 200){
              salePrices.set(theArtNr, response.data.SalePrice);
            } else if (response.status >= 300) {
              setTitle(`${response.data.message} - ${response.data.details}`);
              setShowModal(true);
            } else if (response.status === 401) {
              dispatch(logout());
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    orderInfo && orderInfo[0]?.Items?.length > 0 && orderInfo[0].Items.map((item) => {
      void getSalePrice(item.PartNo);
    });
    if(orderInfo && orderInfo[0]?.Items?.length > 0)
      setItemsSalePrices(salePrices);

    setSaveOrderRowChanges(false);
    setItems([]);

    const checkValidateOrderRestore = async (orderID) => {
      await API.validateOrderRestore(orderID)
          .then((response) => {
            if(response.status === 200){
              setAbleToRestoreOrder(response.data.ableToRestore);
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };

    if(orderInfo && orderInfo[0]?.OrderHead?._id) {
      void checkValidateOrderRestore(orderInfo[0]?.OrderHead?._id);
      setTotalGrossAmount(orderInfo[0].OrderHead.TotalGrossAmount);
      setTotalInvoiceAmount(orderInfo[0].OrderHead.TotalInvoiceAmount);
    }

  },[orderInfo]);

  const back = (e) => {
    e.preventDefault();

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    void dispatch(fetchOrder(""));
  }

  const validateOrder = async (orderNumber) => {
    await API.validateOrder(orderNumber)
        .then((response) => {
          if(response.status === 200){
            setShowModal(true);
            setTitle("Validering av order okej");
          } else if (response.status >= 300) {
            setShowModal(true);
            setTitle(`${response.data.message} - ${response.data.details}`);
          } else if (response.status === 401) {
            dispatch(logout());
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
  };

  const saveOrderHandler = (e, orderID, orderNumber) => {
    e.preventDefault();

    setEditCustomerInfo(!editCustomerInfo)

    const data = {
      NewOrderDate: moment(orderDate).format("yyyy-MM-DD"),
      IgnoreRules: ignoreRules,
    };

    API.updateOrderHead(data, orderID).then((response) => {
      if (response.status === 200){
        dispatch(fetchOrder(encodeURI(orderNumber))).then(() => {
          document.getElementsByClassName("orderForm__title")[0].scrollIntoView({behavior: "instant"});
        });
      } else if (response.status >= 300) {
        setShowModal(true);
        setTitle(`${response.data.message} - ${response.data.details}`);
      } else if (response.status === 401) {
        void dispatch(logout());
      }
      //validateOrder(orderNumber);

      setEditCustomerInfo(false);
    });
  }

  const saveOrderDateHandler = (e,orderID, orderNumber) => {
    e.preventDefault();

    const data = {
      NewOrderDate: moment(orderDate).format("yyyy-MM-DD"),
    };

    API.updateOrderHead(data, orderID).then((response) => {
      if (response.status === 200){
        // TODO separate duplicate code below to a function:
        dispatch(fetchOrder(encodeURI(orderNumber))).then(() => {
          document.getElementsByClassName("orderForm__title")[0].scrollIntoView({behavior: "instant"});
        });
      } else if (response.status >= 300) {
        setShowModal(true);
        setTitle(`${response.data.message} - ${response.data.details.message}`);
      } else if (response.status === 401) {
        void dispatch(logout());
      }
      setEditOrderDate(!editOrderDate);
    });
    void validateOrder(orderNumber);
  }

  /* Commented out code not in use for now
  const saveCustomerInfoHandler = (e, orderNumber, firstName, lastName) => {
    e.preventDefault();

    setEditCustomerInfo(!editCustomerInfo)

    const data = {
      FirstName: firstName,
      LastName: lastName,
      Company: customerCompany,
    };

    API.updateCollatedOrder(data, orderNumber).then((response) => {
      if (response.status === 200){

      } else if (response.status >= 300) {
        setShowModal(true);
        setTitle(`${response.data.message} - ${response.data.details.message}`);
      } else if (response.status === 401) {
        dispatch(logout());
      }
    });
  }
   */

  const  handleSetOrder = (orderID, orderNumber) => {
    setTheOrderID(orderID);
    setTheOrderNumber(orderNumber);
  }

  const handleOnChangeShop = (selectedOption) => {

    if (selectedOption){
      setTheShopName(selectedOption.name);
      setTheShopID(selectedOption.value);
    } else {
      setTheShopName("");
      setTheShopID("");
    }
  }

  const addNewCommentHandler = (e, orderID, orderNumber) => {
    e.preventDefault();

    handleSetOrder(orderID, orderNumber);
    setShowModalAddNewComment(true);
    setTitle("Lägg till kommentar");
  }

  const editCommentHandler = (e, orderID, orderNumber, commentID, commentText) => {
    e.preventDefault();

    handleSetOrder(orderID, orderNumber);
    setTheCommentID(commentID);
    setTheCommentText(commentText);
    setShowModalEditComment(true);
    setTitle("Ändra kommentar");
  }

  const deleteCommentHandler = (e, orderID, orderNumber, commentID) => {
    e.preventDefault();

    const data = {
      CommentId: commentID,
    };

    API.deleteCommentOnOrder(data, orderID).then((response) => {
      if (response.status === 200){
        dispatch(fetchOrder(encodeURI(orderNumber))).then(() => {
          document.getElementsByClassName("orderForm__customerComments")[0].scrollIntoView({behavior: "instant"});
        });
      } else if (response.status === 401) {
        void dispatch(logout());
      }
    });
  }

  const addNewOrderRowHandler = (e, orderID, orderNumber) => {
    e.preventDefault();

    handleSetOrder(orderID, orderNumber);
    setShowModalAddNewOrderRow(true);
    setTitle("Lägg till produkt");

  }

  const connectOrderToBenefitWindowHandler = (e, orderID, orderNumber) => {
    e.preventDefault();

    handleSetOrder(orderID, orderNumber);
    setShowModalConnectOrderToBenefitWindow(true);
    setTitle("Koppla order till beställningsfönster");
  }

  const saveEditOrderRowHandler = (e, orderID, orderNumber) => {
    e.preventDefault();

    const item = [theEditedItem];
    setSaveOrderRowChanges(false);

    API.updateOrderItems(item, orderID).then((response) => {
      if (response.status === 200){
        API.getOrder(orderNumber).then((response) => {
          if (response.status === 200){
            setTotalGrossAmount(response.data[0].OrderHead.TotalGrossAmount);
            setTotalInvoiceAmount(response.data[0].OrderHead.TotalInvoiceAmount);
            setItems(response.data[0].Items);
            setSaveOrderRowChanges(true);
          }
        });
      } else if (response.status === 401) {
        void dispatch(logout());
      }
    });

    setEditOrderRow(!editOrderRow);
    //validateOrder(orderNumber);
  }

  /* Commented out code not in use for now
  const cancelEditOrderRowHandler = (e) => {
    e.preventDefault();

    setEditOrderRow(!editOrderRow);
  }
   */

  const deleteOrderHandler = (e, orderID, orderNumber) => {
    e.preventDefault();

    handleSetOrder(orderID, orderNumber);
    if (!orderInfo[0].OrderHead?.MarkedForDeletion){
      setTitle(`${orderInfo[0].OrderHead?.OuCompleteGenerated ? "VARNING OU HAR GENERERATS PÅ ORDERN - Vill du verkligen makulera ordern?" : "Skall ordern makuleras?"}`);
      setShowModalDeleteOrderConfirmWithButtons(true);
    } else if (orderInfo[0].OrderHead?.MarkedForDeletion) {
        setTitle("Ordern är redan makulerad");
        setShowModal(true);
    }
  }

  const restoreOrderHandler = (e, orderID, orderNumber) => {
    e.preventDefault();

    API.restoreOrder(orderID).then((response) => {
      if (response.status === 201) {
        dispatch(fetchOrder(encodeURI(orderNumber))).then(() => {
          document.getElementsByClassName("orderForm__title")[0].scrollIntoView({behavior: "instant"});
        });
      } else if (response.status >= 300) {
        setShowModal(true);
        setTitle(`${response.data.message} - ${response.data.details.message}`);
      } else if (response.status === 401) {
        void dispatch(logout());
      }
    });
  }

  useEffect(() => {
    if (deleteOrderConfirm){
      const data = {
        MarkedForDeletion: true,
      };

      API.updateOrderHead(data, theOrderID).then((response) => {
        if (response.status === 200) {
          dispatch(fetchOrder(encodeURI(theOrderNumber))).then(() => {
            document.getElementsByClassName("orderForm__title")[0].scrollIntoView({behavior: "instant"});
          });
        } else if (response.status >= 300) {
          setShowModal(true);
          setTitle(`${response.data.message} - ${response.data.details.message}`);
        } else if (response.status === 401) {
          void dispatch(logout());
        }
      });
      //validateOrder(theOrderNumber);
    }
    setDeleteOrderConfirm(false);
  }, [deleteOrderConfirm]);

  const deleteOrderRowHandler = (e, orderID, orderNumber, lineNumber) => {
    e.preventDefault();

    handleSetOrder(orderID, orderNumber);
    setTheLineNumber(lineNumber);
    setTitle("Vill du verkligen ta bort orderraden?");
    setShowModalDeleteOrderRowConfirmWithButtons(true);
  }

  const undoDeletedOrderRowHandler = (e, orderID, orderNumber, index) => {
    e.preventDefault();

    const item =  items[index] ? items[index] : orderInfo[0].Items[index];

    setSaveOrderRowChanges(false);

    item.isDeleted = false;

    API.updateOrderItems([item], orderID).then((response) => {
      if (response.status === 200){
        API.getOrder(orderNumber).then((response) => {
          if (response.status === 200){
            setTotalGrossAmount(response.data[0].OrderHead.TotalGrossAmount);
            setTotalInvoiceAmount(response.data[0].OrderHead.TotalInvoiceAmount);
            setItems(response.data[0].Items);
            setSaveOrderRowChanges(true);
          }
        })
      } else if (response.status === 401) {
        void dispatch(logout());
      }
    });

    setEditOrderRow(!editOrderRow);
    //validateOrder(orderNumber);

  }

  useEffect(() => {

    if (deleteOrderRowConfirm){
      setSaveOrderRowChanges(false);
      const data = [
        {
          LineNumber: theLineNumber,
        }
      ];

      API.deleteOrderItems(data, theOrderID).then((response) => {
        if (response.status === 200){
          API.getOrder(theOrderNumber).then((response) => {
            if (response.status === 200){
              setTotalGrossAmount(response.data[0].OrderHead.TotalGrossAmount);
              setTotalInvoiceAmount(response.data[0].OrderHead.TotalInvoiceAmount);
              setItems(response.data[0].Items);
              setSaveOrderRowChanges(true);
            }
          })
        } else if (response.status === 401) {
          void dispatch(logout());
        }
      });
      //validateOrder(theOrderNumber);
    }
    setDeleteOrderRowConfirm(false);
  }, [deleteOrderRowConfirm]);

  useEffect(() => {

    if (orderInfo && orderInfo[0]?.OrderHead.MarkedForDeletion)
      orderFormContainer?.classList.add("orderForm__ContainerMarkedForDeletion");
    else {
      orderFormContainer?.classList.remove("orderForm__ContainerMarkedForDeletion");
    }
  }, [orderInfo]);

  useEffect(() => {
    if(theShopID && theShopID !== ""){
      const data = {
        NewShop: theShopID,
      };

      API.updateOrderHead(data, orderInfo[0].OrderHead._id).then((response) => {
        if (response.status === 200){
          dispatch(fetchOrder(encodeURI(orderInfo[0].OrderHead.OrderNo))).then(() => {
            document.getElementsByClassName("orderForm__shop")[0].scrollIntoView({behavior: "instant"});
          });
        } else if (response.status >= 300) {
          setShowModal(true);
          setTitle(`${response.data.message} - ${response.data.details}`);
        } else if (response.status === 401) {
          void dispatch(logout());
        }
        //validateOrder(orderNumber);
      });
    }

  }, [theShopID]);

  useEffect(() => {
    if(orderInfo && orderInfo[0]){
      const data = {
        ApprovedForReturn: approvedForReturn,
      };

      API.updateOrderHead(data, orderInfo[0].OrderHead._id).then((response) => {
        if (response.status === 200){
          dispatch(fetchOrder(encodeURI(orderInfo[0].OrderHead.OrderNo))).then(() => {
            document.getElementsByClassName("orderForm__return")[0].scrollIntoView({behavior: "instant"});
          });
        } else if (response.status >= 300) {
          setShowModal(true);
          setTitle(`${response.data.message} - ${response.data.details}`);
        } else if (response.status === 401) {
          void dispatch(logout());
        }
        //validateOrder(orderNumber);
      });
      }

  }, [approvedForReturn]);

  useEffect(() => {
    if(orderInfo && orderInfo[0]) {
      const data = {
        Returned: returned,
      };

      API.updateOrderHead(data, orderInfo[0].OrderHead._id).then((response) => {
        if (response.status === 200) {
          dispatch(fetchOrder(encodeURI(orderInfo[0].OrderHead.OrderNo))).then(() => {
            document.getElementsByClassName("orderForm__return")[0].scrollIntoView({behavior: "instant"});
          });
        } else if (response.status >= 300) {
          setShowModal(true);
          setTitle(`${response.data.message} - ${response.data.details}`);
        } else if (response.status === 401) {
          void dispatch(logout());
        }
        //validateOrder(orderNumber);
      });
    }

  }, [returned]);

  const pdf = (e) => {
    let order = document.getElementsByClassName("orderForm")[0];
    let buttonOne = document.getElementsByClassName("orderForm__button")[0];
    let buttonTwo = document.getElementsByClassName("orderForm__button")[1];
    let buttonGDPR = document.getElementsByClassName("orderForm__buttonGDPR")[0];
    let sumsContainer = document.getElementsByClassName("orderForm__sumsContainerDiv")[1];
    let saveButtonOne = document.getElementsByClassName("orderForm__saveButton")[0];
    //let saveButtonTwo = document.getElementsByClassName("orderForm__saveButton")[1];
    let deleteButtonOne = document.getElementsByClassName("orderForm__deleteButton")[0];
    let deleteButtonTwo = document.getElementsByClassName("orderForm__deleteButton")[1];
    let editOrderDateButton = document.getElementsByClassName("orderForm__editOrderDateButton")[0];
    // let editCustomerInfoButton = document.getElementsByClassName("orderForm__editCustomerButton")[0]; TODO add back once it is needed
    let addNewCommentButton = document.getElementsByClassName("orderForm__editButton")[0];
    let addNewOrderRowButton = document.getElementsByClassName("orderForm__editButton")[1];
    let deleteRowButton = document.getElementsByClassName("orderForm__editButton")[2];
    // let editRowButton = document.getElementsByClassName("orderForm__editButton")[3];
    let deleteCommentButton = document.getElementsByClassName("orderForm__deleteCommentButton")[0];
    let editCommentButton = document.getElementsByClassName("orderForm__editCommentButton")[0];
    // let connectOrderToBenefitWindowButton = document.getElementsByClassName("orderForm__connectOrderToBenefitWindowButton")[0]; TODO add check to see if there is a button on the specific order
    let editButtons = document.getElementsByClassName("orderForm__editButton");
    let addInvoiceNumberDivs = document.getElementsByClassName("invoiceNumbers__addInvoiceNumberDiv");
    let editInvoiceNumberButtons = document.getElementsByClassName("invoiceNumbers__editInvoiceNumberButton");
    let deleteInvoiceNumberButtons = document.getElementsByClassName("invoiceNumbers__deleteInvoiceNumberButton");
    let shopSelect = document.getElementsByClassName("orderForm__filterSearchSelect")[0];

    let opt = {
      margin: [0, 0.2, 0.2, 0.2],
      filename: 'order.pdf',
      image: {type: 'jpeg', quality: 0.98},
      html2canvas: {scale: 2, width: 1500,},
      jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'}
    };

    e.preventDefault();

    if (adminAccess) {
      // Hide on pdf:
      buttonOne.classList.add("orderForm__hidden");
      buttonTwo.classList.add("orderForm__hidden");
      buttonGDPR.classList.add("orderForm__hidden");
      sumsContainer.classList.add("orderForm__hidden");
      saveButtonOne.classList.add("orderForm__hidden");
      //saveButtonTwo.classList.add("orderForm__hidden");
      deleteButtonOne.classList.add("orderForm__hidden");
      deleteButtonTwo.classList.add("orderForm__hidden");
      editOrderDateButton.classList.add("orderForm__hidden");
      // editCustomerInfoButton.classList.add("orderForm__hidden"); TODO add back once it is needed
      addNewCommentButton.classList.add("orderForm__hidden");
      addNewOrderRowButton.classList.add("orderForm__hidden");
      deleteRowButton.classList.add("orderForm__hidden");
      // editRowButton.classList.add("orderForm__hidden");
      editCommentButton && editCommentButton.classList.add("orderForm__hidden");
      deleteCommentButton && deleteCommentButton.classList.add("orderForm__hidden");
      // connectOrderToBenefitWindowButton.classList.add("orderForm__hidden"); TODO add check to see if there is a button on the specific order
      shopSelect && shopSelect.classList.add("orderForm__hidden");
      for (let button of editButtons) {
        button.classList.add("orderForm__hidden");
      }
      for (let div of addInvoiceNumberDivs) {
        div.classList.add("orderForm__hidden");
      }
      for (let button of editInvoiceNumberButtons) {
        button.classList.add("orderForm__hidden");
      }
      for (let button of deleteInvoiceNumberButtons) {
        button.classList.add("orderForm__hidden");
      }

      html2pdf().from(order).set(opt).save().then(() => {
        buttonOne.classList.remove("orderForm__hidden");
        buttonTwo.classList.remove("orderForm__hidden");
        buttonGDPR.classList.remove("orderForm__hidden");
        sumsContainer.classList.remove("orderForm__hidden");
        saveButtonOne.classList.remove("orderForm__hidden");
        //saveButtonTwo.classList.remove("orderForm__hidden");
        deleteButtonOne.classList.remove("orderForm__hidden");
        deleteButtonTwo.classList.remove("orderForm__hidden");
        editOrderDateButton.classList.remove("orderForm__hidden");
        // editCustomerInfoButton.classList.remove("orderForm__hidden"); TODO add back once it is needed
        addNewCommentButton.classList.remove("orderForm__hidden");
        addNewOrderRowButton.classList.remove("orderForm__hidden");
        deleteRowButton.classList.remove("orderForm__hidden");
        // editRowButton.classList.remove("orderForm__hidden");
        editCommentButton && editCommentButton.classList.remove("orderForm__hidden");
        deleteCommentButton && deleteCommentButton.classList.remove("orderForm__hidden");
        // connectOrderToBenefitWindowButton.classList.remove("orderForm__hidden"); TODO add check to see if there is a button on the specific order
        shopSelect && shopSelect.classList.remove("orderForm__hidden");
        for (let button of editButtons) {
          button.classList.remove("orderForm__hidden");
        }
        for (let div of addInvoiceNumberDivs) {
          div.classList.remove("orderForm__hidden");
        }
        for (let button of editInvoiceNumberButtons) {
          button.classList.remove("orderForm__hidden");
        }
        for (let button of deleteInvoiceNumberButtons) {
          button.classList.remove("orderForm__hidden");
        }
      });
    }
  }

  const pdfGDPR = (e) => {
    let order = document.getElementsByClassName("orderForm")[0];
    let buttonOne = document.getElementsByClassName("orderForm__button")[0];
    let buttonTwo = document.getElementsByClassName("orderForm__button")[1];
    let buttonGDPR = document.getElementsByClassName("orderForm__buttonGDPR")[0];
    let saveButtonOne = document.getElementsByClassName("orderForm__saveButton")[0];
    //let saveButtonTwo = document.getElementsByClassName("orderForm__saveButton")[1];
    let deleteButtonOne = document.getElementsByClassName("orderForm__deleteButton")[0];
    let deleteButtonTwo = document.getElementsByClassName("orderForm__deleteButton")[1];
    let editOrderDateButton = document.getElementsByClassName("orderForm__editOrderDateButton")[0];
    //let editCustomerInfoButton = document.getElementsByClassName("orderForm__editCustomerButton")[0]; TODO add back once it is needed
    let addNewCommentButton = document.getElementsByClassName("orderForm__editButton")[0];
    let addNewOrderRowButton = document.getElementsByClassName("orderForm__editButton")[1];
    let deleteRowButton = document.getElementsByClassName("orderForm__editButton")[2];
    //let editRowButton = document.getElementsByClassName("orderForm__editButton")[3];
    let sumsContainer = document.getElementsByClassName("orderForm__sumsContainerDiv")[1];
    let customerAddress = document.getElementsByClassName("orderForm__customerCardRowItem")[3];
    let customerSSN = document.getElementsByClassName("orderForm__customerCardRowItem")[5];
    let customerPostalCode = document.getElementsByClassName("orderForm__customerCardRowItem")[7];
    let customerEmail = document.getElementsByClassName("orderForm__customerCardRowItem")[9];
    let customerEmployer = document.getElementsByClassName("orderForm__customerCardRowItem")[11];
    let customerPhoneNumber = document.getElementsByClassName("orderForm__customerCardRowItem")[13];
    let deletedArticles = document.getElementsByClassName("productRow__itemIsDeleted");
    let deleteCommentButton = document.getElementsByClassName("orderForm__deleteCommentButton")[0];
    let editCommentButton = document.getElementsByClassName("orderForm__editCommentButton")[0];
    // let connectOrderToBenefitWindowButton = document.getElementsByClassName("orderForm__connectOrderToBenefitWindowButton")[0];
    let editButtons = document.getElementsByClassName("orderForm__editButton");
    let addInvoiceNumberDivs = document.getElementsByClassName("invoiceNumbers__addInvoiceNumberDiv");
    let editInvoiceNumberButtons = document.getElementsByClassName("invoiceNumbers__editInvoiceNumberButton");
    let deleteInvoiceNumberButtons = document.getElementsByClassName("invoiceNumbers__deleteInvoiceNumberButton");
    let shopSelect = document.getElementsByClassName("orderForm__filterSearchSelect")[0];

    let opt = {
      margin:       [0, 0.2, 0.2, 0.2],
      filename:     'order.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 2, width: 1500, },
      jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    e.preventDefault();

    if (adminAccess) {
      // Hide on pdf:
      buttonOne.classList.add("orderForm__hidden");
      buttonTwo.classList.add("orderForm__hidden");
      buttonGDPR.classList.add("orderForm__hidden");
      saveButtonOne.classList.add("orderForm__hidden");
      //saveButtonTwo.classList.add("orderForm__hidden");
      deleteButtonOne.classList.add("orderForm__hidden");
      deleteButtonTwo.classList.add("orderForm__hidden");
      editOrderDateButton.classList.add("orderForm__hidden");
      // editCustomerInfoButton.classList.add("orderForm__hidden"); TODO add back once it is needed
      addNewCommentButton.classList.add("orderForm__hidden");
      addNewOrderRowButton.classList.add("orderForm__hidden");
      deleteRowButton.classList.add("orderForm__hidden");
      // editRowButton.classList.add("orderForm__hidden");
      sumsContainer.classList.add("orderForm__hidden");
      customerAddress.classList.add("orderForm__hidden");
      customerSSN.classList.add("orderForm__hidden");
      customerPostalCode.classList.add("orderForm__hidden");
      customerEmail.classList.add("orderForm__hidden");
      customerEmployer.classList.add("orderForm__hidden");
      customerPhoneNumber.classList.add("orderForm__hidden");
      editCommentButton && editCommentButton.classList.add("orderForm__hidden");
      deleteCommentButton && deleteCommentButton.classList.add("orderForm__hidden");
      // connectOrderToBenefitWindowButton.classList.add("orderForm__hidden"); TODO add check to see if there is a button on the specific order
      shopSelect && shopSelect.classList.add("orderForm__hidden");
      for (let node of deletedArticles) {
        node.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.add("orderForm__hidden");
      }
      for (let button of editButtons) {
        button.classList.add("orderForm__hidden");
      }
      for (let div of addInvoiceNumberDivs) {
        div.classList.add("orderForm__hidden");
      }
      for (let button of editInvoiceNumberButtons) {
        button.classList.add("orderForm__hidden");
      }
      for (let button of deleteInvoiceNumberButtons) {
        button.classList.add("orderForm__hidden");
      }

      html2pdf().from(order).set(opt).save().then(() => {
        buttonOne.classList.remove("orderForm__hidden");
        buttonTwo.classList.remove("orderForm__hidden");
        buttonGDPR.classList.remove("orderForm__hidden");
        saveButtonOne.classList.remove("orderForm__hidden");
        //saveButtonTwo.classList.remove("orderForm__hidden");
        deleteButtonOne.classList.remove("orderForm__hidden");
        deleteButtonTwo.classList.remove("orderForm__hidden");
        editOrderDateButton.classList.remove("orderForm__hidden");
        // editCustomerInfoButton.classList.remove("orderForm__hidden"); TODO add back once it is needed
        addNewCommentButton.classList.remove("orderForm__hidden");
        addNewOrderRowButton.classList.remove("orderForm__hidden");
        deleteRowButton.classList.remove("orderForm__hidden");
        // editRowButton.classList.remove("orderForm__hidden");
        sumsContainer.classList.remove("orderForm__hidden");
        customerAddress.classList.remove("orderForm__hidden");
        customerSSN.classList.remove("orderForm__hidden");
        customerPostalCode.classList.remove("orderForm__hidden");
        customerEmail.classList.remove("orderForm__hidden");
        customerEmployer.classList.remove("orderForm__hidden");
        customerPhoneNumber.classList.remove("orderForm__hidden");
        editCommentButton && editCommentButton.classList.remove("orderForm__hidden");
        deleteCommentButton && deleteCommentButton.classList.remove("orderForm__hidden");
        // connectOrderToBenefitWindowButton.classList.remove("orderForm__hidden"); TODO add check to see if there is a button on the specific order
        shopSelect && shopSelect.classList.remove("orderForm__hidden");
        for (let node of deletedArticles) {
          node.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.remove("orderForm__hidden");
        }
        for (let button of editButtons) {
          button.classList.remove("orderForm__hidden");
        }
        for (let div of addInvoiceNumberDivs) {
          div.classList.remove("orderForm__hidden");
        }
        for (let button of editInvoiceNumberButtons) {
          button.classList.remove("orderForm__hidden");
        }
        for (let button of deleteInvoiceNumberButtons) {
          button.classList.remove("orderForm__hidden");
        }
      });
    }
  }

  if (orderInfo !== undefined) {
    return (
      <div className="orderForm">
        {orderInfo && Object.keys(orderInfo).map((order) => (
          <div key={order.Id}>
            <div className="orderForm__Container">
              <div className="orderForm__header">
                {adminAccess && orderInfo[order] && (<button
                    onClick={(e) => saveOrderHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo)}
                    className="orderForm__saveButton">Spara</button>)}
                {adminAccess && orderInfo[order] && (<button
                    onClick={(e) => deleteOrderHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo)}
                    className="orderForm__deleteButton">Makulera</button>)}
                {adminAccess && orderInfo[order] && (
                    <button onClick={(e) => pdf(e)} className="orderForm__button">Skapa PDF</button>)}
                {adminAccess && orderInfo[order] && (
                    <button onClick={(e) => pdfGDPR(e)} className="orderForm__buttonGDPR">Skapa GDPR-PDF</button>)}
                {adminAccess && orderInfo[order] && (
                    <button onClick={(e) => back(e)} className="orderForm__button">Tillbaka</button>)}
                <div className="orderForm__latestChanged"><span>Senaste ändrad: <CalendarTodayIcon
                    className="orderForm__icons"/> {orderInfo[order]?.OrderHead?.LastChange ? orderInfo[order].OrderHead.LastChange.datechanged.slice(0, 10) : "-"}<AccessTimeIcon
                    className="orderForm__icons"/> {orderInfo[order]?.OrderHead?.LastChange ? orderInfo[order].OrderHead.LastChange.datechanged.slice(11, 16) : "-"}<PersonOutlineIcon
                    className="orderForm__icons"/> {orderInfo[order]?.OrderHead?.LastChange ? orderInfo[order].OrderHead.LastChange.name : "-"}</span>
                </div>
                <div className="orderForm__title">
                  {/* TODO Add if security agreement or not in the OrderHead below: */}
                  <h1>ORDERUPPGIFTER</h1>{!orderInfo[order].OrderHead.SentToErp ? <h1>Ej skickad till kassan</h1> : ""}
                  <h1>Trygghetspaket: {orderInfo[order].OrderHead?.SecurityAgreement}</h1>
                  <h1>Avtal: {orderInfo[order].OrderHead?.AgreementName}</h1><h1>Avtal
                  uppladdat: {orderInfo[order].OrderHead?.AgreementUploaded}</h1>
                  <h1>{orderInfo[order].OrderHead?.ApplicationData && orderInfo[order].OrderHead.ApplicationData && orderInfo[order].OrderHead.ApplicationData.SiteOwner} {orderInfo[order].OrderHead?.ApplicationData && orderInfo[order].OrderHead.ApplicationData.CountryCode}</h1>
                  <div className="orderForm__buttonWrap">
                    <h3 className="orderForm__orderType">Ordertyp: <span>{orderInfo[order]?.OrderHead?.OrderType}</span>
                    </h3>
                    {/*
                    <PrintComponent />
                    <button
                      style={{
                        width: "100px",
                        height: "40px",
                        fontSize: "12px",
                        borderRadius: "5px",
                      }}
                      className={
                        toggleState === 1
                          ? "orderForm__content active-content"
                          : "orderForm__content"
                      }
                    >
                      Spara
                    </button>
                    <button
                      style={{
                        width: "100px",
                        height: "40px",
                        fontSize: "12px",
                        borderRadius: "5px",
                      }}
                      className={
                        toggleState === 2
                          ? "orderForm__content active-content"
                          : "orderForm__content"
                      }
                    >
                      Skicka
                    </button>
                    */}
                  </div>
                </div>
                {orderInfo && orderInfo[order].OrderHead.OrderType !== "Weborder" && orderInfo[order].OrderHead.FailedRulesCheck &&
                    <div className="orderForm__failedRulesContainer">
                      <h4>Regelbrott</h4>
                      <h5 dangerouslySetInnerHTML={{__html: orderInfo[order].OrderHead.FailedRulesMessages}}></h5>
                      <div className="orderForm__ignoreRulesDiv">
                        <input onClick={(e) => setIgnoreRules(e.target.checked)} type="checkbox" id="ignoreRules"
                               name="ignoreRules" defaultChecked={orderInfo[order].OrderHead.IgnoreRules}/>
                        {!ignoreRules ? <p className="orderForm__checkboxText"><b>Ignorera regelbrott</b></p> :
                            <p className="orderForm__checkboxText orderForm__checkboxTextBackgroundYellow">Ignorera
                              regelbrott</p>}
                      </div>
                    </div>}
                <div className="orderForm__subHeader">
                  <div className="orderForm__orderContainer">
                    <div className="orderForm__orderNr">
                      <span>Ordernummer</span>
                      <h3>{orderInfo[order].OrderHead?.OrderNo}</h3>
                    </div>
                    <div className="orderForm__datum">
                      <span>Orderdatum</span>
                      {!editOrderDate && !orderInfo[order].OrderHead.NewOrderDate && (
                          <h3>{orderInfo[order].OrderHead.OrderDate.substring(0, 10)}</h3>)}
                      {!editOrderDate && orderInfo[order].OrderHead.NewOrderDate && (<>
                        <h3>{orderInfo[order].OrderHead.NewOrderDate.substring(0, 10)}</h3>
                        <h4>{orderInfo[order].OrderHead.OrderDate.substring(0, 10)} (Orginaldatum)</h4></>)}
                      {editOrderDate && !orderInfo[order].OrderHead.NewOrderDate && (
                          <DatePicker className="orderForm__inputTypeDate" dateFormat="yyyy-MM-dd" selected={orderDate}
                                      onChange={(date) => setOrderDate(date)}
                                      value={orderDate !== undefined && orderDate !== "" ? orderDate : orderInfo[order].OrderHead.OrderDate.substring(0, 10)}
                                      id="orderDate" name="orderDate" locale={sv} required/>)}
                      {editOrderDate && orderInfo[order].OrderHead.NewOrderDate && (
                          <DatePicker className="orderForm__inputTypeDate" dateFormat="yyyy-MM-dd" selected={orderDate}
                                      onChange={(date) => setOrderDate(date)}
                                      value={orderDate !== undefined && orderDate !== "" ? orderDate : orderInfo[order].OrderHead.NewOrderDate.substring(0, 10)}
                                      id="orderDate" name="orderDate" locale={sv} required/>)}
                      {adminAccess && orderInfo && !editOrderDate && (
                          <button onClick={() => setEditOrderDate(!editOrderDate)}
                                  className="orderForm__editOrderDateButton"><span
                              className="orderForm__iconAndText"><EditIcon className="orderForm__icons"/></span>
                          </button>)}
                      {adminAccess && orderInfo && editOrderDate && (<>
                        <button
                            onClick={(e) => saveOrderDateHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo)}
                            className="orderForm__editOrderDateButton"><span
                            className="orderForm__iconAndText"><CheckCircleOutlineIcon
                            className="orderForm__icons"/></span></button>
                        <button onClick={() => setEditOrderDate(!editOrderDate)}
                                className="orderForm__editOrderDateButton"><span
                            className="orderForm__iconAndText"><CancelIcon className="orderForm__icons"/></span>
                        </button>
                      </>)}
                    </div>
                    {orderInfo && orderInfo[order].OrderHead.MarkedForDeletion ?
                        <div className="orderForm__attention"><span>Orderstatus</span>
                          <h3>{orderInfo[order].OrderHead?.OrderStatusText}</h3></div> :
                        <div className="orderForm__status"><span>Orderstatus</span>
                          <h3>{orderInfo[order].OrderHead?.OrderStatusText}</h3></div>}
                  </div>
                  {orderInfo && orderInfo[order].OrderHead.OrderType !== "Weborder" && <div>
                    {orderInfo && orderInfo[order].OrderHead.ConnectedWindowId ?
                        <div className="orderForm__deliveryContainer"><span>Beställningsfönster</span>
                          <h3>{orderInfo[order].OrderHead.ConnectedWindowName}</h3></div> :
                        <div className="orderForm__attention"><span>Beställningsfönster</span>
                          <h3>{orderInfo[order].OrderHead.ConnectedWindowName}
                            <button className="orderForm__connectOrderToBenefitWindowButton"
                                    onClick={(e) => connectOrderToBenefitWindowHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo)}>Koppla
                              order till fönster
                            </button>
                          </h3>
                        </div>}
                  </div>}
                  <div className="orderForm__companyContainer">
                    <span>Företag</span>
                    <h3>{orderInfo[order].OrderHead?.Customer.Company}</h3>
                  </div>
                  <div className="orderForm__deliveryContainer">
                    <span>Enheter/kostnadsställen</span>
                    <h3>{orderInfo[order].OrderHead?.Infos.Personalcyklar !== undefined && orderInfo[order].OrderHead.Infos.Personalcyklar.enhet_kostnadsstalle}</h3>
                  </div>
                  <div className="orderForm__deliveryContainer">
                    <span>Leveranssätt</span>
                    <h3>{orderInfo[order].OrderHead.Infos?.Personalcyklar !== undefined && orderInfo[order].OrderHead.Infos.Personalcyklar.leverans_alternativ !== "" ? orderInfo[order].OrderHead.Infos.Personalcyklar.leverans_alternativ : ""}</h3>
                  </div>
                </div>
                <MuiThemeProvider theme={muiTheme}>
                  <Stepper>
                    {<Step key={"Status"} completed><StepLabel>Order lagd</StepLabel></Step>}
                    {orderInfo[order].OrderHead?.BgGenerated ? (
                        <Step key={"Status"} completed><StepLabel>BG skapat</StepLabel></Step>) : (
                        <Step key={"Status"} active><StepLabel>BG skapat</StepLabel></Step>)}
                    {!orderInfo[order].OrderHead?.RejectedBg && orderInfo[order].OrderHead.ApprovedBg ? (
                        <Step key={"Status"} completed><StepLabel>BG
                          godkänd</StepLabel></Step>) : !orderInfo[order].OrderHead.RejectedBg && (
                        <Step key={"Status"} active={orderInfo[order].OrderHead.BgGenerated}><StepLabel>BG
                          godkänd</StepLabel></Step>)}
                    {orderInfo[order].OrderHead?.RejectedBg && (
                        <Step key={"Status"} className="orderForm__rejectedBG"><StepLabel
                            className="orderForm__rejectedBGStepLabel">BG ej godkänd</StepLabel></Step>)}
                    {orderInfo[order].OrderHead?.KalGenerated ? (
                        <Step key={"Status"} completed><StepLabel>KAL</StepLabel></Step>) : (<Step key={"Status"}
                                                                                                   active={orderInfo[order].OrderHead.ApprovedBg}><StepLabel>KAL</StepLabel></Step>)}
                    {orderInfo[order].OrderHead?.OuCompleteGenerated ? (
                        <Step key={"Status"} completed><StepLabel>OU-Komplett</StepLabel></Step>) : (
                        <Step key={"Status"}
                              active={orderInfo[order].OrderHead.KalGenerated}><StepLabel>OU-Komplett</StepLabel></Step>)}
                  </Stepper>
                </MuiThemeProvider>
                <div className="orderForm__customer">
                  <div className="orderForm__customerCard">
                    {!editCustomerInfo && orderInfo[order].OrderHead && (
                        <>
                          <div className="orderForm__customerCardRow">
                            <div className="orderForm__customerCardRowItem"><span>Kundnamn</span></div>
                            <div className="orderForm__customerCardRowItem">
                              <h3>{`${orderInfo[order].OrderHead.Customer.FirstName} ${orderInfo[order].OrderHead.Customer.LastName}`}</h3>
                            </div>
                            <div className="orderForm__customerCardRowItem"><span>Adress</span></div>
                            <div className="orderForm__customerCardRowItem">
                              <h3>{orderInfo[order].OrderHead.Customer.Address}</h3></div>
                          </div>
                          <div className="orderForm__customerCardRow">
                            <div className="orderForm__customerCardRowItem"><span>Personnummer</span></div>
                            <div className="orderForm__customerCardRowItem">
                              <h3>{orderInfo[order].OrderHead.Customer.SocialSecNoVerifyStatus ? (<span
                                  className="orderForm__customerSocialSecNumVerified">{orderInfo[order].OrderHead.Customer.SocialSecNo}
                                <CheckIcon className="orderForm__icons"/> {"VERIFIERAT"}</span>) : (<span
                                  className="orderForm__customerSocialSecNumNotVerified">{orderInfo[order].OrderHead.Customer.SocialSecNo}
                                <CloseIcon className="orderForm__icons"/> {"EJ VERIFIERAT"}</span>)}</h3></div>
                            <div className="orderForm__customerCardRowItem"><span>Postort</span></div>
                            <div className="orderForm__customerCardRowItem">
                              <h3>{`${orderInfo[order].OrderHead.Customer.ZipCode} ${orderInfo[order].OrderHead.Customer.City}`}</h3>
                            </div>
                          </div>
                          <div className="orderForm__customerCardRow">
                            <div className="orderForm__customerCardRowItem"><span>E-postadress</span></div>
                            <div className="orderForm__customerCardRowItem">
                              <h3>{orderInfo[order].OrderHead.Customer.Email}</h3></div>
                            <div className="orderForm__customerCardRowItem"><span>Arbetsgivare</span></div>
                            <div className="orderForm__customerCardRowItem">
                              <h3>{orderInfo[order].OrderHead.Customer.Company !== undefined ? orderInfo[order].OrderHead.Customer.Company : "-"}</h3>
                            </div>
                          </div>
                          <div className="orderForm__customerCardRow">
                            <div className="orderForm__customerCardRowItem"><span>Telefonnummer</span></div>
                            <div className="orderForm__customerCardRowItem">
                              <h3>{orderInfo[order].OrderHead.Customer.Phone}</h3></div>
                          </div>
                        </>
                    )}
                    {editCustomerInfo && orderInfo[order].OrderHead && (
                        <>
                          <div className="orderForm__customerCardRow">
                            <div className="orderForm__customerCardRowItem"><span>Kundnamn</span></div>
                            <div className="orderForm__customerCardRowItem"><input type="text" id="customerName"
                                                                                   name="customerName"
                                                                                   onChange={(e) => setCustomerName(e.target.value)}
                                                                                   value={customerName !== undefined ? customerName : `${orderInfo[order].OrderHead.Customer.FirstName} ${orderInfo[order].OrderHead.Customer.LastName}`}/>
                            </div>
                            <div className="orderForm__customerCardRowItem"><span>Adress</span></div>
                            <div className="orderForm__customerCardRowItem"><input type="text" id="customerAddress"
                                                                                   name="customerAddress"
                                                                                   onChange={(e) => setCustomerAddress(e.target.value)}
                                                                                   value={customerAddress !== undefined ? customerAddress : orderInfo[order].OrderHead.Customer.Address}/>
                            </div>
                          </div>
                          <div className="orderForm__customerCardRow">
                            <div className="orderForm__customerCardRowItem"><span>Personnummer</span></div>
                            <div className="orderForm__customerCardRowItem">
                              <h3>{orderInfo[order].OrderHead.Customer.SocialSecNoVerifyStatus ? (<span
                                  className="orderForm__customerSocialSecNumVerified">{orderInfo[order].OrderHead.Customer.SocialSecNo}
                                <CheckIcon className="orderForm__icons"/> {"VERIFIERAT"}</span>) : (<span
                                  className="orderForm__customerSocialSecNumNotVerified">{orderInfo[order].OrderHead.Customer.SocialSecNo}
                                <CloseIcon className="orderForm__icons"/> {"EJ VERIFIERAT"}</span>)}</h3></div>
                            <div className="orderForm__customerCardRowItem"><span>Postort</span></div>
                            <div className="orderForm__customerCardRowItem"><input type="text" id="customerZipCode"
                                                                                   name="customerZipCode"
                                                                                   onChange={(e) => setCustomerZipCode(e.target.value)}
                                                                                   value={customerZipCode !== undefined ? customerZipCode : `${orderInfo[order].OrderHead.Customer.ZipCode} ${orderInfo[order].OrderHead.Customer.City}`}/>
                            </div>
                          </div>
                          <div className="orderForm__customerCardRow">
                            <div className="orderForm__customerCardRowItem"><span>E-postadress</span></div>
                            <div className="orderForm__customerCardRowItem"><input type="text" id="customerEmail"
                                                                                   name="customerEmail"
                                                                                   onChange={(e) => setCustomerEmail(e.target.value)}
                                                                                   value={customerEmail !== undefined ? customerEmail : orderInfo[order].OrderHead.Customer.Email}/>
                            </div>
                            <div className="orderForm__customerCardRowItem"><span>Arbetsgivare</span></div>
                            <div className="orderForm__customerCardRowItem"><input type="text" id="customerCompany"
                                                                                   name="customerCompany"
                                                                                   onChange={(e) => setCustomerCompany(e.target.value)}
                                                                                   value={customerCompany !== undefined ? customerCompany : (orderInfo[order].OrderHead.Customer.Company ? orderInfo[order].OrderHead.Customer.Company : "-")}/>
                            </div>
                          </div>
                          <div className="orderForm__customerCardRow">
                            <div className="orderForm__customerCardRowItem"><span>Telefonnummer</span></div>
                            <div className="orderForm__customerCardRowItem"><input type="text" id="customerPhone"
                                                                                   name="customerPhone"
                                                                                   onChange={(e) => setCustomerPhone(e.target.value)}
                                                                                   value={customerPhone !== undefined ? customerPhone : orderInfo[order].OrderHead.Customer.Phone}/>
                            </div>
                          </div>
                        </>
                    )}
                    {/* Not in use for now
                    { orderInfo && !editCustomerInfo && (<button onClick={ (e) => setEditCustomerInfo(!editCustomerInfo) } className="orderForm__editCustomerButton"><span className="orderForm__iconAndText"><EditIcon className="orderForm__icons" /></span></button>) }
                    { orderInfo && editCustomerInfo && (<> <button onClick={ (e) => saveCustomerInfoHandler(e, orderInfo[order].OrderHead.OrderNo, orderInfo[order].OrderHead.Customer.FirstName, orderInfo[order].OrderHead.Customer.LastName) } className="orderForm__editCustomerButton"><span className="orderForm__iconAndText"><CheckCircleOutlineIcon className="orderForm__icons" /></span></button> <button onClick={ (e) => setEditCustomerInfo(!editCustomerInfo) } className="orderForm__editCustomerButton"><span className="orderForm__iconAndText"><CancelIcon className="orderForm__icons" /></span></button> </>) }
                    */}
                  </div>
                </div>
                <div className="orderForm__wholeWidthHorizontalLine"></div>
                {adminAccess && <div className="orderForm__return">
                  <div className="orderForm__checkboxDiv">
                    <p>Godkänd för retur</p><input id="approvedForReturn" name="approvedForReturn" className="orderForm__checkbox" type="checkbox" onClick={(e) => setApprovedForReturn(e.target.checked)} defaultChecked={orderInfo[order].OrderHead.ApprovedForReturn}/>
                  </div>
                  <div className="orderForm__checkboxDiv">
                    <p>Returnerad</p><input id="returned" name="returned" className="orderForm__checkbox" type="checkbox" onClick={(e) => setReturned(e.target.checked)} defaultChecked={orderInfo[order].OrderHead.Returned} disabled={orderInfo[order].OrderHead.Returned || returned}/>
                  </div>
                  {orderInfo && orderInfo[order].OrderHead.Deleted || orderInfo[order].OrderHead.MarkedForDeletion && ableToRestoreOrder && (<button
                      onClick={(e) => restoreOrderHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo)}
                      className="orderForm__editButton orderForm__restoreButton">
                    Återställ order</button>)}
                </div>}
                <div className="orderForm__wholeWidthHorizontalLine"></div>
                <div className="orderForm__shop">
                  {adminAccess && <SelectReact
                      className="itSupport__select orderForm__filterSearchSelect"
                      name="shops"
                      onChange={handleOnChangeShop}
                      options={shopsOptions}
                      styles={customStyles}
                      menuPortalTarget={document.body}
                      isClearable
                  />}
                  <h4>Butik som ordern skickats till</h4>
                  {orderInfo[order].OrderHead?.OriginalShop && (
                      <div className="orderForm__assignedShop">
                        <h6>Ursprungsbutik</h6>
                        <h5>{orderInfo[order].OrderHead.OriginalShop.Name}</h5>
                      </div>
                  )}
                </div>
                {orderInfo[order].OrderHead?.NewShop.Name && (<div className="orderForm__shop">
                  <h4>Butik som ordern är flyttad till</h4>
                  <div className="orderForm__assignedShop">
                    <h6>Flyttad till butik</h6>
                    <h5>{orderInfo[order].OrderHead.NewShop.Name}</h5>
                  </div>
                </div>)}
                <div className="orderForm__wholeWidthHorizontalLine"></div>
                <div className="orderForm__customerComments">
                  <h5>Kundkommentarer ({orderInfo[order].CustomerComments.length}) <ChatBubbleIcon
                      className="orderForm__commentIcon"/></h5>{expandedCustomerComments ? (
                    <button className="orderForm__expandButton" onClick={() => setExpandedCustomerComments(false)}>
                      <ExpandLessIcon/></button>) : (
                    <button className="orderForm__expandButton" onClick={() => setExpandedCustomerComments(true)}>
                      <ExpandMoreIcon/></button>)}
                </div>
                <div className="orderForm__comments">
                  {expandedCustomerComments && orderInfo[order].CustomerComments.sort((a, b) => {
                    return new Date(a.date_created) - new Date(b.date_created)
                  }).map((comment, index) => (
                      <div key={index} className="orderForm__customerComment">
                        <Comment comment={comment}/>
                      </div>
                  ))}
                </div>
                <div className="orderForm__sportsonComments">
                  <h5>Interna kommentarer ({orderInfo[order].SportsonComments.length}) <ChatBubbleIcon
                      className="orderForm__commentIcon"/></h5>{expandedInternalComments ? (
                    <button className="orderForm__expandButton" onClick={() => setExpandedInternalComments(false)}>
                      <ExpandLessIcon/></button>) : (
                    <button className="orderForm__expandButton" onClick={() => setExpandedInternalComments(true)}>
                      <ExpandMoreIcon/></button>)}
                </div>
                <div className="orderForm__comments">
                  {expandedInternalComments && orderInfo[order].SportsonComments.sort((a, b) => {
                    return new Date(a.date_created) - new Date(b.date_created)
                  }).map((comment, index) => (
                      <div key={index} className="orderForm__sportsonComment">
                        <Comment comment={comment}/>
                        {adminAccess && <button className="orderForm__commentButton orderForm__editCommentButton"
                                                onClick={(e) => editCommentHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo, comment._id, comment.comment_text)}>
                          <EditIcon/></button>}
                        {adminAccess && <button className="orderForm__commentButton orderForm__deleteCommentButton"
                                                onClick={(e) => deleteCommentHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo, comment._id)}>
                          <DeleteIcon/></button>}
                      </div>
                  ))}
                  {adminAccess && orderInfo && (<button
                      onClick={(e) => addNewCommentHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo)}
                      className="orderForm__editButton orderForm__addCommentButton"><span className="orderForm__iconAndText"><AddCommentIcon
                      className="orderForm__icons"/> Lägg till kommentar</span></button>)}
                </div>
                <Modal
                    showModal={showModal}
                    closeModalHandler={closeModalHandler}
                    title={title}
                    buttonName="Stäng"
                />
                <ModalAddNewComment
                    showModal={showModalAddNewComment}
                    closeModalHandler={closeModalAddNewCommentHandler}
                    title={title}
                    buttonName="Stäng"
                    orderID={theOrderID}
                    orderNumber={theOrderNumber}
                />
                <ModalAddNewOrderRow
                    showModal={showModalAddNewOrderRow}
                    closeModalHandler={closeModalAddNewOrderRowHandler}
                    title={title}
                    buttonName="Stäng"
                    orderID={theOrderID}
                    orderNumber={theOrderNumber}
                />
                <ModalConnectOrderToBenefitWindow
                    showModal={showModalConnectOrderToBenefitWindow}
                    closeModalHandler={closeModalConnectOrderToBenefitWindowHandler}
                    title={title}
                    buttonName="Stäng"
                    orderID={theOrderID}
                    orderNumber={theOrderNumber}
                    company={orderInfo[order].OrderHead?.Customer.Company}
                />
                <ModalEditComment
                    showModal={showModalEditComment}
                    closeModalHandler={closeModalEditCommentHandler}
                    title={title}
                    buttonName="Stäng"
                    orderID={theOrderID}
                    orderNumber={theOrderNumber}
                    commentID={theCommentID}
                    commentText={theCommentText}
                />
                <ModalDeleteOrderRowConfirmWithButtons
                    showModal={showModalDeleteOrderRowConfirmWithButtons}
                    closeModalHandler={closeModalDeleteOrderRowConfirmWithButtonsHandler}
                    setConfirmHandler={setDeleteOrderRowConfirmHandler}
                    title={title}
                    buttonName="Stäng"
                />
                <ModalConfirmWithButtons
                    showModal={showModalDeleteOrderConfirmWithButtons}
                    closeModalHandler={closeModalDeleteOrderConfirmWithButtonsHandler}
                    setConfirmHandler={setDeleteOrderConfirmHandler}
                    title={title}
                    buttonName="Stäng"
                />
                <hr className="orderForm__horizontalLine"/>
                <InvoiceNumbers key={orderInfo[order].OrderHead._id} orderInfo={orderInfo} order={order}/>
                <div className="orderForm__InfoContainer">
                  <h1>ORDERSUMMERING - {orderInfo[order].OrderHead.OrderNo}</h1>
                  {adminAccess && <button
                      onClick={(e) => addNewOrderRowHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo)}
                      className="orderForm__editButton"><span className="orderForm__iconAndText"><AddIcon
                      className="orderForm__icons"/> Lägg till produkt</span></button>}
                </div>
                <div className="orderForm__sumsContainer">
                  <div className="orderForm__sumsContainerDiv">
                    <h4>Total ordersumma</h4>
                    <h2>{orderInfo[order].OrderHead?.TotalGrossAmount && totalGrossAmount?.toLocaleString()} Kr</h2>
                  </div>
                  <div className="orderForm__sumsContainerDiv">
                    <h4>Fakturabelopp {orderInfo[order].OrderHead.InvoiceDiscountText}</h4>
                    <h2>{orderInfo[order].OrderHead?.TotalInvoiceAmount && totalInvoiceAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Kr</h2>
                  </div>
                </div>
                <div
                    className={
                      toggleState === 1
                          ? "orderForm__content active-content"
                          : "orderForm__content"
                    }
                >
                  <div className="orderForm__titelsKund">
                    <h5 className="orderForm__picProductKund">Bild</h5>
                    <h5 className="orderForm__productNameKund">Namn</h5>
                    <h5 className="orderForm__artNrTextKund">Artikelnummer</h5>
                    <h5 className="orderForm__barcodeKund">Streckkod</h5>
                    <h5 className="orderForm__grossPriceKund">Utpris</h5>
                    <h5 className="orderForm__amountKund">Antal</h5>
                    {/* <h5 className="orderForm__discountPercentage">Procent Rabatt</h5> */}
                    <h5 className="orderForm__discountPercentage">Rabatt i kronor</h5>
                    <h5 className="orderForm__discountAmount">Tot efter rabatt</h5>
                    <h5 className="orderForm__idKund">Ärende-/Ramnr</h5>
                  </div>
                  {!saveOrderRowChanges && items.length === 0 && Object.keys(orderInfo[0].Items).map((index) => (
                      <div className="orderForm__rowContainer">
                        <div className="orderForm__horizontalLine"></div>
                        <div key={index.Id}>
                          {/* ((orderInfo[0].Items[index].Id != orderRow) &&(<ProductRow
                          orderInfo={orderInfo[0].Items}
                          index={index}
                        />)) */}
                          {/* ((orderInfo[0].Items[index].Id == orderRow) &&(<EditProductRow
                          orderInfo={orderInfo[0].Items}
                          index={index}
                          setEditedItem={setTheEditedItem}
                          editedItem={theEditedItem}
                        />)) */}
                          <EditProductRow
                              key={orderInfo[order].OrderHead._id}
                              orderRows={orderInfo[0].Items}
                              index={index}
                              itemSalesPrices={itemsSalePrices}
                              setEditedItem={setTheEditedItem}
                              editedItem={theEditedItem}
                          />
                          {orderInfo && (
                              <>
                                {adminAccess && <button value={orderInfo[0].Items[index].Id}
                                                        onClick={(e) => deleteOrderRowHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo, orderInfo[0].Items[index].LineNumber)}
                                                        className="orderForm__editButton orderForm__deleteOrderRowButton">
                                  <span className="orderForm__iconAndText"><DeleteIcon
                                      className="orderForm__icons"/></span></button>}
                                {/* adminAccess && (orderInfo[0].Items[index].Id !== orderRow) && <button value={orderInfo[0].Items[index].Id} onClick={(e) => editOrderRowHandler(e)} className="orderForm__editButton"><span className="orderForm__iconAndText"><EditIcon className="orderForm__icons" /> Editera</span></button> */}
                                {adminAccess && /* editOrderRow && orderRow && (orderInfo[0].Items[index].Id == orderRow) && */ (<>
                                  <button
                                      onClick={(e) => saveEditOrderRowHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo)}
                                      className="orderForm__editOrderRowButton orderForm__editButton orderForm__hidden"><span
                                      className="orderForm__iconAndText"><CheckCircleOutlineIcon
                                      className="orderForm__icons"/></span></button>
                                  {/* <button onClick={ (e) => cancelEditOrderRowHandler(e)} className="orderForm__editOrderRowButton orderForm__editButton"><span className="orderForm__iconAndText"><CancelIcon className="orderForm__icons" /></span></button> */} </>)}
                                {adminAccess && orderInfo[0].Items[index].isDeleted &&
                                    <button value={orderInfo[0].Items[index].Id}
                                            onClick={(e) => undoDeletedOrderRowHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo, index)}
                                            className="orderForm__editButton orderForm__undoDeletedOrderRowButton"><span
                                        className="orderForm__iconAndText"><UndoIcon
                                        className="orderForm__icons"/></span></button>}
                              </>
                          )}
                        </div>
                      </div>
                  ))}
                  {!saveOrderRowChanges && items.length > 0 && Object.keys(items).map((index) => (
                      <div className="orderForm__rowContainer">
                        <div className="orderForm__horizontalLine"></div>
                        <div key={index.Id}>
                          {/* ((orderInfo[0].Items[index].Id != orderRow) &&(<ProductRow
                          orderInfo={orderInfo[0].Items}
                          index={index}
                        />)) */}
                          {/* ((orderInfo[0].Items[index].Id == orderRow) &&(<EditProductRow
                          orderInfo={orderInfo[0].Items}
                          index={index}
                          setEditedItem={setTheEditedItem}
                          editedItem={theEditedItem}
                        />)) */}
                          <EditProductRow
                              key={orderInfo[order].OrderHead._id}
                              orderRows={items}
                              index={index}
                              itemSalesPrices={itemsSalePrices}
                              setEditedItem={setTheEditedItem}
                              editedItem={theEditedItem}
                          />
                          {orderInfo && (
                              <>
                                {adminAccess && <button value={items[index].Id}
                                                        onClick={(e) => deleteOrderRowHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo, items[index].LineNumber)}
                                                        className="orderForm__editButton orderForm__deleteOrderRowButton">
                                  <span className="orderForm__iconAndText"><DeleteIcon
                                      className="orderForm__icons"/></span></button>}
                                {/* adminAccess && (orderInfo[0].Items[index].Id !== orderRow) && <button value={orderInfo[0].Items[index].Id} onClick={(e) => editOrderRowHandler(e)} className="orderForm__editButton"><span className="orderForm__iconAndText"><EditIcon className="orderForm__icons" /> Editera</span></button> */}
                                {adminAccess && /* editOrderRow && orderRow && (orderInfo[0].Items[index].Id == orderRow) && */ (<>
                                  <button
                                      onClick={(e) => saveEditOrderRowHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo)}
                                      className="orderForm__editOrderRowButton orderForm__editButton orderForm__hidden"><span
                                      className="orderForm__iconAndText"><CheckCircleOutlineIcon
                                      className="orderForm__icons"/></span></button>
                                  {/* <button onClick={ (e) => cancelEditOrderRowHandler(e)} className="orderForm__editOrderRowButton orderForm__editButton"><span className="orderForm__iconAndText"><CancelIcon className="orderForm__icons" /></span></button> */} </>)}
                                {adminAccess && items[index].isDeleted &&
                                    <button value={items[index].Id}
                                            onClick={(e) => undoDeletedOrderRowHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo, index)}
                                            className="orderForm__editButton orderForm__undoDeletedOrderRowButton"><span
                                        className="orderForm__iconAndText"><UndoIcon
                                        className="orderForm__icons"/></span></button>}
                              </>
                          )}
                        </div>
                      </div>
                  ))}
                  {saveOrderRowChanges && items.length > 0 && Object.keys(items).map((index) => (
                      <div className="orderForm__rowContainer">
                        <div className="orderForm__horizontalLine"></div>
                        <div key={index.Id}>
                          {/* ((orderInfo[0].Items[index].Id != orderRow) &&(<ProductRow
                          orderInfo={orderInfo[0].Items}
                          index={index}
                        />)) */}
                          {/* ((orderInfo[0].Items[index].Id == orderRow) &&(<EditProductRow
                          orderInfo={orderInfo[0].Items}
                          index={index}
                          setEditedItem={setTheEditedItem}
                          editedItem={theEditedItem}
                        />)) */}
                          <EditProductRow
                              key={orderInfo[order].OrderHead._id}
                              orderRows={items}
                              index={index}
                              itemSalesPrices={itemsSalePrices}
                              setEditedItem={setTheEditedItem}
                              editedItem={theEditedItem}
                          />
                          {orderInfo && (
                              <>
                                {adminAccess && <button value={items[index].Id}
                                                        onClick={(e) => deleteOrderRowHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo, items[index].LineNumber)}
                                                        className="orderForm__editButton orderForm__deleteOrderRowButton">
                                  <span className="orderForm__iconAndText"><DeleteIcon
                                      className="orderForm__icons"/></span></button>}
                                {/* adminAccess && (orderInfo[0].Items[index].Id !== orderRow) && <button value={orderInfo[0].Items[index].Id} onClick={(e) => editOrderRowHandler(e)} className="orderForm__editButton"><span className="orderForm__iconAndText"><EditIcon className="orderForm__icons" /> Editera</span></button> */}
                                {adminAccess && /* editOrderRow && orderRow && (orderInfo[0].Items[index].Id == orderRow) && */ (<>
                                  <button
                                      onClick={(e) => saveEditOrderRowHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo)}
                                      className="orderForm__editOrderRowButton orderForm__editButton orderForm__hidden"><span
                                      className="orderForm__iconAndText"><CheckCircleOutlineIcon
                                      className="orderForm__icons"/></span></button>
                                  {/* <button onClick={ (e) => cancelEditOrderRowHandler(e)} className="orderForm__editOrderRowButton orderForm__editButton"><span className="orderForm__iconAndText"><CancelIcon className="orderForm__icons" /></span></button> */} </>)}
                                {adminAccess && items[index].isDeleted &&
                                    <button value={items[index].Id}
                                            onClick={(e) => undoDeletedOrderRowHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo, index)}
                                            className="orderForm__editButton orderForm__undoDeletedOrderRowButton"><span
                                        className="orderForm__iconAndText"><UndoIcon
                                        className="orderForm__icons"/></span></button>}
                              </>
                          )}
                        </div>
                      </div>
                  ))}
                </div>
                <div
                    className={
                      toggleState === 2
                          ? "orderForm__content active-content"
                          : "orderForm__content"
                    }
                >
                  <div className="orderForm__titelsButik">
                    <h5 className="orderForm__productTextButik">Produkt</h5>
                    <h5 className="orderForm__artNrTextButik">Artikelnummer</h5>
                    <h5 className="orderForm__priceTextButik">
                      Ursprungligt pris <br/> (kund)
                    </h5>
                    <h5 className="orderForm__shopPriceTextButik">
                      Återköpspris <br/>
                      (butik)
                    </h5>
                    <h5 className="orderForm__newPriceTextButik">
                      Begagnad <br/>
                      utpris
                    </h5>
                    <h5 className="orderForm__tgTextButik">TG %</h5>
                  </div>
                  {Object.keys(orderInfo[0].Items).map((index) => (
                      <div className="orderForm__rowContainer">
                        <div key={index.Id}>
                          <ProductRowShopView
                              key={orderInfo[order].OrderHead._id}
                              orderInfo={orderInfo[0]?.Items}
                              index={index}
                          />
                        </div>
                      </div>
                  ))}
                  {adminAccess && <button className="orderForm__sendBtn">Skicka</button>}
                </div>
                {/* adminAccess && orderInfo && (<button onClick={(e) => saveOrderHandler(e, orderInfo[order].OrderHead._id,orderInfo[order].OrderHead.OrderNo)} className="orderForm__saveButton">Spara</button>) */}
                {adminAccess && orderInfo && (<button
                    onClick={(e) => deleteOrderHandler(e, orderInfo[order].OrderHead._id, orderInfo[order].OrderHead.OrderNo)}
                    className="orderForm__deleteButton orderForm__floatLeft">Makulera</button>)}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return <></>;
  }
}

export default OrderForm;

import React, { useState, useEffect } from "react";
import "./style.css";
import SidebarRow from "../SidebarRow";
import sportsonIcon from "../../../img/sportsonS.png";
//import MenuBookIcon from "@material-ui/icons/MenuBook";
//import VolumeUpOutlinedIcon from "@material-ui/icons/VolumeUpOutlined";
//import StorefrontIcon from "@material-ui/icons/Storefront";
//import ThumbsUpDownOutlinedIcon from "@material-ui/icons/ThumbsUpDownOutlined";
//import ContactsOutlinedIcon from "@material-ui/icons/ContactsOutlined";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EventNoteIcon from '@material-ui/icons/EventNote';
//import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import BusinessIcon from '@material-ui/icons/Business';
//import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import AssignmentReturnOutlinedIcon from "@material-ui/icons/AssignmentReturnOutlined";
import LoopIcon from '@material-ui/icons/Loop';
import { Link } from "react-router-dom";
import { useDispatch /*, useSelector*/ } from "react-redux";
import { logout } from "../../../Redux/actions/userActions";
import Modal from "../../HkModal";
import { Avatar } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import RedeemOutlinedIcon from "@material-ui/icons/RedeemOutlined";
//import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
//import MotorcycleOutlinedIcon from "@material-ui/icons/MotorcycleOutlined";
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CameraIcon from '@material-ui/icons/Camera';
import Tooltip from "@material-ui/core/Tooltip";
import API from "../../../utils/API";
import {fetchOrder} from "../../../Redux/actions/orderAction";

function Sidebar({ onCollapse, adminaccess, shopstaff }) {
  const [select, setSelect] = useState("selected");
  const [benefitBicycle, setBenefitBicycle] = useState(false);
  //const [showModal, setShowModal] = React.useState(false);
  //const [title, setTitle] = useState("");
  const [username, setUsername] = useState("");
  const [inactive, setInactive] = useState(false);
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

    const handleClickOpen = () => {
    setOpen(true);
    // setShowModal(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setShowModal(false);
  };

  //const { userInfo } = useSelector((state) => state.user);
  //const closeModalHandler = () => setShowModal(false);

    /*
  const onClickHandler = () => {
    setShowModal(true);
    console.log("I am modal", showModal);
  };
    */

    const validateToken = async (token) => {
        await API.validateToken().then((response) => {
                if (response.status === 200) {
                } else {
                    if (token !== null)
                        dispatch(logout());
                }
        });
    }

    useEffect(() => {
       void validateToken(localStorage.getItem("token"));
    });

  useEffect(() => {
    onCollapse(inactive);
  }, [inactive]);

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        void dispatch(fetchOrder(""));
    }, [select]);

  useEffect(() => {
    const getUserName = async () => {
      await API.getUserName()
          .then((response) => {
            setUsername(response.data.name);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };
    void getUserName();
  }, [localStorage.getItem("token")]);

  const handleLogout = () => {
    void dispatch(logout());
  };

  return (
    <div className={`sidebar ${inactive ? "inactive" : ""}`}>
      <div className="sidebar__topSection">
        <Avatar component={Link} to="/" className="sidebar__avatar" src={sportsonIcon ? sportsonIcon : ""} />
        <div className="sidebar__input">
          <input placeholder="Sök i portalen" type="text" />
          <SearchIcon className="sidebar__searchIcon" />
        </div>
        <div className="sidebar__section2">
          {/* <Button className="header__logout" onClick={(e) => handleLogout(e)}>
          <Link to="/login" className="header__loglink">
            Logga ut
          </Link>
        </Button> */}
          <span className="sidebar__name">{username}</span>
          <div className="sidebar__logout">
            <div className="sidebar__wrapper">
              <Link to="/" className="sidebar__link">
                {/* <div className="tooltip">
                <span>Logga ut</span>
              </div> */}
                <span className="icon__wrapper">
                  <Tooltip title="Logga ut">
                      <Link to="/login">
                        <ExitToAppIcon
                          className="logoutIcon"
                          onClick={handleLogout}
                        />
                      </Link>
                  </Tooltip>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          className="sidebar__menuBtn"
          onClick={() => setInactive(!inactive)}
        >
          {inactive ? <ArrowForwardIcon /> : <ArrowBackIcon />}
        </button>
      </div>
        {adminaccess && <div>
            <h5 className="sidebar__adminTitle">Admin</h5>
            <Link to="/admin/users" className="sidebar__link">
                <SidebarRow
                    active={select === "users"}
                    onClick={() => setSelect("users")}
                    Icon={PeopleAltIcon}
                    title={decodeURI("Användare")}
                />{" "}
            </Link>
            {/* <Link to="/admin/supporttickets" className="sidebar__link">
                <SidebarRow
                    active={select === "supportTickets"}
                    onClick={() => setSelect("supportTickets")}
                    Icon={HeadsetMicIcon}
                    title={decodeURI("Ärenden")}
                />{" "}
            </Link> */}
            {!benefitBicycle && (
                <Link to="/admin/" className="sidebar__link">
                    <SidebarRow
                        active={select === "formansfonster"}
                        onClick={() => setBenefitBicycle(!benefitBicycle)}
                        Icon={ExpandMoreIcon}
                        title={decodeURI("Förmånscyklar")}
                    />{" "}
                </Link> )}
            {benefitBicycle && (
                <>
                    <Link to="/admin/" className="sidebar__link">
                        <SidebarRow
                            active={select === "formansfonsterHeader"}
                            onClick={() => setBenefitBicycle(!benefitBicycle)}
                            Icon={ExpandLessIcon}
                            title={decodeURI("Förmånscyklar")}
                        />{" "}
                    </Link>
                    <Link to="/admin/company" className="sidebar__link">
                        <SidebarRow
                            active={select === "bolag"}
                            onClick={() => setSelect("bolag")}
                            Icon={BusinessIcon}
                            title={decodeURI("Bolag")}
                            indent={true}
                        />{" "}
                    </Link>
                    <Link to="/admin/benefitwindow" className="sidebar__link">
                        <SidebarRow
                            active={select === "formansfonster"}
                            onClick={() => setSelect("formansfonster")}
                            Icon={ChromeReaderModeIcon}
                            title={decodeURI("Beställningsfönster")}
                            indent={true}
                        />{" "}
                    </Link>
                    {/* Remove not needed link for now
                    <Link to="/admin/planbenefitwindowsadmin" className="sidebar__link">
                        <SidebarRow
                            active={select === "planBenefitWindowsAdmin"}
                            onClick={() => setSelect("planBenefitWindowsAdmin")}
                            Icon={EventNoteIcon}
                            title={decodeURI("Portal-listan")}
                            indent={true}
                        />{" "}
                    </Link>
                    */}
                    {/* Remove not needed link for now
                    <Link to="/admin/planbenefitwindowspc" className="sidebar__link">
                        <SidebarRow
                            active={select === "planBenefitWindowsPC"}
                            onClick={() => setSelect("planBenefitWindowsPC")}
                            Icon={EventNoteIcon}
                            title={decodeURI("HD-listan")}
                            indent={true}
                        />{" "}
                    </Link>
                    */}
                </>
            )}
            <div className="sidebar__adminHr"></div>
        </div> }
        <Link to="/ordercentral" className="sidebar__link">
            <div className="testCont">
                <SidebarRow
                    active={select === "ordercentral"}
                    onClick={() => setSelect("ordercentral")}
                    Icon={DirectionsBikeIcon}
                    title={decodeURI("Ordercentral")}
                />{" "}
            </div>
        </Link>
        <Link to="/" className="sidebar__link">
            <div className="testCont">
                <SidebarRow
                    active={select === "news"}
                    onClick={() => setSelect("news")}
                    Icon={LibraryBooksIcon}
                    title={decodeURI("Nyheter")}
                />{" "}
            </div>
        </Link>
      <Link to="/articleform" className="sidebar__link">
        <SidebarRow
          active={select === "article"}
          onClick={() => setSelect("article")}
          Icon={AssignmentReturnOutlinedIcon}
          title={decodeURI("Artikelbegäran")}
        />{" "}
      </Link>
      <Link to="/giftcard" className="sidebar__link">
        <div className="testCont">
          <SidebarRow
            active={select === "giftcard"}
            onClick={() => setSelect("giftcard")}
            Icon={RedeemOutlinedIcon}
            title={decodeURI("Presentkort nutid")}
          />{" "}
        </div>
      </Link>
        <Link to="/usedbikesform" className="sidebar__link">
            <SidebarRow
                active={select === "usedBikes"}
                onClick={() => setSelect("usedBikes")}
                Icon={LoopIcon}
                title={decodeURI("Begagnatbegäran")}
            />{" "}
        </Link>
      {/* <Link to="/formanscykel" className="sidebar__link">
        <SidebarRow
          active={select === "formanscykel"}
          onClick={(e) => setSelect("formanscykel")}
          Icon={FormatAlignLeftIcon}
          title={decodeURI("Förmånscykel")}
        />{" "}
      </Link>
      <Link to="/order" className="sidebar__link">
        <SidebarRow
          active={select === "searchOrder"}
          onClick={(e) => setSelect("searchOrder")}
          Icon={MotorcycleOutlinedIcon}
          title={decodeURI("Förmånscykel retur")}
        />{" "}
      </Link>

      <Link to="/nyheter" className="sidebar__link">
        <SidebarRow
          active={select === "news"}
          onClick={(e) => setSelect("news")}
          Icon={MenuBookIcon}
          title="NYHETER"
        />
      </Link>
      <Link to="/kampanj" className="sidebar__link">
        <SidebarRow
          active={select === "kampanj"}
          onClick={(e) => setSelect("kampanj")}
          Icon={VolumeUpOutlinedIcon}
          title="KAMPANJER"
        />
      </Link>
      <Link to="/butiksservice" className="sidebar__link">
        <SidebarRow
          active={select === "butiksservice"}
          onClick={(e) => setSelect("butiksservice")}
          Icon={StorefrontIcon}
          title="BUTIKSSERVICE"
        />
      </Link>

      <Link to="/leverantor" className="sidebar__link">
        <SidebarRow
          active={select === "leverantor"}
          onClick={(e) => setSelect("leverantor")}
          Icon={ThumbsUpDownOutlinedIcon}
          title="LEVERANTÖRER"
        />
      </Link>
      <Link to="/kontakt" className="sidebar__link">
        <SidebarRow
          active={select === "kontakt"}
          onClick={(e) => setSelect("kontakt")}
          Icon={ContactsOutlinedIcon}
          title="KONTAKTER"
        />
      </Link> */}
      <hr className="sidebar__divider " />
      {/* <div> */}
      {/* <SidebarRow
        active={select === "minButik"}
        onClick={(e) => setSelect("minButik")}
        Icon={HomeOutlinedIcon}
        title="MIN BUTIK"
        subMenus={[{ name: "Järnbrott" }, { name: "Backaplan" }]}
      /> */}
      {/* <ul className="sidebar__subMenu">
          <li>
            <span>Järnbrott</span>
          </li>
          <li>
            <span>Backaplan</span>
          </li>
        </ul>
      </div> */}
      <div className="sidebar__menuFooter">
        <button className="sidebar__hkSupportBtn" onClick={handleClickOpen}>
          <HelpOutlineIcon className="sidebar__hkIcon" />
          <p className="hk__btnText">HK-Support</p>
        </button>
        <Modal open={open} handleClose={handleClose} />
        {/* <Modal
          showModal={showModal}
          closeModalHandler={closeModalHandler}
          title={title}
          bodyContent=""
        /> */}
        {/* <button className="sidebar__logout" onClick={(e) => handleLogout(e)}>
          <Link to="login" className="sidebar__loglink">
            Logga ut
          </Link>
        </button> */}
      </div>
        <div className="sidebar__menuFooter">
            <a className="sidebar__hkSupportBtn" href="https://apollocyklar.se/sportson/login.php" target="_blank" rel="noopener noreferrer">
                <CameraIcon className="sidebar__hkIcon portalIcon" />
                <p className="hk__btnText">Gamla portalen</p>
            </a>
        </div>
    </div>
  );
}

export default Sidebar;

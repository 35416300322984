import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";
import API from "../../../utils/API";
import { logout } from "../../../Redux/actions/userActions";
import { fetchCard } from "../../../Redux/actions/giftCardAction";
import Modal from "../../Modals/Modal-ConfirmAlert";

function Card() {
  //const [_id, set_id] = useState("");
  const [isused, /*setIsused*/] = useState(true);
  const [usedshopname, setUsedshopname] = useState("");
  const [userShopData, setUserShopData] = useState([]);
  const [userIdData, setUserIdData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const selectedShop = useRef();
  const cardData = useSelector((state) => state.card);

  const closeModalHandler = () => setShowModal(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const getUserShop = async () => {
      await API.getUserShop()
        .then((res) => {
          if (res.status === 200) {
            res.data.sort((a, b) => a.Name.localeCompare(b.Name));
            setUserShopData(res.data);
          }
          if (res.status === 401) {
            dispatch(logout());
            console.log("Du loggas nu ut!");
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    };
    void getUserShop();
  }, [dispatch]);

  useEffect(() => {
    const getUserId = async () => {
      await API.getUserId()
        .then((res) => {
          setUserIdData(res.data);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    };
    void getUserId();
  }, []);

  const getShopName = async (shopID) => {
    await API.getShopList(shopID)
        .then((res) => {
          setUsedshopname(res.data.Name);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
  };

  const editData = () => {
    let localTime = (new Date()).getTimezoneOffset() * 60000;
    let localISOTime = (new Date(Date.now() - localTime)).toISOString().slice(0,-1);

    if (selectedShop.current.value !== "") {
      const data = {
        _id: cardData.cardNo._id,
        isused,
        currentsum: 0,
        useddate: localISOTime,
        usedshopid: selectedShop.current.value,
        useduserid: userIdData.id,
      };

      API.updateGiftCard(data)
        .then((response) => {
          if (response.status >= 300) {
            setShowModal(true);
            setTitle(`${response.data.message} - ${response.data.details.message}`);
          }
          void dispatch(fetchCard(encodeURI(cardData.cardNo.controlid)));
          setShowModal(true);
          setTitle(`Presentkortet inlöst. (Ange det aktuella beloppet ${cardData.cardNo.currentsum} kr på betalsättet Nutid Presentkort i kassan.)`);
        })
        .catch((/*error*/) => {
          setShowModal(true);
          setTitle(`Fel: presentkortet med kontroll ID ${cardData.cardNo.controlid} kunde ej lösas in.`);
        });
    } else {
      setShowModal(true);
      setTitle("Välj en inlösande butik");
    }
  };

  if (cardData.cardNo && !cardData.cardData) {
    return (
      <div className="card">
        <div className="card__header">
          <div className="card__col">
            <span className="card__title">Belopp kvar att nyttja</span>
            <span className="card__text card__currentSum">
                {cardData.cardNo && cardData.cardNo.currentsum} kr
            </span>
          </div>
          {cardData.cardNo && !cardData.cardNo.isused && !cardData.cardNo.isexpired && <>
              <span className="card__title card__title__dropdown">Välj inlösande butik</span>
              <select ref={selectedShop} className="card__shopSelector">
                {userShopData.length > 1 ? <option value="" disabled selected>Välj Butik</option> : null}
                {userShopData.map((item) => (
                  <option key={item.Code} value={item.Code}>
                    {item.Name}
                  </option>
                ))}
              </select>
              <button className="card__button" type="submit" onClick={editData}>
                LÖS IN PRESENTKORT
              </button>
            </>
          }
        </div>
        <div className="card__container">
          <div className="card__topContainer">
            {/*
            <div className="card__amount">
              <span>Ursprungs värde</span>

              <h5>{cardData.cardNo && cardData.cardNo.currentsum} kr </h5>
            </div>
            */}
            <div className="card__col1">
              <span className="card__title">Sålt av</span>
              <span className="card__text">
                {cardData.cardNo && cardData.cardNo.shopName && cardData.cardNo.inactiveshopname ? `${cardData.cardNo.shopName} (${cardData.cardNo.inactiveshopname})` : cardData.cardNo.shopName}
              </span>
            </div>
            <div className="card__col1">
            </div>
            <div className="card__col card__isUsed">
              <span className="card__title">Inlöst av</span>
              <span className="card__text">
                {cardData.cardNo && cardData.cardNo.isused && getShopName(cardData.cardNo.usedshopid) ? `${usedshopname} (${cardData.cardNo.useduserid.name})` : "-"}
              </span>
            </div>
            <div className="card__col">
            </div>
          </div>
          <hr className="card__hr" />
          <div className="card__bottomContainer">
            <div className="card__id">
              <span className="card__title">kontroll ID</span>
              <span className="card__text">
              {cardData.cardNo && cardData.cardNo.controlid}{" "}
            </span>
            </div>
            <div className="card__col1">
              <span className="card__title">Sålt datum</span>
              <span className="card__text">
                {cardData.cardNo && cardData.cardNo.solddate.split("", 10)}{" "}
              </span>
            </div>
            <div className="card__col">
              <span className="card__title">Inlöst</span>
              <span className="card__text">
                {cardData.cardNo && cardData.cardNo.isused ? "Ja" : "Nej"}
              </span>
            </div>
            {cardData.cardNo && cardData.cardNo.isused && <>
              <div className="card__col">
                <span className="card__title">Inlöst belopp</span>
                <span className="card__text card__currentSum">
                    {cardData.cardNo && cardData.cardNo.originalsum} kr
                </span>
              </div>
            </>
            }
            <div className="card__col">
              <span className="card__title">Utgånget</span>
              <span className="card__text">
                {cardData.cardNo && cardData.cardNo.isexpired ? "Ja" : "Nej"}
              </span>
            </div>
          </div>
          <Modal
              showModal={showModal}
              closeModalHandler={closeModalHandler}
              title={title}
              buttonName="Stäng"
          />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default Card;

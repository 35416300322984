import React, { useState } from "react";
import "./SearchOrder.css";
import OrderForm from "../../components/OrderForm";
import Header from "../../components/Header";
import { fetchOrder } from "../../Redux/actions/orderAction";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@material-ui/core";

function SearchOrder() {
  const [orderNo, setOrderNo] = useState("");
  const dispatch = useDispatch();
  const { orderInfo } = useSelector((state) => state.order);

  function fetchData(e) {
    e.preventDefault();

    if (orderNo === "" || null) {
      alert("Please enter ordernumber");
    } else {
      void dispatch(fetchOrder(orderNo));
    }
  }
  return (
    <div className="searchOrder">
      <Header title="Förmånscykel - retur" />
      <form>
        <div className="searchOrder__inputContainer">
          <TextField
            className="searchOrder__inputText"
            label="Ange ordernummer"
            variant="outlined"
            type="text"
            name="inputText"
            value={orderNo}
            onChange={(e) => setOrderNo(e.target.value)}
          />
          <button
            className="searchOrder__searchBtn"
            type="submit"
            onClick={(e) => fetchData(e)}
          >
            SÖK
          </button>
        </div>
      </form>
      <div className="searchOrder__formContainer">
        <OrderForm orderInfo={orderInfo} />
      </div>
    </div>
  );
}

export default SearchOrder;

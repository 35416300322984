import React, {useState, useEffect} from "react";
import "./style.css";
import {useDispatch} from "react-redux";
import Modal from "../Modal-ConfirmAlert";
import ModalValidateOU from "../Modal-ValidateOU";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SyncIcon from '@material-ui/icons/Sync';
import {logout} from "../../../Redux/actions/userActions";
import API from "../../../utils/API";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@material-ui/core";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
});

function InputModal({
    //title,
    buttonName,
    showModal,
    closeModalHandler,
    benefitWindow,
}) {
  const [showTheModal, setShowTheModal] = useState(false);
  const [showTheModalValidateOU, setShowTheModalValidateOU] = useState(false);
  const [theTitle, setTheTitle] = useState("");
  const [theBenefitWindow, setTheBenefitWindow] = useState([]);
  const [expandOrderList, setExpandOrderList] = useState(true);
  const [benefitWindowID, setBenefitWindowID] = useState("");
  const [/* adminAccess */, setAdminAccess] = useState(false);
  const [shopStaff, setShopStaff] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const dispatch = useDispatch();

  const closeModalValidateOUHandler = () => setShowTheModalValidateOU(false);
  const closeTheModalHandler = () => setShowTheModal(false);

  const getLoggedInUserRights = async () => {
    await API.getLoggedInUserRights()
        .then((response) => {
          if (response.data.name === "Butikspersonal")
            setShopStaff(true);
          setAdminAccess(response.data.adminaccess);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
  };

  useEffect(() => {
    setBenefitWindowID(benefitWindow);

    const openBenefitWindow = async (benefitWindow) => {
      await API.getBenefitWindow(benefitWindow,"","", true)
          .then((response) => {
            if (response.status === 200) {
              setTheBenefitWindow(response.data);
            } else if (response.status >= 300) {
              setShowTheModal(true);
              setTheTitle(`${response.data.message} - ${response.data.details.message}`);
            } else if (response.status === 401) {
              dispatch(logout());
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
    };

    void getLoggedInUserRights();
    void openBenefitWindow(benefitWindow);
  }, [benefitWindow, showModal, updateModal, dispatch]);

  const generateBGHandler = (e) => {
    e.preventDefault();

    API.generateBG(benefitWindow).then((response) => {
      if (response.status === 200) {
        setShowTheModal(true);
        setTheTitle("BG genererat för fönstret");
      } else if (response.status >= 300) {
          setShowTheModal(true);
          setTheTitle(`${response.data.message} - ${response.data.details.message}`);
      } else if (response.status === 400) {
        setTheTitle("BG håller redan på att genereras");
        setShowTheModal(true);
      } else if (response.status === 401) {
         void dispatch(logout());
      }
    });
  }

  /*

  const generateKALHandler = (e) => {
    e.preventDefault();

    API.generateKAL(benefitWindow).then((response) => {
      if (response.status === 200){

      } else if (response.status >= 300) {
        setShowTheModal(true);
        setTheTitle(`${response.data.message} - ${response.data.details.message}`);
      } else if (response.status === 401) {
        dispatch(logout());
      }
    });
  }

  const generateOUSTOPPHandler = (e) => {
    e.preventDefault();

    API.generateOUStop(benefitWindow).then((response) => {
      if (response.status === 200){

      } else if (response.status >= 300) {
        setShowTheModal(true);
        setTheTitle(`${response.data.message} - ${response.data.details.message}`);
      } else if (response.status === 401) {
        dispatch(logout());
      }
    });
  }

  const generateOUHandler = (e) => {
    e.preventDefault();

    API.generateOU(benefitWindow).then((response) => {
      if (response.status === 200){

      } else if (response.status >= 300) {
        setShowTheModal(true);
        setTheTitle(`${response.data.message} - ${response.data.details.message}`);
      } else if (response.status === 401) {
        dispatch(logout());
      }
    });
  }
   */

  const validateOUHandler = (e) => {
    e.preventDefault();

    setShowTheModalValidateOU(true);
    setTheTitle("Validering av OU");
  }

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Dialog
          className="modal"
          open={showModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeModalHandler}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="modal__topHeaderBenefitWindow">
            <DialogActions className="modal__topHeaderBenefitWindow">
              {" "}
              <span onClick={closeModalHandler} className="modal__closeBtn">
                x
              </span>
            </DialogActions>
            <h1 className="modal__header">{theBenefitWindow[0] && theBenefitWindow[0].name ? theBenefitWindow[0].name : null}</h1>
            <h2 className="modal__dateHeader">Fönsterdatum</h2>
            <span className="modal__dateRange"><h2>{theBenefitWindow[0] && theBenefitWindow[0].startdate && theBenefitWindow[0].enddate ? (`${theBenefitWindow[0].startdate.slice(0,10) } - ${theBenefitWindow[0].enddate.slice(0,10) }`) : null}</h2></span>
          </div>
          <DialogContent className="modal__benefitWindow__body">
            <Modal
                showModal={showTheModal}
                closeModalHandler={closeTheModalHandler}
                title={theTitle}
                buttonName="Stäng"
            />
            <ModalValidateOU
                showModal={showTheModalValidateOU}
                closeModalHandler={closeModalValidateOUHandler}
                title={theTitle}
                buttonName="Stäng"
                benefitWindowID={benefitWindowID}
            />
            <p>Fönsterordrar</p>
            <div className="modal__benefitWindowOrders">
              <button className="modal__expandButton" onClick={() => setUpdateModal(!updateModal)}><SyncIcon /></button>
              <button onClick={() => setExpandOrderList(!expandOrderList)} className="modal__expandButton">{ expandOrderList ? <ExpandLessIcon /> : <ExpandMoreIcon />}</button><p>Ordrar</p>
              { expandOrderList && theBenefitWindow[0] && theBenefitWindow[0].orderlist && theBenefitWindow[0].orderlist.map((order) =>
                (<>
                  <div className="modal__hr"></div>
                  <a href={`/order/${order.OrderNo}`} rel="noopener noreferrer" target="_blank" className="modal__benefitWindow__orderLink">{order.OrderNo} {order.HasFrameNo ? "(Ramnummer finns)" : "(Ramnummer saknas)"}</a>
                </>))
              }
            </div>
          </DialogContent>
          <div className="modal__benefitWindow__buttonDiv">
            {!shopStaff && <button onClick={(e) => generateBGHandler(e)} className="modal__benefitWindow__button">Generera BG</button>}
            {!shopStaff && <button onClick={(e) => validateOUHandler(e)} className="modal__benefitWindow__button">Validera OU</button>}
            <button onClick={closeModalHandler} className="modal__benefitWindow__button modal__benefitWindow__closeButton">
              {buttonName}{" "}
            </button>
          </div>
        </Dialog>
      </MuiThemeProvider>
    </div>
  );
}

export default InputModal;

import React from "react";
import "./SupportTickets.css";
import Header from "../../components/Header";

function SupportTickets() {

    return (
        <div className="supportTickets">
            <Header title="Ärenden" />
            <h5>Under konstruktion</h5>
        </div>
    );
}

export default SupportTickets;
import React, {useEffect, useState, useRef} from "react";
import "./style.css";
import API from "../../utils/API";
//import {logout} from "../../Redux/actions/userActions";
import ModalBenefitWindow from "../Modals/Modal-BenefitWindow";
import OrderForm from "../OrderForm";

function FormanscykelFonsterPC({fromdate, todate, companyID, onlyOpenBenefitWindows, onlyAffectedStores, skip, limit, notOUWindows, shops, aborInvoiced, salesperson}) {
    const [benefitWindows, setBenefitWindows] = useState([])
    const [numberOfBenefitWindows, setNumberOfBenefitWindows] = useState("");
    const [/*userShopData*/, setUserShopData] = useState([]);
    //const [showOnlyMyShops, setShowOnlyMyShops] = useState(false);
    const [showModalBenefitWindow, setShowModalBenefitWindow] = useState(false);
    const [title, setTitle] = useState("");
    const [benefitWindow, setBenefitWindow] = useState("");
    const currentPageRef = useRef(1);
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [sortByBenefitWindowName, setSortByBenefitWindowName] = useState(false);
    const [sortByInvoiceDate, setSortByInvoiceDate] = useState(false);
    const [agreementOptions, setAgreementOptions] = useState([]);
    //let checkboxShowOnlyMyShops = document.getElementById("windowsMyShops");

    const closeModalBenefitWindowHandler = () => setShowModalBenefitWindow(false);

    const firstPage = (e) => {
        e.preventDefault();

        currentPageRef.current = 1;
        void reloadBenefitWindows();
    }

    const lastPage = (e) => {
        e.preventDefault();

        currentPageRef.current = Math.ceil((numberOfBenefitWindows / 10));
        void reloadBenefitWindows();
    }

    const previousPage = (e) => {
        e.preventDefault();

        currentPageRef.current = currentPageRef.current - 1;
        void reloadBenefitWindows();
    }

    const nextPage = (e) => {
        e.preventDefault();

        currentPageRef.current = currentPageRef.current + 1;
        void reloadBenefitWindows();
    }

    const reloadBenefitWindows = async () => {
        const sortByBenefitWindowNameButton = document.getElementById("formanscykelFonsterPC__sortBenefitWindowByNameButton");
        const sortByInvoiceDateButton = document.getElementById("formanscykelFonsterPC__sortBenefitWindowByInvoiceDateButton");

        if (!sortByBenefitWindowName && !sortByInvoiceDate) {
            setLoadingSpinner(true);
            await API.getBenefitWindowIgnoreCancelled("", fromdate, todate, true, companyID, onlyOpenBenefitWindows, onlyAffectedStores, (currentPageRef.current - 1) * 10, 10, notOUWindows, shops, aborInvoiced, salesperson)
                .then((response) => {
                    response.data.data.sort((a, b) => b.startdate.localeCompare(a.startdate));
                    setBenefitWindows(response.data.data);
                    setNumberOfBenefitWindows(response.data.count);
                    setLoadingSpinner(false);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        } else if (!sortByInvoiceDate && sortByBenefitWindowName) {
            setLoadingSpinner(true);
            await API.getBenefitWindowSortByName("", fromdate, todate, true, companyID, onlyOpenBenefitWindows, onlyAffectedStores, (currentPageRef.current - 1) * 10, 10, notOUWindows, shops, aborInvoiced, salesperson, sortByBenefitWindowNameButton.innerText === "Sortera A-Ö" ? "asc" : "desc")
                .then((response) => {
                    setBenefitWindows(response.data.data);
                    setNumberOfBenefitWindows(response.data.count);
                    setLoadingSpinner(false);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        } else if (!sortByBenefitWindowName && sortByInvoiceDate) {
            setLoadingSpinner(true);
            await API.getBenefitWindowSortByInvoiceDate("", fromdate, todate, true, companyID, onlyOpenBenefitWindows, onlyAffectedStores, (currentPageRef.current - 1) * 10, 10, notOUWindows, shops, aborInvoiced, salesperson, sortByInvoiceDateButton.innerText === "Sortera stigande" ? "asc" : "desc")
                .then((response) => {
                    setBenefitWindows(response.data.data);
                    setNumberOfBenefitWindows(response.data.count);
                    setLoadingSpinner(false);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        }
    };

    useEffect(() => {
        const getUserShop = async () => {
            await API.getUserShop()
                .then((res) => {
                    if (res.status === 200) {
                        res.data.sort((a, b) => a.Name.localeCompare(b.Name));
                        setUserShopData(res.data[0].Code);
                    }
                    if (res.status === 401) {
                    }
                })
                .catch((error) => {

                    console.log("Error: ", error);
                });
        };
        void getUserShop();
        void reloadBenefitWindows();
        const getAgreements = async () => {
            await API.getAgreement("")
                .then((response) => {
                    let agreements = [];
                    response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((agreement) => {
                        agreements.push({ value: agreement._id, label: agreement.name });
                    });
                    setAgreementOptions(agreements);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAgreements();
    }, []);

    useEffect(() => {
        if (sortByBenefitWindowName)
            reloadBenefitWindows();
    }, [sortByBenefitWindowName]);
    useEffect(() => {
        if (sortByInvoiceDate)
            reloadBenefitWindows();
    }, [sortByInvoiceDate]);

    const handleButtonSortByName = (e) => {
        e.preventDefault();

        setSortByInvoiceDate(false);
        if(!sortByBenefitWindowName)
            setSortByBenefitWindowName(true);

        if (e.target.innerText === "Sortera A-Ö") {
            e.target.innerText = "Sortera Ö-A";
            reloadBenefitWindows();
        }
        else {
            e.target.innerText = "Sortera A-Ö";
            reloadBenefitWindows();
        }
    }

    const handleButtonSortByInvoiceDate = (e) => {
        e.preventDefault();

        setSortByBenefitWindowName(false);
        if(!sortByInvoiceDate)
            setSortByInvoiceDate(true);
        if (e.target.innerText === "Sortera stigande") {
            e.target.innerText = "Sortera fallande";
            reloadBenefitWindows();
        }
        else {
            e.target.innerText = "Sortera stigande";
            reloadBenefitWindows();
        }
    }

    return (
          <>
              <ModalBenefitWindow
                  showModal={showModalBenefitWindow}
                  closeModalHandler={closeModalBenefitWindowHandler}
                  title={title}
                  buttonName="Stäng"
                  benefitWindow={benefitWindow}
              />
              <div className="formanscykelFonsterPC">
                  <h4 className="formanscykelFonsterPC__searchResultsHeader">Sökresultat: {numberOfBenefitWindows} fönster</h4>
                  { loadingSpinner && <div className="formanscykelFonsterPC__loadingDiv"><h1>Laddar</h1><div className="formanscykelFonsterPC__loader"></div></div> }
                  { benefitWindows.length > 0 && benefitWindows.map((window,index) => {
                  return  (
                      <table className="formanscykelFonsterPC__table">
                          <tbody>
                              { index === 0 ? <tr className="formanscykelFonsterPC__rowHeader">
                                  {/* <th className="formanscykelFonsterPC__rowColumns"><h5>Datum</h5></th> */}
                                  {/* <th className="formanscykelFonsterPC__rowColumns"><h5 className="formanscykelFonsterPC__company">Företagsnamn</h5></th> */}
                                  <th className="formanscykelFonsterPC__rowColumnsName"><h5><b>Fönster <button id="formanscykelFonsterPC__sortBenefitWindowByNameButton" className="formanscykelFonsterPC__sortButton" onClick={(e) => handleButtonSortByName(e)}>Sortera A-Ö</button></b></h5></th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"><h5><b>Status</b></h5></th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"><h5><b>Säljare</b></h5></th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"><h5><b>Hyresfaktor</b></h5>
                                  </th>
                                  <th className="formanscykelFonsterPC__rowColumns"><h5><b>Avtalstyp</b></h5>
                                  </th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"><h5><b>Portal uppsatt</b></h5>
                                  </th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"><h5><b>Gemensam portal</b></h5>
                                  </th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"><h5><b>Berörda butiker</b></h5>
                                  </th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"><h5><b>OU genererat</b></h5>
                                  </th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"><h5><b>Fakturerad Abor</b></h5>
                                  </th>
                                  <th className="formanscykelFonsterPC__rowColumns"><h5><b>Fakturanummer</b></h5>
                                  </th>
                                  <th className="formanscykelFonsterPC__rowColumns"><h5>
                                      <b>Faktureringsdatum <button id="formanscykelFonsterPC__sortBenefitWindowByInvoiceDateButton" className="formanscykelFonsterPC__sortButton" onClick={(e) => handleButtonSortByInvoiceDate(e)}>Sortera stigande</button></b>
                                  </h5>
                                  </th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"><h5><b>Antal order</b></h5></th>
                              </tr> : <tr>
                                  <th className="formanscykelFonsterPC__rowColumnsName"></th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"></th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"></th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"></th>
                                  <th className="formanscykelFonsterPC__rowColumns"></th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"></th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"></th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"></th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"></th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"></th>
                                  <th className="formanscykelFonsterPC__rowColumns"></th>
                                  <th className="formanscykelFonsterPC__rowColumns"></th>
                                  <th className="formanscykelFonsterPC__rowColumnsHalf"></th>
                              </tr>}
                              {window.length > 0 && index % 2 === 0 ?
                                  <tr className="formanscykelFonsterPC__rowGray formanscykelFonsterPC__row">
                                      {/* <td className="formanscykelFonsterPC__rowColumnsDate formanscykelFonsterPC__rowColumns"><h4><span className="formanscykelFonsterPC__dateRange">{ window && window.startdate ? window.startdate.slice(0,10) : null} - { window && window.enddate ? window.enddate.slice(0,10) : null}</span></h4></td> */}
                                      {/* <td className="formanscykelFonsterPC__rowColumns"><h4 className="formanscykelFonsterPC__company">{ window && window.company ? window.company.name : null}</h4></td> */}
                                      <td className="formanscykelFonsterPC__rowColumnsName"><h5
                                          className="formanscykelFonsterPC__month">{window ? window.name : null}</h5>
                                      </td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">
                                          <h4>{window && window.windowstatustext ? window.windowstatustext : "-"}</h4>
                                      </td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">
                                          <h4>{window && window.sales_person ? window.sales_person.name : "-"}</h4>
                                      </td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">
                                          <h4>{window && window.calculation_factor ? window.calculation_factor : "-"}</h4>
                                      </td>
                                      <td className="formanscykelFonsterPC__rowColumns">
                                          <h4>{window && window.agreement ? (agreementOptions.filter(agreement => agreement.value === window.agreement))[0].label : "-"}</h4></td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">
                                          <h4>{window && window.window_setup_complete ? "Ja" : "Nej"}</h4>
                                      </td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">
                                          <h4>{window && window.general_portal ? "Ja" : "Nej"}</h4>
                                      </td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">{window && window.affectedStoresObjects && window.affectedStoresObjects.length === 1 ?
                                          <h4>{window.affectedStoresObjects[0].Name}</h4> : null}{window && window.affectedStoresObjects && window.affectedStoresObjects.length > 1 &&
                                          <select>{window && window.affectedStoresObjects && window.affectedStoresObjects.length > 1 ? window.affectedStoresObjects.map((store) =>
                                              <option>{store ? store.Name : null}</option>) : null}</select>}</td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">
                                          <h4>{window && window.ou_generated ? "Ja" : "Nej"}</h4></td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">
                                          <h4>{window && window.outgoing_invoicenr ? "Ja" : "Nej"}</h4></td>
                                      <td className="formanscykelFonsterPC__rowColumns">
                                          <h4>{window && window.outgoing_invoicenr ? window.outgoing_invoicenr : "-"}</h4>
                                      </td>
                                      <td className="formanscykelFonsterPC__rowColumns">
                                          <h4>{window && window.invoice_date ? window.invoice_date.substring(0, 10) : "-"}</h4>
                                      </td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">
                                          <h4>{window && window.orderlist.length > 0 ? window.orderlist.length : "0"}</h4>
                                      </td>
                                  </tr> : <tr className="formanscykelFonsterPC__row">
                                      {/* <td className="formanscykelFonsterPC__rowColumnsDate formanscykelFonsterPC__rowColumns"><h4><span className="formanscykelFonsterPC__dateRange">{window && window.startdate ? window.startdate.slice(0,10) : null} - {window && window.enddate ? window.enddate.slice(0,10) : null}</span></h4></td> */}
                                      {/* <td className="formanscykelFonsterPC__rowColumns"><h4 className="formanscykelFonsterPC__company">{window && window.company ? window.company.name : null}</h4></td> */}
                                      <td className="formanscykelFonsterPC__rowColumnsName"><h5
                                          className="formanscykelFonsterPC__month">{window ? window.name : null}</h5>
                                      </td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">
                                          <h4>{window && window.windowstatustext ? window.windowstatustext : "-"}</h4>
                                      </td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">
                                          <h4>{window && window.sales_person ? window.sales_person.name : "-"}</h4>
                                      </td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">
                                          <h4>{window && window.calculation_factor ? window.calculation_factor : "-"}</h4>
                                      </td>
                                      <td className="formanscykelFonsterPC__rowColumns">
                                          <h4>{window && window.agreement ? (agreementOptions.filter(agreement => agreement.value === window.agreement))[0].label : "-"}</h4></td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">
                                          <h4>{window && window.window_setup_complete ? "Ja" : "Nej"}</h4>
                                      </td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">
                                          <h4>{window && window.general_portal ? "Ja" : "Nej"}</h4>
                                      </td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">{window && window.affectedStoresObjects && window.affectedStoresObjects.length === 1 ?
                                          <h4>{window.affectedStoresObjects[0].Name}</h4> : null}{window && window.affectedStoresObjects && window.affectedStoresObjects.length > 1 &&
                                          <select>{window && window.affectedStoresObjects && window.affectedStoresObjects.length > 1 ? window.affectedStoresObjects.map((store) =>
                                              <option>{store ? store.Name : null}</option>) : null}</select>}</td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">
                                          <h4>{window && window.ou_generated ? "Ja" : "Nej"}</h4></td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">
                                          <h4>{window && window.outgoing_invoicenr ? "Ja" : "Nej"}</h4></td>
                                      <td className="formanscykelFonsterPC__rowColumns">
                                          <h4>{window && window.outgoing_invoicenr ? window.outgoing_invoicenr : "-"}</h4>
                                      </td>
                                      <td className="formanscykelFonsterPC__rowColumns">
                                          <h4>{window && window.invoice_date ? window.invoice_date.substring(0, 10) : "-"}</h4>
                                      </td>
                                      <td className="formanscykelFonsterPC__rowColumnsHalf">
                                          <h4>{window && window.orderlist.length > 0 ? window.orderlist.length : "0"}</h4>
                                      </td>
                                  </tr>}
                          </tbody>
                      </table>
                  )
                  })}
                  <div className="formanscykelFonsterPC__pageButtonsDiv">
                      {numberOfBenefitWindows <= 10 && (
                          <>
                              <button key={"page-1"}
                                      className="formanscykelFonsterPC__pageButton formanscykelFonsterPC__currentPage">{1}</button>
                          </>
                      )}
                      {numberOfBenefitWindows > 10 && parseInt(currentPageRef.current ? currentPageRef.current : "") === 1 && (
                          <>
                              <button key={"page-1"} onClick={(e) => firstPage(e)}
                                      className="formanscykelFonsterPC__pageButton formanscykelFonsterPC__currentPage">{1}</button>
                              <button key={"page-next"} onClick={(e) => nextPage(e)}
                                      className="formanscykelFonsterPC__pageButton">{">"}</button>
                              <button key={"page-last"} onClick={(e) => lastPage(e)}
                                      className="formanscykelFonsterPC__pageButton">{">|"}</button>
                          </>
                      )}
                      {parseInt(currentPageRef.current ? currentPageRef.current : "") > 1 && parseInt(currentPageRef.current ? currentPageRef.current : "") !== 1 && parseInt(currentPageRef.current ? currentPageRef.current : "") !== Math.ceil((numberOfBenefitWindows / 10)) && (
                          <>
                              <button key={"page-first"} onClick={(e) => firstPage(e)} className="formanscykelFonsterPC__pageButton">{"|<"}</button>
                              <button key={"page-prev"} onClick={(e) => previousPage(e)} className="formanscykelFonsterPC__pageButton">{"<"}</button>
                              <button key={"page-current"} className="formanscykelFonsterPC__pageButton formanscykelFonsterPC__currentPage">{currentPageRef.current}</button>
                              <button key={"page-next"} onClick={(e) => nextPage(e)} className="formanscykelFonsterPC__pageButton">{">"}</button>
                              <button key={"page-last"} onClick={(e) => lastPage(e)} className="formanscykelFonsterPC__pageButton">{">|"}</button>
                          </>
                      )}
                      { numberOfBenefitWindows > 10 && parseInt(currentPageRef.current ? currentPageRef.current : "") === Math.ceil((numberOfBenefitWindows / 10)) && (
                          <>
                              <button key={"page-first"} onClick={(e) => firstPage(e)} className="formanscykelFonsterPC__pageButton">{"|<"}</button>
                              <button key={"page-prev"} onClick={(e) => previousPage(e)} className="formanscykelFonsterPC__pageButton">{"<"}</button>
                              <button key={"page-current"} onClick={(e) => lastPage(e)} className="formanscykelFonsterPC__pageButton formanscykelFonsterPC__currentPage">{currentPageRef.current}</button>
                          </>
                      )}
                  </div>
                  <div className="formanscykelFonsterPC__orderDiv">
                    <OrderForm />
                  </div>
              </div>
          </>
      );
}

export default FormanscykelFonsterPC;

import React, {useEffect, useRef, useState} from "react";
import "./FormanscykelFonsterHantering.css";
import SelectReact from 'react-select';
import Modal from "../../components/Modals/Modal-ConfirmAlert";
import ModalHtml from "../../components/Modals/Modal-ConfirmAlertHtml";
import ModalConfirmWithButtons from "../../components/Modals/Modal-ConfirmWithButtons";
import { logout } from "../../Redux/actions/userActions";
import { useDispatch } from "react-redux";
import FormanscykelFonster from "../../components/FormanscykelFonster";
import DatePicker from "react-datepicker";
import {sv} from 'date-fns/esm/locale'
import moment from "moment";
import API from "../../utils/API";
//import Select from "../../components/Select";

function FormanscykelFonsterHantering({shopstaff}) {
    //const [number, setNumber] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showModalHtml, setShowModalHtml] = useState(false);
    const [title, setTitle] = useState("");
    const [titleConfirmWithButtonsModal, setTitleConfirmWithButtonsmModal] = useState("");
    const [showConfirmWithButtonsModal, setShowConfirmWithButtonsModal] = useState(false);
    const [validateBGConfirm, setValidateBGConfirm] = useState(false);
    const [companiesOptions, setCompaniesOptions] = useState([]);
    const [shopsOptions, setShopsOptions] = useState([]);
    const [/*theOrderType*/, setTheOrderType] = useState("");
    const [/*theSortType*/, setTheSortType] = useState("");
    const [/*theCompanyName*/, setTheCompanyName] = useState("");
    const [theCompanyID, setTheCompanyID] = useState("");
    const [/*theShopName*/, setTheShopName] = useState("");
    const [theShopID, setTheShopID] = useState("");
    const [theStartDate, setTheStartDate] = useState("");
    const [theEndDate, setTheEndDate] = useState("");
    const [invoiced, setInvoiced] = useState(false);
    const [aborInvoiced, setAborInvoiced] = useState(false);
    const [searchForBenefitWindows, setSearchForBenefitWindows] = useState(false);
    const [onlyMyAffectedStores, setOnlyMyAffectedStores] = useState(false);
    const [onlyOpenBenefitWindows, setOnlyOpenBenefitWindows] = useState(false);
    const [notOUWindows, setNotOUWindows] = useState(true);
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [adminAccess, setAdminAccess] = useState(false);

    const selectCompanyRef = useRef(null);
    const selectShopRef = useRef(null);

    const dispatch = useDispatch();

    const closeModalHandler = () => setShowModal(false);
    const closeModalHtmlHandler = () => setShowModalHtml(false);
    const closeConfirmWithButtonsModalHandler = () => setShowConfirmWithButtonsModal(false);

    const setValidateBGConfirmHandler = () => setValidateBGConfirm(true);

    const forceClearSelect = () => {
        if (selectCompanyRef.current) {
            selectCompanyRef.current.clearValue();
            selectCompanyRef.current.inputValue = "";
        }
        if (selectShopRef.current) {
            selectShopRef.current.clearValue();
            selectShopRef.current.inputValue = "";
        }
    };

    const customStyles = {
        control: (base) => ({
            ...base,
            position: "relative",
            left: "30px",
            height: "30px",
            width: "250px",
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
        }),
    };

    const fetchBenefitWindows = (e) => {
        e.preventDefault();

        if (theStartDate && theEndDate) {
            let compareStartDate = new Date(theStartDate).getTime();
            let compareEndDate = new Date(theEndDate).getTime();
            if (compareStartDate > compareEndDate){
                setShowModal(true);
                setTitle("Startdatumet kan inte ligga efter slutdatumet");
                return;
            }
        }

        setSearchForBenefitWindows(!searchForBenefitWindows);
    }

    const cleanUpFilter = (e) => {
        e.preventDefault();

        setTheOrderType("");
        setTheCompanyName("");
        setTheCompanyID("");
        setTheStartDate("");
        setTheEndDate("");
        setOnlyMyAffectedStores(false);
        setOnlyOpenBenefitWindows(false);
        setNotOUWindows(true);
        setTheSortType("");
        forceClearSelect();
        document.getElementById("ordersForm").reset();
        setSearchForBenefitWindows(!searchForBenefitWindows);
    }

    const validateBGHandler = (e) => {
        e.preventDefault();

        let titleText = "";

        setLoadingSpinner(true);
        API.validateBG("").then((response) => {
            if (response.status === 200){
                setLoadingSpinner(false);
                if (response.data.OkOrders.NumberOfOrders > 0) {
                    titleText += `OK ordrar: ${response.data.OkOrders.NumberOfOrders} antal ordrar på ${response.data.OkOrders.Companies.length} st bolag.<br/>`;
                    //titleText += `OK ordrar: ${response.data.OkOrders.NumberOfOrders} antal ordrar på ${response.data.OkOrders.Companies.length} bolag med ordernummer:<br/>`;

                    /*
                    response.data.OkOrders.Companies.forEach((company) => {
                        titleText += `${company.Name} `;

                        company.OrderNumbers.forEach((orderNo) => {
                            titleText += ` <a href="/order/${orderNo}" target="_blank">${orderNo}</a>,`;
                        });

                        titleText = titleText.slice(0, -1); // Remove the trailing comma
                        titleText += '<br/>';
                    });
                     */
                }
                if (response.data.OkOrders.NumberOfOrders === 0) {
                    titleText += `OK ordrar: 0 st ordrar på 0 st bolag.<br/>`;
                }
                if (response.data.NotOkOrders.NumberOfOrders > 0) {
                    titleText += `Ej OK ordrar: ${response.data.NotOkOrders.NumberOfOrders} st ordrar på ${response.data.NotOkOrders.Companies.length} bolag:<br/>`;

                    response.data.NotOkOrders.Companies.forEach((company) => {
                        titleText += `(${company.Name}) `;

                        company.OrderNumbers.forEach((order) => {
                            if (order.ContainsDummy)
                                titleText += ` <a href="/order/${order.OrderNo}" target="_blank">${order.OrderNo}</a> (dummyorder),`;
                            else
                                titleText += ` <a href="/order/${order.OrderNo}" target="_blank">${order.OrderNo}</a>,`;
                        });

                        titleText = titleText.slice(0, -1); // Remove the trailing comma
                        titleText += '<br/>';
                    });
                }

                setTitle(titleText);
                setShowModalHtml(true);
                titleText = "";
            } else if (response.status === 401) {
                void dispatch(logout());
            }
        });
    }

    const generateBGHandler = (e) => {
        e.preventDefault();

        setTitleConfirmWithButtonsmModal("Vill du generera BG?");
        setShowConfirmWithButtonsModal(true);
    }

    const generateKALHandler = (e) => {
        e.preventDefault();

        setTitle("KAL generering påbörjad, kan ta en stund...");
        setShowModal(true);

        //setLoadingSpinner(true);
        API.generateKAL().then((response) => {
            if(response.status === 200){
                if(response.data.message) {
                    setTitle(response.data.message);
                    setShowModal(true);
                }
                //setLoadingSpinner(false);
                if (response.data.length === 0){
                    setTitle("Inget underlag för KAL har genererats");
                    setShowModal(true);
                } else {
                    setTitle("KAL genererat och skickat till Dropbox");
                    setShowModal(true);
                }
            } else if (response.status === 400) {
                setTitle("KAL håller redan på att genereras");
                setShowModal(true);
            } else if (response.status === 401) {
                void dispatch(logout());
            }
        });
    }

    const generateOUHandler = (e) => {
        e.preventDefault();

        setTitle("OU generering påbörjad, kan ta en stund...");
        setShowModal(true);

        //setLoadingSpinner(true);
        API.generateOU().then((response) => {
            if(response.status === 200){
                if(response.data.message) {
                    setTitle(response.data.message);
                    setShowModal(true);
                }
                //setLoadingSpinner(false);
                if (response.data.length === 0){
                    setTitle("Inget underlag för OU har genererats");
                    setShowModal(true);
                } else {
                    setTitle("OU genererat och skickat till Dropbox");
                    setShowModal(true);
                }
            } else if (response.status === 400) {
                setTitle("OU håller redan på att genereras");
                setShowModal(true);
            } else if (response.status === 401) {
                void dispatch(logout());
            }
        });
    }

    const handleOnChangeCompany = (selectedOption) => {

        if (selectedOption){
            setTheCompanyName(selectedOption.name);
            setTheCompanyID(selectedOption.value);
        } else {
            setTheCompanyName("");
            setTheCompanyID("");
        }
    }

    const handleOnChangeShop = (selectedOption) => {

        if (selectedOption){
            setTheShopName(selectedOption.name);
            setTheShopID(selectedOption.value);
        } else {
            setTheShopName("");
            setTheShopID("");
        }
    }

    useEffect(() => {
        const getLoggedInUserRights = async () => {
                await API.getLoggedInUserRights()
                    .then((response) => {
                        setAdminAccess(response.data.adminaccess);
                    })
                    .catch((error) => {
                        console.log("Error: ", error);
                    });
            };
        void getLoggedInUserRights();

        const getAllCompanies = async () => {
            await API.getCompany("")
                .then((response) => {
                    let companies = [];

                    companies.push({ value: "", label: "Välj företag", isDisabled: true });
                    response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((company) => {
                        companies.push({ value: company._id, label: company.name });
                    });
                    setCompaniesOptions(companies);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllCompanies();


        const getAllShops = async () => {
            await API.getShopList("")
                .then((response) => {
                    let shops = [];

                    shops.push({ value: "", label: "Välj butik", isDisabled: true });
                    response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((shop) => {
                        shops.push({ value: shop.Code, label: shop.Name });
                    });
                    setShopsOptions(shops);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllShops();

        /*
        const getAllBenefitWindowsStatuses = async () => {
            await API.getBenefitWindowStatus("")
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        }
        getAllBenefitWindowsStatuses();
         */

    }, []);

    useEffect(() => {

        if (validateBGConfirm) {
            //setLoadingSpinner(true);
            setTitle("BG generering påbörjad, kan ta en stund...");
            setShowModal(true);
            API.generateBG("").then((response) => {
                if (response.status === 200){
                    if(response.data.message) {
                        setTitle(response.data.message);
                        setShowModal(true);
                    }
                    //setLoadingSpinner(false);
                    if(response.data.length === 0){
                        setTitle("Inget underlag för BG har genererats");
                        setShowModal(true);
                    } else {
                        setTitle("BG genererat och skickat till Dropbox");
                        setShowModal(true);
                    }
                } else if (response.status === 400) {
                    setTitle("BG håller redan på att genereras");
                    setShowModal(true);
                } else if (response.status === 401) {
                    void dispatch(logout());
                }
            });
            setValidateBGConfirm(false);
        }
    },[validateBGConfirm])

    useEffect(() => {
        if (notOUWindows)
            setOnlyOpenBenefitWindows(false)
    }, [notOUWindows])

    useEffect(() => {
        if (onlyOpenBenefitWindows)
            setNotOUWindows(false)
    }, [onlyOpenBenefitWindows])

    useEffect( () => {
        invoiced ? setAborInvoiced(true) : setAborInvoiced(false);
    }, [invoiced])

    return (
        <div className="formanscykelFonsterHantering">
            <div className="formanscykelFonsterHantering__BGKALButtonsDiv"><h1 className="formanscykelFonsterHantering__title">Beställningsfönster</h1>{adminAccess && <> <button className="formanscykelFonsterHantering__BGButton" onClick={(e) => validateBGHandler(e)}>Validera BG</button> <button className="formanscykelFonsterHantering__BGButton" onClick={(e) => generateBGHandler(e)} >Generera BG</button> <button className="formanscykelFonsterHantering__KALButton" onClick={(e) => generateKALHandler(e)} >Generera KAL</button> <button className="formanscykelFonsterHantering__OUButton" onClick={(e) => generateOUHandler(e)} >Generera OU</button> </>}</div>
            <form id="ordersForm">
                <hr />
                <div className="formanscykelFonsterHantering__filter">
                    <ModalConfirmWithButtons
                        showModal={showConfirmWithButtonsModal}
                        closeModalHandler={closeConfirmWithButtonsModalHandler}
                        setConfirmHandler={setValidateBGConfirmHandler}
                        title={titleConfirmWithButtonsModal}
                        buttonName="Stäng"
                    />
                    <Modal
                        showModal={showModal}
                        closeModalHandler={closeModalHandler}
                        title={title}
                        buttonName="Stäng"
                    />
                    <ModalHtml
                        showModal={showModalHtml}
                        closeModalHandler={closeModalHtmlHandler}
                        title={title}
                        buttonName="Stäng"
                    />
                    <div className="formanscykelFonsterHantering__filterOpt">
                        {/*
                        <input
                                className="formanscykelFonsterHantering__inputText"
                                placeholder="Sök"
                                type="text"
                                name="inputText"
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                        />*/}
                        <div className="formanscykelFonsterHantering__filterInputContainer">
                            <div className="formanscykelFonsterHantering__filterSearchDiv">
                                <h6>Företag:</h6>
                                <SelectReact
                                    className="itSupport__select formanscykelFonsterHantering__filterSearchSelect"
                                    name="companies"
                                    onChange={handleOnChangeCompany}
                                    options={companiesOptions}
                                    styles={customStyles}
                                    ref={selectCompanyRef}
                                    menuPortalTarget={document.body}
                                    isClearable
                                />
                                <h6>Butik:</h6>
                                <SelectReact
                                    className="itSupport__select formanscykelFonsterHantering__filterSearchSelect"
                                    name="shops"
                                    onChange={handleOnChangeShop}
                                    options={shopsOptions}
                                    styles={customStyles}
                                    ref={selectShopRef}
                                    menuPortalTarget={document.body}
                                    isClearable
                                />
                                <label>Startdatum:</label>
                                <div className="formanscykelFonsterHantering__customDatePickerWidth">
                                    <DatePicker
                                        className="formanscykelFonsterHantering__inputTypeDate"
                                        dateFormat="yyyy-MM-dd"
                                        selected={theStartDate}
                                        onChange={(date) => setTheStartDate(date)}
                                        id="orderStartDate"
                                        name="orderStartDate"
                                        locale={sv}
                                        required
                                    />
                                </div>
                                <label>Slutdatum:</label>
                                <div className="formanscykelFonsterHantering__customDatePickerWidth">
                                    <DatePicker
                                        className="formanscykelFonsterHantering__inputTypeDate"
                                        dateFormat="yyyy-MM-dd"
                                        selected={theEndDate}
                                        onChange={(date) => setTheEndDate(date)}
                                        id="orderEndDate"
                                        name="orderEndDate"
                                        locale={sv}
                                        required
                                    />
                                </div>
                                <input onClick={() => setOnlyMyAffectedStores(!onlyMyAffectedStores)} type="checkbox" id="onlyMyAffectedStores" name="onlyMyAffectedStores" value="" checked={onlyMyAffectedStores}/>
                                <p className="formanscykelFonsterHantering__checkboxText" >Endast fönster för mina butiker</p>
                                <input onClick={() => setOnlyOpenBenefitWindows(!onlyOpenBenefitWindows)} type="checkbox" id="onlyOpenBenefitWindows" name="onlyOpenBenefitWindows" value="" checked={onlyOpenBenefitWindows}/>
                                <p className="formanscykelFonsterHantering__checkboxText" >Endast öppna fönster</p>
                                <input onClick={() => setNotOUWindows(!notOUWindows)} type="checkbox" id="notOUWindows" name="notOUWindows" value="" checked={notOUWindows}/>
                                <p className="formanscykelFonsterHantering__checkboxText" >Ej klara OU</p>
                                <input onClick={(e) => setInvoiced(!invoiced)} type="checkbox" id="invoiced" name="invoiced" value={invoiced} />
                                <p className="formanscykelFonsterHantering__checkboxText">Fakturerat Abor</p>
                                <button className="formanscykelFonsterHantering__searchBtn" type="submit" onClick={(e) => fetchBenefitWindows(e)}>Sök via filter</button>
                            </div>
                            {/*
                            <Select value={theOrderType} onChange={(e) => setTheOrderType(e.target.value)} title="Status" options={["Pågående","Klart att leverera", "Stopp för förändring", "Fönstret stängt"]} />
                            <label className="formanscykelFonsterHantering__sortLabel"><b>Sortera</b></label>
                            <Select value={theSortType} onChange={(e) => setTheSortType(e.target.value)} title="Sortering" options={["Pågående","Klart att leverera", "Stopp för förändring", "Fönstret stängt"]} />
                            */}
                        </div>
                            {/*
            <Select title="Datumintervall" options={["Ramnummer","Fakturanummer"]} />
            */}
                        {/*
            <Select title="Beställningsfönster" options={["Ramnummer","Fakturanummer"]} />
            */}
                        <button onClick={cleanUpFilter} className="formanscykelFonsterHantering__cleanUpFilterButton"><span>Rensa filter</span></button>
                    </div>
                </div>
            </form>
            { loadingSpinner && <div className="formanscykelFonsterHantering__loadingDiv"><h1>Laddar</h1><div className="formanscykelFonsterHantering__loader"></div></div> }
            {!searchForBenefitWindows && <FormanscykelFonster fromdate={theStartDate ? moment(theStartDate).format("yyyy-MM-DD") : ""} todate={theEndDate ? moment(theEndDate).format("yyyy-MM-DD") : ""} companyID={theCompanyID} onlyOpenBenefitWindows={onlyOpenBenefitWindows} onlyAffectedStores={onlyMyAffectedStores} notOUWindows={notOUWindows} shops={theShopID} aborInvoiced={aborInvoiced}/> }
            {searchForBenefitWindows && <FormanscykelFonster fromdate={theStartDate ? moment(theStartDate).format("yyyy-MM-DD") : ""} todate={theEndDate ? moment(theEndDate).format("yyyy-MM-DD") : ""} companyID={theCompanyID} onlyOpenBenefitWindows={onlyOpenBenefitWindows} onlyAffectedStores={onlyMyAffectedStores} notOUWindows={notOUWindows} shops={theShopID} aborInvoiced={aborInvoiced}/> }
        </div>
    );
}

export default FormanscykelFonsterHantering;

import React from "react";
import "./style.css";

function Comment({ comment }) {
    return (
        <div className="comment__commentDiv">
            <h6 className="comment__date">{comment.date_created.slice(0,10)} {comment.edited_by_id ? ` ( Ändrad ${comment.last_edit.slice(0,10)} ${comment.edited_by_name ? comment.edited_by_name : ""})` : null}</h6>
            <h6><b>Kommentar {comment.created_by_name ? `från ${comment.created_by_name}` : null}</b></h6>
            <h6 className="comment__comment_text" dangerouslySetInnerHTML={{__html: comment.comment_text}}></h6>
        </div>
    );
}

export default Comment;
import React, { useRef } from "react";
import "./style.css";
import { useReactToPrint } from "react-to-print";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import { ComponentToPrint } from "../ComponentToPrint";

function PrintComponent() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({ content: () => componentRef.current });
  return (
    <div>
      <div style={{ display: "none" }}>
        <ComponentToPrint ref={componentRef} />
      </div>
      <button className="printButton" onClick={handlePrint}>
        <div className="buttonText">
          <PrintOutlinedIcon /> Skriv ut kvittens
        </div>
      </button>
    </div>
  );
}

export default PrintComponent;

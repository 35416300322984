import React, {useEffect, useState, useRef} from "react";
import "./Company.css";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Header from "../../components/Header";
import ModalBenefitWindowConditions from "../../components/Modals/Modal-BenefitWindowConditions";
import ModalConfirmAlert from "../../components/Modals/Modal-ConfirmAlert";
import {logout} from "../../Redux/actions/userActions";
import {useDispatch} from "react-redux";
import ModalAddNewContact from "../../components/Modals/Modal-AddNewContact";
import ModalAddNewSalesperson from "../../components/Modals/Modal-AddNewSalesperson";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DatePicker from "react-datepicker";
import {sv} from 'date-fns/esm/locale'
import moment from "moment";
import API from "../../utils/API";
import {TextField} from "@material-ui/core";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CommentCompanyOrWindow from "../../components/CommentCompanyOrWindow";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ModalAddNewCommentCompany from "../../components/Modals/Modal-AddNewCommentCompany";
import ModalEditCommentCompany from "../../components/Modals/Modal-EditCommentCompany";
import AddCommentIcon from "@material-ui/icons/AddComment";
import WarningIcon from '@material-ui/icons/Warning';
import DoneIcon from '@material-ui/icons/Done';
import LockIcon from '@material-ui/icons/Lock';
import Tooltip from "@material-ui/core/Tooltip";

function Company({ id = "" }) {
    const [createCompany, setCreateCompany] = useState(false);
    const [editCompany, setEditCompany] = useState(false);
    const [showModalBenefitWindowConditions, setShowModalBenefitWindowConditions] = useState(false);
    const [publicPortal, setPublicPortal] = useState(false);
    //const [standardAgreement, setStandardAgreement] = useState(false);
    //const [securityAgreement, setSecurityAgreement] = useState(true);
    const [customerSpecificAgreement, setCustomerSpecificAgreement] = useState(false);
    const [bankIdRequired, setBankIdRequired] = useState(false);
    const [weeklyReconciliation, setWeeklyReconciliation] = useState(false);
    const [ongoingReconciliation, setOngoingReconciliation] = useState(false);
    const [title, setTitle] = useState("");
    const [unitOrCostplaceOptions, setUnitOrCostplaceOptions] = useState([]);
    const [loadTheUnitOrCostCenters, setLoadTheUnitOrCostCenters] = useState([]);
    const [shopsSportsonOptions, setShopsSportsonOptions] = useState([]);
    const [shopsExternalPartnersOptions, setShopsExternalPartnersOptions] = useState([]);
    const [shopsMapList, setShopsMapList] = useState([]);
    const [externalPartnersMapList, setExternalPartnersMapList] = useState([]);
    const [theTags, /*setTheTags*/] = useState([]);
    const [theWebsites, /*setTheWebsites*/] = useState([]);
    const [theAlternateName, setTheAlternateName] = useState("");
    const [theCompanyRepresentative, setTheCompanyRepresentative] = useState("");
    const [theAddress, setTheAddress] = useState("");
    const [theBenefitSubcompanyID, setTheBenefitSubcompanyID] = useState([]);
    const [theBenefitCompanyID, setTheBenefitCompanyID] = useState("");
    const [theBenefitCostplaceIDs, setTheBenefitCostplaceIDs] = useState([]);
    const [theOptionalSpellings, setTheOptionalSpellings] = useState([]);
    const [theOrgNr, setTheOrgNr] = useState("");
    const [theDeliveryOptions, setTheDeliveryOptions] = useState([]);
    const [theRulesAndConditions, setTheRulesAndConditions] = useState([]);
    const [theExternalPartners, setTheExternalPartners] = useState([]);
    const [theSportsonShops, setTheSportsonShops] = useState([]);
    const [theApplicationIds, setTheApplicationIds] = useState([]);
    const [loadTheDeliveryOptions, setLoadTheDeliveryOptions] = useState([]);
    const [loadTheSportsonShops, setLoadTheSportsonShops] = useState([]);
    const [loadTheApplicationIds, setLoadTheApplicationIds] = useState([]);
    const [loadTheExternalPartners, setLoadTheExternalPartners] = useState([]);
    const [theShownInContacts, setTheShownInContacts] = useState(false);
    const [theCompanyID, setTheCompanyID] = useState("");
    const [theCompanyName, setTheCompanyName] = useState("");
    const [showModalConfirmAlert, setShowModalConfirmAlert] = useState(false);
    const [companiesOptions, setCompaniesOptions] = useState([]);
    const [benefitCompanyContactsOptions, setBenefitCompanyContactsOptions] = useState([]);
    const [personalcyklarContactsOptions, setPersonalcyklarContactsOptions] = useState([]);
    const [deliveryOptions, setDeliveryOptions] = useState([]);
    const [theSalesperson, setTheSalesperson] = useState("");
    const [theConditions, setTheConditions] = useState("");
    const [thePortalUsername, setThePortalUsername] = useState("");
    const [thePortalPassword, setThePortalPassword] = useState("");
    const [loadTheSalesperson, setLoadTheSalesperson] = useState([]);
    const [loadTheContacts, setLoadTheContacts] = useState([]);
    const [theContacts, setTheContacts] = useState([]);
    const [loadCompanyWindowRules, setLoadCompanyWindowRules] = useState([]);
    const [showModalAddNewContact, setShowModalAddNewContact] = useState(false);
    const [showModalAddNewSalesperson, setShowModalAddNewSalesperson] = useState(false);
    const [theCalculationFactor, setTheCalculationFactor] = useState("");
    const [applicationsOptions, setApplicationsOptions] = useState([]);
    const [agreementOptions, setAgreementOptions] = useState([]);
    const [isDisabledBenefitWindowConditions, setIsDisabledBenefitWindowConditions] = useState(false);
    const [expandedComments, setExpandedComments] = useState(true);
    const [comments, setComments] = useState([]);
    const [showModalAddNewComment, setShowModalAddNewComment] = useState(false);
    const [showModalEditComment, setShowModalEditComment] = useState(false);
    const [theCommentID, setTheCommentID] = useState(undefined);
    const [theCommentText, setTheCommentText] = useState(undefined);
    const [theAgreement, setTheAgreement] = useState("");
    const [loadTheAgreement, setLoadTheAgreement] = useState([]);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [filesChosen, setFilesChosen] = useState(false);
    const [areFilesUploaded, setAreFilesUploaded] = useState(false);
    const [/*theAgreementRef*/, setTheAgreementRef] = useState([]);
    const [agreementUploaded, setAgreementUploaded] = useState(false);

    const selectCompanyRef = useRef(null);
    const selectDeliveryOptionsRef = useRef(null);
    const selectUnitsOrCostplacesRef = useRef(null);
    const selectShopsRef = useRef(null);
    const selectExternalPartnersRef = useRef(null);
    const selectContactsRef = useRef(null);
    const selectApplicationsRef = useRef(null);
    const selectSalespersonRef = useRef(null);
    const selectAgreementRef = useRef(null);
    const dispatch = useDispatch();

    const closeModalConfirmAlertHandler = () => setShowModalConfirmAlert(false);
    const closeModalBenefitWindowConditionsHandler = () => setShowModalBenefitWindowConditions(false);
    const closeModalAddNewContactHandler = () => setShowModalAddNewContact(false);
    const closeModalAddNewSalespersonHandler = () => setShowModalAddNewSalesperson(false);
    const closeModalAddNewCommentHandler = () => setShowModalAddNewComment(false);
    const closeModalEditCommentHandler = () => setShowModalEditComment(false);

    const addNewCommentHandler = (e, companyID) => {
        e.preventDefault();

        if(companyID && companyID !== "") {
            setShowModalAddNewComment(true);
            setTitle("Lägg till kommentar");
        } else {
            setShowModalConfirmAlert(true);
            setTitle("Du måste välja ett bolag för att kunna lägga till en kommentar");
        }
    }

    const editCommentHandler = (e, companyID, commentID, commentText) => {
        e.preventDefault();

        setTheCommentID(commentID);
        setTheCommentText(commentText);
        setShowModalEditComment(true);
        setTitle("Ändra kommentar");
    }

    const deleteCommentHandler = (e, companyID, commentID) => {
        e.preventDefault();

        const data = {
            CommentId: commentID,
        };

        API.deleteCommentOnCompany(data, companyID).then((response) => {
            if (response.status === 200){
                handleGetCompanyComments();
            } else if (response.status === 401) {
                void dispatch(logout());
            }
        });
    }

    const forceClearSelect = () => {

        if (selectDeliveryOptionsRef.current) {
            selectDeliveryOptionsRef.current.clearValue();
            selectDeliveryOptionsRef.current.inputValue = "";
        }
        if (selectUnitsOrCostplacesRef.current) {
            selectUnitsOrCostplacesRef.current.clearValue();
            selectUnitsOrCostplacesRef.current.inputValue = "";
        }
        if (selectShopsRef.current) {
            selectShopsRef.current.clearValue();
            selectShopsRef.current.inputValue = "";
        }
        if (selectExternalPartnersRef.current) {
            selectExternalPartnersRef.current.clearValue();
            selectExternalPartnersRef.current.inputValue = "";
        }
        if (selectContactsRef.current) {
            selectContactsRef.current.clearValue();
            selectContactsRef.current.inputValue = "";
        }
        if (selectApplicationsRef.current) {
            selectApplicationsRef.current.clearValue();
            selectApplicationsRef.current.inputValue = "";
        }
        if (selectSalespersonRef.current) {
            selectSalespersonRef.current.clearValue();
            selectSalespersonRef.current.inputValue = [];
        }
        if (selectAgreementRef.current) {
            selectAgreementRef.current.clearValue();
            selectAgreementRef.current.inputValue = "";
        }
    };

    const forceClearSelectOnPublicPortal = () => {

        if (selectDeliveryOptionsRef.current) {
            selectDeliveryOptionsRef.current.clearValue();
            selectDeliveryOptionsRef.current.inputValue = "";
        }
        if (selectUnitsOrCostplacesRef.current) {
            selectUnitsOrCostplacesRef.current.clearValue();
            selectUnitsOrCostplacesRef.current.inputValue = "";
        }
        if (selectExternalPartnersRef.current) {
            selectExternalPartnersRef.current.clearValue();
            selectExternalPartnersRef.current.inputValue = "";
        }
        if (selectContactsRef.current) {
            selectContactsRef.current.clearValue();
            selectContactsRef.current.inputValue = "";
        }
    };

    const handleResetCompanyFields = () => {

        setTheOrgNr("");
        setTheCompanyName("");
        setTheAlternateName("");
        setTheSalesperson("");
        loadSalesperson([]);
        setTheContacts([]);
        setTheAddress("");
        setPublicPortal(false);
        setTheBenefitCompanyID("");
        setTheBenefitCostplaceIDs([]);
        setLoadTheUnitOrCostCenters([]);
        setTheSportsonShops([]);
        forceClearSelect();
        setLoadTheSportsonShops([]);
        setTheApplicationIds([]);
        setLoadTheApplicationIds([]);
        setTheDeliveryOptions([]);
        setLoadTheDeliveryOptions([]);
        setTheExternalPartners([]);
        setLoadTheExternalPartners([]);
        setBankIdRequired(false);
        setTheCalculationFactor("");
        setTheRulesAndConditions([]);
        setLoadCompanyWindowRules([]);
        //setStandardAgreement(false);
        //setSecurityAgreement(true);
        //setCustomerSpecificAgreement(false);
        setLoadTheAgreement([]);
        setAgreementUploaded(false);
        setWeeklyReconciliation(false);
        setOngoingReconciliation(false);
        setTheConditions("");
        setThePortalUsername("");
        setThePortalPassword("");
        setTheDeliveryOptions([]);
        setTheCompanyRepresentative("");
        setComments([]);
    }

    const handleResetCompanyFieldsOnPublicPortal = () => {

        setTheContacts([]);
        setTheAddress("");
        setTheBenefitCostplaceIDs([]);
        setLoadTheUnitOrCostCenters([]);
        forceClearSelectOnPublicPortal();
        setTheDeliveryOptions([]);
        setLoadTheDeliveryOptions([]);
        setTheExternalPartners([]);
        setLoadTheExternalPartners([]);
        setBankIdRequired(false);
        setTheCalculationFactor("");
        setTheRulesAndConditions([]);
        setLoadCompanyWindowRules([]);
        //setStandardAgreement(false);
        //setSecurityAgreement(true);
        //setCustomerSpecificAgreement(false);
        setWeeklyReconciliation(false);
        setOngoingReconciliation(false);
        setTheConditions("");
        setTheDeliveryOptions([]);
        setTheCompanyRepresentative("");
        setComments([]);
    }

    const handleCreateCompanySwitch = (e) => {
        e.preventDefault();
        setCreateCompany(false);

        if (!createCompany) {
            setCreateCompany(true);
            setEditCompany(false);
            setTheCompanyID("");
            handleResetCompanyFields();
            //setStandardAgreement(true);
            setTheApplicationIds(handleSetOptions([(applicationsOptions.filter(application => application.label === "Sportson (SE)"))[0]]));
            setWeeklyReconciliation(true);
            setTheDeliveryOptions(handleSetOptions([(deliveryOptions.filter(deliveryOption => deliveryOption.label === "Hämtar i vald butik"))[0]]));
        }
        setFilesChosen(false);
        setAreFilesUploaded(false);
    }

    const handleEditCompanySwitch = (e) => {
        e.preventDefault();

        setEditCompany(true);
        setCreateCompany(false);
        handleResetCompanyFields();
        setFilesChosen(false);
        setAreFilesUploaded(false);
    }

    const isValidOrgNr = (orgNr) => {
        const orgNrPattern = /^(\d{6})-(\d{4})$/; // Regex for an org. nr. in the format XXXXXX-XXXX

        return orgNrPattern.test(orgNr);
    };

    const handleCreateCompany = (e) => {
        const formData = new FormData();

        e.preventDefault();

        if (publicPortal && theOrgNr === ""){
            setShowModalConfirmAlert(true);
            setTitle("Fältet för Org. nr. får inte vara tomt om fältet för gemensamma portalen är ibockad");
            return;
        }
        if (!isValidOrgNr(theOrgNr)) {
            setShowModalConfirmAlert(true);
            setTitle("Org. nr. är ej giltig måste vara enligt formatet XXXXXX-XXXX");
            return;
        }
        if (theSalesperson === ""){
            setShowModalConfirmAlert(true);
            setTitle("Fältet för Säljare Personalcyklar får inte vara tomt");
            return;
        }

        if (theAgreement === "" && !publicPortal){
            setShowModalConfirmAlert(true);
            setTitle("Ett UH-avtal måste väljas vid skapa bolag");
            return;
        }

        if (customerSpecificAgreement && theAgreement !== "")
            if(!filesChosen) {
                setShowModalConfirmAlert(true);
                setTitle("Du måste välja ett kundspecifikt UH-avtal att ladda upp");
                return;
            }

        const data = {
            tags: theTags,
            websites: theWebsites,
            name: theCompanyName,
            alternate_name: theAlternateName,
            personalcyklar_salesperson: theSalesperson,
            address: theAddress,
            general_portal: publicPortal,
            benefit_subcompany_id: theBenefitSubcompanyID,
            benefit_company_id: !publicPortal ? `${theCompanyName} (${theOrgNr})` : null,
            benefit_costplace_id: theBenefitCostplaceIDs,
            optional_spellings: theOptionalSpellings,
            org_nr: theOrgNr,
            window_rules: theRulesAndConditions,
            external_partners: theExternalPartners,
            sportson_shops: theSportsonShops,
            shown_in_contacts: theShownInContacts,
            bank_id_required: bankIdRequired,
            calculation_factor: theCalculationFactor,
            contact: theContacts,
            //standard_agreement: customerSpecificAgreement ? false : standardAgreement,
            //security_agreement: securityAgreement,
            customer_specific_agreement: customerSpecificAgreement,
            agreement: theAgreement,
            weekly_reconciliation: weeklyReconciliation,
            ongoing_reconciliation: ongoingReconciliation,
            conditions: theConditions,
            //portal_username: thePortalUsername,
            //portal_password: thePortalPassword,
            delivery_options: theDeliveryOptions,
            company_representative: theCompanyRepresentative,
            ApplicationIds: theApplicationIds.length > 0 ? theApplicationIds : applicationsOptions.filter(application => application.label === "Sportson (SE)")[0].value,
        };

        API.createCompany(data).then((response) => {
            if (response.status === 201){
                setShowModalConfirmAlert(true);
                setTitle("Bolag skapat");
                handleResetCompanyFields();
                setCreateCompany(false);
                setCreateCompany(true);

                if (selectedFiles[0]) {
                    formData.append('file', selectedFiles[0]);
                    API.uploadAgreement(response.data._id, formData)
                        .then(function (response) {
                            if (response.status === 201) {
                                setSelectedFiles([]);
                                setTheAgreementRef([]);
                                setFilesChosen(false);
                                setShowModalConfirmAlert(true);
                                // handleResetCompanyFields();
                            } else if (response.status >= 300) {
                                setShowModalConfirmAlert(true);
                                setTitle(`${response.data.message} - ${response.data.details}`);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            setShowModalConfirmAlert(true);
                            setTitle("Avtalet kunde ej laddas upp.");
                        });
                }
            } else if (response.status >= 300) {
                setShowModalConfirmAlert(true);
                setTitle(`${response.data.message} - ${response.data.details}`);
            } else if (response.status === 401) {
                void dispatch(logout());
            } else if (response.status === 503) {
                setShowModalConfirmAlert(true);
                setTitle("Problem med servern. Försök igen senare.");
            }
        });

    }

    const handleUpdateCompany = (e) => {
        e.preventDefault();

        if (theCompanyName === ""){
            setShowModalConfirmAlert(true);
            setTitle("Fältet för bolagsnamn får inte vara tomt");
            return;
        }
        if (publicPortal && theOrgNr === ""){
            setShowModalConfirmAlert(true);
            setTitle("Fältet för Org. nr. får inte vara tomt om fältet för gemensamma portalen är ibockad");
            return;
        }
        if (!isValidOrgNr(theOrgNr)) {
            setShowModalConfirmAlert(true);
            setTitle("Org. nr. är ej giltig måste vara enligt formatet XXXXXX-XXXX");
            return;
        }
        if (theSalesperson === ""){
            setShowModalConfirmAlert(true);
            setTitle("Fältet för Säljare Personalcyklar får inte vara tomt");
            return;
        }

        if (theAgreement === "" && loadTheAgreement.length === 0 && !publicPortal){
            setShowModalConfirmAlert(true);
            setTitle("Ett UH-avtal måste väljas vid skapa bolag");
            return;
        }

        // TODO take in consideration if customerSpecificAgreement is already uploaded beforehand for the chosen benefit window (when flag for this is added to the backend)

        if(customerSpecificAgreement && theAgreement !== "" && !agreementUploaded)
            if(!areFilesUploaded /* && agreement is not already uploaded */) {
                setShowModalConfirmAlert(true);
                setTitle("Du måste ladda upp ett kundspecifikt UH-avtal");
                return;
            }

        const data = {
            tags: theTags,
            websites: theWebsites,
            name: theCompanyName,
            alternate_name: theAlternateName,
            personalcyklar_salesperson: theSalesperson,
            address: theAddress,
            general_portal: publicPortal,
            benefit_subcompany_id: theBenefitSubcompanyID,
            benefit_company_id: theBenefitCompanyID,
            benefit_costplace_id: theBenefitCostplaceIDs,
            optional_spellings: theOptionalSpellings,
            org_nr: theOrgNr,
            window_rules: theRulesAndConditions,
            external_partners: theExternalPartners,
            sportson_shops: theSportsonShops,
            shown_in_contacts: theShownInContacts,
            bank_id_required: bankIdRequired,
            calculation_factor: theCalculationFactor,
            contact: theContacts,
            //standard_agreement: customerSpecificAgreement ? false : standardAgreement,
            //security_agreement: securityAgreement,
            customer_specific_agreement: customerSpecificAgreement,
            agreement: theAgreement,
            weekly_reconciliation: weeklyReconciliation,
            ongoing_reconciliation: ongoingReconciliation,
            conditions: theConditions,
            portal_username: thePortalUsername,
            portal_password: thePortalPassword,
            delivery_options: theDeliveryOptions,
            company_representative: theCompanyRepresentative,
            ApplicationIds: theApplicationIds,
        };

        API.updateCompany(data, theCompanyID).then((response) => {
            if (response.status === 200){
                setShowModalConfirmAlert(true);
                setTitle("Bolag ändrat");
            } else if (response.status >= 300) {
                setShowModalConfirmAlert(true);
                setTitle(`${response.data.message} - ${response.data.details}`);
            } else if (response.status === 401) {
                void dispatch(logout());
            } else if (response.status === 503) {
                setShowModalConfirmAlert(true);
                setTitle("Problem med servern. Försök igen senare.");
            }
        }).then(() => {
            setEditCompany(false);
            setEditCompany(true);
            handleResetCompanyFields();
            if (selectCompanyRef.current) {
                selectCompanyRef.current.clearValue();
                selectCompanyRef.current.inputValue = "";
            }
        });
    }

    const handleSetCompanyOption = (selectedOption) => {
        const indexOfFirst = selectedOption?.label?.indexOf("(");

        if (selectedOption) {
            setTheCompanyID(selectedOption.value);
            setTheCompanyName(selectedOption.label.substring(0, indexOfFirst - 1));
        } else {
            setTheCompanyID("");
            setTheCompanyName("");
            handleResetCompanyFields();
        }
    }

    const handleOnChangeCompany = (selectedOption) => {
        setFilesChosen(false);
        setAreFilesUploaded(false);
        setAgreementUploaded(false);

        selectedOption ? handleSetCompanyOption(selectedOption) : handleSetCompanyOption();
    }

    const handleOnSearch = (e) => {
        const getCompanyOnSearch = async () => {
            if (e.target.value !== "")
                await API.getCompanySearch("", e.target.value, "",1)
                    .then((response) => {
                        if(response.data[0])
                            if(e.target.value === response.data[0].name) {
                                setTheCompanyID(response.data[0]._id);
                            }
                    })
                    .catch((error) => {
                        console.log("Error: ", error);
                    });
        };
        void getCompanyOnSearch();
    }

    const handleGetCompanyComments = () => {
        const getCompanyComments = async () => {
            await API.getCompanySearch(theCompanyID, "", 0,1)
                .then((response) => {
                    if(response.data[0])
                        setComments(response.data[0].comments);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getCompanyComments();
    }

    const handleSetOptions = (selectedOptions) => {

        let options = [];

        if (selectedOptions.length > 0) {
            selectedOptions.forEach((option) => {
                options.push(option.value);
            });
        }
        return options;
    }

    const handleOnChangeDeliveryOptions = (selectedOptions) => {
        setTheDeliveryOptions(handleSetOptions(selectedOptions));
    }

    const handleOnChangeApplicationOptions = (selectedOptions) => {
        setTheApplicationIds(handleSetOptions(selectedOptions));
    }

    const handleBenefitWindowConditions = (e) => {
        e.preventDefault();

        if(publicPortal)
            setIsDisabledBenefitWindowConditions(true);

        setShowModalBenefitWindowConditions(true);
        setTitle("Begränsningar");
    }

    const handleOnChangeUnitOrCostCenters = (selectedOptions) => {
        setTheBenefitCostplaceIDs(handleSetOptions(selectedOptions));
    }

    const loadDeliveryOptions = (deliveryOptions) => {

        let tempDeliveryOptions = [];

        setLoadTheDeliveryOptions([]);

        if (deliveryOptions.length > 0) {
            deliveryOptions.forEach((deliverOptions) => {
                tempDeliveryOptions.push({value: deliverOptions._id, label: deliverOptions.name});
            });
        }

        setLoadTheDeliveryOptions(tempDeliveryOptions);
    }

    const loadApplicationOptions = (applications) => {
        let tempApplications = [];
        let theLabel = "";

        setLoadTheApplicationIds([]);

        if (applications.length > 0) {
            applications.forEach((application) => {
                applicationsOptions.forEach((applicationOption) => {
                    if (applicationOption.value === application) {
                        theLabel = applicationOption.label;
                        tempApplications.push({value: application, label: theLabel});
                    }
                });
            });
        }

        setLoadTheApplicationIds(tempApplications);
    }

    const loadUnitOrCostCenters = (unitOrCostCenters) => {

        let tempUnitOrCostCenters = [];

        setLoadTheUnitOrCostCenters([]);

        if (unitOrCostCenters.length > 0) {
            unitOrCostCenters.forEach((unitOrCostCenter) => {
                tempUnitOrCostCenters.push({value: unitOrCostCenter, label: unitOrCostCenter});
            });
        }
        setLoadTheUnitOrCostCenters(tempUnitOrCostCenters);
    }

    const loadSportsonShops = (sportsonShops) => {

        let tempSportsonShops = [];
        let theLabel = "";

        setLoadTheSportsonShops([]);

        if (sportsonShops.length > 0) {
            sportsonShops.forEach((sportsonShop) => {
                shopsMapList.forEach((shop) => {
                    if (shop.value === sportsonShop) {
                        theLabel = shop.label;
                        tempSportsonShops.push({value: sportsonShop, label: theLabel});
                    }
                });
            });
        }
        setLoadTheSportsonShops(tempSportsonShops);
    }

    const loadExternalPartners = (partnersExternal) => {

        let tempExternalPartners = [];
        let theLabel = "";

        setLoadTheExternalPartners([]);

        if (partnersExternal.length > 0) {
            partnersExternal.forEach((externalPartner) => {
                externalPartnersMapList.forEach((partner) => {
                    if (partner.value === externalPartner) {
                        theLabel = partner.label;
                        tempExternalPartners.push({value: externalPartner, label: theLabel});
                    }
                });
            });
        }
        setLoadTheExternalPartners(tempExternalPartners);
    }

    const handleOnChangeSportsonShops = (selectedOptions) => {
        setTheSportsonShops(handleSetOptions(selectedOptions));
    }

    const handleOnChangeContacts = (selectedOptions) => {
        setTheContacts(handleSetOptions(selectedOptions));
    }

    const handleOnChangeSalesPerson = (selectedOption) => {
        selectedOption ? setTheSalesperson(selectedOption.value) : setTheSalesperson("");
    }

    const handleOnChangeAgreement = (selectedOption) => {
        selectedOption ? setTheAgreement(selectedOption.value) : setTheAgreement("");
        selectedOption ? setCustomerSpecificAgreement(selectedOption.upload_file_required) : setCustomerSpecificAgreement(false);
    }

    const loadSalesperson = (salesPerson) => {

        let tempSalesPersons = [];

        setLoadTheSalesperson([]);

        if (salesPerson.length > 0)
            tempSalesPersons.push({value: salesPerson[0]._id, label: salesPerson[0].name});

        setLoadTheSalesperson(tempSalesPersons);
    }

    const loadAgreement = (agreement) => {

        let tempAgreement = [];

        setLoadTheAgreement([]);

        agreementOptions.forEach((aAgreement) => {
            if (aAgreement.value === agreement) {
                tempAgreement.push({value: agreement, label: aAgreement.label});
            }
        });
        setLoadTheAgreement(tempAgreement);
    }

    const loadContacts = (contacts) => {

        let tempContacts = [];

        setLoadTheContacts([]);

        if (contacts.length > 0) {
            contacts.forEach((contact) => {
                if (!contact.salesperson)
                    tempContacts.push({value: contact._id, label: contact.name});
            });
        }
        setLoadTheContacts(tempContacts);
    }

    const handleOnChangeExternalPartners = (selectedOptions) => {
        setTheExternalPartners(handleSetOptions(selectedOptions));
    }

    const addNewContact = (e) => {
        e.preventDefault();

        setShowModalAddNewContact(true);
        setTitle("Lägg till ny kontakt");
    }

    const addNewSalesperson = (e) => {
        e.preventDefault();

        setShowModalAddNewSalesperson(true);
        setTitle("Lägg till en ny säljare");
    }

    const customStyles = {
        control: (base) => ({
            ...base,
            height: "56px",
            width: "852px",
            marginLeft: "-10px",
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            textAlign: "center"
        }),
        menuList: styles => {
            return {
                ...styles,
                maxHeight: 136
            };
        },
        valueContainer: (provided) => ({
            ...provided,
            whiteSpace: "nowrap",
            overflow: "hidden",
            flexWrap: 'nowrap',
        }),
    };

    const customAdaptableStyles = {
        control: (base) => ({
            ...base,
            height: `${theBenefitCostplaceIDs.length * 15}px`,
            width: "852px",
            marginLeft: "-10px",
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            textAlign: "center"
        }),
        menuList: styles => {
            return {
                ...styles,
                maxHeight: 136
            };
        },
        container: (provided) => ({
            ...provided,
            marginBottom: `${theBenefitCostplaceIDs.length * 14}px`
        })
    };

    const customControlStyles = {
        control: (base) => ({
            ...base,
            height: "160px !important",
            width: "852px",
            marginLeft: "-10px",
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            textAlign: "center"
        }),
        menuList: styles => {
            return {
                ...styles,
                maxHeight: 136
            };
        },
    };

    const getGetPersonalcyklarContactTagID = async () => {
        await API.getGetPersonalcyklarContactTagId()
            .then((response) => {
                const getAllPersonalCyklarContacts = async () => {
                    await API.getContact("",response.data._id,"")
                        .then((response) => {
                            let salespersons = [];
                            salespersons.push({ value: "", label: "Välj säljare på Personalcyklar", isDisabled: true });
                            response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((salesperson) => {
                                salespersons.push({ value: salesperson._id, label: salesperson.name });
                            });
                            setPersonalcyklarContactsOptions(salespersons);
                        })
                        .catch((error) => {
                            console.log("Error: ", error);
                        });
                };
                getAllPersonalCyklarContacts();
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    useEffect(() => {

        if (theCompanyID !== "" || theCompanyID !== undefined) {
            const getBenefitCompanyContactTagID = async () => {
                await API.getBenefitCompanyContactTagId()
                    .then(() => {
                        const getAllBenefitCompanyContacts = async () => {
                            await API.getContact("", "", theCompanyID)
                                .then((response) => {
                                    let contacts = [];
                                    contacts.push({
                                        value: "",
                                        label: "Välj kontaktperson/kontaktpersoner",
                                        isDisabled: true
                                    });
                                    response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((contact) => {
                                        if (!contact.salesperson)
                                            contacts.push({value: contact._id, label: contact.name});
                                    });
                                    setBenefitCompanyContactsOptions(contacts);
                                })
                                .catch((error) => {
                                    console.log("Error: ", error);
                                });
                        };
                        getAllBenefitCompanyContacts();
                    })
                    .catch((error) => {
                        console.log("Error: ", error);
                    });
            };
            void getBenefitCompanyContactTagID();
        }
    },[theCompanyID, showModalAddNewContact, showModalAddNewComment, showModalEditComment]);

    useEffect(() => {
        if (theCompanyID !== "") {
            const getCompanyToEdit = async () => {
                handleResetCompanyFields();
                await API.getCompany(theCompanyID)
                    .then((response) => {
                        if (response.data[0].agreement) {
                            setTheAgreement(response.data[0].agreement);
                            loadAgreement(response.data[0].agreement);
                        }
                        setTheApplicationIds(response.data[0].ApplicationIds);
                        loadApplicationOptions(response.data[0].ApplicationIds);
                        setTheOrgNr(response.data[0].org_nr);
                        setTheCompanyName(response.data[0].name);
                        setTheAlternateName(response.data[0].alternate_name);
                        //loadAlternateName(theCompanyID, response.data[0].alternate_name);
                        setTheSalesperson(response.data[0].personalcyklar_salesperson);
                        loadSalesperson(response.data[0].personalcyklar_salesperson);
                        setTheAddress(response.data[0].address);
                        setPublicPortal(response.data[0].general_portal);
                        if(!response.data[0].general_portal){
                            setTheCalculationFactor(response.data[0].calculation_factor);
                            setTheRulesAndConditions([]);
                            setLoadCompanyWindowRules([]);
                            setTheRulesAndConditions(response.data[0].window_rules);
                            setLoadCompanyWindowRules(response.data[0].window_rules);
                            setTheBenefitSubcompanyID(response.data[0].benefit_subcompany_id);
                            setTheBenefitCompanyID(response.data[0].benefit_company_id);
                            setTheBenefitCostplaceIDs(response.data[0].benefit_costplace_id);
                            setTheDeliveryOptions(response.data[0].delivery_options);
                            loadDeliveryOptions(response.data[0].delivery_options);
                            loadUnitOrCostCenters(response.data[0].benefit_costplace_id);
                            setTheOptionalSpellings(response.data[0].optional_spellings);
                            setTheExternalPartners(response.data[0].external_partners);
                            loadExternalPartners(response.data[0].external_partners);
                            setBankIdRequired(response.data[0].bank_id_required);
                            setTheConditions(response.data[0].conditions);
                        }
                        setTheSportsonShops(response.data[0].sportson_shops);
                        loadSportsonShops(response.data[0].sportson_shops);
                        setTheShownInContacts(response.data[0].shown_in_contacts);
                        //setStandardAgreement(response.data[0].standard_agreement);
                        //setSecurityAgreement(response.data[0].security_agreement);
                        setCustomerSpecificAgreement(response.data[0].customer_specific_agreement);
                        setAgreementUploaded(response.data[0].agreement_uploaded)
                        setWeeklyReconciliation(response.data[0].weekly_reconciliation);
                        setOngoingReconciliation(response.data[0].ongoing_reconciliation);
                        setThePortalUsername(response.data[0].portal_username);
                        setThePortalPassword(response.data[0].portal_password);
                        setTheCompanyRepresentative(response.data[0].company_representative);
                        setComments(response.data[0].comments);
                        const getContacts = async () => {
                            await API.getContact("","",theCompanyID)
                                .then((response) => {
                                    loadContacts(response.data);
                                })
                                .catch((error) => {
                                    console.log("Error: ", error);
                                });
                        };
                        getContacts();
                    })
                    .catch((error) => {
                        console.log("Error: ", error);
                    });
            };
            void getCompanyToEdit();
        }
    }, [theCompanyID]);

    useEffect(() => {
        void getGetPersonalcyklarContactTagID();

        const getAllUnitsOrCostPlaces = () => {
            let unitsOrCostPlaces = [];

            unitsOrCostPlaces.push({ value: "", label: "Skriv namnet på respektive bolag", isDisabled: true });

            setUnitOrCostplaceOptions(unitsOrCostPlaces);
        };
        getAllUnitsOrCostPlaces();

        const getAllShopsSportson = async () => {
            await API.getShopList("", "sportson")
                .then((response) => {
                    let shopsSportson = [];
                    shopsSportson.push({ value: "", label: "Välj din butik/dina butiker *", isDisabled: true });
                    response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((shop) => {
                        shopsSportson.push({ value: shop.Code, label: shop.Name });
                    });
                    setShopsSportsonOptions(shopsSportson);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllShopsSportson();

        const getAllShopsExternalPartners = async () => {
            await API.getShopList("", "external")
                .then((response) => {
                    let shopsExternalPartners = [];
                    shopsExternalPartners.push({ value: "", label: "Välj externa partners *", isDisabled: true });
                    response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((shop) => {
                        shopsExternalPartners.push({ value: shop.Code, label: shop.Name });
                    });
                    setShopsExternalPartnersOptions(shopsExternalPartners);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllShopsExternalPartners();

        const getAllCompanies = async () => {
            await API.getCompany("")
                .then((response) => {
                    let companies = [];
                    companies.push({ value: "", label: "Välj bolag *", isDisabled: true });
                    response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((company) => {
                        company.alternate_name ? companies.push({ value: company._id, label: `${company.name} (${company.org_nr}) (${company.alternate_name})` }) : companies.push({ value: company._id, label: `${company.name} (${company.org_nr})` });
                    });
                    setCompaniesOptions(companies);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllCompanies();

        const getDeliveryOptions = async () => {
            await API.getDeliveryOptions()
                .then((response) => {
                    let theDeliveryOptions = [];

                    theDeliveryOptions.push({ value: "", label: "Välj leveransalternativ *", isDisabled: true });
                    response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((deliveryOption) => {
                        theDeliveryOptions.push({ value: deliveryOption._id, label: deliveryOption.name });
                    });
                    setDeliveryOptions(theDeliveryOptions);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getDeliveryOptions();
        const getAllShopsMapList = async () => {
            await API.getShopList("", "")
                .then((response) => {
                    let shops = [];
                    response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((shop) => {
                        shops.push({ value: shop.Code, label: shop.Name });
                    });
                    setShopsMapList(shops);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllShopsMapList();

        const getAllExternalPartnersMapList = async () => {
            await API.getShopList("", "external")
                .then((response) => {
                    let externalPartners = [];
                    response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((externalPartner) => {
                        externalPartners.push({ value: externalPartner.Code, label: externalPartner.Name });
                    });
                    setExternalPartnersMapList(externalPartners);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAllExternalPartnersMapList();

    }, [createCompany, editCompany]);

    useEffect(() => {
        const getApplications = async () => {
            await API.getApplications()
                .then((response) => {
                    let applications = [];
                    applications.push({ value: "", label: "Välj applikationer", isDisabled: true });
                    response.data.sort((a, b) => a.Name.localeCompare(b.Name)).forEach((application) => {
                        applications.push({ value: application.Id, label: `${application.Name} (${application.CountryCode})` });
                    });
                    setApplicationsOptions(applications);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getApplications();

        const getAgreements = async () => {
            await API.getAgreement("")
                .then((response) => {
                    let agreements = [];
                    agreements.push({ value: "", label: "Välj avtal", isDisabled: true });
                    response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((agreement) => {
                        agreements.push({ value: agreement._id, label: agreement.name, upload_file_required: agreement.upload_file_required });
                    });
                    setAgreementOptions(agreements);
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        };
        void getAgreements();
    }, []);

    useEffect(() => {
        void getGetPersonalcyklarContactTagID();
    }, [showModalAddNewSalesperson]);

    useEffect(() => {
        if (theCompanyID && theCompanyID !== "")
            handleGetCompanyComments();
    }, [showModalAddNewComment, showModalEditComment]);

    useEffect(() => {
        if (theCompanyID !== "" || theCompanyID !== undefined) {
            const getBenefitCompanyContactTagID = async () => {
                await API.getBenefitCompanyContactTagId()
                    .then(() => {
                        const getAllBenefitCompanyContacts = async () => {
                            await API.getContact("", "", theCompanyID)
                                .then((response) => {
                                    let contacts = [];
                                    contacts.push({
                                        value: "",
                                        label: "Välj kontaktperson/kontaktpersoner",
                                        isDisabled: true
                                    });
                                    response.data.sort((a, b) => a.name.localeCompare(b.name)).forEach((contact) => {
                                        if (!contact.salesperson)
                                            contacts.push({value: contact._id, label: contact.name});
                                    });
                                    setBenefitCompanyContactsOptions(contacts);
                                })
                                .catch((error) => {
                                    console.log("Error: ", error);
                                });
                        };
                        getAllBenefitCompanyContacts();
                    })
                    .catch((error) => {
                        console.log("Error: ", error);
                    });
            };
            void getBenefitCompanyContactTagID();
        }
    }, [showModalAddNewContact]);

    useEffect(() => {
        if (weeklyReconciliation)
            setOngoingReconciliation(false);
    }, [weeklyReconciliation]);

    useEffect(() => {
        if (ongoingReconciliation)
            setWeeklyReconciliation(false);
    }, [ongoingReconciliation]);

    useEffect(() => {
        setIsDisabledBenefitWindowConditions(publicPortal);
        if(publicPortal) {
            setLoadTheAgreement([(agreementOptions.filter(agreement => agreement.label === "AvtalTrygg - Standardavtalet med Trygghetspaketet"))[0]]);
            handleResetCompanyFieldsOnPublicPortal();
        }
    }, [publicPortal]);

    const handleFileChange = (event) => {
        setSelectedFiles([...selectedFiles, ...event.target.files]);
        setFilesChosen(true);
        setAreFilesUploaded(false);
        event.target.value = "";
    };

    const handleUploadFiles = (e) => {
        const formData = new FormData();

        e.preventDefault();
        if (selectedFiles.length === 1) {
            formData.append('file', selectedFiles[0]);
            if (theCompanyID && theCompanyID[0] !== "") {
                API.uploadAgreement(theCompanyID, formData)
                    .then(function (response) {
                        if (response.status === 201) {
                            setAreFilesUploaded(true);
                            setUploadedFiles(selectedFiles);
                            setTheAgreementRef(response.data);
                            setShowModalConfirmAlert(true);
                            setTitle("Avtalet har laddats upp.");
                        } else if (response.status >= 300) {
                            setShowModalConfirmAlert(true);
                            setTitle(`${response.data.message} - ${response.data.details}`);
                        }
                    })
                    .catch(function (/*error*/) {
                        setShowModalConfirmAlert(true);
                        setTitle("Avtalet kunde ej laddas upp.");
                    })
            } else {
                setShowModalConfirmAlert(true);
                setTitle("Du måste välja ett bolag att koppla avtalet till");
            }
            //setTheFormdata(formData);
        } else {
            setShowModalConfirmAlert(true);
            setTitle('1 avtal måste bifogas. Lägg till dessa under knappen "Bläddra" och ladda upp med knappen "Ladda upp filer".');
        }
    };

    const handleRemoveFiles = () => {
        setSelectedFiles([]);
        setTheAgreementRef([]);
        setFilesChosen(false);
    };

    useEffect(() => {
        setEditCompany(true);
        setTheCompanyID(id);
    }, [id]);

    return (
        <>
            <div className="company">
                <Header title="Bolag" />
                <ModalConfirmAlert
                    showModal={showModalConfirmAlert}
                    closeModalHandler={closeModalConfirmAlertHandler}
                    title={title}
                    buttonName="Stäng"
                />
                <ModalBenefitWindowConditions
                    key={theCompanyID}
                    showModal={showModalBenefitWindowConditions}
                    closeModalHandler={closeModalBenefitWindowConditionsHandler}
                    companyID={theCompanyID}
                    companyWindowRules={loadCompanyWindowRules}
                    setCompanyWindowRules={setLoadCompanyWindowRules}
                    setTheRulesAndConditions={setTheRulesAndConditions}
                    title={title}
                    buttonName="Stäng"
                    isDisabledBenefitWindowConditions={isDisabledBenefitWindowConditions}
                />
                <ModalAddNewContact
                    showModal={showModalAddNewContact}
                    closeModalHandler={closeModalAddNewContactHandler}
                    title={title}
                    companyID={theCompanyID}
                    companyName={theCompanyName}
                    buttonName="Stäng"
                />
                <ModalAddNewSalesperson
                    showModal={showModalAddNewSalesperson}
                    closeModalHandler={closeModalAddNewSalespersonHandler}
                    title={title}
                    companyID={theCompanyID}
                    companyName={theCompanyName}
                    buttonName="Stäng"
                />
                <ModalAddNewCommentCompany
                    showModal={showModalAddNewComment}
                    closeModalHandler={closeModalAddNewCommentHandler}
                    title={title}
                    buttonName="Stäng"
                    companyID={theCompanyID}
                />
                <ModalEditCommentCompany
                    showModal={showModalEditComment}
                    closeModalHandler={closeModalEditCommentHandler}
                    title={title}
                    buttonName="Stäng"
                    companyID={theCompanyID}
                    commentID={theCommentID}
                    commentText={theCommentText}
                />
                <div className="company__inputContainer">
                    { !createCompany && !editCompany && <h1 className="company__header"><button className="company__switchButton">Ändra Bolag</button></h1> }
                    { editCompany && <h1 className="company__header"><button className="company__switchButton">Ändra Bolag</button></h1> }
                    { createCompany && (
                        <>
                            <h6>Applikationer</h6>
                            <Select
                                className="itSupport__select"
                                name="theApplicationOptions"
                                isMulti
                                isClearable
                                options={applicationsOptions}
                                styles={customStyles}
                                onChange={handleOnChangeApplicationOptions}
                                ref={selectApplicationsRef}
                                menuPortalTarget={document.body}
                                defaultValue={[(applicationsOptions.filter(application => application.label === "Sportson (SE)"))[0]]}
                            >
                            </Select>
                            {publicPortal && <TextField
                                label="Org. nr."
                                variant="outlined"
                                name="OrganisationNumber"
                                fullWidth
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setTheOrgNr(e.target.value)}
                                value={theOrgNr}
                                required
                            >
                            </TextField>}
                            {!publicPortal && <TextField
                                label="Org. nr."
                                variant="outlined"
                                name="OrganisationNumber"
                                fullWidth
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setTheOrgNr(e.target.value)}
                                value={theOrgNr}
                                required
                            >
                            </TextField>}
                            <TextField
                                label="Bolagsnamn (måste vara unikt)"
                                variant="outlined"
                                name="CompanyName"
                                fullWidth
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setTheCompanyName(e.target.value)}
                                value={theCompanyName}
                                required
                            >
                            </TextField>
                            <TextField
                                label="Publikt namn (måste vara unikt)"
                                variant="outlined"
                                name="AlternativeName"
                                fullWidth
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setTheAlternateName(e.target.value)}
                                value={theAlternateName}
                            >
                            </TextField>
                            <TextField
                                label="Avsändare till portalens välkomstmeddelande (valfritt)"
                                variant="outlined"
                                name="CompanyRepresentative"
                                fullWidth
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setTheCompanyRepresentative(e.target.value)}
                                value={theCompanyRepresentative}
                            >
                            </TextField>
                            <TextField
                                label="Adress"
                                variant="outlined"
                                name="Address"
                                fullWidth
                                multiline
                                minRows={4}
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setTheAddress(e.target.value)}
                                value={theAddress}
                            >
                            </TextField>
                            <h6>Leveransalternativ</h6>
                            {!publicPortal && <Select
                                className="itSupport__select"
                                name="theDeliveryOptions"
                                isMulti
                                isClearable
                                options={deliveryOptions}
                                styles={customStyles}
                                onChange={handleOnChangeDeliveryOptions}
                                ref={selectDeliveryOptionsRef}
                                menuPortalTarget={document.body}
                                defaultValue={[(deliveryOptions.filter(deliveryOption => deliveryOption.label === "Hämtar i vald butik"))[0]]}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                            >
                            </Select>}
                            {publicPortal && <Select
                                className="itSupport__select"
                                name="theDeliveryOptions"
                                isMulti
                                isClearable
                                options={deliveryOptions}
                                styles={customStyles}
                                onChange={handleOnChangeDeliveryOptions}
                                ref={selectDeliveryOptionsRef}
                                menuPortalTarget={document.body}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                isDisabled
                            >
                            </Select>}
                            {publicPortal && <LockIcon className="formansfonster__disabledField"/>}
                            <TextField
                                label="Hyresfaktor"
                                variant="outlined"
                                name="rentFactor"
                                fullWidth
                                InputProps={{
                                    className: "formansfonster__textFields",
                                }}
                                onChange={(e) => setTheCalculationFactor(e.target.value)}
                                value={theCalculationFactor}
                                hidden
                            >
                            </TextField>
                            {/*
                            <h6>Kontaktpersoner:</h6>
                            <Select
                                className="itSupport__select"
                                name="contacts"
                                isMulti
                                isClearable
                                options={benefitCompanyContactsOptions}
                                styles={customStyles}
                                onChange={handleOnChangeContacts}
                                menuPortalTarget={document.body}
                            >
                            </Select>
                            <button onClick={(e) => addNewContactForANewCompany(e)} className="company__addNewContactButton"><PersonAddIcon /></button>
                            */}
                            <h6>Enheter/kostnadsställen</h6>
                            <CreatableSelect
                                className="itSupport__select"
                                name="unitOrCostCenter"
                                isMulti
                                isClearable
                                options={unitOrCostplaceOptions}
                                styles={customAdaptableStyles}
                                onChange={handleOnChangeUnitOrCostCenters}
                                ref={selectUnitsOrCostplacesRef}
                                menuPortalTarget={document.body}
                                isDisabled={publicPortal}
                            >
                            </CreatableSelect>
                            {publicPortal && <LockIcon className="company__disabledField"/>}
                            <h6>Säljare Personalcyklar *</h6>
                            <Select
                                className="itSupport__select"
                                name="salesperson"
                                options={personalcyklarContactsOptions}
                                styles={customStyles}
                                onChange={handleOnChangeSalesPerson}
                                required
                                menuPortalTarget={document.body}
                                ref={selectSalespersonRef}
                            >
                            </Select>
                            <h6>Berörda Sportsonbutiker (företaget finns på följande orter)</h6>
                            <Select
                                className="itSupport__select company__theShops"
                                name="theShops"
                                isMulti
                                isClearable
                                options={shopsSportsonOptions}
                                styles={customControlStyles}
                                onChange={handleOnChangeSportsonShops}
                                ref={selectShopsRef}
                                menuPortalTarget={document.body}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                            >
                            </Select>
                            <h6>Externa partners</h6>
                            <Select
                                className="itSupport__select company__theExternalPartners"
                                name="theExternalPartners"
                                onChange={handleOnChangeExternalPartners}
                                isMulti
                                isClearable
                                options={shopsExternalPartnersOptions}
                                styles={customControlStyles}
                                ref={selectExternalPartnersRef}
                                menuPortalTarget={document.body}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                isDisabled={publicPortal}
                            >
                            </Select>
                            {publicPortal && <LockIcon className="company__disabledField"/>}
                            <div className="company__flexCenter">
                                <div>
                                    <input onClick={() => setPublicPortal(!publicPortal)} type="checkbox" id="publicPortal" name="publicPortal" checked={publicPortal}/>
                                    <p className="company__checkboxText company__warningText" >Gemensamma portalen<Tooltip title={"Gemensamma portalen får endast användas för bolag med max 1-2 anställda"} arrow><WarningIcon className="company__commentIcon"/></Tooltip></p>
                                </div>
                                <div>
                                    <input onClick={() => setBankIdRequired(!bankIdRequired)} type="checkbox" id="bankID" name="bankID" checked={bankIdRequired} disabled={publicPortal}/>
                                    <p className="company__checkboxText" >Förmånstagaren signerar med BankID</p>
                                    {publicPortal && <LockIcon className="company__disabledFieldCheckboxWithoutTooltip" />}
                                </div>
                                <div>
                                    <input onClick={() => setWeeklyReconciliation(!weeklyReconciliation)} type="checkbox" id="WeeklyReconciliation" name="WeeklyReconciliation" checked={weeklyReconciliation} />
                                    <p className="company__checkboxText" >Veckovis avstämning</p>
                                </div>
                                <div>
                                    <input onClick={() => setOngoingReconciliation(!ongoingReconciliation)} type="checkbox" id="OngoingReconciliation" name="OngoingReconciliation" checked={ongoingReconciliation} />
                                    <p className="company__checkboxText" >Avvikande avstämning</p>
                                </div>
                                {/*<div>
                                    <input onClick={() => setStandardAgreement(!standardAgreement)} type="checkbox" id="standardAgreement" name="standardAgreement" checked={standardAgreement} />
                                    <p className="company__checkboxText" >Standard UH</p>
                                </div>*/}
                                {/*<div>
                                    <input onClick={() => setCustomerSpecificAgreement(!customerSpecificAgreement)} type="checkbox" id="customerSpecificAgreement" name="customerSpecificAgreement" checked={customerSpecificAgreement} />
                                    <p className="company__checkboxText" >Kundspecifikt UH</p>
                                </div>*/}
                            </div>
                            <h6>UH-avtal *</h6>
                            {loadTheAgreement.length > 0 && <> <Select
                                className="itSupport__select"
                                name="agreement"
                                options={agreementOptions}
                                styles={customStyles}
                                onChange={handleOnChangeAgreement}
                                required
                                menuPortalTarget={document.body}
                                ref={selectAgreementRef}
                                isClearable
                                isDisabled={publicPortal}
                                defaultValue={publicPortal ? [(agreementOptions.filter(agreement => agreement.label === "AvtalTrygg - Standardavtalet med Trygghetspaketet"))[0]] : loadTheAgreement}
                            >
                            </Select> {publicPortal && <LockIcon className="company__disabledField"/>} </>}
                            {loadTheAgreement.length === 0 && <> <Select
                                className="itSupport__select"
                                name="agreement"
                                options={agreementOptions}
                                styles={customStyles}
                                onChange={handleOnChangeAgreement}
                                required
                                menuPortalTarget={document.body}
                                ref={selectAgreementRef}
                                isClearable
                                isDisabled={publicPortal}
                                defaultValue={publicPortal ? [(agreementOptions.filter(agreement => agreement.label === "AvtalTrygg - Standardavtalet med Trygghetspaketet"))[0]] : []}
                            >
                            </Select> {publicPortal && <LockIcon className="company__disabledField"/>} </>}
                            <div className="company__securityAgreementDiv">
                                { customerSpecificAgreement && <div className="company__fileUploadDiv">
                                    <legend className="company__fileUploadDivLegend">Ladda upp kundspecifikt UH-avtal</legend>
                                    <div className="company__fileUploadButtonsDiv">
                                        <input className="company__fileInputButton" type="file" multiple onChange={handleFileChange} />
                                        {filesChosen && <button className="company__fileUploadButtons" onClick={handleRemoveFiles}>Ta bort avtal</button>}
                                    </div>
                                    {filesChosen && !areFilesUploaded && (
                                        <div>
                                            <>
                                                <h6>Följande avtal är valda för uppladdning:</h6>
                                                {selectedFiles.map((file, index) => (
                                                    <p key={index}>{file.name}</p>
                                                ))}
                                                <h6>Ta bort valda avtal för uppladdning med </h6>
                                                <h6>knappen "Ta bort avtal".</h6>
                                            </>
                                        </div>
                                    )}
                                    {!filesChosen && !areFilesUploaded && (
                                        <>
                                            <p>Välj avtal på knappen till vänster.</p>
                                        </>
                                    )}
                                </div>}
                            </div>
                            {/*
                            {!publicPortal && <TextField
                                label="B2E Site"
                                variant="outlined"
                                name="bTwoEsite"
                                fullWidth
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setTheBenefitCompanyID(e.target.value)}
                                value={theBenefitCompanyID}
                                disabled
                            >
                            </TextField>}
                            <TextField
                                label="Portalanvändarnamn"
                                variant="outlined"
                                name="PortalUsername"
                                fullWidth
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setThePortalUsername(e.target.value)}
                                value={thePortalUsername}
                                disabled
                            >
                            </TextField>
                            <TextField
                                label="Portallösenord"
                                variant="outlined"
                                name="PortalPassword"
                                fullWidth
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setThePortalPassword(e.target.value)}
                                value={thePortalPassword}
                                disabled
                            >
                            </TextField>
                            */}
                            <TextField
                                label="Subvention"
                                variant="outlined"
                                name="Conditions"
                                fullWidth
                                multiline
                                minRows={2}
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setTheConditions(e.target.value)}
                                value={theConditions}
                                disabled={publicPortal}
                            >
                            </TextField>
                            {publicPortal && <LockIcon className="company__disabledField"/>}
                            <button className="company__conditionsButton company__conditionsButtonAlignment" onClick={(e) => handleBenefitWindowConditions(e)} disabled={publicPortal}>Begränsningar {theRulesAndConditions.length > 0 || loadCompanyWindowRules.length > 0 ? <DoneIcon className="company__iconInButton" /> : ""}</button>
                            {publicPortal && <div className="company__disabledButton"><LockIcon className="company__lockIcon"/></div>}
                            <button className="company__confirmButton" onClick={(e) => handleCreateCompany(e)}>Skapa bolag</button>
                        </>
                    )}
                    { editCompany && (
                        <>
                            <h6>Applikationer</h6>
                            {loadTheApplicationIds.length > 0 && <Select
                                className="itSupport__select"
                                name="theApplicationOptions"
                                isMulti
                                isClearable
                                options={applicationsOptions}
                                styles={customStyles}
                                onChange={handleOnChangeApplicationOptions}
                                ref={selectApplicationsRef}
                                menuPortalTarget={document.body}
                                defaultValue={loadTheApplicationIds}
                            >
                            </Select>}
                            {loadTheApplicationIds.length === 0 && <Select
                                className="itSupport__select"
                                name="theApplicationOptions"
                                isMulti
                                isClearable
                                options={applicationsOptions}
                                styles={customStyles}
                                onChange={handleOnChangeApplicationOptions}
                                ref={selectApplicationsRef}
                                menuPortalTarget={document.body}
                            >
                            </Select>}
                            {publicPortal && <TextField
                                label="Org. nr."
                                variant="outlined"
                                name="OrganisationNumber"
                                fullWidth
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setTheOrgNr(e.target.value)}
                                value={theOrgNr}
                                required
                            >
                            </TextField>}
                            {!publicPortal && <TextField
                                label="Org. nr."
                                variant="outlined"
                                name="OrganisationNumber"
                                fullWidth
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setTheOrgNr(e.target.value)}
                                value={theOrgNr}
                                required
                            >
                            </TextField>}
                            <TextField
                                label="Bolagsnamn (måste vara unikt)"
                                variant="outlined"
                                name="CompanyName"
                                fullWidth
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setTheCompanyName(e.target.value)}
                                value={theCompanyName}
                                required
                            >
                            </TextField>
                            <TextField
                                label="Publikt namn (måste vara unikt)"
                                variant="outlined"
                                name="AlternativeName"
                                fullWidth
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setTheAlternateName(e.target.value)}
                                value={theAlternateName}
                            >
                            </TextField>
                            <TextField
                                label="Avsändare till portalens välkomstmeddelande (valfritt)"
                                variant="outlined"
                                name="CompanyRepresentative"
                                fullWidth
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setTheCompanyRepresentative(e.target.value)}
                                value={theCompanyRepresentative}
                            >
                            </TextField>
                            <TextField
                                label="Adress"
                                variant="outlined"
                                name="Address"
                                fullWidth
                                multiline
                                minRows={4}
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setTheAddress(e.target.value)}
                                value={theAddress}
                            >
                            </TextField>
                            <h6>Leveransalternativ</h6>
                            {loadTheDeliveryOptions.length > 0 && <>
                                <Select
                                    className="itSupport__select"
                                    name="theDeliveryOptions"
                                    isMulti
                                    isClearable
                                    options={deliveryOptions}
                                    styles={customStyles}
                                    onChange={handleOnChangeDeliveryOptions}
                                    ref={selectDeliveryOptionsRef}
                                    defaultValue={loadTheDeliveryOptions}
                                    menuPortalTarget={document.body}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    isDisabled={publicPortal}
                                >
                                </Select> {publicPortal && <LockIcon className="formansfonster__disabledField"/>} </>}
                            {loadTheDeliveryOptions.length === 0 && <>
                                <Select
                                    className="itSupport__select"
                                    name="theDeliveryOptions"
                                    isMulti
                                    isClearable
                                    options={deliveryOptions}
                                    styles={customStyles}
                                    onChange={handleOnChangeDeliveryOptions}
                                    ref={selectDeliveryOptionsRef}
                                    menuPortalTarget={document.body}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    isDisabled={publicPortal}
                                >
                                </Select> {publicPortal && <LockIcon className="formansfonster__disabledField"/>} </>}
                            <TextField
                                label="Hyresfaktor"
                                variant="outlined"
                                name="rentFactor"
                                fullWidth
                                InputProps={{
                                    className: "formansfonster__textFields",
                                }}
                                onChange={(e) => setTheCalculationFactor(e.target.value)}
                                value={theCalculationFactor}
                                hidden
                            >
                            </TextField>
                            <h6>Enheter/kostnadsställen</h6>
                            {loadTheUnitOrCostCenters.length > 0 && <CreatableSelect
                                className="itSupport__select"
                                name="unitOrCostCenter"
                                isMulti
                                isClearable
                                options={unitOrCostplaceOptions}
                                styles={customAdaptableStyles}
                                onChange={handleOnChangeUnitOrCostCenters}
                                defaultValue={loadTheUnitOrCostCenters}
                                ref={selectUnitsOrCostplacesRef}
                                menuPortalTarget={document.body}
                                isDisabled={publicPortal}
                            >
                            </CreatableSelect>}
                            {loadTheUnitOrCostCenters.length === 0 && <CreatableSelect
                                className="itSupport__select"
                                name="unitOrCostCenter"
                                isMulti
                                isClearable
                                options={unitOrCostplaceOptions}
                                styles={customAdaptableStyles}
                                onChange={handleOnChangeUnitOrCostCenters}
                                ref={selectUnitsOrCostplacesRef}
                                menuPortalTarget={document.body}
                                isDisabled={publicPortal}
                            >
                            </CreatableSelect>}
                            {publicPortal && <LockIcon className="company__disabledField"/>}
                            <div className="company__flexCenter">
                                <h6>Kontaktpersoner</h6>
                                <button onClick={(e) => addNewContact(e)} className="company__addNewContactButton"><PersonAddIcon /></button>
                            </div>
                            { loadTheContacts.length > 0 && <Select
                                className="itSupport__select"
                                name="contacts"
                                isMulti
                                isClearable
                                options={benefitCompanyContactsOptions}
                                styles={customStyles}
                                onChange={handleOnChangeContacts}
                                defaultValue={loadTheContacts}
                                menuPortalTarget={document.body}
                                ref={selectContactsRef}
                            >
                            </Select> }
                            { loadTheContacts.length === 0 && <Select
                                className="itSupport__select"
                                name="contacts"
                                isMulti
                                isClearable
                                options={benefitCompanyContactsOptions}
                                styles={customStyles}
                                onChange={handleOnChangeContacts}
                                menuPortalTarget={document.body}
                                ref={selectContactsRef}
                            >
                            </Select> }
                            <div className="company__flexCenter">
                                <h6>Säljare Personalcyklar *</h6>
                                <button onClick={(e) => addNewSalesperson(e)} className="company__addNewContactButton"><PersonAddIcon /></button>
                            </div>
                            {loadTheSalesperson.length > 0 && <Select
                                className="itSupport__select"
                                name="salesperson"
                                options={personalcyklarContactsOptions}
                                styles={customStyles}
                                onChange={handleOnChangeSalesPerson}
                                required
                                defaultValue={loadTheSalesperson}
                                menuPortalTarget={document.body}
                                ref={selectSalespersonRef}
                            >
                            </Select>}
                            {loadTheSalesperson.length === 0 && <Select
                                className="itSupport__select"
                                name="salesperson"
                                options={personalcyklarContactsOptions}
                                styles={customStyles}
                                onChange={handleOnChangeSalesPerson}
                                required
                                menuPortalTarget={document.body}
                                ref={selectSalespersonRef}
                            >
                            </Select>}
                            <h6>Berörda Sportsonbutiker (företaget finns på följande orter)</h6>
                            {loadTheSportsonShops.length > 0 && <Select
                                className="itSupport__select company__theShops"
                                name="theShops"
                                onChange={handleOnChangeSportsonShops}
                                isMulti
                                isClearable
                                options={shopsSportsonOptions}
                                styles={customControlStyles}
                                defaultValue={loadTheSportsonShops}
                                ref={selectShopsRef}
                                menuPortalTarget={document.body}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                            >
                            </Select>}
                            {loadTheSportsonShops.length === 0 && <Select
                                className="itSupport__select company__theShops"
                                name="theShops"
                                onChange={handleOnChangeSportsonShops}
                                isMulti
                                isClearable
                                options={shopsSportsonOptions}
                                styles={customControlStyles}
                                ref={selectShopsRef}
                                menuPortalTarget={document.body}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                            >
                            </Select>}
                            <h6>Externa partners</h6>
                            { loadTheExternalPartners.length > 0 && <Select
                                className="itSupport__select company__theExternalPartners"
                                name="theExternalPartners"
                                onChange={handleOnChangeExternalPartners}
                                isMulti
                                isClearable
                                options={shopsExternalPartnersOptions}
                                styles={customControlStyles}
                                defaultValue={loadTheExternalPartners}
                                ref={selectExternalPartnersRef}
                                menuPortalTarget={document.body}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                isDisabled={publicPortal}
                            >
                            </Select> }
                            { loadTheExternalPartners.length === 0 && <Select
                                className="itSupport__select company__theExternalPartners"
                                name="theExternalPartners"
                                onChange={handleOnChangeExternalPartners}
                                isMulti
                                isClearable
                                options={shopsExternalPartnersOptions}
                                styles={customControlStyles}
                                ref={selectExternalPartnersRef}
                                menuPortalTarget={document.body}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                isDisabled={publicPortal}
                            >
                            </Select> }
                            {publicPortal && <LockIcon className="company__disabledField"/>}
                            <div className="company__flexCenter">
                                <div>
                                    <input onClick={() => setPublicPortal(!publicPortal)} type="checkbox" id="publicPortal" name="publicPortal" checked={publicPortal}/>
                                    <p className="company__checkboxText company__warningText" >Gemensamma portalen<Tooltip title={"Gemensamma portalen får endast användas för bolag med max 1-2 anställda"} arrow><WarningIcon className="company__commentIcon"/></Tooltip></p>
                                </div>
                                <div>
                                    <input onClick={() => setBankIdRequired(!bankIdRequired)} type="checkbox" id="bankID" name="bankID" checked={bankIdRequired} disabled={publicPortal}/>
                                    <p className="company__checkboxText" >Förmånstagaren signerar med BankID</p>
                                    {publicPortal && <LockIcon className="company__disabledFieldCheckboxWithoutTooltip" />}
                                </div>
                                <div>
                                    <input onClick={() => setWeeklyReconciliation(!weeklyReconciliation)} type="checkbox" id="WeeklyReconciliation" name="WeeklyReconciliation" checked={weeklyReconciliation} />
                                    <p className="company__checkboxText" >Veckovis avstämning</p>
                                </div>
                                <div>
                                    <input onClick={() => setOngoingReconciliation(!ongoingReconciliation)} type="checkbox" id="OngoingReconciliation" name="OngoingReconciliation" checked={ongoingReconciliation} />
                                    <p className="company__checkboxText" >Avvikande avstämning</p>
                                </div>
                                {/*<div>
                                    <input onClick={() => setStandardAgreement(!standardAgreement)} type="checkbox" id="standardAgreement" name="standardAgreement" checked={standardAgreement}/>
                                    <p className="company__checkboxText" >Standard UH</p>
                                </div>*/}
                                {/*<div>
                                    <input onClick={() => setCustomerSpecificAgreement(!customerSpecificAgreement)} type="checkbox" id="customerSpecificAgreement" name="customerSpecificAgreement" checked={customerSpecificAgreement} />
                                    <p className="company__checkboxText" >Kundspecifikt UH</p>
                                </div>*/}
                            </div>
                            <h6>UH-avtal *</h6>
                            {loadTheAgreement.length > 0 && <> <Select
                                className="itSupport__select"
                                name="agreement"
                                options={agreementOptions}
                                styles={customStyles}
                                onChange={handleOnChangeAgreement}
                                required
                                menuPortalTarget={document.body}
                                ref={selectAgreementRef}
                                isClearable
                                isDisabled={publicPortal}
                                defaultValue={publicPortal ? [(agreementOptions.filter(agreement => agreement.label === "AvtalTrygg - Standardavtalet med Trygghetspaketet"))[0]] : loadTheAgreement}
                            >
                            </Select> {publicPortal && <LockIcon className="company__disabledField"/>} </>}
                            {loadTheAgreement.length === 0 && <> <Select
                                className="itSupport__select"
                                name="agreement"
                                options={agreementOptions}
                                styles={customStyles}
                                onChange={handleOnChangeAgreement}
                                required
                                menuPortalTarget={document.body}
                                ref={selectAgreementRef}
                                isClearable
                                isDisabled={publicPortal}
                                defaultValue={publicPortal ? [(agreementOptions.filter(agreement => agreement.label === "AvtalTrygg - Standardavtalet med Trygghetspaketet"))[0]] : []}
                            >
                            </Select> {publicPortal && <LockIcon className="company__disabledField"/>} </>}
                            <div className="company__securityAgreementDiv">
                                { customerSpecificAgreement && <div className="company__fileUploadDiv">
                                    <legend className="company__fileUploadDivLegend">Ladda upp kundspecifikt
                                        UH-avtal
                                    </legend>
                                    <div className="company__fileUploadButtonsDiv">
                                        <input className="company__fileInputButton" type="file" multiple
                                               onChange={handleFileChange}/>
                                        <button className="company__fileUploadButtons"
                                                onClick={(e) => handleUploadFiles(e)}>Ladda upp avtal
                                        </button>
                                        {filesChosen && <button className="company__fileUploadButtons"
                                                                onClick={handleRemoveFiles}>Ta bort avtal</button>}
                                    </div>
                                    {agreementUploaded && (
                                        <div className="company__centerText">
                                            <>
                                                <h6><b>Ett avtal är redan uppladdat.</b></h6>
                                            </>
                                        </div>
                                    )}
                                    {filesChosen && !areFilesUploaded && (
                                        <div className="company__centerText">
                                            <>
                                                <h6>Följande avtal är valda:</h6>
                                                {selectedFiles.map((file, index) => (
                                                    <p key={index}>{file.name}</p>
                                                ))}
                                                <h6>Ladda upp avtal med </h6>
                                                <h6>knappen "Ladda upp avtal".</h6>
                                                <h6>Alternativt ta bort avtal med </h6>
                                                <h6>knappen "Ta bort avtal".</h6>
                                            </>
                                        </div>
                                    )}
                                    {areFilesUploaded && (
                                        <div className="company__centerText">
                                            <>
                                                <p>Följande avtal har laddats upp:</p>
                                                {uploadedFiles.map((file, index) => (
                                                    <p key={index}>{file.name}</p>
                                                ))}
                                            </>
                                        </div>
                                    )}
                                    {!filesChosen && !areFilesUploaded && (
                                        <>
                                            <p>Välj avtal på knappen till vänster</p>
                                            <p>och ladda upp avtal med knappen "Ladda upp avtal".</p>
                                        </>
                                    )}
                                </div>}
                            </div>
                            <TextField
                                label="Subvention"
                                variant="outlined"
                                name="Conditions"
                                fullWidth
                                multiline
                                minRows={2}
                                InputProps={{
                                    className: "company__textFields",
                                }}
                                onChange={(e) => setTheConditions(e.target.value)}
                                value={theConditions}
                                disabled={publicPortal}
                            >
                            </TextField>
                            {publicPortal && <LockIcon className="company__disabledField"/>}
                            <div className="company__portalDiv">
                                <label>Fylls i av Bike Action</label>
                                {!publicPortal && <TextField
                                    label="B2E Site"
                                    variant="outlined"
                                    name="bTwoEsite"
                                    fullWidth
                                    InputProps={{
                                        className: "company__textFields",
                                    }}
                                    onChange={(e) => setTheBenefitCompanyID(e.target.value)}
                                    value={theBenefitCompanyID}
                                >
                                </TextField>}
                                <TextField
                                    label="Portalanvändarnamn"
                                    variant="outlined"
                                    name="PortalUsername"
                                    fullWidth
                                    InputProps={{
                                        className: "company__textFields",
                                    }}
                                    onChange={(e) => setThePortalUsername(e.target.value)}
                                    value={thePortalUsername}
                                >
                                </TextField>
                                <TextField
                                    label="Portallösenord"
                                    variant="outlined"
                                    name="PortalPassword"
                                    fullWidth
                                    InputProps={{
                                        className: "company__textFields",
                                    }}
                                    onChange={(e) => setThePortalPassword(e.target.value)}
                                    value={thePortalPassword}
                                >
                                </TextField>
                            </div>
                            <button className="company__conditionsButton company__conditionsButtonAlignment" onClick={(e) => handleBenefitWindowConditions(e)} disabled={publicPortal}>Begränsningar {theRulesAndConditions.length > 0 || loadCompanyWindowRules.length > 0 ? <DoneIcon className="company__iconInButton" /> : ""}</button>
                            {publicPortal && <div className="company__disabledButton"><LockIcon className="company__lockIcon"/></div>}
                            <div className="company__sportsonComments">
                                <h5>Kommentarer <ChatBubbleIcon className="company__commentIcon" /></h5>{expandedComments ? (<button className="company__expandButton" onClick={() => setExpandedComments(false)}><ExpandLessIcon /></button>) : (<button className="company__expandButton" onClick={() => setExpandedComments(true)}><ExpandMoreIcon /></button>)}
                            </div>
                            { expandedComments && <div className="company__comments">
                                { comments && comments.sort((a, b) => {return new Date(a.DateCreated) - new Date(b.DateCreated)}).map((comment, index) => (
                                    (!comment.Deleted && <div key={index} className="company__sportsonComment">
                                        <CommentCompanyOrWindow comment={comment} />
                                        <button className="company__commentButton" value={comment.CommentText} onClick={(e) => editCommentHandler(e,theCompanyID, comment._id, comment.CommentText)}><EditIcon /></button>
                                        <button className="company__commentButton" onClick={(e) => deleteCommentHandler(e,theCompanyID, comment._id)}><DeleteIcon /></button>
                                    </div>)
                                ))}
                                <button onClick={(e) => addNewCommentHandler(e, theCompanyID)} className="company__editButton"><span className="company__iconAndText"><AddCommentIcon className="company__icons" /> Lägg till kommentar</span></button>
                            </div>}
                            <button className="company__confirmButton" onClick={(e) => handleUpdateCompany(e)}>Spara</button>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default Company;